/* eslint-disable react-hooks/exhaustive-deps */

import GAME_CONFIG from "game-files/gameConfig";
import { Position } from "game-engine/types";
import { Rect } from "react-konva";
import useGame from "game-engine/hooks/useGame";

const GuiBlocker = (props: {
  isActive: boolean;
  unskippable?: boolean;
  onClick: (clickedPosition: Position) => void;
  forceIsActive?: boolean;
}) => {
  const { engineConfig, logger, gameFns } = useGame();

  const isActive = props.isActive || props.forceIsActive;

  const onClick = (e) => {
    if (props.unskippable || props.forceIsActive) {
      logger.info(`gui blocker onClick disabled`, props);
      return;
    }

    const position = gameFns.getClickedPosition(e);

    logger.info(`gui blocker clicked [${position.x}, ${position.y}]`, props);

    if (props.onClick) {
      props.onClick(position);
    }
  };

  return isActive ? (
    <Rect
      onClick={onClick}
      onTap={onClick}
      x={0}
      y={0}
      width={GAME_CONFIG.window.canvasDimensions.x}
      height={GAME_CONFIG.window.canvasDimensions.y}
      fill={props.unskippable ? "#f005" : "#0f03"}
      opacity={engineConfig.state.visibleGuiBlocker && isActive ? 1 : 0}
    />
  ) : null;
};

export default GuiBlocker;
