import Glowcap from "./Glowcap";
import { ItemConfigId } from "game-files/common/ids";
import { ItemConfigType } from "game-engine/types";
import LanternCelestialFlame from "./LanternCelestialFlame";
import LanternEmpty from "./LanternEmpty";
import Test_Flask from "./_test/Test_Flask";
import Test_FlaskToxic from "./_test/Test_FlaskToxic";
import Test_FlaskWater from "./_test/Test_FlaskWater";
import Test_Key from "./_test/Test_Key";
import TesterHat from "./TesterHat";

const ITEM_CONFIGS: { [key in ItemConfigId]: ItemConfigType } = {
  Test_Key,
  Test_Flask,
  Test_FlaskWater,
  Test_FlaskToxic,
  LanternEmpty,
  LanternCelestialFlame,
  Glowcap,
  TesterHat,
};

export default ITEM_CONFIGS;

export const getItemConfigById = (itemConfigId: ItemConfigId) => {
  return ITEM_CONFIGS[itemConfigId];
};
