export * from "./character";
export * from "./gameConfig";
export * from "./font";
export * from "./gamePlay";
export * from "./gameSettings";
export * from "./scene";
export * from "./actions";
export * from "./item";
export * from "./gameItems";
export * from "./gameEffects";
export * from "./cursor";
export * from "./audio";
export * from "./logger";
export * from "./animation";
export * from "./cinematic";
export * from "./skill";
export * from "./video";
export * from "./sprite";
export * from "./image";
export * from "./imageComposition";
export * from "./text";

export enum Language {
  en = "en",
  cz = "cz",
}

export type TranslatedString = {
  en: string;
  cz: string;
};

export type Dimensions = {
  x: number;
  y: number;
};

export type Position = {
  x: number;
  y: number;
};

export enum TextAlign {
  left = "left",
  center = "center",
  right = "right",
}

export enum TextVerticalAlign {
  top = "top",
  bottom = "bottom",
  center = "center",
}

export enum Direction {
  left = "left",
  right = "right",
  up = "up",
  down = "down",
}

export type ColorRGBA = { r: number; g: number; b: number; a: number };

export type ColorRGBAArray = [number, number, number, number];

export type ColorHex = string;
