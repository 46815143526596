/* eslint-disable react-hooks/exhaustive-deps */

import {
  CharacterConfigType,
  CharacterRenderType,
  ImageFilters,
  ImageType,
} from "game-engine/types";
import { getCharacterDimensions, getImageOffset } from "game-engine/utils";

import GAME_CONFIG from "game-files/gameConfig";
import { Group } from "react-konva";
import SpriteAnimated from "game-engine/components/basic-elements/SpriteAnimated";
import useGame from "game-engine/hooks/useGame";

const CharacterImageDefaultNoHead = (props: {
  config: CharacterConfigType;
  imageFilters: ImageFilters;
  characterRender: CharacterRenderType;
  isActive?: boolean;
  onImagesLoaded: () => void;
}) => {
  const { config, imageFilters, characterRender, isActive } = props;
  const { engineConfig, logger } = useGame();

  const image: ImageType = characterRender.defaultNoHead?.image;

  const { width, height } = getCharacterDimensions(characterRender);

  if (!image?.src) {
    logger.error(
      `default-no-head character image missing (${config.id})`,
      config
    );
    return null;
  }

  const onImageLoaded = () => {
    props.onImagesLoaded();
    logger.graphics(`${config?.id} default-no-head image loaded`, config);
  };

  const position = getImageOffset({ width, height });

  const sizeDifference = {
    x: width - characterRender.defaultNoHead.width,
    y: height - characterRender.defaultNoHead.height,
  };

  position.x = position.x + Math.floor(sizeDifference.x / 2);
  position.y = position.y + Math.floor(sizeDifference.y);

  //
  // RENDER
  //
  return (
    <Group opacity={isActive ? 1 : 0} listening={isActive} position={position}>
      <Group
        x={characterRender.defaultNoHead.offsetX || 0}
        y={characterRender.defaultNoHead.offsetY || 0}
      >
        <SpriteAnimated
          src={image.src}
          spriteConfig={
            characterRender.defaultNoHead.spriteConfig ?? {
              ...GAME_CONFIG.sprites.defaultSpriteConfig,
              frameWidth: characterRender.defaultNoHead.width,
              frameHeight: characterRender.defaultNoHead.height,
            }
          }
          loggerString={
            characterRender.defaultNoHead.spriteConfig
              ? `character default-no-head animation (${config.id})`
              : undefined
          }
          controlledAnimation={{
            playAnimation: isActive,
          }}
          renderOutline={engineConfig.state.renderCharacterOutline}
          renderFill={engineConfig.state.renderCharacterFill}
          outlineColor={engineConfig.state.characterOutlineColor}
          fillColor={engineConfig.state.characterFillColor}
          onImageLoaded={onImageLoaded}
          filters={imageFilters}
          isHidden={!isActive}
        />
      </Group>
    </Group>
  );
};

export default CharacterImageDefaultNoHead;
