import {
  CharacterRenderType,
  CharacterWalkPath,
  CursorRenderType,
  Direction,
  Position,
  SceneWalkAreaBaseType,
  SceneWalkPathAnyType,
  SceneWalkPathBlockedType,
  SceneWalkPathHorizontalType,
  SceneWalkPathVerticalType,
  SceneWalkPathWalkableType,
  SceneWalkPathsType,
  WalkConfigType,
} from "game-engine/types";
import { getDirection, getOppositeDirection } from "./distance";

import { EngineConfigType } from "game-engine/configs/engine-config";
import GAME_CONFIG from "game-files/gameConfig";
import { getLinePath } from "./path";

export const getLineWalkPath: (props: {
  walkConfig: WalkConfigType;
  startPosition?: Position;
  endPosition?: Position;
  direction: Direction;
  distance: number;
  getPixelDepth: (p: Position) => number;
  engineConfig: EngineConfigType;
}) => CharacterWalkPath = (props) => {
  return convertPathToWalk({
    path: getLinePath(props),
    getPixelDepth: props.getPixelDepth,
    engineConfig: props.engineConfig,
  });
};

//
// GET WALK MATRIX COMPRESSION PARAMS
//
export const getWalkCompressionParams = (props: {
  walkConfig: WalkConfigType;
}) => {
  const everyNthHorizontal = Math.round(
    props.walkConfig.horizontal.pixelsWalked /
      props.walkConfig.horizontal.frameCount
  );
  const everyNthVertical = Math.round(
    props.walkConfig.vertical.pixelsWalked /
      props.walkConfig.vertical.frameCount
  );

  return { everyNthHorizontal, everyNthVertical };
};

//
// CONVERT PATH TO WALK POINTS (INCLUDES FACING AND DEPTH SCALING INFO)
//
export const convertPathToWalk: (props: {
  path: Position[];
  getPixelDepth: (p: Position) => number;
  engineConfig: EngineConfigType;
}) => CharacterWalkPath = ({ path, getPixelDepth, engineConfig }) => {
  const characterPath: CharacterWalkPath = [];

  if (path.length === 1) {
    return [
      {
        position: path[0],
        scale: engineConfig.ignoreDepthMap ? 1 : getPixelDepth(path[0]),
        facing: Direction.left,
      },
    ];
  }

  let prevFacing;

  const getFacing = (i) => {
    const from = path[i - 1];
    const to = path[i];
    return getDirection(from, to);
  };
  const getNextFacing = (i) => {
    const from = path[i];
    const to = path[i + 1];
    return getDirection(from, to);
  };
  path.forEach((position, i) => {
    let facing;

    if (i === 0) {
      // first point
      facing = getNextFacing(i);
    } else if (i < path.length - 1) {
      // points in the middle -> eliminate jittering -> if previous and next facing are the same, keep it for this point as well
      // e.g. '1 0 1' -> '1 1 1'
      const nextFacing = getNextFacing(i);
      if (nextFacing === prevFacing) {
        facing = nextFacing;
      } else {
        facing = getFacing(i);
      }
    } else {
      // last point
      //facing = getFacing(i);
      facing = getDirection(path[0], path[path.length - 1]); // facing based on general direction (from start to end point)
    }

    if (facing) {
      prevFacing = facing;
    }
    if (!facing) {
      facing = prevFacing;
    }

    characterPath.push({
      position,
      scale: engineConfig.ignoreDepthMap ? 1 : getPixelDepth(position),
      facing,
    });
  });

  return characterPath;
};

//
// GET WALK-IN SCENE POSITION
//
export const getSceneWalkInPosition = (props: {
  fromDirection: Direction;
  walkInData: SceneWalkPathWalkableType;
}) => {
  const { fromDirection } = props;

  const direction = getOppositeDirection(fromDirection);

  let walkInData;

  switch (direction) {
    case Direction.left:
      walkInData = props.walkInData as SceneWalkPathHorizontalType;
      return walkInData.walkToEnd ?? { x: 0, y: walkInData.edgeWalkY };

    case Direction.right:
      walkInData = props.walkInData as SceneWalkPathHorizontalType;
      return (
        walkInData.walkToEnd ?? {
          x: GAME_CONFIG.scene.dimensions.x - 1,
          y: walkInData.edgeWalkY,
        }
      );

    case Direction.up:
      walkInData = props.walkInData as SceneWalkPathVerticalType;
      return (
        walkInData.walkToEnd ?? {
          x: walkInData.edgeWalkX,
          y: 0,
        }
      );

    case Direction.down:
      walkInData = props.walkInData as SceneWalkPathVerticalType;
      return (
        walkInData.walkToEnd ?? {
          x: walkInData.edgeWalkX,
          y: GAME_CONFIG.scene.dimensions.y - 1,
        }
      );
  }
};

//
// GET WALK-IN SCENE PATH
//
export const getOutOfSceneWalkInPath = (props: {
  walkConfig: WalkConfigType;
  fromDirection: Direction;
  walkInData: SceneWalkPathWalkableType;
  engineConfig: EngineConfigType;
  getPixelDepth: (p: Position) => number;
}) => {
  const { walkConfig, fromDirection, engineConfig, walkInData, getPixelDepth } =
    props;

  const walkLine = getLineWalkPath({
    walkConfig,
    endPosition: getSceneWalkInPosition({
      fromDirection,
      walkInData,
    }),
    direction: fromDirection,
    distance: fromDirection === Direction.down ? 34 : 20,
    getPixelDepth,
    engineConfig,
  });

  return walkLine;
};

//
// GET WALK-IN SCENE ACTIONS
//
export const getSceneWalkInPath = (props: {
  characterRender: CharacterRenderType;
  sceneWalkPaths: SceneWalkPathsType;
  engineConfig: EngineConfigType;
  getPixelDepth: (p: Position) => number;
  getWalkPath;
  fromDirection: Direction;
}) => {
  const {
    characterRender,
    sceneWalkPaths,
    fromDirection,
    engineConfig,
    getPixelDepth,
    getWalkPath,
  } = props;

  if (fromDirection) {
    const direction = getOppositeDirection(fromDirection);
    const _walkInData: SceneWalkPathAnyType = (sceneWalkPaths || {})[direction];

    if (isBlockedWalkPath(_walkInData)) {
      return [];
    }

    const walkInData = _walkInData as SceneWalkPathWalkableType;

    if (walkInData) {
      const walkConfig: WalkConfigType = {
        horizontal: {
          frameCount: characterRender?.walkLeft?.spriteConfig?.frameCount,
          pixelsWalked: characterRender?.walkLeft?.pixelsWalked,
        },
        vertical: {
          frameCount: characterRender?.walkDown?.spriteConfig?.frameCount,
          pixelsWalked: characterRender?.walkDown?.pixelsWalked,
        },
      };

      const outPath = getOutOfSceneWalkInPath({
        walkConfig,
        fromDirection: fromDirection,
        walkInData,
        engineConfig: engineConfig,
        getPixelDepth,
      });

      const lastOutPosition = outPath.pop(); // pop to also remove the last point, otherwise it will be twice in the final path

      const matrixPath = getWalkPath({
        from: lastOutPosition?.position,
        to: walkInData.walkTo,
        walkConfig,
      });

      return [...outPath, ...matrixPath];
    }
  }
  return [];
};

export const getSceneWalkOutPath = (props: {
  characterRender: CharacterRenderType;
  sceneWalkPaths: SceneWalkPathsType;
  engineConfig: EngineConfigType;
  getPixelDepth: (p: Position) => number;
  getWalkPath;
  toDirection: Direction;
}) => {
  // scene walk-out is the reverse of walk-in
  const path = getSceneWalkInPath({
    ...props,
    fromDirection: getOppositeDirection(props.toDirection),
  }).reverse();

  return path;
};

//
// CHECK IF WALK-PATH ALLOWS WALKING
//
export const isWalkableWalkPath = (walkPath: SceneWalkPathAnyType) => {
  if (!walkPath || isBlockedWalkPath(walkPath) || !(walkPath as any)?.walkTo) {
    // if path doesn't specify edge walk value, it's not walkable
    return false;
  }
  return (walkPath as any)?.walkTo;
};

//
// CHECK IF WALK-PATH ALLOWS WALKING
//
export const isBlockedWalkPath = (walkPath: SceneWalkPathAnyType) => {
  return (walkPath as SceneWalkPathBlockedType)?.isBlocked ?? false;
};

//
// GET WALK AREA BASE (POSITIONED RECTANGLE)
//
export const getWalkAreaBase: (options: {
  located: Direction;
}) => SceneWalkAreaBaseType = (options) => {
  let { located } = options;

  const { widthInsideGame, widthOutsideGame } = GAME_CONFIG.scene.walkOutAreas;
  const areaWidth = widthInsideGame + widthOutsideGame;

  switch (located) {
    case Direction.left:
      return {
        origin: { x: -widthOutsideGame, y: 0 },
        direction: Direction.left,
        cursor: CursorRenderType.arrowLeft,
        width: areaWidth,
        height: GAME_CONFIG.scene.dimensions.y,
      };

    case Direction.right:
      return {
        origin: {
          x: GAME_CONFIG.scene.dimensions.x - widthInsideGame,
          y: 0,
        },
        direction: Direction.right,
        cursor: CursorRenderType.arrowRight,
        width: areaWidth,
        height: GAME_CONFIG.scene.dimensions.y,
      };

    case Direction.up:
      return {
        origin: { x: 0, y: -widthOutsideGame },
        direction: Direction.up,
        cursor: CursorRenderType.arrowUp,
        width: GAME_CONFIG.scene.dimensions.x,
        height: areaWidth,
      };

    case Direction.down:
      return {
        origin: {
          x: 0,
          y: GAME_CONFIG.scene.dimensions.y - widthInsideGame,
        },
        direction: Direction.down,
        cursor: CursorRenderType.arrowDown,
        width: GAME_CONFIG.scene.dimensions.x,
        height: areaWidth,
      };
  }
};
