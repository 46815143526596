import { Group, Rect } from "react-konva";
import React, { useEffect, useState } from "react";
import { TextAlign, TranslatedString } from "game-engine/types";

import LayoutColumn from "game-engine/components/basic-elements/LayoutColumn";
import MenuButton from "./MenuButton";
import MenuSpacer from "./MenuSpacer";
import Text from "game-engine/components/basic-elements/Text";

const MenuScrollable = (props: {
  children?: React.ReactNode;
  visibleChildrenCount?: number;
  ifEmpty?: {
    text: string | TranslatedString;
    height?: number;
  };
  x?: number;
  y?: number;
  paddingX?: number;
  paddingY?: number;
  width: number;
  minHeight?: number;
  centered?: boolean;
  gap?: number;
}) => {
  const {
    x = 0,
    y = 0,
    paddingX = 0,
    paddingY = 0,
    width,
    minHeight = 0,
    visibleChildrenCount = 4,
    centered,
    gap = 2,
    children,
  } = props;

  const [activeIndex, setActiveIndex] = useState(0);
  const [visibleChildren, setVisibleChildren] = useState<React.ReactNode[]>([]);

  const emptyHeight = props.ifEmpty?.height || 20;

  useEffect(() => {
    const childrenArray = React.Children.toArray(children);
    const visible = childrenArray.slice(
      activeIndex,
      activeIndex + visibleChildrenCount
    );
    setVisibleChildren(visible);
  }, [children, activeIndex, visibleChildrenCount]);

  //
  // NAVIGATION
  //
  const renderNavigationButton = (params: {
    direction: "up" | "down";
    step: number;
  }) => {
    const childrenArray = React.Children.toArray(children);
    const minIndex = 0;
    const maxIndex = childrenArray.length - visibleChildrenCount;

    const newIndex = Math.max(
      minIndex,
      Math.min(activeIndex + params.step, maxIndex)
    );

    let isActive = childrenArray?.length > visibleChildrenCount;

    if (isActive) {
      if (params.direction === "up" && activeIndex <= minIndex) {
        isActive = false;
      } else if (params.direction === "down" && activeIndex >= maxIndex) {
        isActive = false;
      }
    }

    return (
      <MenuButton
        x={Math.round(width / 2)}
        width={21}
        centered
        paddingX={2}
        paddingY={0}
        label={params.direction === "up" ? "↑" : "↓"}
        onClick={() => setActiveIndex(newIndex)}
        opacity={isActive ? 1 : 0.1}
      />
    );
  };

  //
  // RENDER
  //
  return (
    <Group x={centered ? x - Math.round(width / 2) : x} y={y}>
      <Rect width={0} height={minHeight} fill="#0000" listening={false} />

      {visibleChildren.length === 0 && props.ifEmpty ? (
        <Group y={Math.round(emptyHeight / 2 - 4)}>
          <MenuSpacer height={emptyHeight} />
          <Text
            position={{ x: Math.round(width / 2), y: 0 }}
            text={props.ifEmpty.text}
            minWidth={width}
            maxWidth={width}
            align={TextAlign.center}
            outlined={false}
            shadow
          />
        </Group>
      ) : (
        <LayoutColumn gap={3}>
          {renderNavigationButton({
            direction: "up",
            step: -1,
          })}

          <Group x={paddingX} y={paddingY}>
            <LayoutColumn gap={gap}>
              {visibleChildren.map((child, i) =>
                React.cloneElement(child as React.ReactElement, {
                  key: i,
                })
              )}
            </LayoutColumn>
          </Group>

          {renderNavigationButton({
            direction: "down",
            step: 1,
          })}
        </LayoutColumn>
      )}
    </Group>
  );
};

export default MenuScrollable;
