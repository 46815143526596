/* eslint-disable react-hooks/exhaustive-deps */

import CHARACTER_CONFIGS, {
  getCharacterConfigById,
} from "game-files/characters/CHARACTER_CONFIGS";
import {
  CharacterConfigType,
  SceneCharacterDataType,
  SceneCharacterType,
} from "game-engine/types";

import CharacterBox from "./CharacterBox";
import { GameObjective } from "game-files/gameObjectives";
import NoDataLabel from "../NoDataLabel";
import { getCharacterRenderByCurrentObjective } from "game-engine/utils/get-by-current-objective";
import { getSceneCharacterDataByCurrentObjective } from "game-engine/utils";
import useGame from "game-engine/hooks/useGame";
import { useMemo } from "react";

const CharacterList = (props: {
  isInSceneInfo?: boolean;
  sceneCharactersInfo?: {
    sceneCharacter: SceneCharacterType;
    sceneCharacterData: SceneCharacterDataType;
  }[];
  thumbnailSettings?: {
    width: number;
    height: number;
  };
  previewSettings?: {
    width: number;
    height: number;
    scale: number;
  };
}) => {
  const {
    sceneCharactersInfo,
    thumbnailSettings,
    previewSettings,
    isInSceneInfo,
  } = props;

  const { gameFns } = useGame();

  const characters = useMemo<CharacterConfigType[]>(() => {
    if (!isInSceneInfo) {
      return Object.values(CHARACTER_CONFIGS);
    }

    const characterConfigs: CharacterConfigType[] = [];

    sceneCharactersInfo.forEach((sceneCharacterInfo) => {
      const characterId = sceneCharacterInfo.sceneCharacter.config.id;

      const sceneCharByCurrentObjective =
        getSceneCharacterDataByCurrentObjective({
          config: sceneCharacterInfo.sceneCharacter.config,
          dataByCurrentObjective:
            sceneCharacterInfo.sceneCharacter.dataByCurrentObjective,
          currentObjective: gameFns.getCurrentObjective(),
          events: gameFns.getEvents(),
        });

      const renderByCurrObjective = getCharacterRenderByCurrentObjective({
        dataByCurrentObjective: sceneCharByCurrentObjective.config.render,
        currentObjective: gameFns.getCurrentObjective(),
        events: gameFns.getEvents(),
      });

      const characterConfig = getCharacterConfigById(characterId);
      const updatedCharacterConfig: CharacterConfigType = {
        ...characterConfig,
        devTools: {
          sceneCharacter: sceneCharByCurrentObjective,
          renderAssets: [
            {
              name: `${GameObjective[gameFns.getCurrentObjective()]}`,
              render: renderByCurrObjective,
            },
          ],
        },
      };

      characterConfigs.push(updatedCharacterConfig);
    });

    return characterConfigs;
  }, [sceneCharactersInfo]);

  //
  // RENDER
  //
  return (
    <>
      {!characters.length ? (
        <NoDataLabel>no characters</NoDataLabel>
      ) : (
        characters.map((character) => (
          <CharacterBox
            key={character.id}
            characterId={character.id}
            characterConfig={character}
            thumbnailSettings={thumbnailSettings}
            previewSettings={previewSettings}
          />
        ))
      )}
    </>
  );
};

export default CharacterList;
