import { ACTION } from "game-engine/utils";
import { ActionQueueWithItemType } from "game-engine/types";
import { ItemConfigId } from "game-files/common/ids";

const onClickWithItem: ActionQueueWithItemType = {
  //
  // Default
  //
  default: [
    ACTION.talkRandom({
      dialogOptions: [
        {
          en: `You can keep that, thank you.`,
          cz: "Díky, ale nech si to.",
        },
        {
          en: `Do not give me that.`,
          cz: `Nedávej mi to.`,
        },
      ],
    }),
  ],

  // LanternCelestialFlame
  //
  [ItemConfigId.LanternCelestialFlame]: [
    ACTION.talkRandom({
      dialogOptions: [
        {
          en: `Whoa! I haven't seen Celestial Flame in years!`,
          cz: "Páni! Nebeský plamen jsem neviděl ani nepamatuji!",
        },
        {
          en: `That is very valuable, you should keep it.`,
          cz: `To je cenná věcička, měl by sis ji nechat.`,
        },
      ],
    }),
  ],

  // Test_FlaskWater
  //
  [ItemConfigId.Test_FlaskWater]: [
    ACTION.swapItemInCursorConfig({
      newItemConfigId: ItemConfigId.Test_Flask,
      blockGui: true,
    }),
    ACTION.talkRandom({
      dialogOptions: [
        {
          en: `Thank you for the water, how very considerate.`,
          cz: `Děkuji ti za vodu, jak pozorné.`,
        },
      ],
    }),
  ],
};

export default onClickWithItem;
