import DevScene01 from "../__to-delete__dev-scenes/DevScene01";
import DevScene02 from "../__to-delete__dev-scenes/DevScene02";
import DevScene03 from "../__to-delete__dev-scenes/DevScene03";
import DevScene04 from "../__to-delete__dev-scenes/DevScene04";
import DevScene05 from "../__to-delete__dev-scenes/DevScene05";
import DevScene06 from "../__to-delete__dev-scenes/DevScene06";
import DevScene07 from "../__to-delete__dev-scenes/DevScene07";
import DevScene_CharacterAssetPreview from "./DevScene_CharacterAssetPreview";
import { SceneDefinitionsType } from "game-engine/types";

const SCENE_CONFIGS_DEV: SceneDefinitionsType = {
  DevScene_CharacterAssetPreview,
  DevScene01,
  DevScene02,
  DevScene03,
  DevScene04,
  DevScene05,
  DevScene06,
  DevScene07,
};

export default SCENE_CONFIGS_DEV;
