/* eslint-disable react-hooks/exhaustive-deps */

import {
  ACTION,
  createActionName,
  getActionsByCurrentObjective,
  storeEventInDevTools,
} from "game-engine/utils";
import {
  ActionQueueType,
  ItemType,
  Position,
  SceneBackgroundType,
  SceneCharacterDataType,
  SceneLayerType,
  SceneRenderLayerType,
  SceneType,
} from "game-engine/types";
import React, { useContext, useEffect, useMemo, useState } from "react";

import Character from "../../Character";
import { CharacterId } from "game-files/common/ids";
import { DevToolsContext } from "game-engine";
import { GetDynamicLightingFiltersFunc } from "./useDynamicLighting";
import Item from "../../Item";
import ItemInSceneOverlay from "../../ItemInSceneOverlay";
import SceneLayer from "../../SceneLayer";
import useGame from "game-engine/hooks/useGame";

const useSceneLayers = (props: {
  scene: SceneType;
  sceneBackground: SceneBackgroundType;
  runActions;
  stopActions;
  mainCharacter: SceneCharacterDataType;
  setMainCharacter: (c: SceneCharacterDataType) => void;
  sceneCharacters: SceneCharacterDataType[];
  getPixelDepth: (p: Position) => number;
  dropItemInScene: (p: Position) => void;
  onSceneLayerImageLoaded: (sceneLayer: SceneLayerType) => void;
  onSceneCharactersImagesLoaded: () => void;
  getDynamicLightingFilters: GetDynamicLightingFiltersFunc;
  ignoreIdle?: boolean;
  ignoreFaceClickedPosition?: boolean;
}) => {
  const {
    scene,
    sceneBackground,
    runActions,
    stopActions,
    mainCharacter,
    setMainCharacter,
    sceneCharacters,
    getPixelDepth,
    dropItemInScene,
    onSceneLayerImageLoaded,
    onSceneCharactersImagesLoaded,
    getDynamicLightingFilters,
    ignoreIdle,
    ignoreFaceClickedPosition,
  } = props;

  const { gamePlay, gameItems, mainCharacterConfig, gameFns, cursor, logger } =
    useGame();
  const devTools = useContext(DevToolsContext);

  useEffect(() => {
    setMainCharacter(mainCharacter);
  }, [sceneBackground?.depthSettings]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // RENDER LAYER - CHARACTERS - HANDLE IMAGE LOADING
  //

  const sceneCharactersImages = { [mainCharacterConfig.id]: false }; // TODO - IF MAIN CHARACTER IS HIDDEN, THIS (EXPLICITLY INCLUDED MAIN CHARACTER) WILL CAUSE PROBLEMS (UNLESS MAIN CHARACTER IS HIDDEN VIA POSITION OUTSIDE OF SCENE OR OPACITY)
  sceneCharacters.forEach((c) => (sceneCharactersImages[c.config.id] = false));

  const [sceneCharactersImagesLoaded, setSceneCharactersImagesLoaded] =
    useState(sceneCharactersImages);

  useEffect(() => {
    const waitToLoadCount = Object.values(sceneCharactersImagesLoaded).filter(
      (v) => !v
    ).length;
    if (!waitToLoadCount) {
      logger.graphics(
        `all scene characters images loaded`,
        sceneCharactersImagesLoaded
      );
      onSceneCharactersImagesLoaded();
    }
  }, [sceneCharactersImagesLoaded]);

  const onSceneCharacterImagesLoaded = (characterId: CharacterId) => {
    setSceneCharactersImagesLoaded((prevImageLoader) => {
      const updatedLoaded = { ...prevImageLoader, [characterId]: true };
      return updatedLoaded;
    });
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // RENDER LAYER - CHARACTERS
  //

  const onSceneCharacterClick = (e, character: SceneCharacterDataType) => {
    const clickedPosition = gameFns.getClickedScenePosition(e);

    storeEventInDevTools({
      devTools,
      event: {
        name: createActionName({
          name: "clicked on",
          value: character.config?.id,
        }),
        data: {
          characterId: character.config?.id,
          itemInCursor: gameItems.state.itemInCursor?.id,
        },
      },
    });

    // 01 - is mainCharacter with Celestial Flame active -> no item clicks allowed
    if (
      character.config.id === mainCharacterConfig.id &&
      gameItems.state.itemInCursor &&
      gamePlay.state.currentSkills?.CelestialFlame?.isActive
    ) {
      return dropItemInScene(clickedPosition);
    }

    // 02 - check override actions
    let actions = getActionsByCurrentObjective({
      actionsByCurrentObjective:
        character.onClickOverride?.actionsByCurrentObjective,
      currentObjective: gameFns.getCurrentObjective(),
      itemInCursor: gameItems.state.itemInCursor,
    });

    // 03 - if no override actions, check character config actions
    if (!actions?.length) {
      actions = getActionsByCurrentObjective({
        actionsByCurrentObjective:
          character.config?.onClick?.actionsByCurrentObjective,
        currentObjective: gameFns.getCurrentObjective(),
        itemInCursor: gameItems.state.itemInCursor,
      });
    }

    // 04 - no actions with item in cursor -> drop it
    if (gameItems.state.itemInCursor && !actions?.length) {
      return dropItemInScene(clickedPosition);
    }

    // run actions
    runActions(
      [
        ACTION.face({
          faceCharacterId: character.config.id,
        }),
        ...actions,
      ],
      { clickedPosition, clickedDepthY: character?.position?.y }
    );
  };

  const getCharacterLayer = (character: SceneCharacterDataType) => {
    const sceneRenderLayer: SceneRenderLayerType = {
      key: character.config.id,
      depthY: character.position.y,
      render: (renderProps?: { isInvisibilityRender?: boolean }) => (
        <Character
          key={character.config.id}
          renderMode={character.renderMode}
          config={character.config}
          facing={character.facing}
          position={character.position}
          walkAnimationFrame={character.walkAnimationFrame}
          scale={character.scale || getPixelDepth(character.position)}
          dialog={character.dialog}
          playAnimation={character.playAnimation}
          talkRenderOptions={character.talkRenderOptions}
          onClick={(e) => onSceneCharacterClick(e, character)}
          onImagesLoaded={() =>
            renderProps?.isInvisibilityRender
              ? undefined
              : onSceneCharacterImagesLoaded(character.config.id)
          }
          isInvisibilityRender={renderProps?.isInvisibilityRender}
          ignoreIdle={ignoreIdle}
        />
      ),
    };
    return sceneRenderLayer;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // RENDER LAYER - ITEMS
  //
  const onSceneItemClick = (e, item: ItemType) => {
    stopActions();
    const clickedPosition = gameFns.getClickedScenePosition(e);
    if (gameItems.state.itemInCursor) {
      // ITEM CLICK WITH ITEM IN CURSOR
      dropItemInScene(clickedPosition);
    } else {
      // DEFAULT ITEM CLICK
      gameFns.grabItem(item);
    }
  };

  const getItemLayer = (item: ItemType) => {
    // ITEM PLACED IN SCENE
    if (item.inScene?.sceneId && item.inScene?.sceneId === scene.uniqueId) {
      let dropAnimation;

      if (
        cursor.state.itemDropAnimation &&
        cursor.state.itemDropAnimation.itemId === item.id
      ) {
        dropAnimation = cursor.state.itemDropAnimation;
        gameFns.setCursorItemDropAnimation(null);
      }

      const sceneRenderLayer: SceneRenderLayerType = {
        key: item.id,
        depthY: item.inScene.position.y,
        render: (renderProps?: { isInvisibilityRender?: boolean }) => (
          <Item
            key={item.id}
            item={item}
            position={item.inScene.position}
            scale={
              (item.inScene.scale || 1) * getPixelDepth(item.inScene.position)
            }
            onClick={(e) => onSceneItemClick(e, item)}
            dropAnimation={dropAnimation}
          />
        ),
      };
      return sceneRenderLayer;
    }

    // ITEM IN SCENE OVERLAY
    if (
      item.inSceneOverlay?.sceneId &&
      item.inSceneOverlay?.sceneId === scene.uniqueId
    ) {
      const position = item.inSceneOverlay.position;
      const depthY = item.inSceneOverlay?.depthY ?? position.y;

      const sceneRenderLayer: SceneRenderLayerType = {
        key: item.id,
        depthY: depthY,
        render: (renderProps?: { isInvisibilityRender?: boolean }) => (
          <ItemInSceneOverlay
            key={item.id}
            item={item}
            position={position}
            scale={
              item.inSceneOverlay.scale ??
              getPixelDepth({
                x: position?.x,
                y: depthY, // items that are 'floating' should be treated as y = depthY value
              })
            }
            onClick={undefined}
            sceneOverlay={item.inSceneOverlay}
          />
        ),
      };

      return sceneRenderLayer;
    }

    return null;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // RENDER LAYER - SCENE
  //
  const onSceneLayerClick = (e, sceneLayer: SceneLayerType) => {
    const clickedPosition = gameFns.getClickedScenePosition(e);
    let actions: ActionQueueType;

    // SCENE LAYER THAT GENERATES ITEMS
    if (sceneLayer?.generateItem) {
      actions = [
        ACTION.putNewItemIntoCursor({
          newItemConfigId: sceneLayer.generateItem.itemConfigId,
        }),
      ];

      if (sceneLayer.generateItem.pause?.durationSec) {
        actions.push(
          ACTION.execute({
            funcName: `hide scene layer ${sceneLayer.id}`,
            blockGui: false,
            func: () => {
              gameFns.hideSceneLayer({
                uniqueSceneId: scene.uniqueId,
                sceneLayerId: sceneLayer.id,
                durationSec: sceneLayer.generateItem.pause?.durationSec,
              });
            },
          })
        );
      }
    }

    // REGULAR SCENE LAYER CLICK
    else {
      actions = getActionsByCurrentObjective({
        actionsByCurrentObjective: sceneLayer.actionsByCurrentObjective,
        currentObjective: gameFns.getCurrentObjective(),
        itemInCursor: gameItems.state.itemInCursor,
      });

      // no actions with item in cursor -> drop it
      if (gameItems.state.itemInCursor && !actions?.length) {
        return dropItemInScene(clickedPosition);
      }
    }

    if (!ignoreFaceClickedPosition) {
      actions = [
        ACTION.face({
          facePosition: clickedPosition,
        }),
        ...actions,
      ];
    }

    // run actions
    runActions(actions, { clickedPosition, clickedDepthY: sceneLayer?.depthY });
  };

  const SCENE_LAYER_KEY_PREFIX = "scene-layer";

  const getSceneLayer = (sceneLayer: SceneLayerType) => {
    const isHidden =
      !!gamePlay.state.hiddenLayers?.[scene.uniqueId]?.[sceneLayer.id];

    const sceneRenderLayer: SceneRenderLayerType = {
      key: `${SCENE_LAYER_KEY_PREFIX}-${sceneLayer.id}`,
      depthY: sceneLayer.depthY,
      render: (renderProps?: { isInvisibilityRender?: boolean }) => (
        <SceneLayer
          sceneLayer={sceneLayer}
          onClick={(e) => onSceneLayerClick(e, sceneLayer)}
          onImageLoaded={
            renderProps?.isInvisibilityRender
              ? undefined
              : () => onSceneLayerImageLoaded(sceneLayer)
          }
          getDynamicLightingFilters={getDynamicLightingFilters}
          isHidden={isHidden}
        />
      ),
    };

    return sceneRenderLayer;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE LAYERS
  //
  const sceneLayers: SceneRenderLayerType[] = useMemo(() => {
    if (!scene) {
      return [];
    }

    const layers: SceneRenderLayerType[] = [
      /* scene layers */
      ...scene.images.layers.map((sceneLayer) => getSceneLayer(sceneLayer)),

      /* items */
      ...[
        ...gameFns.getItemsInScene(scene.uniqueId),
        ...gameFns.getItemsInSceneOverlay(scene.uniqueId),
      ].map((item) => getItemLayer(item)),

      /* scene characters */
      ...(sceneCharacters || []).map((char) => getCharacterLayer(char)),

      /* main character */
      scene.mainCharacterOptions?.isHidden
        ? null
        : getCharacterLayer(mainCharacter),
    ];

    const layersByDepth = layers
      .filter((v) => v)
      .sort((a, b) =>
        a.depthY === b.depthY
          ? a.key < b.key
            ? -1
            : 1
          : a.depthY < b.depthY
          ? -1
          : 1
      );

    return layersByDepth;
  }, [
    scene,
    gamePlay.state.hiddenLayers,
    gameItems.state,
    cursor.state.itemDropAnimation,
    sceneCharacters,
    mainCharacter,
    getDynamicLightingFilters,
    ignoreIdle,
  ]);

  //
  // RENDER
  //
  const renderInvisibilityLayers = (params: {
    beforeIndex?: number;
    afterIndex?: number;
  }) => {
    const { beforeIndex, afterIndex } = params;

    return sceneLayers.map((layer, index) => {
      if (!layer) {
        return null;
      }
      if (
        (beforeIndex === undefined || index < beforeIndex) &&
        (afterIndex === undefined || index > afterIndex)
      ) {
        return (
          <React.Fragment key={layer.key}>
            {layer.render({ isInvisibilityRender: true })}
          </React.Fragment>
        );
      }
      return null;
    });
  };

  const renderSceneLayers = (props?: {
    mainCharacterRender?: boolean;
    invisibilityBackground?: boolean;
    invisibilityForeground?: boolean;
  }) => {
    if (props?.mainCharacterRender) {
      const layer = sceneLayers.find(
        (layer) => layer.key === mainCharacterConfig.id
      );
      return layer.render({ isInvisibilityRender: true });
    }

    if (props?.invisibilityBackground || props?.invisibilityForeground) {
      const mainCharacterIndex = sceneLayers.findIndex(
        (layer) => layer.key === mainCharacterConfig.id
      );
      if (props?.invisibilityBackground) {
        return renderInvisibilityLayers({ beforeIndex: mainCharacterIndex });
      }
      if (props?.invisibilityForeground) {
        return renderInvisibilityLayers({ afterIndex: mainCharacterIndex });
      }
    }

    return sceneLayers.map((layer) =>
      layer ? (
        <React.Fragment key={layer.key}>{layer.render()}</React.Fragment>
      ) : null
    );
  };

  //
  // RETURN
  //
  return { renderSceneLayers };
};

export default useSceneLayers;
