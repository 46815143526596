/* eslint-disable react-hooks/exhaustive-deps */

import {
  AnimationFrameSequence,
  Position,
  SpriteDirection,
} from "game-engine/types";
import { useEffect, useState } from "react";

import SectionUploadImages from "./components/SectionUploadImages";
import SidebarLeft from "./components/SidebarLeft";
import SidebarRight from "./components/SidebarRight";
import SpriteImagePreview from "./components/SpriteImagePreview";
import { generateSpriteSheet } from "./utils";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  overflow: hidden;
`;

const Main = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export type SpriteFrameType = {
  src: string;
  file: File;
  width: number;
  height: number;
};

export type SpriteCalculatedDataType = {
  filename: string;
  frameWidth: number;
  frameHeight: number;
  frameCount: number;
  spriteUrl: string;
  spriteDirection: SpriteDirection;
  originOffset?: Position;
};

export type SpriteEditorType = {
  frameDurationMilliseconds: number;
  frameSequenceRandom: boolean;
  uploadedSpriteImage: SpriteFrameType;
  frames: SpriteFrameType[];
  frameSequence: AnimationFrameSequence;
  paddingX: number;
  paddingY: number;
  calculated: SpriteCalculatedDataType;
};

const SpriteEditor = () => {
  const [spriteEditor, setSpriteEditor] = useState<SpriteEditorType>();

  useEffect(() => {
    if (spriteEditor?.frames?.length > 0) {
      const asyncFunc = async () => {
        if (spriteEditor?.frames?.length) {
          const calculated = await generateSpriteSheet(spriteEditor);

          setSpriteEditor((prevSpriteEditor) => {
            return { ...prevSpriteEditor, calculated };
          });
        }
      };

      asyncFunc();
    }
  }, [
    spriteEditor?.frames,
    spriteEditor?.paddingX,
    spriteEditor?.paddingY,
    spriteEditor?.calculated?.spriteDirection,
  ]);

  useEffect(() => {
    if (spriteEditor?.frames?.length > 0) {
      const asyncFunc = async () => {
        if (spriteEditor?.frames?.length) {
          const calculated = await generateSpriteSheet(spriteEditor);
          setSpriteEditor((prevSpriteEditor) => {
            return { ...prevSpriteEditor, calculated };
          });
        }
      };

      asyncFunc();
    }
  }, [
    spriteEditor?.frames,
    spriteEditor?.paddingX,
    spriteEditor?.paddingY,
    spriteEditor?.calculated?.spriteDirection,
  ]);

  //
  // RENDER
  //
  if (spriteEditor?.uploadedSpriteImage) {
    return (
      <Wrapper>
        <Main>
          <SpriteImagePreview spriteEditor={spriteEditor} />
        </Main>

        <SidebarRight
          spriteEditor={spriteEditor}
          setSpriteEditor={setSpriteEditor}
        />
      </Wrapper>
    );
  }

  if (spriteEditor?.frames?.length > 0) {
    return (
      <Wrapper>
        <SidebarLeft
          spriteEditor={spriteEditor}
          setSpriteEditor={setSpriteEditor}
        />

        <Main>
          <SpriteImagePreview spriteEditor={spriteEditor} />
        </Main>

        <SidebarRight
          spriteEditor={spriteEditor}
          setSpriteEditor={setSpriteEditor}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <SectionUploadImages setSpriteEditor={setSpriteEditor} />
    </Wrapper>
  );
};

export default SpriteEditor;
