/* eslint-disable react-hooks/exhaustive-deps */

import {
  CharacterConfigType,
  CharacterRenderAnimationType,
  CharacterRenderMode,
  CharacterRenderType,
  Direction,
  Position,
  SceneCharacterPlayAnimationType,
  TalkRenderOptionsType,
  TranslatedString,
} from "game-engine/types";
import { useEffect, useMemo, useState } from "react";

import CharacterDialog from "./dialog";
import CharacterRender from "./render-mode";
import { GameEvent } from "game-files/gameEvents";
import { Group } from "react-konva";
import { SkillId } from "game-files/common/ids";
import { getCharacterRenderByCurrentObjective } from "game-engine/utils/get-by-current-objective";
import useCharacterIdle from "./hooks/useCharacterIdle";
import useGame from "game-engine/hooks/useGame";

export enum CharacterPrimaryRenderMode {
  "default" = "default",
  "defaultNoHead" = "defaultNoHead",
  "walk" = "walk",
  "talk" = "talk",
  "animations" = "animations",
}

export type CharacterProps = {
  config: CharacterConfigType;
  characterRender?: CharacterRenderType; //SHOULD ONLY BE USED FOR DEV TOOLS!
  renderMode?: CharacterRenderMode;
  facing?: Direction;
  position: Position;
  ignoreIdle?: boolean;
  walkAnimationFrame?: number;
  scale?: number;
  dialog?: TranslatedString;
  onClick?: (e?: any) => void;
  onImagesLoaded?: () => void;
  talkRenderOptions?: TalkRenderOptionsType;
  playAnimation?: SceneCharacterPlayAnimationType;
  renderOrigin?: boolean;
  originColor?: string;
  isPoisoned?: boolean;
  isInvisibilityRender?: boolean; // this means the character is rendered offscreen canvas for alpha masking
  isAssetPreview?: boolean;

  forceRenderOverrideSkillId?: SkillId; // for asset preview
};

const Character = (props: CharacterProps) => {
  const {
    config,
    onImagesLoaded,
    ignoreIdle,
    renderOrigin,
    originColor,
    isInvisibilityRender,
    isAssetPreview,
    forceRenderOverrideSkillId,
  } = props;
  const { gamePlay, mainCharacterConfig, engineConfig, logger, gameFns } =
    useGame();

  //
  // DATA BY CURRENT OBJECTIVE
  //
  const characterRender = useMemo<CharacterRenderType>(() => {
    let render: CharacterRenderType;

    if (!isAssetPreview && !!gamePlay.state.devPreview) {
      render =
        mainCharacterConfig.devTools.renderAssets[
          gamePlay.state.devPreview.mainCharacterSettings.renderIndex
        ]?.render;

      return render;
    }

    if (props.characterRender) {
      // THIS SHOULD ONLY BE USED FOR DEV TOOLS
      render = props.characterRender;
      return render;
    }

    render = getCharacterRenderByCurrentObjective({
      dataByCurrentObjective: config.render,
      currentObjective: gameFns.getCurrentObjective(),
      events: gameFns.getEvents(),
    });
    return render;
  }, [
    props.characterRender,
    config,
    gamePlay.state.currentObjective,
    gamePlay.state.events,
    gamePlay.state.devPreview,
  ]);

  const idleAnimations = useMemo<CharacterRenderAnimationType[]>(() => {
    return characterRender?.animations?.filter((a) => a.isIdle) || [];
  }, [characterRender]);

  //
  // HANDLE IMAGE LOADING
  //
  const [imageLoader, setImageLoader] = useState<{
    [renderMode in CharacterPrimaryRenderMode]: boolean;
  }>({
    [CharacterPrimaryRenderMode.default]: false,
    [CharacterPrimaryRenderMode.defaultNoHead]: false,
    [CharacterPrimaryRenderMode.talk]: false,
    [CharacterPrimaryRenderMode.walk]: false,
    [CharacterPrimaryRenderMode.animations]: false,
  });

  useEffect(() => {
    const waitToLoadCount = Object.values(imageLoader).filter((v) => !v).length;
    if (!waitToLoadCount) {
      logger.info(
        `scene character images loaded (${config?.id})`,
        imageLoader,
        config
      );
      if (onImagesLoaded) onImagesLoaded();
    }
  }, [imageLoader]);

  const onImageLoaded = (key: CharacterPrimaryRenderMode) => {
    setImageLoader((prevImageLoader) => {
      const updatedLoaded = { ...prevImageLoader, [key]: true };
      return updatedLoaded;
    });
  };

  //
  // STATE
  //
  const isDead = () => {
    return (
      config.id === mainCharacterConfig.id && gameFns.isMainCharacterDead()
    );
  };

  //
  // STATE - RENDER MODE
  //
  const [renderMode, setRenderMode] = useState(
    props.renderMode || CharacterRenderMode.default
  );

  const renderDialog =
    (renderMode === CharacterRenderMode.talk ||
      renderMode === CharacterRenderMode.talkIdle ||
      engineConfig.state.renderCharacterDialogLong ||
      engineConfig.state.renderCharacterDialogShort) &&
    !isDead();

  useEffect(() => {
    if (isDead()) {
      // DEAD CHARACTER CANNOT CHANGE RENDER MODE
      logger.info(
        `render mode ignored, character is dead (${config.id})`,
        props
      );
      return;
    }
    if (
      props.renderMode === CharacterRenderMode.talkIdle &&
      renderMode !== CharacterRenderMode.default
    ) {
      // PREVENT IDLE TALK IF CHARACTER IS ALREADY DOING SOMETHING
      logger.info(`idle talk ignored (${config.id})`, props);
      return;
    }

    setRenderMode(props.renderMode || CharacterRenderMode.default);
  }, [props.renderMode]);

  //
  // STATE - FACING
  //
  const [facing, setFacing] = useState<Direction>(
    props.facing || Direction.left
  );

  useEffect(() => {
    if (props.facing) {
      setFacing(props.facing);
    }
  }, [props.facing]);

  //
  // STATE - IS INVISIBLE
  //
  const [isInvisible, setIsInvisible] = useState<boolean>(); // TODO - USE IT TO MAKE THE CHARACTER INVISIBLE

  useEffect(() => {
    if (config.id === mainCharacterConfig.id) {
      const invisibilityIsActive =
        gamePlay.state.currentSkills?.Invisibility?.isActive;

      const invisibilityTransitionIsFinished =
        gamePlay.state.currentSkills?.Invisibility?.progress === 2;

      setIsInvisible(invisibilityIsActive && invisibilityTransitionIsFinished);
    }
  }, [
    mainCharacterConfig,
    gamePlay.state.currentSkills?.Invisibility?.isActive,
    gamePlay.state.currentSkills?.Invisibility?.progress,
  ]);

  //
  // STATE - IS POISONED
  //
  const isPoisoned = useMemo(() => {
    if (props.isPoisoned !== undefined) {
      return props.isPoisoned;
    }
    if (gamePlay.state.devPreview) {
      return gamePlay.state.devPreview.mainCharacterSettings.isPoisoned;
    }
    return (
      gamePlay.state.events[GameEvent.poisonedCharacterIds] || []
    ).includes(config.id);
  }, [props.isPoisoned, gamePlay.state.events, gamePlay.state.devPreview]);

  //
  // ON IDLE
  //
  const { onIdleEnd, idleIndex } = useCharacterIdle({
    characterConfig: config,
    idleAnimations,
    renderMode,
    setRenderMode,
  });

  //
  // LOGIC
  //
  const onClick = (e) => {
    logger.info(`character clicked (${config.id})`, config);
    if (props.onClick) {
      props.onClick(e);
    }
  };

  //
  // RENDER
  //
  return (
    <>
      <Group
        opacity={
          isInvisibilityRender
            ? 1 // invisibility render is the separately rendered layer for distortion effect alpha channel - must be visible
            : isInvisible
            ? 0.01
            : 1
        }
      >
        <CharacterRender
          config={config}
          position={props.position}
          scale={props.scale}
          facing={facing}
          isPoisoned={isPoisoned}
          renderMode={renderMode}
          characterRender={characterRender}
          onClick={onClick}
          onImageLoaded={onImageLoaded}
          onIdleEnd={onIdleEnd}
          idleIndex={idleIndex}
          idleAnimations={idleAnimations}
          ignoreIdle={ignoreIdle}
          walkAnimationFrame={props.walkAnimationFrame}
          talkRenderOptions={props.talkRenderOptions}
          playAnimation={props.playAnimation}
          renderOrigin={renderOrigin}
          originColor={originColor}
          forceRenderOverrideSkillId={forceRenderOverrideSkillId}
        />
      </Group>

      {!isInvisibilityRender && renderDialog ? (
        <CharacterDialog
          characterConfig={config}
          characterPosition={props.position}
          dialog={props.dialog}
        />
      ) : null}
    </>
  );
};

export default Character;
