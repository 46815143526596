/* eslint-disable react-hooks/exhaustive-deps */

import {
  CharacterConfigType,
  CharacterRenderType,
  Direction,
  ImageFilters,
} from "game-engine/types";
import { useEffect, useState } from "react";

import { Group } from "react-konva";
import SpriteFrame from "game-engine/components/basic-elements/SpriteFrame";
import { getImageOffset } from "game-engine/utils";
import useGame from "game-engine/hooks/useGame";

const CharacterAnimationWalk = (props: {
  config: CharacterConfigType;
  imageFilters: ImageFilters;
  characterRender: CharacterRenderType;
  direction: Direction;
  animationFrame: number;
  isActive?: boolean;
  onImagesLoaded: () => void;
}) => {
  const {
    config,
    imageFilters,
    direction,
    isActive,
    characterRender,
    onImagesLoaded,
    animationFrame,
  } = props;
  const { engineConfig, logger } = useGame();

  //
  // HANDLE IMAGE LOADING
  //
  const [imagesLoaded, setImagesLoaded] = useState({
    [Direction.left]: false,
    [Direction.right]: false,
    [Direction.up]: false,
    [Direction.down]: false,
  });

  useEffect(() => {
    if (Object.values(imagesLoaded).filter((loaded) => !loaded).length === 0) {
      if (onImagesLoaded) {
        onImagesLoaded();
      }
      logger.graphics(`${config?.id} walk images loaded`, config, imagesLoaded);
    }
  }, [imagesLoaded]);

  const onImageLoaded = (key: Direction) => {
    setImagesLoaded((prevImageLoader) => {
      const updatedLoaded = { ...prevImageLoader, [key]: true };
      return updatedLoaded;
    });
  };

  //
  // RENDER
  //
  return (
    <>
      {[
        { direction: Direction.left, animation: characterRender.walkLeft },
        { direction: Direction.right, animation: characterRender.walkLeft },
        { direction: Direction.up, animation: characterRender.walkUp },
        { direction: Direction.down, animation: characterRender.walkDown },
      ].map((walk) => {
        if (!walk.animation) {
          return null;
        }
        const isActiveDirection = isActive && walk.direction === direction;

        return (
          <Group
            key={walk.direction}
            opacity={isActiveDirection ? 1 : 0}
            listening={isActiveDirection}
            position={getImageOffset({
              width: walk.animation.spriteConfig.frameWidth,
              height: walk.animation.spriteConfig.frameHeight,
            })}
          >
            <Group
              x={walk.animation.offsetX || 0}
              y={walk.animation.offsetY || 0}
            >
              {/* 'key' IS NECESSARY FOR RE-RENDERING ON SRC CHANGE ! */}
              <SpriteFrame
                key={walk.animation.image.src}
                src={walk.animation.image.src}
                frameIndex={animationFrame}
                spriteConfig={walk.animation.spriteConfig}
                renderOutline={engineConfig.state.renderCharacterOutline}
                renderFill={engineConfig.state.renderCharacterFill}
                outlineColor={engineConfig.state.characterOutlineColor}
                fillColor={engineConfig.state.characterFillColor}
                onImageLoaded={() => onImageLoaded(walk.direction)}
                filters={imageFilters}
                isHidden={!isActiveDirection}
              />
            </Group>
          </Group>
        );
      })}
    </>
  );
};

export default CharacterAnimationWalk;
