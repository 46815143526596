import {
  AnimationColorEnum,
  BlendingMode,
  SpriteDirection,
} from "game-engine/types";

import { SceneOverlayAnimationId } from "game-files/common/ids";
import { createAnimationConfig } from "./src/_files";

//
// ANIMATION CONFIGS
//
const ANIMATION_CONFIGS = {
  //////////////////////////////////////////////////////////////////////

  Stars: createAnimationConfig({
    filename: "stars",
    name: "Stars",
    spriteConfig: {
      direction: SpriteDirection.Horizontal,
      frameWidth: 30,
      frameHeight: 36,
      frameCount: 16,
      frameDurationMilliseconds: 80,
    },
    blendingMode: undefined,
    colors: {
      [AnimationColorEnum.white]: {
        colorWhite: "#ffffff",
        colorBlack: "#969696",
      },
      [AnimationColorEnum.red]: {
        colorWhite: "#ffc8c8",
        colorBlack: "#ff0000",
      },
      [AnimationColorEnum.orange]: {
        colorWhite: "#fffac8",
        colorBlack: "#ff9600",
      },
      [AnimationColorEnum.yellow]: {
        colorWhite: "#ffff96",
        colorBlack: "#b4b400",
      },
      [AnimationColorEnum.green]: {
        colorWhite: "#c8ffc8",
        colorBlack: "#009600",
      },
      [AnimationColorEnum.blue]: {
        colorWhite: "#c8c8ff",
        colorBlack: "#0000ff",
      },
      [AnimationColorEnum.purple]: {
        colorWhite: "#e6c8ff",
        colorBlack: "#800080",
      },
      [AnimationColorEnum.pink]: {
        colorWhite: "#ffdcff",
        colorBlack: "#fa6464",
      },
      [AnimationColorEnum.cyan]: {
        colorWhite: "#c8ffff",
        colorBlack: "#009696",
      },
    },
  }),

  //////////////////////////////////////////////////////////////////////

  Flame: createAnimationConfig({
    filename: "flame",
    name: "Flame",
    spriteConfig: {
      direction: SpriteDirection.Horizontal,
      frameWidth: 34,
      frameHeight: 44,
      frameCount: 16,
      frameDurationMilliseconds: 50,
    },
    blendingMode: undefined,
    colors: {
      [AnimationColorEnum.white]: {
        colorWhite: "#ffffff",
        colorBlack: "#646464",
      },
      [AnimationColorEnum.red]: {
        colorWhite: "#ffc896",
        colorBlack: "#960000",
      },
      [AnimationColorEnum.orange]: {
        colorWhite: "#ffc878",
        colorBlack: "#8c1e00",
      },
      [AnimationColorEnum.yellow]: {
        colorWhite: "#ffff64",
        colorBlack: "#c89600",
      },
      [AnimationColorEnum.green]: {
        colorWhite: "#c8ffc8",
        colorBlack: "#009600",
      },
      [AnimationColorEnum.blue]: {
        colorWhite: "#c8c8ff",
        colorBlack: "#0000ff",
      },
      [AnimationColorEnum.purple]: {
        colorWhite: "#e6c8ff",
        colorBlack: "#800080",
      },
      [AnimationColorEnum.pink]: {
        colorWhite: "#ffdcff",
        colorBlack: "#fa6464",
      },
      [AnimationColorEnum.cyan]: {
        colorWhite: "#c8ffff",
        colorBlack: "#009696",
      },
    },
  }),

  //////////////////////////////////////////////////////////////////////
  //
  // SCENE OVERLAY ANIMATIONS
  //
  //////////////////////////////////////////////////////////////////////

  SceneOverlayFrost: createAnimationConfig({
    filename: "scene-overlay-frost",
    name: "Scene Overlay - Frost",
    spriteConfig: {
      direction: SpriteDirection.Vertical,
      frameWidth: 320,
      frameHeight: 160,
      frameCount: 20,
      frameDurationMilliseconds: 120,
    },
    asSceneOverlayAnimation: {
      sceneOverlayAnimationId: SceneOverlayAnimationId.frost,
      pause: { durationSec: 2 },
      fadeOut: { durationSec: 10 },
    },
    blendingMode: BlendingMode.Screen,
  }),
};

//
// EXPORT AND UTILS
//
export default ANIMATION_CONFIGS;

export const getAnimationBySceneOverlayAnimationId = (
  sceneOverlayAnimationId: SceneOverlayAnimationId
) => {
  if (!sceneOverlayAnimationId) {
    return null;
  }
  const animation = Object.values(ANIMATION_CONFIGS).find(
    (animationConfig) =>
      animationConfig?.asSceneOverlayAnimation?.sceneOverlayAnimationId ===
      sceneOverlayAnimationId
  );
  if (!animation) {
    console.error(
      `Scene overlay animation '${sceneOverlayAnimationId}' not found in ANIMATION_CONFIGS!`
    );
  }
  return animation;
};
