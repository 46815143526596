import { ColorHex, ColorRGBAArray } from "game-engine/types";

export const hexToRgba = (hex: ColorHex) => {
  let color = normalizeHex(hex);
  if (color) {
    return [
      parseInt(color.slice(1, 3), 16), // Red
      parseInt(color.slice(3, 5), 16), // Green
      parseInt(color.slice(5, 7), 16), // Blue
      parseInt(color.slice(7, 9), 16), // Alpha
    ] as ColorRGBAArray;
  }
  return undefined;
};

export const hexToRgbaObject = (hex: ColorHex) => {
  let color = normalizeHex(hex);
  if (color) {
    return {
      r: parseInt(color.slice(1, 3), 16), // Red,
      g: parseInt(color.slice(3, 5), 16), // Green
      b: parseInt(color.slice(5, 7), 16), // Blue
      a: parseInt(color.slice(7, 9), 16), // Alpha
    };
  }
  return undefined;
};

export const normalizeHex = (hex: ColorHex) => {
  if (!hex) {
    return undefined;
  }
  if (hex[0] === "#") {
    hex = hex.slice(1); // Remove the '#' symbol
  }

  if (hex.length === 3) {
    // expand short hex notation (e.g. #123 -> #112233ff)
    return `#${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}ff`;
  }
  if (hex.length === 4) {
    // expand short hex notation (e.g. #1234 -> #11223344)
    return `#${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
  }
  if (hex.length === 6) {
    // add missing alpha channel
    return `#${hex}ff`;
  }

  return `#${hex}`;
};

export const isRGBColorClose = (
  colorA: ColorRGBAArray,
  colorB: ColorRGBAArray,
  threshold: number = 0
) => {
  return (
    Math.abs(colorA[0] - colorB[0]) <= threshold &&
    Math.abs(colorA[1] - colorB[1]) <= threshold &&
    Math.abs(colorA[2] - colorB[2]) <= threshold
  );
};

export const isRGBColorEqual = (
  colorA: ColorRGBAArray,
  colorB: ColorRGBAArray
) => {
  return isRGBColorClose(colorA, colorB);
};

export const isHexColorClose = (
  colorA: ColorHex,
  colorB: ColorHex,
  threshold: number = 0
) => {
  return isRGBColorClose(hexToRgba(colorA), hexToRgba(colorB), threshold);
};

export const isHexColorEqual = (colorA, colorB) => {
  return isHexColorClose(colorA, colorB);
};
