/* eslint-disable react-hooks/exhaustive-deps */

import { CharacterAnimationId, SkillId } from "game-files/common/ids";
import {
  CharacterConfigType,
  CharacterDevToolsRenderAssetsType,
  CharacterRenderMode,
  Direction,
} from "game-engine/types";
import React, { useEffect, useRef, useState } from "react";
import {
  capitalizeFirstLetter,
  splitByCapitalLetters,
} from "game-engine/utils";
import styled, { css } from "styled-components";

import AssetPreview from "game-engine/_dev/basic-components/AssetPreview";
import Box from "../Box";
import Character from "game-engine/components/game-elements/Character";
import Checkbox from "../inputs/Checkbox";
import Divider from "../Divider";
import GAMEPLAY from "game-files/gamePlay";
import SKILL_CONFIGS from "game-files/skills/SKILL_CONFIGS";

const CharacterPreview = styled.div<{ width?: string }>`
  ${(props) => css`
    max-width: ${props.width};
  `}
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 6px;
`;

const CharacterRenderAssets = (props: {
  isOpen?: boolean;
  characterConfig: CharacterConfigType;
  characterRender: CharacterDevToolsRenderAssetsType;
  previewSettings?: {
    width: number;
    height: number;
    scale: number;
  };
}) => {
  const { isOpen, characterConfig, characterRender, previewSettings } = props;

  const { width, height, scale } = previewSettings || {
    width: 100,
    height: 100,
    scale: 4,
  };

  //
  // RENDER SETTINGS
  //
  const [isPoisoned, setIsPoisoned] = useState(false);
  const [skillIdOverride, setSkillIdOverride] = useState<SkillId>();

  const [renderSettings, setRenderSettings] = useState<{
    renderMode: CharacterRenderMode;
    direction: Direction;
    walkAnimationFrame: number;
    playAnimation?: {
      id: string;
      onAnimationEnd?: () => void;
    };
  }>({
    renderMode: CharacterRenderMode.default,
    direction: Direction.left,
    walkAnimationFrame: 0,
    playAnimation: undefined,
  });

  const onClickDefault = () => {
    setRenderSettings({
      ...renderSettings,
      renderMode: CharacterRenderMode.default,
      walkAnimationFrame: 0,
      playAnimation: undefined,
    });
  };

  const onClickTalk = () => {
    setRenderSettings({
      ...renderSettings,
      renderMode: CharacterRenderMode.talk,
      walkAnimationFrame: 0,
      playAnimation: undefined,
    });
  };

  const onClickWalk = (direction: Direction) => {
    setRenderSettings({
      ...renderSettings,
      renderMode: CharacterRenderMode.walk,
      direction,
      walkAnimationFrame: 0,
      playAnimation: undefined,
    });
  };

  const onClickAnimation = (params: {
    animationId: string;
    isUndefinedAnimation;
    onStart?: () => void;
    onEnd?: () => void;
  }) => {
    params.onStart?.();

    setRenderSettings({
      ...renderSettings,
      renderMode: CharacterRenderMode.animation,
      walkAnimationFrame: 0,
      playAnimation: {
        id: params.animationId,
        onAnimationEnd: () => {
          params.onEnd?.();
          onClickDefault();
        },
      },
    });

    if (params.isUndefinedAnimation) {
      // undefined animations are clickable to test what happens
      // when a character is given an animation that is not defined
      setTimeout(onClickDefault, 600);
    }
  };

  const renderButton = (options: {
    name: string;
    isActive: boolean;
    isFaded?: boolean;
    onClick: () => void;
  }) => {
    return (
      <Checkbox
        label={options.name}
        value={options.isActive}
        // to let animations play until the end (otherwise there are problems)
        isDisabled={renderSettings.renderMode === CharacterRenderMode.animation}
        onChange={(v) => {
          if (v) {
            options.onClick();
          } else {
            onClickDefault();
          }
        }}
        style={options.isFaded ? { opacity: 0.5 } : undefined}
      />
    );
  };

  //
  // WALK ANIMATION FRAME
  //
  const timerRef = useRef<any>();
  const animationFrameRef = useRef(0);

  useEffect(() => {
    animationFrameRef.current = 0;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (renderSettings.renderMode === CharacterRenderMode.walk) {
      timerRef.current = setInterval(() => {
        animationFrameRef.current = animationFrameRef.current + 1;
        setRenderSettings({
          ...renderSettings,
          walkAnimationFrame: animationFrameRef.current,
        });
      }, GAMEPLAY.settings.walkSpeed);
    }
  }, [renderSettings.renderMode, renderSettings.direction]);

  //
  // RENDER
  //
  return (
    <Box
      label={`Preview - ${characterRender.name}`}
      level={3}
      gap="20px"
      isOpen={isOpen ?? true}
      useFlex
    >
      {/* RENDERED CHARACTER */}
      <CharacterPreview width={`${width * scale}px`}>
        <AssetPreview width={width} height={height} scale={scale}>
          <Character
            config={characterConfig}
            characterRender={characterRender.render}
            position={{
              x: Math.round(width / 2),
              y: Math.round(height * 0.75),
            }}
            facing={renderSettings.direction}
            renderMode={renderSettings.renderMode}
            playAnimation={renderSettings.playAnimation}
            walkAnimationFrame={renderSettings.walkAnimationFrame}
            ignoreIdle
            renderOrigin
            isPoisoned={isPoisoned}
            forceRenderOverrideSkillId={skillIdOverride}
            isAssetPreview
          />
        </AssetPreview>
      </CharacterPreview>

      {/* FILTERS */}
      <Box
        label="Render Customization"
        level={2}
        isOpen={true}
        childMinWidth="90%"
        minWidth="150px"
        maxWidth={`${width * scale}px`}
        contentPaddingTop="10px"
      >
        <Checkbox
          label={`Poisoned`}
          value={isPoisoned}
          isDisabled={!characterRender.render?.filters?.poison}
          onChange={setIsPoisoned}
        />

        {Object.entries(SKILL_CONFIGS).map(([skillId, skillConfig]) => {
          if (!skillConfig?.characterRender?.override?.image?.src) {
            return null;
          }
          return (
            <Checkbox
              key={skillId}
              label={`${splitByCapitalLetters(skillId, " ")}`}
              value={skillIdOverride === skillId}
              isDisabled={!characterRender.render?.filters?.poison}
              onChange={() =>
                setSkillIdOverride(
                  skillIdOverride !== skillId ? (skillId as SkillId) : undefined
                )
              }
            />
          );
        })}
      </Box>

      {/* ANIMATION BUTTONS */}
      <Box
        label="Render Mode"
        level={2}
        isOpen={true}
        childMinWidth="40%"
        useFlex
      >
        <Box renderContentOnly isOpen childMinWidth="90%" minWidth="200px">
          <div>
            <Column>
              <Divider name="Common:" marginTop="0" />

              {/* DEFAULT */}
              {renderButton({
                name: `Default`,
                isActive:
                  renderSettings.renderMode === CharacterRenderMode.default,
                onClick: onClickDefault,
              })}

              {/* TALK */}
              {renderButton({
                name: `Talk`,
                isActive:
                  renderSettings.renderMode === CharacterRenderMode.talk,
                onClick: onClickTalk,
              })}

              {/* WALK */}
              {[
                Direction.left,
                Direction.right,
                Direction.up,
                Direction.down,
              ].map((direction) => (
                <React.Fragment key={direction}>
                  {renderButton({
                    name: `Walk ${capitalizeFirstLetter(direction)}`,
                    isActive:
                      renderSettings.renderMode === CharacterRenderMode.walk &&
                      renderSettings.direction === direction,
                    onClick: () => onClickWalk(direction),
                  })}
                </React.Fragment>
              ))}
            </Column>
          </div>
        </Box>

        <Box renderContentOnly isOpen childMinWidth="90%" minWidth="200px">
          {/* ANIMATIONS */}
          <div>
            <Column>
              <Divider name="Animations:" marginTop="0" />

              {Object.keys(CharacterAnimationId).map((animationId, i) => {
                const isUndefinedAnimation =
                  !characterRender?.render?.animations
                    ?.map((a) => a.animationId)
                    .includes(animationId);

                return (
                  <React.Fragment key={animationId}>
                    {renderButton({
                      name: `${i < 10 ? "0" : ""}${
                        i + 1
                      } - ${splitByCapitalLetters(
                        capitalizeFirstLetter(animationId),
                        " "
                      )}`,

                      isActive:
                        renderSettings.renderMode ===
                          CharacterRenderMode.animation &&
                        renderSettings.playAnimation?.id === animationId,

                      isFaded: isUndefinedAnimation,

                      onClick: () =>
                        onClickAnimation({
                          animationId,
                          isUndefinedAnimation,

                          onStart:
                            animationId ===
                            CharacterAnimationId.celestialFlameEnd
                              ? () => {
                                  setSkillIdOverride(undefined);
                                }
                              : undefined,

                          onEnd:
                            animationId ===
                            CharacterAnimationId.celestialFlameStart
                              ? () => {
                                  setSkillIdOverride(SkillId.CelestialFlame);
                                }
                              : undefined,
                        }),
                    })}
                  </React.Fragment>
                );
              })}
            </Column>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default CharacterRenderAssets;
