import SCENE_CONFIGS_DEV from "./_DEVELOPMENT";
import SCENE_CONFIGS_TEST from "./__to-delete__test-scenes";
import { SceneDefinitionsType } from "game-engine/types";

const SCENE_CONFIGS: SceneDefinitionsType = {
  ...SCENE_CONFIGS_DEV,
  ...SCENE_CONFIGS_TEST,
};

export default SCENE_CONFIGS;

export const getSceneConfigById = (sceneConfigId) => {
  // Note that the component name and configId need to be the same!
  return SCENE_CONFIGS[sceneConfigId];
};
