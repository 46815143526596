import {
  EditorDialogActions,
  EditorType,
} from "game-engine/_dev/scene-editor-create-new";
import {
  SceneWalkPathBlockedType,
  SceneWalkPathHorizontalType,
  SceneWalkPathVerticalType,
} from "game-engine/types";

import { GameObjective } from "game-files/gameObjectives";
import { getCode_SceneTalkActions } from "./code-actions";

//
// SCENE WALK PATH
//
export const getCode_SceneWalkPath = (
  walkPath:
    | SceneWalkPathHorizontalType
    | SceneWalkPathVerticalType
    | SceneWalkPathBlockedType,
  blockedActions: EditorDialogActions
) => {
  if (!walkPath) {
    return "undefined";
  }

  const codeLines = ["{"];

  if ((walkPath as SceneWalkPathBlockedType)?.isBlocked) {
    codeLines.push(`isBlocked: true,`);
    codeLines.push(`actions: ${getCode_SceneTalkActions(blockedActions)}`);
  }

  if ((walkPath as SceneWalkPathVerticalType)?.edgeWalkX) {
    codeLines.push(
      `edgeWalkX: ${(walkPath as SceneWalkPathVerticalType)?.edgeWalkX},`
    );
  }
  if ((walkPath as SceneWalkPathHorizontalType)?.edgeWalkY) {
    codeLines.push(
      `edgeWalkY: ${(walkPath as SceneWalkPathHorizontalType)?.edgeWalkY},`
    );
  }

  const _walkPath = walkPath as
    | SceneWalkPathHorizontalType
    | SceneWalkPathVerticalType;

  if (_walkPath?.walkTo) {
    codeLines.push(
      `walkTo: {x: ${_walkPath?.walkTo?.x}, y: ${_walkPath?.walkTo?.y}},`
    );
  }

  codeLines.push("}");
  return codeLines.join("\n");
};

//
// SCENE WALK PATHS
//
export const getCode_SceneWalkPaths = (editor: EditorType) => {
  const walkPaths = editor.scene.sceneWalkPaths[GameObjective.GAME_START];
  const actions = editor.meta.walkPathsBlockedActions;

  const codeLines = ["{"];
  codeLines.push(
    `left: ${getCode_SceneWalkPath(walkPaths.left, actions.left)},`
  );
  codeLines.push(
    `right: ${getCode_SceneWalkPath(walkPaths.right, actions.right)},`
  );
  codeLines.push(`up: ${getCode_SceneWalkPath(walkPaths.up, actions.up)},`);

  codeLines.push(
    `down: ${getCode_SceneWalkPath(walkPaths.down, actions.down)},`
  );

  if (!editor?.noCodeExamples) {
    codeLines.push(`
    /*
    // EXAMPLE OF CUSTOM CLICK AREAS
    left: {
      edgeWalkY: 146,
      walkTo: { x: 72, y: 137 },
      clickArea: {
        rectangle: { x: 5, y: 5, width: 50, height: 100 },
      },
    },

    right: {
      walkTo: { x: 235, y: 124 },
      walkToEnd: { x: 299, y: 122 },
      clickArea: {
        image: { src: IMAGE_WalkOutArea_Cave },
      },
    },
    */`);
  }

  if (!editor?.noCodeExamples) {
    codeLines.push(`
    /*
    // EXAMPLE OF CONDITIONAL SCENE WALK-PATHS
    conditional: ({ events }) => [
    
      {
        condition: events.test_treeClickCount > 1,
        state: {
          right: {
            edgeWalkY: 100,
            walkTo: { x: 278, y: 106 },
          },
        },
      }

    ]
    */`);
  }

  codeLines.push("}");
  return codeLines.join("\n");
};
