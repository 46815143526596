import GAME_CONFIG from "game-files/gameConfig";
import { Group } from "react-konva";
import { Portal } from "react-konva-utils";
import { SCENE_OVERLAY_NAME } from "game-engine/components/game-viewport/GameViewport";
import useGame from "game-engine/hooks/useGame";

const SceneOverlay = (props: { listening?: boolean; children?: any }) => {
  const { listening } = props;
  const { sceneOffset } = useGame();

  return (
    <Portal selector={`.${SCENE_OVERLAY_NAME}`} enabled={true}>
      <Group
        listening={listening ?? false}
        position={sceneOffset}
        clipX={0}
        clipY={0}
        clipWidth={GAME_CONFIG.scene.dimensions.x}
        clipHeight={GAME_CONFIG.scene.dimensions.y}
      >
        {props.children}
      </Group>
    </Portal>
  );
};

export default SceneOverlay;
