import { CharacterConfigType } from "game-engine/types";
import { CharacterId } from "game-files/common/ids";
import Felix from "./Felix";
import TestCharacter from "./TestCharacter";
import Tester from "./_Tester";

const CHARACTER_CONFIGS: { [key in CharacterId]: CharacterConfigType } = {
  Felix,
  Tester,
  TestCharacter,
};

export const getCharacterConfigById = (characterConfigId: CharacterId) => {
  return CHARACTER_CONFIGS[characterConfigId];
};

export default CHARACTER_CONFIGS;
