import {
  CharacterAnimationId,
  ItemConfigId,
  SkillId,
} from "game-files/common/ids";

import { ACTION } from "game-engine/utils";
import { ActionQueueWithItemType } from "game-engine/types";
import ITEM_CONFIGS from "game-files/items/ITEM_CONFIGS";

const onClickWithItem: ActionQueueWithItemType = {
  //
  // Default
  //
  default: [
    ACTION.talkRandom({
      dialogOptions: [
        {
          en: `What do you expect me to do with it?`,
          cz: "Co s tím mám dělat?",
        },
        {
          en: `Correct, that's an item.`,
          cz: `Správně, tohle je předmět.`,
        },
      ],
    }),
  ],

  // LanternCelestialFlame
  //
  [ItemConfigId.LanternCelestialFlame]: [
    ACTION.startSkill(SkillId.CelestialFlame),
  ],

  // Test_Key
  //
  [ItemConfigId.Test_Key]: [
    ACTION.talkRandom({
      dialogOptions: [
        {
          en: `That's a ${ITEM_CONFIGS.Test_Key.name.en.toLowerCase()}.`,
          cz: `To je ${ITEM_CONFIGS.Test_Key.name.cz.toLowerCase()}.`,
        },
        {
          en: `Yea I can't eat that.`,
          cz: `Toho se nenajím.`,
        },
      ],
    }),
  ],

  // Test_FlaskWater
  //
  [ItemConfigId.Test_FlaskWater]: [
    ACTION.swapItemInCursorConfig({
      newItemConfigId: ItemConfigId.Test_Flask,
      blockGui: true,
    }),
    ACTION.talkRandom({
      dialogOptions: [
        {
          en: `What a refreshing feeling!`,
          cz: `Jak osvěžující!`,
        },
        {
          en: `Water is not my cup of tea.`,
          cz: `Voda není můj šálek čaje.`,
        },
      ],
    }),
  ],

  // Test_FlaskToxic
  //
  [ItemConfigId.Test_FlaskToxic]: [
    ACTION.characterAnimation({
      animationId: CharacterAnimationId.yawn,
    }),
    ACTION.poisonMainCharacter(),
    ACTION.deleteItemInCursor(),
    ACTION.pause({
      durationSec: 1,
    }),
    ACTION.talkRandom({
      dialogOptions: [
        {
          en: `That's didn't feel quite right.`,
          cz: `Neudělalo mi to moc dobře.`,
        },
        {
          en: `That tasted about as good as it looked.`,
          cz: `Chutnalo to stejně jedovatě jako to vypadalo.`,
        },
      ],
    }),
  ],
};

export default onClickWithItem;
