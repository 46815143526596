import {
  CharacterAnimationId,
  CharacterId,
  CinematicId,
  ItemConfigId,
} from "game-files/common/ids";
import {
  CursorRenderType,
  Direction,
  SceneType,
  SpriteDirection,
  TalkDirection,
} from "game-engine/types";

import { ACTION } from "game-engine/utils";
import { GameEvent } from "game-files/gameEvents";
import { GameObjective } from "game-files/gameObjectives";
import ImageBackground from "./assets/01_background.png";
import ImageBackground2 from "./assets/01_background_02.png";
import ImageLayerRock from "./assets/07_rock.png";
import ImageLayerTree1_State1 from "./assets/03_tree_state-01.png";
import ImageLayerTree1_State2 from "./assets/03_tree_state-02.png";
import ImageLayerTree2 from "./assets/04_tree.png";
import ImageLayerTree3 from "./assets/05_tree.png";
import ImageLayerTree4 from "./assets/06_tree.png";
import ImageMapDepth from "./assets/_MAP_depth.png";
import ImageMapDrop from "./assets/_MAP_drop.png";
import ImageMapWalk from "./assets/_MAP_walk.png";
import ImagePreview from "./assets/preview.png";
import ImageStream from "./assets/02_stream.png";
import ImageStreamSprite from "./assets/02_stream-sprite.png";
import MUSIC_CONFIGS from "game-files/audio/MUSIC_CONFIGS";
import SHARED_DATA from "game-files/scenes/SHARED_DATA";
import SOUND_CONFIGS from "game-files/audio/SOUND_CONFIGS";
import TestCharacterConfig from "game-files/characters/TestCharacter";

const DevScene02: SceneType = {
  isDev: true,
  isUsingTestObjectives: true, // IMPORTANT TO KEEP TEST AND GAME OBJECTIVES SEPARATELY
  sceneNeighbors: undefined, // IS GENERATED FROM SCENES LAYOUT
  uniqueId: "DevScene02", // USUALLY GENERATED FROM SCENES LAYOUT, UNLESS DEV SCENE THAT'S NOT IN SCENE LAYOUTS!
  configId: "DevScene02",
  preview: { src: ImagePreview },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Scene Name
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  name: {
    [GameObjective.GAME_START]: { en: "Sick Guy", cz: undefined },
    [GameObjective.obj_01_find_grandma]: {
      en: "Sick Guy 2 (progress 1)",
      cz: undefined,
    },
    [GameObjective.obj_02_find_lantern]: {
      en: "Sick Guy 2 (progress 2)",
      cz: undefined,
    },
    [GameObjective.obj_03_get_floating_fire]: {
      en: "Sick Guy 2 (progress 3)",
      cz: undefined,

      // EXAMPLE OF CONDITIONAL SCENE NAME
      conditional: ({ events }) => [
        {
          condition: events.test_treeClickCount > 3,
          state: {
            en: "Sick Guy 2 (conditional)",
            cz: undefined,
          },
        },
      ],
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Scene Audio
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  audio: {
    [GameObjective.GAME_START]: {
      music: {
        musicConfigId: MUSIC_CONFIGS.Test_01.id,
      },
      sound: {
        soundConfigId: SOUND_CONFIGS.Test_River.id,
        volume: 0.5,
        loop: true,
      },
    },
    [GameObjective.obj_02_find_lantern]: {
      music: {
        musicConfigId: MUSIC_CONFIGS.Test_03.id,
      },
    },
    [GameObjective.obj_03_get_floating_fire]: {
      music: {
        musicConfigId: MUSIC_CONFIGS.Test_02.id,
      },

      // EXAMPLE OF CHANGING STATE BASED ON EVENTS
      conditional: ({ events }) => [
        {
          condition: events.test_treeClickCount > 3,
          state: {
            sound: {
              soundConfigId: SOUND_CONFIGS.Test_Bird.id,
              volume: 0.5,
              loop: true,
            },
          },
        },
      ],
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Scene Walk Paths
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  sceneWalkPaths: {
    [GameObjective.GAME_START]: {
      up: {
        isBlocked: true,
        actions: [
          /* ACTION.playCinematic({
            cinematicId: CinematicId.TestCinematicScene01,
            onEnd: {
              returnToThisScene: true,
            },
          }), */

          ACTION.playCinematicSequence({
            cinematicIds: [
              CinematicId.TestCinematicScreen05,
              CinematicId.TestCinematicScene01,
              CinematicId.TestCinematicScreen04,
              CinematicId.TestCinematicScene02,
            ],
            onEnd: {
              returnToThisScene: true,
              sceneFadeIn: true,
            },
            pauseBetween: {
              durationSec: 1,
            },
          }),
        ],
      },
      left: {
        edgeWalkY: 100,
        walkTo: { x: 27, y: 110 },
      },
      right: {
        isBlocked: true, // EXAMPLE OF BLOCKING AREA + IT CAN BE CHANGED BY OBJECTIVE OR CONDITION (BELOW)
        actions: [
          ACTION.talkRandom({
            dialogOptions: [
              {
                en: `Conditional won't allow me to go.`,
                cz: "Conditional nastavení mi to nedovolí.",
              },
            ],
          }),
        ],
      },
      down: {
        edgeWalkX: 250,
        walkTo: { x: 251, y: 131 },
      },

      // EXAMPLE OF CHANGING SCENE WALK PATHS BASED ON EVENTS
      conditional: ({ events }) => [
        {
          condition: events.test_treeClickCount > 1,
          state: {
            right: {
              edgeWalkY: 100,
              walkTo: { x: 278, y: 106 },
            },
          },
        },
        {
          condition: events.test_treeClickCount > 1,
          state: {
            left: {
              isBlocked: true, // EXAMPLE OF BLOCKING AREA + IT CAN BE CHANGED BY OBJECTIVE OR CONDITION (BELOW)
              actions: [
                ACTION.talkRandom({
                  dialogOptions: [
                    {
                      en: `Conditional won't allow me to go.`,
                      cz: "Conditional nastavení mi to nedovolí.",
                    },
                  ],
                }),
              ],
            },
          },
        },
        {
          condition: events.test_treeClickCount > 1,
          state: {
            down: {
              isBlocked: true, // EXAMPLE OF BLOCKING AREA + IT CAN BE CHANGED BY OBJECTIVE OR CONDITION (BELOW)
              actions: [
                ACTION.talkRandom({
                  dialogOptions: [
                    {
                      en: `Conditional won't allow me to go.`,
                      cz: "Conditional nastavení mi to nedovolí.",
                    },
                  ],
                }),
              ],
            },
          },
        },
      ],
    },

    [GameObjective.obj_01_find_grandma]: {
      left: {
        isBlocked: true, // EXAMPLE OF CHANGING WALK-AREA + POSSIBILITY TO BLOCK IT
        actions: [
          ACTION.talkRandom({
            dialogOptions: [
              {
                en: `I cannot go there right now.`,
                cz: "Tam zatím jít nemůžu.",
              },
              {
                en: `That's not going to happen.`,
                cz: "Nějak se k tomu nemám.",
              },
            ],
          }),
        ],
      },
    },
  },

  images: {
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // SCENE BACKGROUND
    //
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    background: {
      dataByCurrentObjective: {
        [GameObjective.GAME_START]: {
          image: { src: ImageBackground },
          dropMap: { src: ImageMapDrop },
          walkMap: { src: ImageMapWalk },
          depthMap: { src: ImageMapDepth },
          depthSettings: undefined,
        },

        // EXAMPLE OF CHANGING BACKGROUND BASED ON CURRENT OBJECTIVE
        [GameObjective.obj_02_find_lantern]: {
          image: { src: ImageBackground2, transition: { durationSec: 1 } }, // fade-in new image
        },
        [GameObjective.obj_03_get_floating_fire]: {
          image: { src: ImageBackground, transition: { durationSec: 0 } }, // instantly show new image

          // EXAMPLE OF CHANGING BACKGROUND BASED ON EVENTS
          conditional: ({ events }) => [
            {
              condition: events.test_treeClickCount > 3,
              state: {
                image: {
                  src: ImageBackground2,
                  transition: { durationSec: 1 },
                },
              },
            },
          ],
        },
      },

      actionsByCurrentObjective: {
        [GameObjective.GAME_START]: {
          actions: [
            ACTION.talkRandom({
              dialogOptions: [
                ...SHARED_DATA.dialogOptions.forestBackground,
                {
                  en: `That's just the background..`,
                  cz: "To je jen pozadí...",
                },
                {
                  en: `I'm not gonna go there.`,
                  cz: "Tam nelezu.",
                },
              ],
            }),
          ],

          actionsWithItem: undefined,
        },

        [GameObjective["02_fillFlask"]]: {
          actions: [
            ACTION.talkRandom({
              dialogOptions: [
                {
                  en: `I'm only saying this to prove that objective has been changed.`,
                  cz: "Tohle říkám jen abych dokázal, že se změnil aktuální úkol.",
                },
              ],
            }),
          ],
        },
      },
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // SCENE LAYERS
    //
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    layers: [
      {
        id: "stream",
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: ImageStream },
          },
        },
        actionsByCurrentObjective: {
          //
          // DEFAULT ACTIONS
          //
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  {
                    en: "I should ask that guy over there if he's thirsty.",
                    cz: "Měl bych se tam toho chlapíka zeptat, jestli nemá žízeň.",
                  },
                ],
              }),
            ],

            actionsWithItem: {
              default: [
                ACTION.talkRandom({
                  dialogOptions: [
                    {
                      en: `I better be careful not to drop it.`,
                      cz: `Pozor aby mi to tam nespadlo.`,
                    },
                    {
                      en: `I don't really know what I'm trying to achieve here.`,
                      cz: `Teda nevím čeho jsem tím chtěl docílit.`,
                    },
                  ],
                }),
              ],

              [ItemConfigId.Test_Flask]: [
                ACTION.playSound({
                  soundConfigId: SOUND_CONFIGS.Test_Vial_Full_Grab.id,
                }),
                ACTION.swapItemInCursorConfig({
                  newItemConfigId: ItemConfigId.Test_FlaskWater,
                  blockGui: true,
                }),
                ACTION.talkRandom({
                  dialogOptions: [
                    {
                      en: `A flask of water may come in handy.`,
                      cz: `Láhev vody se vždycky může hodit.`,
                    },
                    {
                      en: `I'll fill my flask.`,
                      cz: `Naplním si láhev.`,
                    },
                  ],
                }),
              ],
            },
          },

          //
          // ACTIONS - OBJECTIVE FILL FLASK FOR THE GREEN GUY
          //
          [GameObjective.obj_01_find_grandma]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  {
                    en: "I need a flask.",
                    cz: "Potřebuji láhev.",
                  },
                ],
              }),
            ],
          },

          //
          // ACTIONS - OBJECTIVE DONE
          //
          [GameObjective.obj_03_get_floating_fire]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  {
                    en: "I don't need any more water.",
                    cz: "Vodu už nepotřebuji.",
                  },
                ],
              }),
            ],
          },
        },
      },

      {
        id: "stream-animation",
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            ignoreOnClick: true,
            image: { src: ImageStreamSprite },
            spriteConfig: {
              direction: SpriteDirection.Horizontal,
              frameCount: 13,
              frameDurationMilliseconds: 60,
            },
          },
        },
      },

      {
        id: "tree-0",
        depthY: 64,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: ImageLayerTree1_State1 },

            // EXAMPLE OF CONDITIONAL SCENE LAYER IMAGE
            conditional: ({ events }) => [
              {
                condition:
                  events.test_treeClickCount > 0 &&
                  events.test_treeClickCount % 2 === 0,
                state: {
                  image: {
                    src: ImageLayerTree1_State2,
                    transition: { durationSec: 1 },
                  },
                },
              },
            ],
          },
        },

        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              // EXAMPLE OF CONDITIONAL ACTIONS
              ACTION.conditional(({ gamePlay, gameItems }) => [
                {
                  condition: gamePlay.events.test_treeClickCount === 0,
                  actions: [
                    ACTION.talk({
                      dialog: {
                        en: "This is the first time I'm talking about it.",
                        cz: "Tohle je poprvé co o tom mluvím.",
                      },
                    }),
                  ],
                },
                {
                  condition: gamePlay.events.test_treeClickCount > 0,
                  actions: [
                    ACTION.talk({
                      dialog: {
                        en: `Click count is at ${gamePlay.events.test_treeClickCount}.`,
                        cz: `Počet kliknutí je ${gamePlay.events.test_treeClickCount}.`,
                      },
                    }),
                  ],
                },
              ]),

              ACTION.setEvents((events) => {
                return {
                  ...events,
                  [GameEvent.test_treeClickCount]:
                    events.test_treeClickCount + 1,
                };
              }),
            ],
          },
        },
      },

      {
        id: "tree-1",
        depthY: 66,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: ImageLayerTree2 },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  {
                    en: `Yep, that's a tree.`,
                    cz: "Správně, to je strom.",
                  },
                  {
                    en: `This is an incredibly long and boring text, just to try out the timing of this particular dialog in the game.`,
                    cz: "Tohle je nesmyslně dlouhý a nudný text jen abych vyzkoušel časovaní pro tento konkrétní text ve hře.",
                  },
                ],
              }),
            ],
          },
        },
      },

      {
        id: "tree-2",
        depthY: 97,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: ImageLayerTree3 },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.walkTo({
                position: { x: 120, y: 110 },
              }),
              ACTION.face({
                faceDirection: Direction.left,
              }),
              ACTION.talkRandom({
                dialogOptions: [
                  {
                    en: `That tree has to be centuries old!`,
                    cz: "Ten strom tu musí stát celá staletí!",
                  },
                  {
                    en: `What a nice tree.`,
                    cz: "Jaký to pěkný strom.",
                  },
                  [
                    {
                      en: `I bet it's older than me...`,
                      cz: "Ten je určitě starší než já...",
                    },
                    {
                      en: `At least a year or two for sure.`,
                      cz: "Tak o rok nebo dva určitě.",
                    },
                  ],
                ],
              }),
            ],
          },
        },
      },

      {
        id: "tree-3",
        depthY: 10000,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: ImageLayerTree4 },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  {
                    en: `I bet I can hide behind that tree.`,
                    cz: "Za ten strom se určitě můžu schovat.",
                  },
                ],
              }),
            ],
          },
        },
      },

      {
        id: "massive-rock",
        depthY: 10000,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            cursorOnHover: CursorRenderType.notAllowed,
            image: { src: ImageLayerRock },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actions: [
              // EXAMPLE OF VIEWPORT SHAKE EFFECT
              //
              ACTION.viewportShake({ durationSec: 1 }),
              ACTION.pause({ durationSec: 1 }),
              ACTION.viewportShake({ durationSec: 1 }),
              ACTION.pause({ durationSec: 1 }),

              ACTION.talkRandom({
                dialogOptions: [
                  {
                    en: `Whoa! Did you feel that?`,
                    cz: "Ty jo! Taky jsi to cítil?",
                  },
                ],
              }),

              ACTION.pause({
                durationSec: 1,
              }),

              // EXAMPLE OF VIEWPORT FADE OUT -> PLEASE NOTE THAT THIS EFFECT IS PRESERVED GLOBALLY
              // SO IT IS POSSIBLE TO FADE-OUT, SWAP SCENES AND THEN FADE-IN (INSIDE THAT SCENE CONFIG)
              // -- the fade-in can be done in scene onInit actions
              // -- then set next objective which will prevent the fade-in from repeating next time the scene is visited
              //
              ACTION.viewportFadeOut(/* { durationSec: 1 } */),
              ACTION.pause({
                durationSec: 1,
              }),
              ACTION.viewportFadeIn(/* { durationSec: 1 } */),

              ACTION.pause({
                durationSec: 1,
              }),

              // EXAMPLE OF SCENE FADE EFFECT
              //
              ACTION.sceneFadeOut(/* { durationSec: 1 } */),
              ACTION.pause({
                durationSec: 1,
              }),
              ACTION.sceneFadeIn(/* { durationSec: 1 } */),

              ACTION.pause({
                durationSec: 1,
              }),

              ACTION.talkRandom({
                dialogOptions: [
                  {
                    en: `Did somebody turn off the lights?`,
                    cz: "To tu někdo zhasnul světla?",
                  },
                ],
              }),

              ACTION.face({
                blockGui: true,
                faceDirection: Direction.right,
              }),

              ACTION.pause({
                durationSec: 1,
              }),

              ACTION.face({
                blockGui: true,
                faceDirection: Direction.left,
              }),

              ACTION.pause({
                durationSec: 1.5,
              }),

              ACTION.characterAnimation({
                animationId: CharacterAnimationId.shrug,
                //faceDirection: Direction.right,
              }),

              ACTION.conversation([
                {
                  dialog: [
                    {
                      en: "Here I go having a conversation with myself",
                      cz: "Tak si tu stojím a povídám si sám se sebou.",
                    },
                  ],
                  faceDirection: Direction.left,
                  renderOptions: {
                    eyesClosed: true,
                    direction: TalkDirection.down,
                  },
                },
                {
                  characterId: CharacterId.TestCharacter,
                  dialog: [
                    {
                      en: "You can do that quietly.",
                      cz: "Tak to dělej potichu.",
                    },
                  ],
                  faceDirection: Direction.right,
                  renderOptions: {
                    direction: TalkDirection.up,
                  },
                },
              ]),
            ],

            actionsWithItem: {
              [ItemConfigId.Test_Key]: [
                ACTION.talkRandom({
                  dialogOptions: [
                    {
                      en: `I'm not really sure what you expect the key to do to the rock.`,
                      cz: `Nejsem si úplně jistý co čekáš že klíč zmůže s kusem skály.`,
                    },
                  ],
                }),
              ],
            },
          },
        },
      },
    ],
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE ACTIONS
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  onSceneIdle: {
    // idleAfterMinSec: 1,
    // idleAfterMaxSec: 2,
    actionsByCurrentObjective: {
      [GameObjective.GAME_START]: {
        actions: [
          ACTION.sequence([
            ACTION.talk({
              characterId: CharacterId.TestCharacter,
              dialog: [
                {
                  en: `Hey there! Are you going to do something?`,
                  cz: "Hej! Budeš tu jen tak postávat, nebo i něco uděláš?",
                },
              ],
            }),
            ACTION.talk({
              dialog: [
                {
                  en: `I hope so...`,
                  cz: "Doufám že jo...",
                },
              ],
            }),
          ]),

          ACTION.talk({
            characterId: CharacterId.TestCharacter,
            dialog: [
              {
                en: `This scene is quite weird... it's one of the first ones.`,
                cz: "Tahle scéna je podivná... je to jedna z prvních.",
              },
            ],
          }),
        ],
      },
    },
  },

  onSceneInit: {
    actionsByCurrentObjective: {
      [GameObjective.GAME_START]: {
        actions: [
          // EXAMPLE OF CONDITIONAL INIT ACTIONS
          ACTION.conditional(({ gamePlay, gameItems }) => [
            {
              condition: gamePlay.events.test_treeClickCount === 0,
              actions: [
                ACTION.talkRandom({
                  characterId: CharacterId.TestCharacter,
                  dialogOptions: [
                    {
                      en: `Scene initialized, try to click on the tree.`,
                      cz: "Scéna inicializována, klikni na támhle ten strom.",
                    },
                  ],
                }),
              ],
            },

            {
              condition: gamePlay.events.test_treeClickCount > 0,
              actions: [
                ACTION.talkRandom({
                  characterId: CharacterId.TestCharacter,
                  dialogOptions: [
                    {
                      en: `So, this is the scene initialized.`,
                      cz: "Tímto je scéna inicializována,",
                    },
                  ],
                }),
              ],
            },
          ]),
        ],
      },
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE CHARACTERS
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  characters: [
    {
      config: TestCharacterConfig,
      dataByCurrentObjective: {
        [GameObjective.GAME_START]: {
          position: { x: 78, y: 102 },
          facing: Direction.right,

          onClickOverride: {
            actionsByCurrentObjective: {
              [GameObjective.GAME_START]: {
                actions: [
                  ACTION.setNextObjective(GameObjective.obj_01_find_grandma),

                  ACTION.talk({
                    characterId: CharacterId.TestCharacter,
                    dialog: [
                      {
                        en: "Talk to me again.",
                        cz: "Zeptej se mě znovu",
                      },
                    ],
                  }),
                ],

                actionsWithItem: {
                  default: [
                    ACTION.talkRandom({
                      characterId: CharacterId.TestCharacter,
                      dialogOptions: [
                        {
                          en: "What am I supposed to do with that?",
                          cz: "Co s tím mám dělat?",
                        },
                        {
                          en: "Nope, you keep it.",
                          cz: "Nech si to.",
                        },
                      ],
                    }),
                  ],
                },
              },

              [GameObjective.obj_01_find_grandma]: {
                actions: [
                  ACTION.setNextObjective(GameObjective.obj_02_find_lantern),

                  ACTION.talk({
                    characterId: CharacterId.TestCharacter,
                    dialog: [
                      {
                        en: "Hello there!",
                        cz: "Hej ty tam!",
                      },
                      {
                        en: "I'm not feeling very good at the moment, could you help me?",
                        cz: "Necítím se zrovna nejlépe, nemohl bys mi pomoct?",
                      },
                    ],
                  }),

                  ACTION.talk({
                    dialog: [
                      {
                        en: "Sure, what do you need green sir?",
                        cz: "Jasně, co potřebujete zelený pane?",
                      },
                    ],
                  }),

                  ACTION.talk({
                    characterId: CharacterId.TestCharacter,
                    dialog: {
                      en: "Take this flask and fill it with water far me.",
                      cz: "Vezmi si tuhle láhev a naplň mi ji vodou.",
                    },
                  }),

                  ACTION.talk({
                    dialog: [
                      {
                        en: "I'll be right back!",
                        cz: "Hned jsem zpět!",
                      },
                    ],
                  }),

                  ACTION.putNewItemIntoCursor({
                    newItemConfigId: ItemConfigId.Test_Flask,
                  }),
                ],
              },

              [GameObjective.obj_02_find_lantern]: {
                actions: [
                  ACTION.talkRandom({
                    characterId: CharacterId.TestCharacter,
                    dialogOptions: [
                      {
                        en: "Just bring me the water, please...",
                        cz: "Přines mi prosím tu vodu...",
                      },
                    ],
                  }),
                ],

                actionsWithItem: {
                  default: [
                    ACTION.talkRandom({
                      characterId: CharacterId.TestCharacter,
                      dialogOptions: [
                        {
                          en: "I might be mistaken... but that is NOT water!",
                          cz: "Možná se mýlím... ale tohle NENÍ voda!",
                        },
                        {
                          en: "I just need water, please...",
                          cz: "Potřebuju vodu, prosím...",
                        },
                      ],
                    }),
                  ],

                  [ItemConfigId.Test_FlaskWater]: [
                    ACTION.deleteItemInCursor(),
                    ACTION.talk({
                      characterId: CharacterId.TestCharacter,
                      dialog: [
                        {
                          en: "Thank you kind sir! I really needed this!",
                          cz: "Díky převzácný pane! To jsem potřeboval!",
                        },
                      ],
                    }),

                    ACTION.pause({ durationSec: 0.5 }),

                    ACTION.characterAnimation({
                      characterId: CharacterId.TestCharacter,
                      animationId: CharacterAnimationId.yawn,
                    }),

                    ACTION.setEvent({
                      key: GameEvent.test_testCharacterHealthy,
                      value: true,
                    }),

                    ACTION.characterAnimation({
                      characterId: CharacterId.TestCharacter,
                      animationId: CharacterAnimationId.shrug,
                    }),

                    ACTION.pause({ durationSec: 0.5 }),

                    ACTION.talk({
                      characterId: CharacterId.TestCharacter,
                      dialog: [
                        {
                          en: "I'm feeling better already!",
                          cz: "Hned se cítím lépe!",
                        },
                      ],
                    }),

                    ACTION.talk({
                      dialog: [
                        {
                          en: "Glad to help!",
                          cz: "Ale to vůbec není zač!",
                        },
                      ],
                    }),
                    ACTION.walkTo({
                      characterId: CharacterId.TestCharacter,
                      position: { x: 26, y: 102 },
                    }),

                    ACTION.setNextObjective(
                      GameObjective.obj_03_get_floating_fire
                    ),
                  ],
                },
              },

              [GameObjective.obj_03_get_floating_fire]: {
                actions: [
                  ACTION.talkRandom({
                    characterId: CharacterId.TestCharacter,
                    dialogOptions: [
                      {
                        en: "Thanks for your help, you can go away now!",
                        cz: "Díky za pomoc, už můžeš jít pryč!",
                      },
                    ],
                  }),
                ],

                actionsWithItem: {
                  default: [
                    ACTION.talkRandom({
                      characterId: CharacterId.TestCharacter,
                      dialogOptions: [
                        {
                          en: "Thanks for the water, but you can keep this.",
                          cz: "Díky za vodu, ale tohle si můžeš nechat.",
                        },
                        {
                          en: "I already have all I need, thanks.",
                          cz: "Díky, ale už mám vše, co potřebuji.",
                        },
                      ],
                    }),
                  ],

                  [ItemConfigId.Test_FlaskWater]: [
                    ACTION.talkRandom({
                      characterId: CharacterId.TestCharacter,
                      dialogOptions: [
                        {
                          en: "Thank you, but I already have enough water.",
                          cz: "Díky, ale vody už mám dostatek.",
                        },
                        {
                          en: "I don't need any more water.",
                          cz: "Více vody už nepotřebuji.",
                        },
                      ],
                    }),
                  ],
                },
              },
            },
          },
        },

        [GameObjective.obj_03_get_floating_fire]: {
          // EXAMPLE OF CURRENT-OBJECTIVE BASED CHARACTER OPTIONS
          facing: Direction.left,
          position: { x: 26, y: 102 },

          /* conditional: ({ events }) => [
            {
              // EXAMPLE OF CONDITIONAL CHARACTER OPTIONS
              condition: events.test_treeClickCount > 3,
              state: {
                facing: Direction.right,
                position: { x: 267, y: 86 },
              },
            },
          ], */
        },
      },
    },
  ],

  // TODO - ADD MAIN CHARACTER ONCLICK OVERRIDES (for example - in a dark scene he can say "I'm afraid of the dark" or stuff like that)
};

export default DevScene02;
