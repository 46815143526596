import {
  ActionQueueType,
  ActionsByCurrentObjectiveType,
  BlendingMode,
  CharacterConfigType,
  CharacterRenderMode,
  Direction,
  FontType,
  ImageFilters,
  ImageType,
  Position,
  SpriteConfigType,
  TalkRenderOptionsType,
  TextAlign,
  TextOutlineStyle,
  TextVerticalAlign,
  TintFilterOptions,
  TranslatedString,
} from ".";
import { ItemConfigId, SceneTag, SkillId } from "game-files/common/ids";

import { CursorRenderType } from "./cursor";
import { GameEventsType } from "game-files/gameEvents";
import { GameObjective } from "game-files/gameObjectives";

//
// SCENE
//
export type SceneType = {
  uniqueId: string; // this will be generated by scene layout, unique
  configId: string; // this identifies the scene definition, can be used multiple times

  isDev?: boolean;
  isUsingTestObjectives?: boolean;

  name: SceneNameByCurrentObjective;

  tags?: SceneTag[]; // TODO - NOT USED FOR ANYTHING YET (USE FOR FILTERING IN SCENE-LAYOUTS? - E.G. tag.act1)

  preview?: ImageType;
  sceneNeighbors?: SceneNeighborsType;
  sceneWalkPaths?: SceneWalkPathsByCurrentObjective;

  audio?: GameAudioByCurrentObjective;

  dynamicLighting?: DynamicLightingByCurrentObjective;

  images: {
    background: {
      dataByCurrentObjective: SceneBackgroundByCurrentObjective;
      actionsByCurrentObjective?: ActionsByCurrentObjectiveType;
    };
    layers?: SceneLayerType[];
  };

  mainCharacterOptions?: SceneMainCharacterOptions;

  onSceneIdle?: {
    idleAfterMinSec?: number;
    idleAfterMaxSec?: number;

    actionsByCurrentObjective?: {
      [key in GameObjective]?: {
        actions?: ActionQueueType;
      };
    };
  };

  onSceneInit?: {
    actionsByCurrentObjective?: {
      [key in GameObjective]?: {
        actions?: ActionQueueType;
      };
    };
  };

  onSkillStart?: {
    [skill in SkillId]?: {
      actionsByCurrentObjective?: {
        [key in GameObjective]?: {
          actionsBeforeSkill?: ActionQueueType;
          actions?: ActionQueueType;
        };
      };
    };
  };

  characters?: SceneCharactersType;
};

//
// GENERIC CONDITIONAL TYPES
//
export type StateConditionalProps = {
  events: GameEventsType;
};
export type StateConditionalData = {
  condition: boolean;
  state: any;
};

//
// SCENE NAME
//
export type SceneNameType = TranslatedString;

export type SceneNameConditionalFunc = (
  props: StateConditionalProps
) => (StateConditionalData & { state: SceneNameType })[];

export type SceneNameByCurrentObjective = {
  [key in GameObjective]?: SceneNameType & {
    conditional?: SceneNameConditionalFunc;
  };
};

//
// SCENE AUDIO
//
export type GameAudioType = {
  music?: SceneMusicType;
  sound?: SceneSoundsType;
};

export type GameAudioConditionalFunc = (
  props: StateConditionalProps
) => (StateConditionalData & { state: GameAudioType })[];

export type GameAudioByCurrentObjective = {
  [key in GameObjective]?: GameAudioType & {
    conditional?: SceneBackgroundConditionalFunc;
  };
};

export type SceneMusicType = {
  musicConfigId: string;
  volume?: number;
};

export type SceneSoundsType = {
  soundConfigId: string;
  volume?: number;
  loop?: boolean;
};

//
// SCENE BACKGROUND
//
export type SceneBackgroundType = {
  image?: ImageType;
  imageSpriteConfig?: SpriteConfigType;
  fillColor?: string;
  dropMap?: ImageType;
  walkMap?: ImageType;
  depthMap?: ImageType;
  depthSettings?: SceneDepthSettings;
};

export type SceneBackgroundConditionalFunc = (
  props: StateConditionalProps
) => (StateConditionalData & { state: SceneBackgroundType })[];

export type SceneBackgroundByCurrentObjective = {
  [key in GameObjective]?: SceneBackgroundType & {
    conditional?: SceneBackgroundConditionalFunc;
  };
};

//
// SCENE LAYER
//
export type SceneLayerDataType = {
  shape?: SceneLayerShapeType;
  image?: ImageType; // animation sprite image also goes here (animation settings are then below)
  spriteConfig?: SpriteConfigType;
  offset?: Position; // enables to offset scene layers with different dimensions than scene (especially sprites can save a lot of space)
  ignoreOnClick?: boolean; // allows the layer to be click-through (for foreground rain or similar effects)
  isInvisible?: boolean; // allows to specify click-areas with specific actions and hide them so it works only for onClick events
  cursorOnHover?: CursorRenderType;
  clickArea?: SceneLayerClickArea;
};

export type SceneLayerConditionalFunc = (
  props: StateConditionalProps
) => (StateConditionalData & { state: SceneLayerDataType })[];

export type SceneLayerByCurrentObjective = {
  [key in GameObjective]?: SceneLayerDataType & {
    conditional?: SceneLayerConditionalFunc;
  };
};

export type SceneLayerGenerateItem = {
  itemConfigId: ItemConfigId;
  pause?: {
    durationSec: number; // layer will be hidden after spawning the item for this long (timer is only checked on scene-change!)
  };
  // TODO - ADD MAX-SPAWN-COUNT
  // this will be used for things like a hat or something that should only
  // appear once in the game -> limit spawn count to 1
};

export type SceneLayerType = {
  id: string;
  depthY?: number; // Y coordinate to determine z-index
  generateItem?: SceneLayerGenerateItem;
  dataByCurrentObjective: SceneLayerByCurrentObjective;
  actionsByCurrentObjective?: ActionsByCurrentObjectiveType;
};

export type SceneLayerClickArea = {
  image: ImageType;
  offset?: Position;
};

//
// SCENE LAYER SHAPE
//
export type SceneLayerShapeType = {
  rect?: SceneLayerShapeRectType;
  text?: SceneLayerShapeTextType;
};

export type SceneLayerShapeRectType = {
  width: number;
  height: number;
  x?: number;
  y?: number;
  color?: string; // hex
  opacity?: number;
};

export type SceneLayerShapeTextType = {
  text: TranslatedString;
  width?: number;
  x?: number;
  y?: number;
  color?: string; // hex
  opacity?: number;
  outlined?: boolean;
  outlineStyle?: TextOutlineStyle;
  shadow?: boolean;
  align?: TextAlign;
  verticalAlign?: TextVerticalAlign;
  font?: FontType;
};

//
// SCENE RENDER LAYER
//
export type SceneRenderLayerType = {
  key: string;
  depthY: number;
  render: (props?: any) => React.ReactNode;
} | null;

//
// SCENE DEPTH
//
export type SceneDepthSettings = {
  scaleMin?: number; // scale on BLACK color in depth map
  scaleMax?: number; // scale on WHITE color in depth map
};

//
// SCENE WALK PATHS
//
export type WalkPathClickAreaImage = {
  image: ImageType;
};

export type WalkPathClickAreaRectangle = {
  rectangle: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
};

export type WalkPathClickAreaType =
  | WalkPathClickAreaImage
  | WalkPathClickAreaRectangle;

export type SceneWalkPathHorizontalType = {
  walkTo: Position;
  edgeWalkY?: number;
  walkToEnd?: Position;
  clickArea?: WalkPathClickAreaType;
};

export type SceneWalkPathVerticalType = {
  walkTo: Position;
  edgeWalkX?: number;
  walkToEnd?: Position;
  clickArea?: WalkPathClickAreaType;
};

export type SceneWalkPathWalkableType =
  | SceneWalkPathHorizontalType
  | SceneWalkPathVerticalType;

export type SceneWalkPathBlockedType = {
  isBlocked: boolean;
  actions?: ActionQueueType;
};

export type SceneWalkPathAnyType =
  | SceneWalkPathWalkableType
  | SceneWalkPathBlockedType;

export type SceneWalkPathsType = {
  left?: SceneWalkPathHorizontalType | SceneWalkPathBlockedType;
  right?: SceneWalkPathHorizontalType | SceneWalkPathBlockedType;
  up?: SceneWalkPathVerticalType | SceneWalkPathBlockedType;
  down?: SceneWalkPathVerticalType | SceneWalkPathBlockedType;
};

export type SceneWalkPathsConditionalFunc = (
  props: StateConditionalProps
) => (StateConditionalData & { state: SceneWalkPathsType })[];

export type SceneWalkPathsByCurrentObjective = {
  [key in GameObjective]?: SceneWalkPathsType & {
    conditional?: SceneWalkPathsConditionalFunc;
  };
};

//
// SCENE WALK AREA
//
export enum SceneWalkAreaEnum {
  "walkable" = "walkable",
  "blocked" = "blocked",
}

export type SceneWalkAreaBaseType = {
  direction: Direction;
  width: number;
  height: number;
  cursor: CursorRenderType;
  origin: Position;
  clickArea?: WalkPathClickAreaType;
};

export type SceneWalkAreaType = SceneWalkAreaBaseType & {
  type: SceneWalkAreaEnum.walkable;
  scene: SceneType;
  walkTo: Position;
};

export type BlockedSceneWalkAreaType = SceneWalkAreaBaseType & {
  type: SceneWalkAreaEnum.blocked;
  actions: ActionQueueType;
};

//
// SCENE NEIGHBORS
//
export type SceneNeighborsType = {
  sceneId_left?: string;
  sceneId_right?: string;
  sceneId_up?: string;
  sceneId_down?: string;
};

//
// SCENES LAYOUT
//
export type LayoutSceneType = {
  scene: SceneType;
  overrideSceneId?: string; // ..to be able to assign a unique id to a reuseable scene for dropping items
  // ...........................(this way, changing scenes-layout will not interfere with items that need to be dropped to a specific scene)
  noWalkLeft?: boolean;
  noWalkRight?: boolean;
  noWalkUp?: boolean;
  noWalkDown?: boolean;
};

export type ScenesLayoutLinkType = {
  sceneLinkId: string;
  scene1: {
    uniqueId: string; // id  of scene
    direction: Direction; // direction where scene2 is located
  };
  scene2: {
    uniqueId: string; // id  of scene
    direction: Direction; // direction where scene1 is located
  };
};

export type ScenesLayoutGridType = (LayoutSceneType | null)[][];

export type ScenesLayoutType = {
  id: string;
  grid: ScenesLayoutGridType;
  neighborGrid?: SceneType[][];
};

export type ScenesLayoutsDefType = {
  scenesLayouts: ScenesLayoutType[];
  sceneLinks: ScenesLayoutLinkType[]; // to link scenes between separate scenes layouts
};

export type GameScenesDataType = {
  scenesLayoutsDef: ScenesLayoutsDefType;
  scenesLayouts: ScenesLayoutType[];
  scenes: SceneType[];
  sceneIds: string[];
};

export type SceneDefinitionsType = { [id: string]: SceneType };

//
// SCENE CHARACTERS
//
export type SceneCharacterDataType = {
  config: CharacterConfigType; // WARNING - THIS CONFIG IS FILLED AUTOMATICALLY WHEN RETRIEVING DATA BY OBJECTIVE
  position: Position;
  renderMode?: CharacterRenderMode;
  isPoisoned?: boolean;
  walkAnimationFrame?: number;
  scale?: number;
  facing?: Direction;
  dialog?: TranslatedString;
  talkRenderOptions?: TalkRenderOptionsType;
  playAnimation?: SceneCharacterPlayAnimationType;

  // This overrides onClick from character config
  onClickOverride?: {
    actionsByCurrentObjective?: ActionsByCurrentObjectiveType;
  };
};

export type SceneCharacterPlayAnimationType = {
  id: string;
  onAnimationEnd?: () => void;
  keepLastFrame?: boolean;
};

export type SceneCharacterConditionalFunc = (
  props: StateConditionalProps
) => (StateConditionalData & { state: Optional<SceneCharacterDataType> })[];

export type SceneCharacterByCurrentObjective = {
  [key in GameObjective]?: Optional<SceneCharacterDataType> & {
    conditional?: SceneCharacterConditionalFunc;
  };
};

export type SceneCharacterType = {
  config: CharacterConfigType;
  dataByCurrentObjective?: SceneCharacterByCurrentObjective;
};

export type SceneCharactersType = SceneCharacterType[];

export type Optional<T> = {
  [K in keyof T]?: T[K];
};

export type SceneMainCharacterOptions = {
  isHidden?: boolean;
  position?: Position;
  facing?: Direction;
};

//
// DYNAMIC LIGHTING
//
export type DynamicLightingConditionalFunc = (
  props: StateConditionalProps
) => (StateConditionalData & { state: Optional<DynamicLightingType> })[];

export type DynamicLightingByCurrentObjective = {
  [key in GameObjective]?: Optional<DynamicLightingType> & {
    conditional?: DynamicLightingConditionalFunc;
  };
};

export type DynamicLightingType = {
  deathByDarkness?: boolean;
  vignette?: DynamicLightingVignette;
  filters?: DynamicLightingSceneFilters;
};

export type DynamicLightingSceneFilters = {
  light?: ImageFilters;
  shadowLayers?: ImageFilters;
  shadowForeground?: ImageFilters;
};

export type DynamicLightingVignette = {
  opacity?: number;
  color?: string;
  size?: number; // 0 - 1
  blendingMode?: BlendingMode;
};

export type DynamicLightingLightSource = {
  position?: Position;
  tint: TintFilterOptions;
  flicker?: {
    color?: string;
    amount?: number;
    stepDurationSec?: number;
    opacityMin?: number;
    opacityMax?: number;
    blendingMode?: BlendingMode;
  };
};
