/* eslint-disable react-hooks/exhaustive-deps */

import {
  CharacterConfigType,
  CharacterRenderAnimationType,
  CharacterRenderType,
  ImageFilters,
  SpriteConfigType,
} from "game-engine/types";
import { useEffect, useState } from "react";

import { Group } from "react-konva";
import SpriteAnimated from "game-engine/components/basic-elements/SpriteAnimated";
import { getImageOffset } from "game-engine/utils";
import useGame from "game-engine/hooks/useGame";

const CharacterAnimation = (props: {
  isActive?: boolean;
  activeAnimation?: CharacterRenderAnimationType;
  config: CharacterConfigType;
  imageFilters: ImageFilters;
  characterRender: CharacterRenderType;
  onAnimationEnd?: () => void;
  keepLastFrame?: boolean;
  onImagesLoaded: () => void;
}) => {
  const {
    config,
    imageFilters,
    keepLastFrame,
    isActive,
    activeAnimation,
    characterRender,
    onImagesLoaded,
  } = props;
  const { engineConfig, logger } = useGame();

  const animations = characterRender.animations ?? [];

  //
  // IMAGE LOADER
  //
  const animationsLoadData = {};
  animations.forEach((a) => (animationsLoadData[a.animationId] = false));

  const [imagesLoaded, setImagesLoaded] = useState(animationsLoadData);

  useEffect(() => {
    const waitToLoadCount = Object.values(imagesLoaded).filter(
      (v) => !v
    ).length;
    if (!waitToLoadCount) {
      logger.graphics(
        `${config?.id} animation images loaded`,
        config,
        imagesLoaded
      );
      onImagesLoaded();
    }
  }, [imagesLoaded]);

  const onImageLoaded = (animationId: string) => {
    setImagesLoaded((prevImageLoader) => {
      const updatedLoaded = { ...prevImageLoader, [animationId]: true };
      return updatedLoaded;
    });
  };

  //
  // ON ANIMATION END
  //
  const [renderLastFrame, setRenderLastFrame] = useState(false);

  const onAnimationEnd = () => {
    if (keepLastFrame) {
      setRenderLastFrame(true);
    }
    if (props.onAnimationEnd) {
      props.onAnimationEnd();
    }
  };

  useEffect(() => {
    setRenderLastFrame(false);
  }, [activeAnimation?.animationId]);

  //
  // RENDER
  //
  return (
    <>
      {animations.map((animation) => {
        const isAnimationActive =
          isActive && activeAnimation?.animationId === animation.animationId;

        const spriteConfig: SpriteConfigType = {
          playCount: 1,
          ...animation.spriteConfig,
        };

        return (
          <Group
            key={animation.animationId}
            opacity={isAnimationActive ? 1 : 0}
            listening={isAnimationActive}
            position={getImageOffset({
              width: animation.spriteConfig.frameWidth,
              height: animation.spriteConfig.frameHeight,
            })}
          >
            <Group x={animation.offsetX || 0} y={animation.offsetY || 0}>
              <SpriteAnimated
                src={animation.image.src}
                spriteConfig={spriteConfig}
                onAnimationEnd={onAnimationEnd}
                loggerString={`custom animation ${animation.animationId}, ${config.id}`}
                controlledAnimation={{
                  playAnimation: renderLastFrame ? false : isAnimationActive,
                }}
                renderOutline={engineConfig.state.renderCharacterOutline}
                renderFill={engineConfig.state.renderCharacterFill}
                outlineColor={engineConfig.state.characterOutlineColor}
                fillColor={engineConfig.state.characterFillColor}
                onImageLoaded={() => onImageLoaded(animation.animationId)}
                filters={imageFilters}
                isHidden={!isAnimationActive}
              />
            </Group>
          </Group>
        );
      })}
    </>
  );
};

export default CharacterAnimation;
