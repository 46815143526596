import { Arrow, Group } from "react-konva";

import { Position } from "game-engine/types";

const Vector = (props: {
  lineWidth?: number;
  color?: string;
  position?: Position;
  vector: Position;
}) => {
  const {
    lineWidth = 1,
    color = "red",
    position = { x: 0, y: 0 },
    vector,
  } = props;

  const positionEnd = { x: position.x + vector.x, y: position.y + vector.y };

  // Adjust the position if lineWidth is odd to prevent blurry lines
  const offset = lineWidth % 2 === 1 ? 0.5 : 0;

  // Round the positions to integer values and adjust by the offset
  const roundedPosition = {
    x: Math.round(position.x) + offset,
    y: Math.round(position.y) + offset,
  };
  const roundedPositionEnd = {
    x: Math.round(positionEnd.x) + offset,
    y: Math.round(positionEnd.y) + offset,
  };

  //
  // RENDER
  //
  return (
    <Group>
      <Arrow
        points={[
          roundedPosition.x,
          roundedPosition.y,
          roundedPositionEnd.x,
          roundedPositionEnd.y,
        ]}
        stroke={color} // Color of the line
        fill={color} // Color of the arrowhead
        strokeWidth={lineWidth} // Thickness of the line
        pointerLength={2} // Length of the arrowhead
        pointerWidth={4} // Width of the arrowhead
      />
    </Group>
  );
};

export default Vector;
