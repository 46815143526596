import Box from "game-engine/_dev/basic-components/Box";
import Font from "./Font";
import FontMain from "game-engine/glyphs/FontMain";
import FontMini from "game-engine/glyphs/FontMini";

const AssetsFont = (props: { isOpen?: boolean }) => {
  //
  // RENDER
  //
  return (
    <Box
      label="Font"
      isOpen={props.isOpen}
      level={1}
      childMinWidth="90%"
      gap="20px"
    >
      {[
        { fontName: "Font Main", font: FontMain },
        { fontName: "Font Mini", font: FontMini },
      ].map(({ fontName, font }, i) => (
        <Font key={i} isOpen={true} font={font} fontName={fontName} />
      ))}
    </Box>
  );
};

export default AssetsFont;
