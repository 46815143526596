import {
  ActionId,
  Action_SaveGame,
  Action_SaveGameTypeEnum,
} from "game-engine/types";

import { createActionName } from ".";

//
// CREATE BASIC SAVE GAME ACTION
//
type SaveGameProps = {};

type SaveGameReturn = () => Action_SaveGame;

export const saveGameBeforeDeath: (props?: SaveGameProps) => SaveGameReturn =
  (props) => () => {
    return {
      ...(props ?? {}),
      _name: createActionName({
        name: `save game before death`,
      }),
      id: ActionId.saveGame,
      type: Action_SaveGameTypeEnum.deathsave,
    };
  };
