import { AnimationConfigType, ImageFilters } from "game-engine/types";

import SpriteAnimatedSingle from "game-engine/components/basic-elements/SpriteAnimatedSingle";

const Animation = (props: {
  animationConfig: AnimationConfigType;
  playAnimation: boolean;
  onImageLoaded?: () => void;
  onAnimationEnd?: () => void;
  playCount?: number;
  filters?: ImageFilters;
  isHidden?: boolean;
}) => {
  const {
    animationConfig,
    playAnimation,
    onImageLoaded,
    onAnimationEnd,
    filters,
    isHidden,
  } = props;

  const spriteSrc = animationConfig.sprite?.src;

  const spriteConfig = {
    ...animationConfig.spriteConfig,
    playCount: props.playCount ?? animationConfig.spriteConfig?.playCount,
  };

  return (
    <SpriteAnimatedSingle
      src={spriteSrc}
      spriteConfig={spriteConfig}
      onImageLoaded={onImageLoaded}
      onAnimationEnd={onAnimationEnd}
      controlledAnimation={{ playAnimation: playAnimation }}
      blendingMode={animationConfig?.blendingMode}
      filters={filters}
      isHidden={isHidden}
      loggerString={`animation ${animationConfig.name}`}
    />
  );
};

export default Animation;
