import { ImageFilters, SpriteConfigType } from "game-engine/types";
import { useEffect, useState } from "react";

import AssetPreview from "game-engine/_dev/basic-components/AssetPreview";
import SpriteAnimatedSingle from "game-engine/components/basic-elements/SpriteAnimatedSingle";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #000;

  button {
    align-self: flex-end;
  }
`;

const SpriteAnimationPreview = (props: {
  src?: string;
  spriteConfig: SpriteConfigType;
  frameIndex?: number;
  playSpritePreview: boolean;
  onAnimationEnd: () => void;
  width?: number;
  height?: number;
  maxWidth?: number;
  maxHeight?: number;
  filters?: ImageFilters;
}) => {
  const {
    src,
    spriteConfig,
    frameIndex,
    playSpritePreview,
    onAnimationEnd,
    filters,
  } = props;
  const { frameWidth, frameHeight } = spriteConfig;

  let finalWidth =
    props.width || Math.min(frameWidth * 3, props.maxWidth || 500);

  let finalHeight =
    props.height || Math.min(frameHeight * 3, props.maxHeight || 500);

  const scaleByWidth = finalWidth / frameWidth;
  const scaleByHeight = finalHeight / frameHeight;
  let scale;

  if (finalWidth > finalHeight) {
    finalHeight = frameHeight * scaleByWidth;
    scale = scaleByWidth;
  } else {
    finalWidth = frameWidth * scaleByHeight;
    scale = scaleByHeight;
  }

  const [playReset, setPlayReset] = useState(0);

  useEffect(() => {
    setPlayReset((prevState) => prevState + 1);
  }, [playSpritePreview]);

  return src ? (
    <Wrapper key={playReset}>
      <AssetPreview
        width={finalWidth / scale}
        height={finalHeight / scale}
        contentWidth={frameWidth}
        contentHeight={frameHeight}
        scale={scale}
      >
        <SpriteAnimatedSingle
          src={src}
          spriteConfig={spriteConfig}
          controlledAnimation={{ playAnimation: playSpritePreview }}
          onAnimationEnd={onAnimationEnd}
          frameIndex={playSpritePreview ? undefined : frameIndex}
          filters={filters}
          isHidden={false}
        />
      </AssetPreview>
    </Wrapper>
  ) : null;
};

export default SpriteAnimationPreview;
