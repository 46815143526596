import { CharacterConfigType } from "game-engine/types";
import onClick from "./on-click";
import onPoison from "./on-poison";
import onSceneIdle from "./on-scene-idle";

const MAIN_CHARACTER_CONFIG: Partial<CharacterConfigType> = {
  idleConfig: {
    idleAfterMinSec: 6,
    idleAfterMaxSec: 12,
  },

  onSceneIdle: onSceneIdle,
  onPoison: onPoison,
  onClick: onClick,
};

export default MAIN_CHARACTER_CONFIG;
