/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";

import CustomPopUp from "./menus/CustomPopUp";
import DeathMenu from "./menus/DeathMenu";
import { Group } from "react-konva";
import LoadGameDialog from "./menus/LoadGameDialog";
import LoadGameError from "./menus/LoadGameError";
import LoadMenu from "./menus/LoadMenu";
import MainMenu from "./menus/MainMenu";
import MenuButton from "./menu-elements/MenuButton";
import NewGame from "./menus/NewGame";
import SaveGameDialog from "./menus/SaveGameDialog";
import SaveMenu from "./menus/SaveMenu";
import { SavedGameType } from "game-engine/utils";
import SavedGamesImportDialog from "./menus/SavedGamesImportDialog";
import SavedGamesImportError from "./menus/SavedGamesImportError";
import SettingsMenu from "./menus/SettingsMenu";
import { StopActionsType } from "game-engine/components/game-elements/Scene/logic/useSceneActions";
import useGame from "game-engine/hooks/useGame";

export enum MENU_IDS {
  "mainMenu" = "mainMenu",
  "loadGame" = "loadGame",
  "loadGameDialog" = "loadGameDialog",
  "loadGameError" = "loadGameError",
  "saveGame" = "saveGame",
  "saveGameDialog" = "saveGameDialog",
  "savedGamesImportDialog" = "savedGamesImportDialog",
  "savedGamesImportError" = "savedGamesImportError",
  "newGame" = "newGame",
  "settings" = "settings",
  "deathMenu" = "deathMenu",
  "customPopUp" = "customPopUp",
}

export type MenuBreadcrumbType_Default = {
  menuId: Exclude<MENU_IDS, MENU_IDS.saveGameDialog>;
};

export type MenuBreadcrumbType_SaveGameDialog = {
  menuId: MENU_IDS.saveGameDialog;
  data?: {
    overwriteSavedGame?: SavedGameType;
  };
};

export type MenuBreadcrumbType_LoadGameDialog = {
  menuId: MENU_IDS.loadGameDialog;
  data?: {
    savedGame?: SavedGameType;
  };
};

export type MenuBreadcrumbType =
  | MenuBreadcrumbType_Default
  | MenuBreadcrumbType_SaveGameDialog
  | MenuBreadcrumbType_LoadGameDialog;

export type MenuProps = {
  isActive: boolean;
  closeMenu: () => void;
  closeSubMenu: () => void;
  replaceMenu: (breadcrumb: MenuBreadcrumbType) => void;
  openSubMenu: (breadcrumb: MenuBreadcrumbType) => void;
  breadcrumb: MenuBreadcrumbType;
  onExitGame?: () => void;
  onNewGame?: () => void;
};

const Menu = (props: {
  stopActions: StopActionsType;
  onExitGame: () => void;
  onNewGame: () => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
}) => {
  const { stopActions, onMenuOpen, onMenuClose } = props;
  const { gamePlay, gameItems, logger } = useGame();

  const [activeMenuIdBreadcrumbs, setActiveMenuIdBreadcrumbs] = useState<
    MenuBreadcrumbType[]
  >([]);

  useEffect(() => {
    logger.info(`active menu breadcrumbs set to [${activeMenuIdBreadcrumbs}]`);
    if (activeMenuIdBreadcrumbs?.length && onMenuOpen) {
      onMenuOpen();
    }
    if (!activeMenuIdBreadcrumbs?.length && onMenuClose) {
      onMenuClose();
    }
  }, [activeMenuIdBreadcrumbs]);

  //
  // GUI MENU BUTTON
  //
  const onMenuClick = () => {
    if (gameItems.state.itemInCursor) {
      return;
    }
    if (stopActions) {
      stopActions();
    }
    openMainMenu();
  };

  //
  // GLOBAL FUNCS
  //
  const onExitGame = () => {
    props.onExitGame();
  };

  const onNewGame = () => {
    props.onNewGame();
  };

  //
  // HANDLE DEATH MENU
  //
  useEffect(() => {
    if (gamePlay.state.mainCharacterIsDead) {
      openSubMenu({ menuId: MENU_IDS.deathMenu });
    }
  }, [gamePlay.state.mainCharacterIsDead]);

  //
  // HANDLE CUSTOM DIALOG
  //
  useEffect(() => {
    if (gamePlay.state.customPopUp) {
      openSubMenu({ menuId: MENU_IDS.customPopUp });
    }
  }, [gamePlay.state.customPopUp]);

  //
  // HANDLE BREADCRUMBS MENU
  //
  const openMainMenu = () => {
    setActiveMenuIdBreadcrumbs([{ menuId: MENU_IDS.mainMenu }]);
  };
  const closeMenu = () => {
    setActiveMenuIdBreadcrumbs([]);
  };

  const openSubMenu = (breadcrumb: MenuBreadcrumbType) => {
    setActiveMenuIdBreadcrumbs([...activeMenuIdBreadcrumbs, breadcrumb]);
  };
  const closeSubMenu = () => {
    const newBreadcrumbs = [...activeMenuIdBreadcrumbs];
    newBreadcrumbs.pop();
    setActiveMenuIdBreadcrumbs(newBreadcrumbs);
  };

  const replaceMenu = (breadcrumb: MenuBreadcrumbType) => {
    const newBreadcrumbs = [...activeMenuIdBreadcrumbs];
    newBreadcrumbs.pop();
    setActiveMenuIdBreadcrumbs([...newBreadcrumbs, breadcrumb]);
  };

  const getActiveBreadcrumb: () => MenuBreadcrumbType = () => {
    return activeMenuIdBreadcrumbs[activeMenuIdBreadcrumbs.length - 1];
  };

  const getActiveMenuId = () => {
    return getActiveBreadcrumb()?.menuId;
  };

  const isActiveMenuId = (menuId: MENU_IDS) => {
    return getActiveMenuId() === menuId;
  };

  //
  // MENUS
  //
  const menus: {
    [key in MENU_IDS]: React.FC<any>;
  } = {
    [MENU_IDS.mainMenu]: MainMenu,
    [MENU_IDS.loadGame]: LoadMenu,
    [MENU_IDS.loadGameDialog]: LoadGameDialog,
    [MENU_IDS.loadGameError]: LoadGameError,
    [MENU_IDS.saveGame]: SaveMenu,
    [MENU_IDS.saveGameDialog]: SaveGameDialog,
    [MENU_IDS.savedGamesImportDialog]: SavedGamesImportDialog,
    [MENU_IDS.savedGamesImportError]: SavedGamesImportError,
    [MENU_IDS.newGame]: NewGame,
    [MENU_IDS.settings]: SettingsMenu,
    [MENU_IDS.deathMenu]: DeathMenu,
    [MENU_IDS.customPopUp]: CustomPopUp,
  };

  //
  // RENDER
  //
  return (
    <Group>
      <Group>
        {/* TODO - REPLACE BUTTON WITH IMAGE + TEXT ON TOP OF IT */}
        <MenuButton
          label={{ en: "Options", cz: "Nabídka" }}
          x={5}
          y={160}
          width={80}
          height={37}
          paddingX={0}
          paddingY={-8}
          centeredText
          color="#444"
          onClick={onMenuClick}
        />
      </Group>

      {activeMenuIdBreadcrumbs?.length > 0 ? (
        <>
          {Object.entries(menus).map(([id, Component]) => (
            <Component
              key={id}
              isActive={isActiveMenuId(id as any)}
              closeMenu={closeMenu}
              closeSubMenu={closeSubMenu}
              openSubMenu={openSubMenu}
              replaceMenu={replaceMenu}
              breadcrumb={getActiveBreadcrumb()}
              onExitGame={onExitGame}
              onNewGame={onNewGame}
            />
          ))}
        </>
      ) : null}
    </Group>
  );
};

export default Menu;
