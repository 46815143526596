/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";

import GAME_CONFIG from "game-files/gameConfig";
import { Group } from "react-konva";
import SKILL_CONFIGS from "game-files/skills/SKILL_CONFIGS";
import { SkillId } from "game-files/common/ids";
import SpriteAnimated from "game-engine/components/basic-elements/SpriteAnimated";
import { SpriteConfigType } from "game-engine/types";
import { getImageOffset } from "game-engine/utils";
import useGame from "game-engine/hooks/useGame";

const CharacterSkillOverride = (props: {
  isActive?: boolean;
  renderOverrideSkillId?: SkillId;
  onImagesLoaded?: () => void;
}) => {
  const { isActive, renderOverrideSkillId } = props;
  const { engineConfig } = useGame();

  const skillEntries = Object.entries(SKILL_CONFIGS).filter(
    ([skillId, skillConfig]) =>
      !!skillConfig?.characterRender?.override?.image?.src
  );

  const [skillIdsToLoad, setSkillIdsToLoad] = useState(
    skillEntries.map(([skillId, skillConfig]) => skillId)
  );

  useEffect(() => {
    if (props.onImagesLoaded && !skillIdsToLoad.length) {
      props.onImagesLoaded();
    }
  }, [skillIdsToLoad]);

  const onImageLoaded = (skillId: SkillId) => {
    setSkillIdsToLoad((prevState) => prevState.filter((id) => id !== skillId));
  };

  //
  // RENDER
  //
  return (
    <>
      {skillEntries.map(([_skillId, skillConfig]) => {
        const skillId = _skillId as SkillId;
        const isSkillActive =
          isActive &&
          renderOverrideSkillId &&
          renderOverrideSkillId === skillId;
        const renderOverride = skillConfig.characterRender?.override;

        const spriteConfig: SpriteConfigType = renderOverride.spriteConfig ?? {
          ...GAME_CONFIG.sprites.defaultSpriteConfig,
          frameWidth: renderOverride.width,
          frameHeight: renderOverride.height,
        };

        return (
          <Group
            key={skillId}
            opacity={isSkillActive ? 1 : 0}
            listening={isSkillActive}
            position={getImageOffset({
              width: spriteConfig.frameWidth,
              height: spriteConfig.frameHeight,
            })}
          >
            <Group
              x={renderOverride.offsetX || 0}
              y={renderOverride.offsetY || 0}
            >
              <SpriteAnimated
                src={renderOverride.image.src}
                spriteConfig={spriteConfig}
                onAnimationEnd={undefined}
                loggerString={`skill override render ${skillId}, ${skillConfig}`}
                controlledAnimation={{
                  playAnimation: isSkillActive,
                }}
                renderOutline={engineConfig.state.renderCharacterOutline}
                renderFill={engineConfig.state.renderCharacterFill}
                outlineColor={engineConfig.state.characterOutlineColor}
                fillColor={engineConfig.state.characterFillColor}
                onImageLoaded={() => onImageLoaded(skillId)}
                filters={undefined}
                isHidden={!isSkillActive}
              />
            </Group>
          </Group>
        );
      })}
    </>
  );
};

export default CharacterSkillOverride;
