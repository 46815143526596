import { CursorGlyphsType, CursorRenderType } from "game-engine/types/cursor";

const CursorGlyphs: CursorGlyphsType = {
  [CursorRenderType.default]: {
    offsetX: 0,
    offsetY: 0,
    outlined: true,
    pixels: [
      [1, 0, 0, 0, 0, 0],
      [1, 1, 0, 0, 0, 0],
      [1, 1, 1, 0, 0, 0],
      [1, 1, 1, 1, 0, 0],
      [1, 1, 1, 1, 1, 0],
      [1, 1, 1, 1, 1, 1],
      [0, 0, 0, 1, 1, 0],
      [0, 0, 0, 0, 1, 1],
    ],
  },

  [CursorRenderType.arrowLeft]: {
    offsetX: 0,
    offsetY: -5,
    outlined: true,
    pixels: [
      [0, 0, 0, 0, 1, 0, 0],
      [0, 0, 0, 1, 1, 0, 0],
      [0, 0, 1, 1, 1, 1, 1],
      [0, 1, 1, 1, 1, 1, 1],
      [1, 1, 1, 1, 1, 1, 1],
      [0, 1, 1, 1, 1, 1, 1],
      [0, 0, 1, 1, 1, 1, 1],
      [0, 0, 0, 1, 1, 0, 0],
      [0, 0, 0, 0, 1, 0, 0],
    ],
  },

  [CursorRenderType.arrowRight]: {
    offsetX: -8,
    offsetY: -5,
    outlined: true,
    pixels: [
      [0, 0, 1, 0, 0, 0, 0],
      [0, 0, 1, 1, 0, 0, 0],
      [1, 1, 1, 1, 1, 0, 0],
      [1, 1, 1, 1, 1, 1, 0],
      [1, 1, 1, 1, 1, 1, 1],
      [1, 1, 1, 1, 1, 1, 0],
      [1, 1, 1, 1, 1, 0, 0],
      [0, 0, 1, 1, 0, 0, 0],
      [0, 0, 1, 0, 0, 0, 0],
    ],
  },

  [CursorRenderType.arrowUp]: {
    offsetX: -5,
    offsetY: 0,
    outlined: true,
    pixels: [
      [0, 0, 0, 0, 1, 0, 0, 0, 0],
      [0, 0, 0, 1, 1, 1, 0, 0, 0],
      [0, 0, 1, 1, 1, 1, 1, 0, 0],
      [0, 1, 1, 1, 1, 1, 1, 1, 0],
      [1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 0, 1, 1, 1, 1, 1, 0, 0],
      [0, 0, 1, 1, 1, 1, 1, 0, 0],
    ],
  },

  [CursorRenderType.arrowDown]: {
    offsetX: -5,
    offsetY: -8,
    outlined: true,
    pixels: [
      [0, 0, 1, 1, 1, 1, 1, 0, 0],
      [0, 0, 1, 1, 1, 1, 1, 0, 0],
      [1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 1, 1, 1, 1, 1, 1, 1, 0],
      [0, 0, 1, 1, 1, 1, 1, 0, 0],
      [0, 0, 0, 1, 1, 1, 0, 0, 0],
      [0, 0, 0, 0, 1, 0, 0, 0, 0],
    ],
  },

  [CursorRenderType.notAllowed]: {
    color: `#CF6461`,
    offsetX: -5,
    offsetY: -5,
    outlined: true,
    outlineNoDiagonal: true,
    pixels: [
      [0, 0, 1, 1, 1, 1, 1, 0, 0],
      [0, 1, 0, 0, 0, 0, 1, 1, 0],
      [1, 0, 0, 0, 0, 1, 1, 0, 1],
      [1, 0, 0, 0, 1, 1, 0, 0, 1],
      [1, 0, 0, 1, 1, 0, 0, 0, 1],
      [1, 0, 1, 1, 0, 0, 0, 0, 1],
      [0, 1, 1, 0, 0, 0, 0, 1, 0],
      [0, 0, 1, 1, 1, 1, 1, 0, 0],
    ],
  },

  [CursorRenderType.pointer]: {
    offsetX: -3,
    offsetY: 0,
    outlined: true,
    pixels: [
      [0, 0, 1, 0, 0, 0, 0],
      [0, 0, 1, 0, 0, 0, 0],
      [0, 0, 1, 0, 0, 0, 0],
      [0, 0, 1, 1, 1, 1, 0],
      [1, 0, 1, 1, 1, 1, 1],
      [1, 0, 1, 1, 1, 1, 1],
      [1, 1, 1, 1, 1, 1, 1],
      [0, 1, 1, 1, 1, 1, 1],
      [0, 0, 1, 1, 1, 1, 0],
    ],
  },
};

export default CursorGlyphs;
