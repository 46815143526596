import { FontType, TextAlign, TextOutlineStyle } from "game-engine/types";

import AssetPreviewText from "game-engine/_dev/basic-components/AssetPreviewText";
import Box from "game-engine/_dev/basic-components/Box";
import Checkbox from "game-engine/_dev/basic-components/inputs/Checkbox";
import Divider from "game-engine/_dev/basic-components/Divider";
import NumberInput from "game-engine/_dev/basic-components/inputs/NumberInput";
import TextInput from "game-engine/_dev/basic-components/inputs/TextInput";
import styled from "styled-components";
import { useState } from "react";

const Glyphs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const Font = (props: {
  isOpen?: boolean;
  fontName: string;
  font: FontType;
}) => {
  const [textInput, setTextInput] = useState<string>(
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
  );
  const [minWidthPx, setMinWidthPx] = useState<number>(100);
  const [maxWidthPx, setMaxWidthPx] = useState<number>(160);
  const [outlinedRound, setOutlinedRound] = useState<boolean>(true);
  const [outlinedFull, setOutlinedFull] = useState<boolean>(false);
  const [shadow, setShadow] = useState<boolean>(false);
  const [align, setAlign] = useState<TextAlign>(TextAlign.center);
  const [font, setFont] = useState<FontType>({ ...props.font });

  const textWidth = 190;
  const textHeight = 94;
  const textScale = 3;

  const glyphScale = 3;
  const glyphWidth = 14;
  const glyphHeight = 15;

  //
  // RENDER
  //
  return (
    <Box
      label={`${props.fontName}`}
      useFlex
      isOpen={props.isOpen}
      level={2}
      contentPaddingTop="10px"
      contentPaddingLeft="20px"
      contentPaddingRight="20px"
      contentPaddingBottom="30px"
    >
      <Box
        label={`Preview (${textWidth} x ${textHeight * textScale}px)`}
        level={2}
        isOpen
        minWidth={`min(90%, ${textWidth * textScale + 20}px)`}
        contentCenteredVertical
      >
        <AssetPreviewText
          font={font}
          text={textInput}
          width={textWidth}
          height={textHeight}
          scale={textScale}
          textAlign={align}
          textMinWidth={minWidthPx}
          textMaxWidth={maxWidthPx}
          outlined={outlinedFull || outlinedRound}
          outlineStyle={
            outlinedFull ? TextOutlineStyle.full : TextOutlineStyle.round
          }
          shadow={shadow}
          background="#fff1"
          textFillColor="#8f81"
        />
      </Box>

      <Box label="Text" level={2} isOpen childMinWidth="90%" gap="30px">
        <TextInput value={textInput} onChange={setTextInput} />

        <Box
          renderContentOnly
          isOpen
          minWidth="60%"
          childMinWidth="150px"
          gap="20px"
        >
          <Box renderContentOnly childMinWidth="90%" gap="4px">
            <NumberInput
              prefix="Letter Spacing"
              prefixWidth="124px"
              value={font.letterSpacing}
              onChange={(v) => setFont({ ...font, letterSpacing: v })}
              min={0}
              rangeController={{ min: 0, max: 10, step: 1 }}
            />

            <NumberInput
              prefix="Line Spacing"
              prefixWidth="124px"
              value={font.lineSpacing}
              onChange={(v) => setFont({ ...font, lineSpacing: v })}
              rangeController={{ min: -10, max: 10, step: 1 }}
            />

            <NumberInput
              prefix="Min Line Width (Px)"
              prefixWidth="124px"
              value={minWidthPx}
              onChange={(v) => setMinWidthPx(v)}
              rangeController={{ min: 0, max: 200, step: 5 }}
            />

            <NumberInput
              prefix="Max Line Width (Px)"
              prefixWidth="124px"
              value={maxWidthPx}
              onChange={(v) => setMaxWidthPx(v)}
              rangeController={{ min: 0, max: 200, step: 5 }}
            />
          </Box>

          <Box renderContentOnly childMinWidth="90%">
            <Box renderContentOnly childMinWidth="90%">
              {Object.values(TextAlign).map((key) => {
                const isActive = key === align;
                return (
                  <Checkbox
                    key={key}
                    label={`align ${key}`}
                    value={isActive}
                    onChange={(v) => setAlign(key as any)}
                  />
                );
              })}

              <Divider name="Text Effects:" marginTop="10px" />

              <Checkbox
                label="Outlined (Round)"
                value={outlinedRound}
                onChange={(v) => {
                  setOutlinedRound(v);
                  setOutlinedFull(false);
                }}
              />

              <Checkbox
                label="Outlined (Full)"
                value={outlinedFull}
                onChange={(v) => {
                  setOutlinedRound(false);
                  setOutlinedFull(v);
                }}
              />

              <Checkbox label="Shadow" value={shadow} onChange={setShadow} />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box label="Font Glyphs" level={2} minWidth="90%">
        <Glyphs>
          {Object.entries(font.glyphs).map(([key, data]) => {
            return (
              <Box
                key={key}
                label={key === " " ? "⌴" : key}
                level={3}
                childMinWidth="18px"
                isOpen
                gap="0px"
                contentPaddingTop="-10px"
                contentPaddingBottom="8px"
              >
                <AssetPreviewText
                  font={font}
                  text={key}
                  width={glyphWidth}
                  height={glyphHeight}
                  scale={glyphScale}
                  textAlign={TextAlign.center}
                />
              </Box>
            );
          })}
        </Glyphs>
      </Box>
    </Box>
  );
};

export default Font;
