import {
  ActionQueueType,
  ActionsByCurrentObjectiveType,
  Direction,
  ImageType,
  Position,
  SceneCharacterDataType,
  SpriteConfigType,
  StateConditionalData,
  StateConditionalProps,
  TextAlign,
  TintFilterColorOptions,
  TranslatedString,
} from ".";

import { CharacterId } from "game-files/common/ids";
import { CursorRenderType } from "./cursor";
import { GameObjective } from "game-files/gameObjectives";

//
// CHARACTER
//
export type CharacterConfigType = {
  id: CharacterId;
  name: TranslatedString;
  render: CharacterRenderByCurrentObjective;
  cursorOnHover?: CursorRenderType;
  dialogConfig?: CharacterDialogConfigByCurrentObjective;
  idleConfig?: CharacterIdleConfigType;
  onSceneIdle?: CharacterOnSceneIdleType;
  onPoison?: CharacterOnPoisonType;
  onClick?: CharacterOnClickType;

  devTools: CharacterDevToolsPreviewType;
};

//
// CHARACTER - RENDER MODE
//
export enum CharacterRenderMode {
  "default" = "default",
  "walk" = "walk",
  "talk" = "talk",
  "talkIdle" = "talkIdle",
  "idle" = "idle",
  "animation" = "animation",
}

//
// CHARACTER - RENDER
//
export type CharacterRenderType = {
  facing?: Direction.left | Direction.right;
  offsetX?: number;
  offsetY?: number; // offset from origin (to GLOBALLY position feet on the origin)

  filters?: {
    skin: {
      includeColors?: TintFilterColorOptions[];
      ignoreColors?: TintFilterColorOptions[];
    };

    poison?: {
      color: string; // hex
      amount: number; // 0 - 1
      brightness: number; // 1 = default
    };
  };

  default?: CharacterRenderDefaultType;
  defaultNoHead?: CharacterRenderDefaultType;
  talk?: CharacterRenderTalkType;
  walkLeft?: CharacterRenderWalkType;
  walkUp?: CharacterRenderWalkType;
  walkDown?: CharacterRenderWalkType;

  animations?: CharacterRenderAnimationType[];
};

export type CharacterRenderConditionalFunc = (
  props: StateConditionalProps
) => (StateConditionalData & { state: CharacterRenderType })[];

export type CharacterRenderByCurrentObjective = {
  [key in GameObjective]?: CharacterRenderType & {
    conditional?: CharacterRenderConditionalFunc;
  };
};

//
// RENDER - DEFAULT
//
export type CharacterRenderDefaultType = {
  image?: ImageType; // sprites go here as well
  spriteConfig?: SpriteConfigType;
  width?: number;
  height?: number;
  offsetX?: number;
  offsetY?: number; // offset from origin (to position feet on the origin)
};

//
// RENDER - TALK
//
export type CharacterRenderTalkType = {
  offsetX?: number;
  offsetY?: number;
  image?: ImageType;
  spriteConfig?: SpriteConfigType;
  renderCharacterBody?: boolean;
};

//
// RENDER - WALK
//
export type CharacterRenderWalkType = {
  image: ImageType;
  spriteConfig: SpriteConfigType;
  offsetX?: number;
  offsetY?: number; // offset from origin (to position feet on the origin)
  pixelsWalked: number;
};

//
// RENDER - ANIMATION
//
export type CharacterRenderAnimationType = {
  image: ImageType;
  spriteConfig: SpriteConfigType;
  renderCharacterBody?: boolean;
  animationId: string;
  isIdle?: boolean;
  offsetX?: number; // offset from origin (to position feet on the origin)
  offsetY?: number; // offset from origin (to position feet on the origin)
};

//
// CHARACTER IDLE CONFIG
//
export type CharacterIdleConfigType = {
  idleAfterMinSec?: number;
  idleAfterMaxSec?: number;
};

//
// CHARACTER DIALOG OPTIONS
//
export enum CharacterDialogPosition {
  above = "above",
  below = "below",
  auto = "auto",
}

export type CharacterDialogConfigType = {
  aboveOffsetMax?: number;
  aboveOffsetMin?: number;
  offsetBelowMax?: number;
  verticalPosition?: CharacterDialogPosition;
  align?: TextAlign;
  maxWidth?: number;
  color?: string;
  outlined?: boolean;
};

export type CharacterDialogConfigConditionalFunc = (
  props: StateConditionalProps
) => (StateConditionalData & { state: CharacterDialogConfigType })[];

export type CharacterDialogConfigByCurrentObjective = {
  [key in GameObjective]?: CharacterDialogConfigType & {
    conditional?: CharacterDialogConfigConditionalFunc;
  };
};

//
// CHARACTER - ACTION - TALK
//
export type CharacterDialogType = TranslatedString | TranslatedString[]; // array is meant for multi-text dialog (<say something 1> -> <say something 2>)

//
// CHARACTER - ACTION - WALK
//
export type CharacterPathPoint = {
  position: Position;
  facing: Direction;
  scale: number;
};
export type CharacterWalkPath = CharacterPathPoint[];

export type WalkConfigType = {
  horizontal: {
    frameCount: number;
    pixelsWalked: number;
  };
  vertical: {
    frameCount: number;
    pixelsWalked: number;
  };
};

//
// CHARACTER - ON SCENE IDLE
//
export type CharacterOnSceneIdleType = {
  actionsByCurrentObjective?: {
    [key in GameObjective]?: {
      actions?: ActionQueueType;
    };
  };
};

//
// CHARACTER - ON POISON
//
export type CharacterOnPoisonType = {
  actionsOnHeal?: ActionQueueType;
  actionsOnDeath?: ActionQueueType;
};

//
// CHARACTER - ON CLICK
//
export type CharacterOnClickType = {
  actionsByCurrentObjective?: ActionsByCurrentObjectiveType;
};

//
// DEVTOOLS PREVIEWS
//
export type CharacterDevToolsPreviewType = {
  renderAssets: CharacterDevToolsRenderAssetsType[];
  sceneCharacter?: SceneCharacterDataType;
};

export type CharacterDevToolsRenderAssetsType = {
  name: string;
  render: CharacterRenderType;
};
