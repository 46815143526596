/* eslint-disable react-hooks/exhaustive-deps */

import { Position, SceneBackgroundType, SceneType } from "game-engine/types";

import GAME_CONFIG from "game-files/gameConfig";
import { GetDynamicLightingFiltersFunc } from "../Scene/layers/useDynamicLighting";
import { Group } from "react-konva";
import Konva from "konva";
import SceneFill from "../SceneFill";
import SpriteAnimated from "game-engine/components/basic-elements/SpriteAnimated";
import useGame from "game-engine/hooks/useGame";
import { useMemo } from "react";

const SceneBackground = (props: {
  scene: SceneType;
  sceneBackground: SceneBackgroundType;
  onBackgroundClick?: (data: { clickedPosition?: Position }) => void;
  onImageLoaded: () => void;
  getDynamicLightingFilters: GetDynamicLightingFiltersFunc;
}) => {
  const { sceneBackground, getDynamicLightingFilters } = props;

  const { engineConfig, gameFns, logger } = useGame();

  //
  // LOGIC
  //
  const onBackgroundClick = (e: Konva.KonvaPointerEvent) => {
    const clickedPosition = gameFns.getClickedScenePosition(e);

    logger.info(
      `background clicked [${clickedPosition.x}, ${clickedPosition.y}]`
    );

    if (props.onBackgroundClick) {
      props.onBackgroundClick({ clickedPosition });
    }
  };

  //
  // DYNAMIC LIGHTING
  //
  const imageFilters = useMemo(() => {
    return getDynamicLightingFilters({ depthY: 0 });
  }, [getDynamicLightingFilters]);

  //
  // RENDER
  //
  return (
    <Group>
      {/* FILL */}
      {sceneBackground?.fillColor && (
        <SceneFill color={sceneBackground.fillColor} />
      )}

      {/* DEV BACKGROUND */}
      {engineConfig.state.renderCanvasBackground && <SceneFill color="blue" />}

      {/* BACKGROUND */}
      <Group
        opacity={
          engineConfig.state.hideSceneBackground
            ? 0
            : sceneBackground?.image?.opacity ?? 1
        }
      >
        {/* SPRITE IS USED EVEN FOR SINGLE IMAGES TO BETTER HANDLE CHANGING .SRC */}
        <SpriteAnimated
          src={sceneBackground?.image?.src}
          srcTransitionOnChange={sceneBackground?.image?.transition}
          spriteConfig={{
            ...(sceneBackground?.imageSpriteConfig || {}),
            frameWidth:
              sceneBackground?.imageSpriteConfig?.frameWidth ??
              sceneBackground?.image?.width ??
              GAME_CONFIG.scene.dimensions.x,
            frameHeight:
              sceneBackground?.imageSpriteConfig?.frameHeight ??
              sceneBackground?.image?.height ??
              GAME_CONFIG.scene.dimensions.y,
          }}
          controlledAnimation={{
            playAnimation: !!sceneBackground?.imageSpriteConfig,
          }}
          getSpriteFrameRenderIndex={undefined}
          onImageLoaded={props.onImageLoaded}
          onClick={onBackgroundClick}
          filters={imageFilters}
          isHidden={false} // background is always visible
        />
      </Group>
    </Group>
  );
};

export default SceneBackground;
