import {
  ACTION,
  getSceneBackgroundByCurrentObjective,
  getSceneLayerByCurrentObjective,
} from "game-engine/utils";
import {
  Direction,
  Position,
  SceneLayerType,
  SceneType,
  SceneWalkPathBlockedType,
} from "game-engine/types";
import { EditorType, OnSceneMouseClick } from "../..";
import {
  RunActionsType,
  StopActionsType,
} from "game-engine/components/game-elements/Scene/logic/useSceneActions";
import { useEffect, useRef, useState } from "react";

import { DevCursorType } from "game-engine/components/game-elements/Cursor";
import GAMEPLAY from "game-files/gamePlay";
import GAME_CONFIG from "game-files/gameConfig";
import { GAME_EVENTS } from "game-files/gameEvents";
import { GUIBlockerProps } from "game-engine/components/game-viewport/GameViewport";
import { GameObjective } from "game-files/gameObjectives";
import GameViewport from "game-engine/components/game-viewport/GameViewport";
import SHARED_DATA from "game-files/scenes/SHARED_DATA";
import Scene from "game-engine/components/game-elements/Scene";

const NewScenePreview = (props: {
  editor: EditorType;
  onSceneMouseClick: OnSceneMouseClick;
  setOnSceneMouseClick: (v: OnSceneMouseClick) => void;

  ignoreIdle?: boolean;
  ignoreFaceClickedPosition?: boolean;

  forceWalkIn?: { fromDirection: Direction };
  forceWalkOut?: { walkTo: Position; direction: Direction };
}) => {
  const {
    editor,
    onSceneMouseClick,
    setOnSceneMouseClick,
    ignoreIdle = true,
    ignoreFaceClickedPosition = false,
    forceWalkIn,
    forceWalkOut,
  } = props;

  const [scene, setScene] = useState<SceneType>();

  const [forceGuiBlocker, setForceGuiBlocker] = useState<boolean>(false);
  const setForceGuiBlockerRef = useRef(setForceGuiBlocker);

  //
  // FORCE REFRESH (required when changing sprite settings)
  //
  const [forceRefresh, setForceRefresh] = useState(false);
  useEffect(() => {
    if (forceRefresh) {
      setForceRefresh(!forceRefresh);
    }
  }, [forceRefresh]);

  const prevRefreshSettingsRef = useRef("");

  useEffect(() => {
    // Force Refresh on sprite settings change (sprite component cannot react to changes directly -> needs to be re-rendered)
    const refreshSettings = editor.scene?.images?.layers
      ?.map((layer) => {
        const spriteConfig =
          layer.dataByCurrentObjective[GameObjective.GAME_START]?.spriteConfig;
        if (spriteConfig) {
          return JSON.stringify({ layerId: layer.id, spriteConfig });
        }
        return null;
      })
      .filter((v) => v)
      .join(";");

    if (prevRefreshSettingsRef.current !== refreshSettings) {
      prevRefreshSettingsRef.current = refreshSettings;
      setForceRefresh(true);
    }
  }, [editor.scene?.images?.layers]);

  //
  // SCENE
  //
  useEffect(() => {
    if (editor?.scene) {
      const anyInSceneMouseClick = !!onSceneMouseClick?.sceneLayerId;
      const isAnyLayerHighlighted =
        editor.sceneLayersMeta.filter((layerMeta) => layerMeta.isHighlighted)
          ?.length > 0;

      const fadedBackground = isAnyLayerHighlighted || anyInSceneMouseClick;

      const backgroundData = getSceneBackgroundByCurrentObjective({
        dataByCurrentObjective:
          editor.scene.images?.background?.dataByCurrentObjective,
        currentObjective: GameObjective.GAME_START,
        events: GAME_EVENTS,
      });

      let backgroundActions = undefined;
      if (
        editor.meta.background.dialogActions.sharedDialogOptionIds?.length ||
        editor.meta.background.dialogActions.customDialogOptions?.length
      ) {
        backgroundActions = [
          ACTION.talkRandom({
            dialogOptions: [
              ...editor.meta.background.dialogActions.sharedDialogOptionIds
                ?.map((dialogKey) => SHARED_DATA.dialogOptions[dialogKey] || [])
                .flat(),
              ...(editor.meta.background.dialogActions.customDialogOptions ||
                []),
            ],
          }),
        ];
      }

      const walkPaths = {
        ...editor.scene.sceneWalkPaths[GameObjective.GAME_START],
      };

      Object.values(Direction).forEach((direction) => {
        if ((walkPaths[direction] as any)?.isBlocked) {
          const metaWalkPath = editor.meta.walkPathsBlockedActions[direction];

          if (
            metaWalkPath.sharedDialogOptionIds?.length ||
            metaWalkPath.customDialogOptions?.length
          ) {
            (walkPaths[direction] as SceneWalkPathBlockedType).actions = [
              ACTION.talkRandom({
                dialogOptions: [
                  ...metaWalkPath.sharedDialogOptionIds
                    ?.map(
                      (dialogKey) => SHARED_DATA.dialogOptions[dialogKey] || []
                    )
                    .flat(),
                  ...(metaWalkPath.customDialogOptions || []),
                ],
              }),
            ];
          } else {
            (walkPaths[direction] as SceneWalkPathBlockedType).actions = [];
          }
        }
      });

      // Make adjustments to displayed scene / layers
      const processedScene: SceneType = {
        ...editor.scene,

        sceneWalkPaths: {
          [GameObjective.GAME_START]: walkPaths,
        },

        images: {
          background: {
            dataByCurrentObjective: {
              [GameObjective.GAME_START]: {
                ...backgroundData,
                image: {
                  ...backgroundData.image,
                  opacity: fadedBackground
                    ? 0.2
                    : backgroundData?.image?.opacity || 1,
                },
                fillColor: fadedBackground ? "#fff8" : undefined,
              },
            },
            actionsByCurrentObjective: {
              [GameObjective.GAME_START]: {
                actions: backgroundActions,
              },
            },
          },

          layers: editor.scene.images.layers?.map((layer) => {
            const layerData = getSceneLayerByCurrentObjective({
              dataByCurrentObjective: layer.dataByCurrentObjective,
              currentObjective: GameObjective.GAME_START,
              events: GAMEPLAY.events,
            });

            const layerMeta = editor.sceneLayersMeta.find(
              (l) => l.sceneLayerId === layer.id
            );

            let isInvisible = layerData.isInvisible;
            let ignoreOnClick = layerData.ignoreOnClick;
            if (anyInSceneMouseClick) {
              isInvisible = onSceneMouseClick?.sceneLayerId !== layer.id;
              ignoreOnClick = ignoreOnClick || isInvisible;
            } else if (isAnyLayerHighlighted) {
              isInvisible = !layerMeta.isHighlighted;
              ignoreOnClick = ignoreOnClick || isInvisible;
            }

            let actions = undefined;
            if (
              layerMeta.dialogActions.sharedDialogOptionIds?.length ||
              layerMeta.dialogActions.customDialogOptions?.length
            ) {
              actions = [
                ACTION.talkRandom({
                  dialogOptions: [
                    ...layerMeta.dialogActions.sharedDialogOptionIds
                      ?.map(
                        (dialogKey) =>
                          SHARED_DATA.dialogOptions[dialogKey] || []
                      )
                      .flat(),
                    ...(layerMeta.dialogActions.customDialogOptions || []),
                  ],
                }),
              ];
            }

            const updatedLayer: SceneLayerType = {
              ...layer,
              depthY: layerMeta.isInFront
                ? GAME_CONFIG.scene.dimensions.y + 1000
                : layerMeta.isInBack
                ? 0
                : layer.depthY,
              dataByCurrentObjective: {
                [GameObjective.GAME_START]: {
                  ...layerData,
                  isInvisible,
                  ignoreOnClick,
                },
              },
              actionsByCurrentObjective: {
                [GameObjective.GAME_START]: {
                  actions: actions,
                  actionsWithItem: undefined,
                },
              },
            };

            return updatedLayer;
          }),
        },

        mainCharacterOptions: {
          ...editor.scene.mainCharacterOptions,
          isHidden: anyInSceneMouseClick
            ? true
            : editor.scene.mainCharacterOptions?.isHidden,
        },

        onSceneIdle: {
          idleAfterMinSec: 100 ^ 100, // prevent default idle
          idleAfterMaxSec: 100 ^ 100, // prevent default idle
        },
      };

      const processedSceneDef: SceneType = {
        ...editor.scene,
        ...processedScene,
      };

      setScene(processedSceneDef);
    } else {
      setScene(undefined);
    }
  }, [
    editor?.scene,
    editor?.sceneLayersMeta,
    onSceneMouseClick,
    editor?.scene?.mainCharacterOptions,
    editor?.meta,
  ]);

  const getDevCursor: () => DevCursorType | undefined = () => {
    if (onSceneMouseClick) {
      const clickParamId = onSceneMouseClick.paramId;

      const isNotClickingX =
        clickParamId.endsWith("_edgeWalkY") || clickParamId.endsWith("_depthY");

      const isNotClickingY = clickParamId.endsWith("_edgeWalkX");

      if (isNotClickingX) {
        return DevCursorType.LineHorizontal;
      }
      if (isNotClickingY) {
        return DevCursorType.LineVertical;
      }
      return DevCursorType.Cross;
    }
    return undefined;
  };

  //
  // GUI BLOCKER
  //
  const [guiBlocker, setGuiBlocker] = useState<GUIBlockerProps>(undefined);
  const onGuiBlockerClickRef = useRef<(clickedPosition: Position) => void>();

  const runActionsRef = useRef<RunActionsType>();
  const stopActionsRef = useRef<StopActionsType>();

  //
  // RENDER
  //
  return !scene || forceRefresh ? null : (
    <GameViewport
      renderGUI={{ onExitGame: undefined, onNewGame: undefined }}
      noCursor={forceGuiBlocker}
      guiBlocker={guiBlocker}
      guiBlockerForced={forceGuiBlocker}
      guiBlockerClickRef={onGuiBlockerClickRef}
      canvasClickSettings={{
        onClick: onSceneMouseClick
          ? ({ scenePosition, canvasPosition }) => {
              onSceneMouseClick.func(scenePosition);
              setOnSceneMouseClick(undefined);
            }
          : undefined,
        cursor: getDevCursor(),
      }}
      runActionsRef={runActionsRef}
      stopActionsRef={stopActionsRef}
    >
      <Scene
        key={scene?.uniqueId}
        noAutosave
        ignoreIdle={ignoreIdle}
        ignoreFaceClickedPosition={ignoreFaceClickedPosition}
        scene={scene}
        walkIn={
          !!forceWalkIn
            ? {
                fromDirection: forceWalkIn.fromDirection,
              }
            : undefined
        }
        isVisible={true}
        setForceGuiBlockerRef={setForceGuiBlockerRef}
        setGuiBlocker={setGuiBlocker}
        onGuiBlockerClickRef={onGuiBlockerClickRef}
        runActionsRef={runActionsRef}
        stopActionsRef={stopActionsRef}
        forceWalkIn={!!forceWalkIn}
        forceWalkOut={forceWalkOut}
      />
    </GameViewport>
  );
};

export default NewScenePreview;
