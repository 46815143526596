/* eslint-disable react-hooks/exhaustive-deps */

import {
  CharacterConfigType,
  CharacterRenderAnimationType,
  CharacterRenderMode,
  CharacterRenderType,
  Direction,
  ImageFilters,
  Position,
  SceneCharacterPlayAnimationType,
  TalkRenderOptionsType,
} from "game-engine/types";
import { useEffect, useMemo, useState } from "react";

import CharacterAnimation from "./render-mode-animation";
import CharacterAnimationTalk from "./render-mode-talk";
import CharacterAnimationWalk from "./render-mode-walk";
import CharacterImageDefault from "./render-mode-default";
import CharacterImageDefaultNoHead from "./render-mode-default-no-head";
import { CharacterPrimaryRenderMode } from "..";
import CharacterSkillOverride from "./render-mode-skill-override";
import { CursorRenderType } from "game-engine/types/cursor";
import DepthLine from "game-engine/components/basic-elements/DepthLine";
import { Group } from "react-konva";
import Origin from "game-engine/components/basic-elements/Origin";
import { SkillId } from "game-files/common/ids";
import Vector from "game-engine/components/basic-elements/Vector";
import { getSkillConfigById } from "game-files/skills/SKILL_CONFIGS";
import { isCharacterMirrored } from "game-engine/utils";
import useGame from "game-engine/hooks/useGame";

export type CharacterRenderProps = {
  config: CharacterConfigType;
  characterRender?: CharacterRenderType; //SHOULD ONLY BE USED FOR DEV TOOLS!
  renderMode?: CharacterRenderMode;
  isPoisoned?: boolean;
  facing?: Direction;
  position: Position;
  walkAnimationFrame?: number;
  scale?: number;
  onClick?: (e?: any) => void;
  onImageLoaded?: (key: CharacterPrimaryRenderMode) => void;
  talkRenderOptions?: TalkRenderOptionsType;
  playAnimation?: SceneCharacterPlayAnimationType;
  renderOrigin?: boolean;
  originColor?: string;
  facingColor?: string;
  onIdleEnd?: () => void;
  idleIndex?: number;
  idleAnimations?: CharacterRenderAnimationType[];
  ignoreIdle?: boolean;

  forceRenderOverrideSkillId?: SkillId;
};

const CharacterRender = (props: CharacterRenderProps) => {
  const {
    config,
    position,
    scale = 1,
    facing,
    renderMode,
    isPoisoned,
    characterRender,
    onClick,
    onImageLoaded,
    onIdleEnd,
    idleIndex,
    idleAnimations,
    ignoreIdle,
    walkAnimationFrame,
    talkRenderOptions,
    playAnimation,
    renderOrigin,
    originColor,
    facingColor,
    forceRenderOverrideSkillId,
  } = props;

  const { engineConfig, mainCharacterConfig, gameFns, gamePlay, gameItems } =
    useGame();

  const getScaleX = () => {
    return (
      scale *
      (isCharacterMirrored({
        facing,
        defaultFacing: characterRender.facing,
      })
        ? -1
        : 1)
    );
  };

  //
  // IMAGE FILTERS (POISON)
  //
  const imageFilters = useMemo<ImageFilters>(() => {
    if (isPoisoned) {
      const skin = characterRender.filters?.skin;
      const poison = characterRender.filters?.poison;

      if (skin && poison) {
        return {
          tint: {
            color: poison.color,
            amount: poison.amount,
            brightness: poison.brightness,
            finalColor: poison.color,
            includeColors: characterRender.filters?.skin?.includeColors,
            ignoreColors: characterRender.filters?.skin?.ignoreColors,
          },
        };
      }

      console.warn(`Character ${config.id} has no poison filter!`, config);
    }

    return undefined;
  }, [isPoisoned, characterRender]);

  //
  // ACTIVE ANIMATION OR IDLE ANIMATION
  //
  const animations = characterRender.animations ?? [];

  const activeAnimation = useMemo<CharacterRenderAnimationType>(() => {
    let activeAnimation: CharacterRenderAnimationType;
    let animationRequired = false;

    if (renderMode === CharacterRenderMode.idle) {
      animationRequired = true;
      activeAnimation = idleAnimations[idleIndex] || idleAnimations[0];
    }
    if (renderMode === CharacterRenderMode.animation) {
      animationRequired = true;
      if (playAnimation?.id) {
        activeAnimation = animations.find(
          (a) => a.animationId === playAnimation?.id
        );
      }
    }

    if (
      !activeAnimation &&
      animationRequired &&
      playAnimation?.onAnimationEnd
    ) {
      setTimeout(() => {
        console.warn(
          `Character (${config.id}) animation (${playAnimation?.id}) missing and skipped`
        );
        // trigger onAnimationEnd if animation is required but missing
        // (fallback, missing animations otherwise freeze actionQueue because they do not end)
        playAnimation?.onAnimationEnd();
      }, 200);
    }

    return activeAnimation;
  }, [playAnimation?.id, idleIndex, renderMode]);

  //
  // ACTIVE MODE
  //
  const isActive_Talk =
    renderMode === CharacterRenderMode.talk ||
    renderMode === CharacterRenderMode.talkIdle;

  const isActive_Walk = renderMode === CharacterRenderMode.walk;

  const isActive_Animation =
    activeAnimation &&
    (renderMode === CharacterRenderMode.animation ||
      (!ignoreIdle && renderMode === CharacterRenderMode.idle));

  const isActive_DefaultNoHead =
    (isActive_Talk && characterRender?.talk?.renderCharacterBody) ||
    (isActive_Animation && activeAnimation?.renderCharacterBody);

  const isActive_Default =
    !isActive_Talk && !isActive_Walk && !isActive_Animation;

  //
  // RENDER MODE - SKILL OVERRIDES
  //
  const [renderOverrideSkillId, setRenderOverrideSkillId] = useState<SkillId>();

  useEffect(() => {
    setRenderOverrideSkillId((prevSkillId) => {
      if (forceRenderOverrideSkillId) {
        // forced render override should take priority
        return forceRenderOverrideSkillId;
      }

      if (config.id !== mainCharacterConfig.id) {
        // skill overrides are only for the main character
        return undefined;
      }

      const currentSkillEntries = Object.entries(
        gamePlay.state.currentSkills || {}
      );

      for (let i = 0; i < currentSkillEntries.length; i++) {
        const [_skillId, skillValues] = currentSkillEntries[i];
        const skillId = _skillId as SkillId;
        const skillConfig = getSkillConfigById(skillId);

        const renderOverride = skillConfig?.characterRender;

        // skip skills without override
        if (!renderOverride) {
          continue;
        }

        // return active skill
        if (skillValues?.isActive) {
          return skillId;
        }

        // skill is no longer active, but is required to wait for a specific animation
        const endAnimationId = renderOverride.overrideEndAnimationId;

        if (
          !endAnimationId ||
          !characterRender?.animations?.find(
            (animation) => animation.animationId === endAnimationId
          )
        ) {
          // skip if no end animation is defined, or it is, but the character doesn't have it
          continue;
        }

        if (
          prevSkillId === skillId &&
          endAnimationId &&
          endAnimationId !== activeAnimation?.animationId
        ) {
          // keep skill until specified animation is reached
          return skillId;
        }
      }

      return undefined;
    });
  }, [
    mainCharacterConfig,
    gamePlay.state.currentSkills,
    forceRenderOverrideSkillId,
    activeAnimation,
  ]);

  //
  // RENDER
  //
  return (
    <Group
      x={position?.x || 0}
      y={position?.y || 0}
      onClick={onClick}
      onTap={onClick}
      onMouseEnter={
        !gameItems.state.itemInCursor && config.cursorOnHover
          ? () => gameFns.setCursorRenderMode(config.cursorOnHover)
          : undefined
      }
      onMouseLeave={
        !gameItems.state.itemInCursor && config.cursorOnHover
          ? () => gameFns.setCursorRenderMode(CursorRenderType.default)
          : undefined
      }
      scaleY={scale}
      scaleX={getScaleX()}
      listening={!engineConfig.state.hideSceneCharacters}
      opacity={engineConfig.state.hideSceneCharacters ? 0 : 1}
    >
      <Group
        position={{
          // TODO - THIS MAKES THE CHARACTER JUMP ON RENDER CHANGE - DUE TO IMAGES BEING DISPLAYED WITH A DELAY
          // -> MOVE AS 'OFFSET' VARIABLE TO INDIVIDUAL RENDERS (sprite with interchangeable images should handle the change)
          x: characterRender?.offsetX || 0,
          y: characterRender?.offsetY || 0,
        }}
      >
        {/* RENDER IMAGES */}
        {/* conditional image rendering should be done via opacity
         *  -> otherwise the rendered image will (re)load on image component mount, causing flickering
         */}

        {/**
         *
         * OVERRIDE RENDER MODES
         *
         */}
        <Group
          opacity={!!renderOverrideSkillId ? 1 : 0}
          listening={!!renderOverrideSkillId}
        >
          <CharacterSkillOverride
            isActive={!!renderOverrideSkillId}
            renderOverrideSkillId={renderOverrideSkillId}
            /* onImagesLoaded={() => onImageLoaded("flying")} */
          />
        </Group>

        {/**
         *
         * RENDER MODES
         *
         */}
        <Group
          opacity={!renderOverrideSkillId ? 1 : 0}
          listening={!renderOverrideSkillId}
        >
          {/* NO HEAD */}
          <CharacterImageDefaultNoHead
            isActive={isActive_DefaultNoHead}
            config={config}
            imageFilters={imageFilters}
            characterRender={characterRender}
            onImagesLoaded={() =>
              onImageLoaded(CharacterPrimaryRenderMode.defaultNoHead)
            }
          />

          {/* TALK */}
          <CharacterAnimationTalk
            isActive={isActive_Talk}
            config={config}
            imageFilters={imageFilters}
            characterRender={characterRender}
            renderOptions={talkRenderOptions}
            onImagesLoaded={() =>
              onImageLoaded(CharacterPrimaryRenderMode.talk)
            }
          />

          {/* WALK */}
          <CharacterAnimationWalk
            isActive={isActive_Walk}
            config={config}
            imageFilters={imageFilters}
            characterRender={characterRender}
            animationFrame={walkAnimationFrame || 0}
            direction={facing}
            onImagesLoaded={() =>
              onImageLoaded(CharacterPrimaryRenderMode.walk)
            }
          />

          {/* ANIMATIONS */}
          <CharacterAnimation
            isActive={isActive_Animation}
            config={config}
            imageFilters={imageFilters}
            characterRender={characterRender}
            activeAnimation={activeAnimation}
            keepLastFrame={playAnimation?.keepLastFrame}
            onAnimationEnd={
              renderMode === CharacterRenderMode.idle
                ? onIdleEnd
                : playAnimation?.onAnimationEnd
            }
            onImagesLoaded={() =>
              onImageLoaded(CharacterPrimaryRenderMode.animations)
            }
          />

          {/* DEFAULT */}
          <CharacterImageDefault
            isActive={isActive_Default}
            config={config}
            imageFilters={imageFilters}
            characterRender={characterRender}
            onImagesLoaded={() =>
              onImageLoaded(CharacterPrimaryRenderMode.default)
            }
          />
        </Group>
      </Group>

      {(renderOrigin || engineConfig.state.renderCharacterOrigin) && (
        <Origin
          color={originColor || engineConfig.state.characterOriginColor}
        />
      )}

      {engineConfig.state.renderCharacterFacing && (
        <Group scaleX={getScaleX()}>
          <Vector
            color={facingColor || engineConfig.state.characterFacingColor}
            vector={
              facing === Direction.right ? { x: 14, y: 0 } : { x: -14, y: 0 }
            }
          />
        </Group>
      )}

      {engineConfig.state.renderCharacterDepthLine && (
        <DepthLine color={engineConfig.state.characterDepthLineColor} />
      )}
    </Group>
  );
};

export default CharacterRender;
