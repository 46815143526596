import { MENU_IDS, MenuProps } from "..";

import GAME_CONFIG from "game-files/gameConfig";
import MenuButton from "../menu-elements/MenuButton";
import MenuSpacer from "../menu-elements/MenuSpacer";
import MenuWrapper from "../menu-elements/MenuWrapper";

const MainMenu = (props: MenuProps) => {
  const { isActive, closeMenu, openSubMenu, onExitGame } = props;

  const menuWidth = 200;
  const menuHeight = 174;

  const buttonWidth = 144;
  const buttonPaddingX = 18;

  const footerButtonWidth = 88;
  const footerButtonPaddingX = 4;

  return (
    <MenuWrapper
      isActive={isActive}
      width={menuWidth}
      height={menuHeight}
      title={GAME_CONFIG.name}
      titleGap={10}
    >
      {[
        {
          label: { en: "Load a game", cz: "Nahrát hru" },
          onClick: () => openSubMenu({ menuId: MENU_IDS.loadGame }),
        },
        {
          label: { en: "Save this game", cz: "Uložit hru" },
          onClick: () => openSubMenu({ menuId: MENU_IDS.saveGame }),
        },
        {
          label: { en: "Start a new game", cz: "Začít novou hru" },
          onClick: () => openSubMenu({ menuId: MENU_IDS.newGame }),
        },
        {
          label: { en: "Game settings", cz: "Herní nastavení" },
          onClick: () => openSubMenu({ menuId: MENU_IDS.settings }),
        },
        {
          label: { en: "Quit playing", cz: "Opustit hru" },
          onClick: () => onExitGame(),
        },
      ].map((p, i) => (
        <MenuButton
          key={i}
          x={Math.round(menuWidth / 2)}
          width={buttonWidth}
          centered
          paddingX={buttonPaddingX}
          label={p.label}
          onClick={p.onClick}
        />
      ))}

      <MenuSpacer height={10} />

      <MenuButton
        x={Math.round(menuWidth / 2 + buttonWidth / 2 - footerButtonWidth)}
        width={footerButtonWidth}
        paddingX={footerButtonPaddingX}
        label={{ en: "Resume game", cz: "Zpět do hry" }}
        onClick={closeMenu}
        centeredText
      />
    </MenuWrapper>
  );
};

export default MainMenu;
