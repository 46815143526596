/* eslint-disable react-hooks/exhaustive-deps */

import {
  ACTION,
  getOppositeDirection,
  getWalkAreaBase,
  isBlockedWalkPath,
  isWalkableWalkPath,
} from "game-engine/utils";
import {
  ActionQueueType,
  BlockedSceneWalkAreaType,
  CursorRenderType,
  Direction,
  Position,
  SceneNeighborsType,
  SceneWalkAreaEnum,
  SceneWalkAreaType,
  SceneWalkPathBlockedType,
  SceneWalkPathHorizontalType,
  SceneWalkPathVerticalType,
  SceneWalkPathWalkableType,
  SceneWalkPathsType,
  WalkPathClickAreaImage,
  WalkPathClickAreaRectangle,
} from "game-engine/types";
import { Group, Line, Rect } from "react-konva";
import React, { useEffect, useMemo, useState } from "react";
import {
  SCENES_LAYOUTS_DEF,
  getSceneByUniqueId,
} from "game-files/scenes/SCENE_LAYOUTS";

import { CinematicId } from "game-files/common/ids";
import DIALOG_OPTIONS from "game-files/common/dialogs";
import GAME_CONFIG from "game-files/gameConfig";
import Image from "game-engine/components/basic-elements/Image";
import { getDynamicLightingByCurrentObjective } from "game-engine/utils/get-by-current-objective/get-scene-dynamic-lighting";
import useGame from "game-engine/hooks/useGame";

enum WalkOutType {
  "missingLightWarning" = "missingLightWarning",
  "deathByDarkness" = "deathByDarkness",
}

const SceneWalkOutAreas = (props: {
  sceneWalkPaths: SceneWalkPathsType;
  sceneNeighbors: SceneNeighborsType;
  runActions;
  walkOutInitiatedRef: any;
  setForceGuiBlockerRef: { current: (b: boolean) => void };
  forceWalkOut?: { walkTo: Position; direction: Direction };
  onSceneWalkOut?: () => void;
  listening?: boolean;
}) => {
  const {
    sceneWalkPaths,
    sceneNeighbors,
    runActions,
    forceWalkOut,
    walkOutInitiatedRef,
    onSceneWalkOut,
    listening = true,
  } = props;

  const { gamePlay, gameItems, engineConfig, gameFns, cursor } = useGame();

  const ignoreWalkAreas = !!gameItems.state.itemInCursor;

  const warnCountBeforeDeathByDarkness =
    GAME_CONFIG.actions.death.byDarkness.warningCount;

  //
  // DIRECTION CLICK COUNTERS
  //
  const [directionClickCount, setDirectionClickCount] = useState<{
    [key in Direction]: number;
  }>({
    [Direction.left]: 0,
    [Direction.right]: 0,
    [Direction.up]: 0,
    [Direction.down]: 0,
  });

  const incrementDirectionCounter = (direction: Direction) => {
    const updatedCounters = { ...directionClickCount };
    updatedCounters[direction] += 1;
    setDirectionClickCount(updatedCounters);
  };

  //
  // SCENE PATH AREAS
  //
  const getWalkableWalkOutArea: (options: {
    located: Direction;
    scenePath: SceneWalkPathHorizontalType | SceneWalkPathVerticalType;
  }) => SceneWalkAreaType = (options) => {
    let { located, scenePath: _scenePath } = options;
    let neighborScene = undefined;

    const areaBase = getWalkAreaBase({ located });
    let scenePath;

    switch (located) {
      case Direction.left:
        scenePath = _scenePath as SceneWalkPathHorizontalType;
        neighborScene = getSceneByUniqueId(sceneNeighbors?.sceneId_left);
        if (!neighborScene) {
          return;
        }
        return {
          ...areaBase,
          type: SceneWalkAreaEnum.walkable,
          scene: neighborScene,
          walkTo: scenePath.walkToEnd ?? { x: 0, y: scenePath.edgeWalkY },
          clickArea: scenePath.clickArea,
        };

      case Direction.right:
        scenePath = _scenePath as SceneWalkPathHorizontalType;
        neighborScene = getSceneByUniqueId(sceneNeighbors?.sceneId_right);
        if (!neighborScene) {
          return;
        }
        return {
          ...areaBase,
          type: SceneWalkAreaEnum.walkable,
          scene: neighborScene,
          walkTo: scenePath.walkToEnd ?? {
            x: GAME_CONFIG.scene.dimensions.x,
            y: scenePath.edgeWalkY,
          },
          clickArea: scenePath.clickArea,
        };

      case Direction.up:
        scenePath = _scenePath as SceneWalkPathVerticalType;
        neighborScene = getSceneByUniqueId(sceneNeighbors?.sceneId_up);
        if (!neighborScene) {
          return;
        }
        return {
          ...areaBase,
          type: SceneWalkAreaEnum.walkable,
          scene: neighborScene,
          walkTo: scenePath.walkToEnd ?? { x: scenePath.edgeWalkX, y: 0 },
          clickArea: scenePath.clickArea,
        };

      case Direction.down:
        scenePath = _scenePath as SceneWalkPathVerticalType;
        neighborScene = getSceneByUniqueId(sceneNeighbors?.sceneId_down);
        if (!neighborScene) {
          return;
        }
        return {
          ...areaBase,
          type: SceneWalkAreaEnum.walkable,
          scene: neighborScene,
          walkTo: scenePath.walkToEnd ?? {
            x: scenePath.edgeWalkX,
            y: GAME_CONFIG.scene.dimensions.y,
          },
          clickArea: scenePath.clickArea,
        };
    }
  };

  const areas: (SceneWalkAreaType | BlockedSceneWalkAreaType)[] =
    useMemo(() => {
      const walkOutAreas: (SceneWalkAreaType | BlockedSceneWalkAreaType)[] = [];

      // SCENE WALK PATHS
      Object.entries(sceneWalkPaths || {}).forEach(([located, scenePath]) => {
        if (scenePath) {
          // WALKABLE PATHS
          if (isWalkableWalkPath(scenePath)) {
            const area: SceneWalkAreaType = getWalkableWalkOutArea({
              located: located as Direction,
              scenePath: scenePath as SceneWalkPathWalkableType,
            });
            walkOutAreas.push(area);
          }

          // BLOCKED PATHS
          else if (isBlockedWalkPath(scenePath)) {
            const area: BlockedSceneWalkAreaType = {
              ...getWalkAreaBase({ located: located as Direction }),
              type: SceneWalkAreaEnum.blocked,
              actions: (scenePath as SceneWalkPathBlockedType).actions,
            };
            walkOutAreas.push(area);
          }
        }
      });

      return walkOutAreas;
    }, [sceneWalkPaths]);

  //
  // LOGIC
  //
  const onTransitionClick = (
    area: SceneWalkAreaType | BlockedSceneWalkAreaType,
    clickedPosition: Position
  ) => {
    /* BLOCKED AREAS */
    if (area.type === SceneWalkAreaEnum.blocked) {
      return (
        area.actions?.length &&
        runActions([
          ACTION.face({ facePosition: clickedPosition }),
          ...area.actions,
        ])
      );
    }

    /* WALKABLE AREAS */
    if (area.type === SceneWalkAreaEnum.walkable) {
      const targetSceneId = area.scene.uniqueId;

      const sceneIds = [
        gamePlay.state.currentScene?.uniqueSceneId,
        targetSceneId,
      ];
      const sceneLink = SCENES_LAYOUTS_DEF.sceneLinks.find(
        (sceneLink) =>
          sceneIds.includes(sceneLink.scene1.uniqueId) &&
          sceneIds.includes(sceneLink.scene2.uniqueId)
      );

      // By default, the direction between scenes is specified by scenes layout
      let targetSceneFromDirection = getOppositeDirection(area.direction);

      // Scene Links can go between different directions specified via sceneLinks -> find the target scene direction
      if (sceneLink) {
        const newScene =
          sceneLink.scene1.uniqueId === targetSceneId
            ? sceneLink.scene1
            : sceneLink.scene2;
        targetSceneFromDirection = newScene.direction;
      }

      const targetScene = getSceneByUniqueId(targetSceneId);

      let walkOutType;

      // Check if next scene requires a light source to prevent death
      if (targetScene?.dynamicLighting) {
        const targetDynamicLighting = getDynamicLightingByCurrentObjective({
          dataByCurrentObjective: targetScene?.dynamicLighting,
          currentObjective: gameFns.getCurrentObjective(),
          events: gameFns.getEvents(),
        });

        if (targetDynamicLighting?.deathByDarkness) {
          const nextSceneLightSource = gameFns.getSceneLightSource({
            uniqueSceneId: targetScene.uniqueId,
            dynamicLighting: targetDynamicLighting,
            isNextScene: true,
          });

          // Going to the next scene without light
          if (!nextSceneLightSource) {
            if (
              directionClickCount[area.direction] >=
              warnCountBeforeDeathByDarkness
            ) {
              walkOutType = WalkOutType.deathByDarkness;
            } else {
              // Warn the player that a light source is needed
              walkOutType = WalkOutType.missingLightWarning;
            }
          }
        }
      }

      incrementDirectionCounter(area.direction);

      /* WALK-OUT ACTIONS - STAY IN SCENE AND WARN THE PLAYER ABOUT NEEDING A LIGHT SOURCE */
      if (walkOutType === WalkOutType.missingLightWarning) {
        return runActions([
          ACTION.talkRandom({
            dialogOptions: DIALOG_OPTIONS.death.byDarkness.warningDialogOptions,
          }),
        ]);
      }

      const walkOutActionsBefore: ActionQueueType = [];
      const walkOutActionsAfter: ActionQueueType = [];

      if (onSceneWalkOut) {
        walkOutActionsBefore.push(
          ACTION.execute({
            funcName: "run external onSceneWalkOut func",
            func: onSceneWalkOut,
            blockGui: true,
          })
        );
      }

      /* WALK-OUT ACTIONS - DEATH BY DARKNESS */
      if (walkOutType === WalkOutType.deathByDarkness) {
        // before going out of scene
        walkOutActionsBefore.push(
          ACTION.saveGameBeforeDeath(),
          ACTION.talkRandom({
            dialogOptions:
              DIALOG_OPTIONS.death.byDarkness.beforeLeavingDialogOptions,
          })
        );

        // after leaving scene
        walkOutActionsAfter.push(
          ACTION.playCinematic({
            cinematicId: CinematicId.DeathByDarkness,
            onEnd: {
              death: true,
            },
          })
        );
      } else {
        /* WALK-OUT ACTIONS - GO TO NEIGHBORING SCENE */
        walkOutActionsAfter.push(
          // disable GUI blocker events to avoid cursor flickering
          ACTION.execute({
            funcName: "disable GUI blocker events",
            func: () => {
              props.setForceGuiBlockerRef?.current(true);
            },
            blockGui: true,
          }),

          // set new scene
          ACTION.setCurrentScene({
            uniqueSceneId: targetSceneId,
            walkIn: {
              fromDirection: targetSceneFromDirection,
            },
          })
        );
      }

      /* WALK-OUT ACTIONS */
      return runActions([
        ...walkOutActionsBefore,

        // walk out of the scene with blocked (unskippable) GUI
        // (character cannot return from outside the walk matrix)
        ACTION.walkOutOfScene({
          toDirection: area.direction,
          unskippableWalkOut: true,
          unskippableWalkOutInitiatedRef: walkOutInitiatedRef,
        }),

        // HIDDEN LAYERS - check timers of hidden layers for the next scene
        ACTION.execute({
          funcName: `check hidden layer timers for ${targetSceneId}`,
          func: () => {
            gameFns.checkAndShowHiddenLayersForScene(targetSceneId);
          },
          blockGui: true,
        }),

        // ITEMS - update items sceneChangeCounters
        ACTION.execute({
          funcName: "update scene-change-counters in items",
          func: () => {
            gameFns.incrementItemSceneChangeAndGetDecayed();
          },
          blockGui: true,
        }),

        ...walkOutActionsAfter,
      ]);
    }
  };

  useEffect(() => {
    if (forceWalkOut) {
      // FOR SCENE EDITOR PREVIEW OF WALK-OUT ACTION
      runActions([
        ACTION.walkTo({
          position: forceWalkOut.walkTo,
        }),
        ACTION.walkInDirection({
          fromPosition: forceWalkOut.walkTo,
          direction: forceWalkOut.direction,
          distance: forceWalkOut.direction === Direction.down ? 34 : 20,
        }),
      ]);
    }
  }, [forceWalkOut]);

  //
  // RENDER
  //
  return (
    <>
      <Group listening={listening && !ignoreWalkAreas}>
        {areas.map((area, i) => {
          if (!area) {
            return null;
          }

          const onClick = (e) => {
            if (cursor.state.isHidden || gameItems.state.itemInCursor) {
              // ignore path areas when cursor is hidden or is carrying an item
              return;
            }
            onTransitionClick(area, gameFns.getClickedPosition(e));
          };

          const onMouseEnter = (e) => {
            if (!gameItems.state.itemInCursor && area.cursor) {
              gameFns.setCursorRenderMode(area.cursor);
            }
          };

          const onMouseLeave = (e) => {
            if (!gameItems.state.itemInCursor && area.cursor) {
              gameFns.setCursorRenderMode(CursorRenderType.default);
            }
          };

          const color =
            area.type === SceneWalkAreaEnum.blocked
              ? engineConfig.state.sceneWalkOutAreasBlockedColor
              : engineConfig.state.sceneWalkOutAreasColor;

          const opacity =
            (engineConfig.state.renderSceneWalkOutAreas ? 1 : 0) * 0.4;

          let imageClickArea: WalkPathClickAreaImage;
          let rectangleClickArea: WalkPathClickAreaRectangle;

          // RENDER IMAGE CLICK AREA
          if ((area.clickArea as WalkPathClickAreaImage)?.image) {
            imageClickArea = area.clickArea as WalkPathClickAreaImage;

            return (
              <Image
                key={i}
                src={imageClickArea.image.src}
                onClick={onClick}
                onImageLoaded={undefined}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                opacity={opacity}
                renderFill={false}
                renderOutline={false}
                filters={{
                  tint: { finalColor: color, amount: 1 },
                }}
              />
            );
          }

          // RENDER RECTANGLE CLICK AREA
          if ((area.clickArea as WalkPathClickAreaRectangle)?.rectangle) {
            rectangleClickArea = area.clickArea as WalkPathClickAreaRectangle;
          } else {
            // default scene edge clickable area
            rectangleClickArea = {
              rectangle: {
                x: area.origin?.x,
                y: area.origin?.y,
                width: area.width,
                height: area.height,
              },
            };
          }

          return (
            <Rect
              key={i}
              fill={color}
              x={rectangleClickArea.rectangle.x}
              y={rectangleClickArea.rectangle.y}
              width={rectangleClickArea.rectangle.width}
              height={rectangleClickArea.rectangle.height}
              opacity={opacity}
              onTap={onClick}
              onClick={onClick}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            />
          );
        })}
      </Group>

      <Group listening={false}>
        {/* WALK PATHS */}
        {engineConfig.state.renderSceneWalkOutPositions &&
          Object.entries(sceneWalkPaths)
            ?.filter(([direction, directionValue]) =>
              isWalkableWalkPath(directionValue)
            )
            ?.map(([direction, directionValue], i) => {
              const elems: any[] = [];

              const color = engineConfig.state.sceneWalkOutPositionsColor;

              const walkTo = (directionValue as SceneWalkPathWalkableType)
                ?.walkTo;
              const edgeWalkY = (directionValue as SceneWalkPathHorizontalType)
                ?.edgeWalkY;
              const edgeWalkX = (directionValue as SceneWalkPathVerticalType)
                ?.edgeWalkX;
              const walkToEnd = (directionValue as SceneWalkPathWalkableType)
                ?.walkToEnd;

              if (walkTo) {
                elems.push(
                  <Rect
                    key={`point-${i}`}
                    x={walkTo.x - 1}
                    y={walkTo.y - 1}
                    width={3}
                    height={3}
                    fill={color}
                  />
                );

                if (walkToEnd) {
                  elems.push(
                    <Line
                      key={`line-edgeWalk-${i}`}
                      x={0.5}
                      y={0.5}
                      points={[walkToEnd.x, walkToEnd.y, walkTo.x, walkTo.y]}
                      opacity={0.6}
                      stroke={color}
                      strokeWidth={1}
                      perfectDrawEnabled={false}
                    />,
                    <Rect
                      key={`point-end-${i}`}
                      x={walkToEnd.x - 1}
                      y={walkToEnd.y - 1}
                      width={3}
                      height={3}
                      fill={color}
                    />
                  );
                }

                if (edgeWalkY) {
                  elems.push(
                    <Line
                      key={`line-edgeWalkY-${i}`}
                      x={0.5}
                      y={0.5}
                      points={[
                        direction === "left"
                          ? 0
                          : GAME_CONFIG.scene.dimensions.x,
                        edgeWalkY,
                        walkTo.x,
                        walkTo.y,
                      ]}
                      opacity={0.6}
                      stroke={color}
                      strokeWidth={1}
                      perfectDrawEnabled={false}
                    />
                  );
                }

                if (edgeWalkX) {
                  elems.push(
                    <Line
                      key={`line-edgeWalkX-${i}`}
                      x={0.5}
                      y={0.5}
                      points={[
                        edgeWalkX,
                        direction === "up" ? 0 : GAME_CONFIG.scene.dimensions.y,
                        walkTo.x,
                        walkTo.y,
                      ]}
                      opacity={0.6}
                      stroke={color}
                      strokeWidth={1}
                    />
                  );
                }
              }

              return <React.Fragment key={i}>{elems}</React.Fragment>;
            })}
      </Group>
    </>
  );
};

export default SceneWalkOutAreas;
