import { ACTION, createActionName } from ".";
import {
  ActionId,
  ActionType,
  Action_CharacterPoison,
} from "game-engine/types";

import { CharacterId } from "game-files/common/ids";

//
// POISON CHARACTER
//
type PoisonReturn = () => Action_CharacterPoison;

export const poisonCharacter: (props?: {
  characterId?: CharacterId;
  isPoisoned?: boolean;
}) => PoisonReturn = (props) => () => {
  const isPoisoned = props?.isPoisoned ?? true;

  return {
    _name: createActionName({
      name: isPoisoned ? "poison start" : "poison end",
      prefix: props.characterId || "<main-character>",
    }),
    id: ActionId.characterPoison,
    characterId: props.characterId,
    isPoisoned,
    blockGui: true,
    unskippable: true,
  };
};

//
// POISON MAIN CHARACTER
//
export const poisonMainCharacter: (props?: {
  isPoisoned?: boolean;
}) => () => ActionType[] = (props) => () => {
  const isPoisoned = props?.isPoisoned ?? true;

  return [
    ...(isPoisoned
      ? [
          ACTION.saveGameBeforeDeath({ blockGui: true })(),
          ACTION.stopAllSkills({ prependStopActions: true })(),
        ]
      : []),
    ACTION.poisonCharacter({ characterId: undefined, isPoisoned })(),
  ];
};
