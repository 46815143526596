import { ACTION } from "game-engine/utils";
import { ActionQueueType } from "game-engine/types";

const OnClickWithoutItem: ActionQueueType = [
  ACTION.talkRandom({
    dialogOptions: [
      {
        en: "Hey! Stop poking me!",
        cz: "Hej! Přestaň do mě šťouchat!",
      },
      {
        en: "I feel kinda pixelated.",
        cz: "Připadám si pixelovaně.",
      },
      [
        {
          en: "What was I thinking about again?",
          cz: "O čem že jsem to přemýšlel?",
        },
        {
          en: "Ah! I forgot to eat my lunch!",
          cz: "Ah! Zapomněl jsem se naobědvat!",
        },
      ],
    ],
  }),
];

export default OnClickWithoutItem;
