import {
  CharacterRenderType,
  Direction,
  SpriteDirection,
} from "game-engine/types";

import { CharacterAnimationId } from "game-files/common/ids";
import IMG_default from "./assets/default.png";
import IMG_default_no_head from "./assets/default-no-head.png";
import IMG_hat_on from "./assets/hat-on.png";
import IMG_talk_head from "./assets/talk-head.png";
import IMG_walk_down from "./assets/walk-down.png";
import IMG_walk_left from "./assets/walk-left.png";
import IMG_walk_up from "./assets/walk-up.png";

const RenderDefault: CharacterRenderType = {
  facing: Direction.left,
  offsetX: 1,
  offsetY: 2,

  filters: {
    skin: {
      includeColors: [
        { hex: "#F0896E", threshold: 1 },
        { hex: "#D76F59", threshold: 1 },
        { hex: "#C0564F", threshold: 1 },
        { hex: "#AC413F", threshold: 1 },
        { hex: "#8D2B33", threshold: 1 },
      ],
      ignoreColors: [],
    },
    poison: {
      color: "#66B057",
      amount: 1,
      brightness: 1.7,
    },
  },

  //
  // DEFAULT
  //
  default: {
    image: { src: IMG_default },
    offsetX: 0,
    offsetY: 0,
    width: 22,
    height: 52,
  },

  //
  // DEFAULT NO-HEAD
  //
  defaultNoHead: {
    image: { src: IMG_default_no_head },
    offsetX: 0,
    offsetY: 0,
    width: 22,
    height: 39,
  },

  //
  // TALK
  //
  talk: {
    renderCharacterBody: true,
    offsetX: 0,
    offsetY: -37,
    image: { src: IMG_talk_head },
    spriteConfig: {
      direction: SpriteDirection.Horizontal,
      frameWidth: 19,
      frameHeight: 15,
      frameCount: 12,
    },
  },

  //
  // WALK
  //
  walkLeft: {
    offsetX: 1,
    offsetY: 0,
    image: { src: IMG_walk_left },
    spriteConfig: {
      direction: SpriteDirection.Horizontal,
      frameWidth: 28,
      frameHeight: 52,
      frameCount: 8,
    },
    pixelsWalked: 34, // how many pixels the character travels in one loop
  },

  walkUp: {
    offsetX: 0,
    offsetY: 2,
    image: { src: IMG_walk_up },
    spriteConfig: {
      direction: SpriteDirection.Horizontal,
      frameWidth: 26,
      frameHeight: 53,
      frameCount: 6,
    },
    pixelsWalked: 18, // how many pixels the character travels in one loop
  },

  walkDown: {
    offsetX: 0,
    offsetY: 1,
    image: { src: IMG_walk_down },
    spriteConfig: {
      direction: SpriteDirection.Horizontal,
      frameWidth: 25,
      frameHeight: 53,
      frameCount: 6,
    },
    pixelsWalked: 18, // how many pixels the character travels in one loop
  },

  //
  // ANIMATIONS
  //
  animations: [
    {
      animationId: CharacterAnimationId.hatOn,
      isIdle: true,
      offsetX: -3,
      offsetY: 0,
      image: { src: IMG_hat_on },
      spriteConfig: {
        direction: SpriteDirection.Horizontal,
        frameWidth: 28,
        frameHeight: 60,
        frameCount: 20,
        frameDurationMilliseconds: 120,
      },
    },
  ],
};

export default RenderDefault;
