/* eslint-disable react-hooks/exhaustive-deps */

import { ACTION, getSceneFromCinematicScene } from "game-engine/utils";
import {
  ActionQueueType,
  CinematicOnEnd,
  CinematicSceneType,
} from "game-engine/types";

import { GUIBlockerProps } from "game-engine/components/game-viewport/GameViewport";
import Scene from "../../../Scene";
import { StopActionsType } from "game-engine/components/game-elements/Scene/logic/useSceneActions";
import useGame from "game-engine/hooks/useGame";
import { useMemo } from "react";

const CinematicScene = (props: {
  cinematicScene: CinematicSceneType;
  onEndSettings?: CinematicOnEnd;
  stopActionsRef?: { current: StopActionsType };
  isVisible?: boolean;
  setGuiBlocker: (data: GUIBlockerProps) => void;
  onGuiBlockerClickRef: any;
  setForceGuiBlockerRef: { current: (b: boolean) => void };
  onCinematicLoaded?: () => void;
}) => {
  const {
    cinematicScene,
    onEndSettings,
    stopActionsRef,
    isVisible,
    setGuiBlocker,
    onGuiBlockerClickRef,
    setForceGuiBlockerRef,
    onCinematicLoaded,
  } = props;

  const { mainCharacterConfig } = useGame();

  const onEndActions: ActionQueueType = useMemo(() => {
    // 0: shared actions
    let actions: ActionQueueType = [
      // disable GUI blocker events to avoid cursor flickering
      ACTION.execute({
        funcName: "disable GUI blocker events",
        func: () => {
          props.setForceGuiBlockerRef?.current(true);
        },
        blockGui: true,
      }),
    ];

    if (onEndSettings?.pause) {
      actions.push(ACTION.pause(onEndSettings.pause));
    }

    // 1: check if cinematic sequence has next cinematic
    if (onEndSettings?.setCinematicIds?.length) {
      actions.push(
        ACTION.playCinematicSequence({
          cinematicIds: onEndSettings.setCinematicIds,
          onEnd: {
            setSceneId: onEndSettings.setSceneId,
            sceneFadeIn: onEndSettings.sceneFadeIn,
            sceneWalkIn: onEndSettings.sceneWalkIn,
          },
          pauseBetween: onEndSettings.pause,
        })
      );

      return actions;
    }

    // 2: if no cinematics remaining, check if scene is specified
    if (onEndSettings?.setSceneId) {
      actions.push(
        ACTION.setCurrentScene({
          uniqueSceneId: onEndSettings.setSceneId,
          fadeIn: onEndSettings.sceneFadeIn,
          walkIn: onEndSettings.sceneWalkIn,
        })
      );

      return actions;
    }

    // 3: there's no way to continue
    console.warn(
      "Cinematic sequence ended without onEndSettings resolution (from cinematic scene)."
    );
    return undefined;
  }, [onEndSettings]);

  const scene = useMemo(() => {
    return getSceneFromCinematicScene({
      cinematicScene,
      onEndActions,
      mainCharacterConfig,
    });
  }, [cinematicScene?.scene]);

  //
  // RENDER
  //
  return (
    <Scene
      noAutosave
      isCinematic
      ignoreIdle
      scene={scene}
      stopActionsRef={stopActionsRef}
      isVisible={isVisible}
      setGuiBlocker={setGuiBlocker}
      onGuiBlockerClickRef={onGuiBlockerClickRef}
      setForceGuiBlockerRef={setForceGuiBlockerRef}
      onSceneLoaded={onCinematicLoaded}
      animateLoadTransition={false}
      fadeIn={
        cinematicScene.fadeIn
          ? { durationSec: (cinematicScene.fadeIn as any).durationSec ?? 1 }
          : undefined
      }
    />
  );
};

export default CinematicScene;
