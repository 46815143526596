/* eslint-disable react-hooks/exhaustive-deps */

import { Group, Rect } from "react-konva";
import { SceneLayerDataType, SceneLayerType } from "game-engine/types";
import {
  createActionName,
  getSceneLayerByCurrentObjective,
  storeEventInDevTools,
} from "game-engine/utils";
import { useContext, useEffect, useMemo } from "react";

import { CursorRenderType } from "game-engine/types/cursor";
import DepthLine from "game-engine/components/basic-elements/DepthLine";
import { DevToolsContext } from "game-engine";
import GAME_CONFIG from "game-files/gameConfig";
import { GetDynamicLightingFiltersFunc } from "../Scene/layers/useDynamicLighting";
import Image from "game-engine/components/basic-elements/Image";
import SpriteAnimated from "game-engine/components/basic-elements/SpriteAnimated";
import Text from "game-engine/components/basic-elements/Text";
import useGame from "game-engine/hooks/useGame";

const SceneLayer = (props: {
  sceneLayer: SceneLayerType;
  onClick?: (e?: any) => void;
  onImageLoaded?: () => void;
  getDynamicLightingFilters: GetDynamicLightingFiltersFunc;
  isHidden?: boolean;
}) => {
  const { sceneLayer, getDynamicLightingFilters, onImageLoaded, isHidden } =
    props;
  const { gamePlay, engineConfig, logger, gameFns, gameItems } = useGame();
  const devTools = useContext(DevToolsContext);

  //
  // SCENE
  //
  const sceneLayerData = useMemo<SceneLayerDataType>(() => {
    const dataByCurrentObjective = getSceneLayerByCurrentObjective({
      dataByCurrentObjective: sceneLayer.dataByCurrentObjective,
      currentObjective: gameFns.getCurrentObjective(),
      events: gameFns.getEvents(),
    });

    return dataByCurrentObjective;
  }, [sceneLayer, gamePlay.state.currentObjective, gamePlay.state.events]);

  useEffect(() => {
    if (!sceneLayerData?.image?.src && onImageLoaded) {
      // mark image as loaded, if no image is provided
      onImageLoaded();
    }
  }, [sceneLayerData]);

  //
  // DYNAMIC LIGHTING
  //
  const imageFilters = useMemo(() => {
    return getDynamicLightingFilters({ depthY: sceneLayer.depthY });
  }, [getDynamicLightingFilters]);

  //
  // SETTINGS
  //
  const renderOutline =
    // do not render for full-scene-dimension layers
    sceneLayerData?.offset && engineConfig.state.renderSceneLayerOutline;
  const renderFill =
    // do not render for full-scene-dimension layers
    (sceneLayerData?.offset?.x || sceneLayerData?.offset?.y) &&
    engineConfig.state.renderSceneLayerFill;

  const ignoreOnClick =
    isHidden ||
    sceneLayerData?.ignoreOnClick ||
    engineConfig.state.hideSceneLayers;

  const isInvisible =
    isHidden ||
    sceneLayerData?.isInvisible ||
    engineConfig.state.hideSceneLayers;

  const cursorOnHover = sceneLayerData?.cursorOnHover;

  const clickArea = sceneLayerData?.clickArea;

  const isNotEmpty =
    !!sceneLayerData && (sceneLayerData.image || sceneLayerData.shape);

  //
  // ON CLICK
  //
  const onClick = (e) => {
    const clickedPosition = gameFns.getClickedScenePosition(e);

    logger.info(
      `scene layer clicked [${clickedPosition.x}, ${clickedPosition.y}] (${sceneLayer.id})`,
      sceneLayer,
      sceneLayerData
    );

    storeEventInDevTools({
      devTools,
      event: {
        name: createActionName({
          name: "clicked on",
          value: sceneLayer?.id,
        }),
        data: {
          sceneLayerId: sceneLayer.id,
        },
      },
    });

    if (props.onClick) {
      props.onClick(e);
    }
  };

  //
  // MOUSE
  //
  const onMouseEnter = () => {
    if (!gameItems.state.itemInCursor && cursorOnHover) {
      gameFns.setCursorRenderMode(cursorOnHover);
    }
  };

  const onMouseLeave = () => {
    if (!gameItems.state.itemInCursor && cursorOnHover) {
      gameFns.setCursorRenderMode(CursorRenderType.default);
    }
  };

  //
  // CONTENT
  //
  const renderContentImage = () => {
    if (sceneLayerData?.image?.src) {
      return (
        <SpriteAnimated
          src={sceneLayerData?.image?.src}
          srcTransitionOnChange={sceneLayerData?.image?.transition}
          spriteConfig={{
            ...(sceneLayerData?.spriteConfig || {}),
            frameWidth:
              sceneLayerData?.spriteConfig?.frameWidth ??
              sceneLayerData?.image?.width ??
              GAME_CONFIG.scene.dimensions.x,
            frameHeight:
              sceneLayerData?.spriteConfig?.frameHeight ??
              sceneLayerData?.image?.height ??
              GAME_CONFIG.scene.dimensions.y,
          }}
          controlledAnimation={{
            playAnimation: !!sceneLayerData?.spriteConfig,
          }}
          getSpriteFrameRenderIndex={undefined}
          onImageLoaded={onImageLoaded}
          renderOutline={renderOutline}
          renderFill={renderFill}
          filters={imageFilters}
          isHidden={isInvisible}
        />
      );
    }
  };

  const renderContentRect = () => {
    if (sceneLayerData?.shape?.rect) {
      const {
        width,
        height,
        x = 0,
        y = 0,
        color = "#fff",
        opacity,
      } = sceneLayerData?.shape?.rect;

      return (
        <Rect
          width={width}
          height={height}
          x={x}
          y={y}
          fill={color}
          opacity={opacity}
        />
      );
    }

    return null;
  };

  const renderContentText = () => {
    if (sceneLayerData?.shape?.text) {
      const {
        text,
        width,
        x = 0,
        y = 0,
        color = "#fff",
        opacity,
        outlined,
        outlineStyle,
        shadow,
        align,
        verticalAlign,
        font,
      } = sceneLayerData?.shape?.text;

      return (
        <Text
          text={text}
          color={color}
          position={{ x, y }}
          maxWidth={width}
          minWidth={width}
          opacity={opacity}
          outlined={outlined}
          outlineStyle={outlineStyle}
          shadow={shadow}
          align={align}
          verticalAlign={verticalAlign}
          font={font}
        />
      );
    }

    return null;
  };

  //
  // RENDER
  //
  return !isNotEmpty ? null : (
    <Group
      listening={!ignoreOnClick}
      position={sceneLayerData?.offset || { x: 0, y: 0 }}
      opacity={isInvisible ? 0 : 1}
    >
      <Group
        onClick={onClick}
        onTap={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {/* SPRITE IS USED EVEN FOR SINGLE IMAGES TO BETTER HANDLE CHANGING .SRC */}
        <Group listening={!clickArea}>
          {renderContentImage()}
          {renderContentRect()}
          {renderContentText()}
        </Group>

        {clickArea ? (
          <Group opacity={0} position={clickArea.offset ?? { x: 0, y: 0 }}>
            <Image src={clickArea.image.src} isHidden={isInvisible} />
          </Group>
        ) : null}
      </Group>

      {engineConfig.state.renderSceneLayerDepthLine && sceneLayer?.depthY ? (
        <DepthLine
          color={engineConfig.state.sceneLayerDepthLineColor}
          position={{
            x: 0,
            y: sceneLayer?.depthY - (sceneLayerData?.offset?.y || 0),
          }}
        />
      ) : null}
    </Group>
  );
};

export default SceneLayer;
