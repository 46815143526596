import render, { devTools } from "./render";

import { CharacterConfigType } from "game-engine/types";
import { CharacterId } from "game-files/common/ids";
import { GameObjective } from "game-files/gameObjectives";

const THIS_ID = CharacterId.Felix;

//
// CONFIG
//
const CharacterConfig: CharacterConfigType = {
  id: THIS_ID,
  name: { en: "Felix", cz: "Felix" },

  dialogConfig: {
    [GameObjective.GAME_START]: {
      aboveOffsetMax: 54,
      aboveOffsetMin: 40,
      offsetBelowMax: -5,
      color: `#fff`,
    },
  },

  render: render,
  devTools: devTools,
  idleConfig: undefined, //  defined in MAIN_CHARACTER_CONFIG
  onSceneIdle: undefined, // defined in MAIN_CHARACTER_CONFIG
  onPoison: undefined, //    defined in MAIN_CHARACTER_CONFIG
  onClick: undefined, //     defined in MAIN_CHARACTER_CONFIG
};

export default CharacterConfig;
