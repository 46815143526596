import { CharacterAnimationId, SkillId } from "game-files/common/ids";
import { SkillType, SpriteDirection } from "game-engine/types";

import { ACTION } from "game-engine/utils";
import { GameObjective } from "game-files/gameObjectives";
import IMAGE_RenderOverride from "./assets/celestial-flame.png";

const CelestialFlame: SkillType = {
  id: SkillId.CelestialFlame,

  name: { en: "Celestial Flame", cz: "Nebeský plamen" },

  guiSkillSlot: undefined,

  characterRender: {
    override: {
      image: { src: IMAGE_RenderOverride },
      spriteConfig: {
        direction: SpriteDirection.Horizontal,
        frameWidth: 50,
        frameHeight: 56,
        frameCount: 40,
        frameDurationMilliseconds: 60,
      },
      width: 62,
      height: 71,
      offsetX: 0,
      offsetY: -4,
    },

    // override will be used until this animation is reached:
    overrideEndAnimationId: CharacterAnimationId.celestialFlameEnd,
  },

  states: {
    [GameObjective.GAME_START]: {
      state_start: {
        getActions: ({ gamePlay, gameItems }) => [
          ACTION.talk({
            dialog: { en: "Let's do this.", cz: "Jdeme na to." },
          }),

          ACTION.characterAnimation({
            animationId: CharacterAnimationId.celestialFlameStart,
            keepLastFrame: true,
          }),
        ],
      },

      state_active: {
        duration: {
          sceneChangeCount: 1, // stop after each scene change
          durationSec: undefined, // keep until scene changes
        },
      },

      state_stop: {
        ignoreStopIf: {
          deathByDarkness: true,
        },

        getActions: ({ gamePlay, gameItems }) => [
          ACTION.talkRandom({
            dialogOptions: [
              {
                en: "Uh-oh, my spark's about to fizzle!",
                cz: "Jéje, plamen začíná dohořívat!",
              },
              {
                en: "The fire's fading... I'm grounded soon!",
                cz: "Oheň pohasíná... za chvíli přistanu!",
              },
              {
                en: "Looks like my fiery ride is burning out!",
                cz: "Zdá se, že můj ohnivý let dohořívá!",
              },
              {
                en: "Time to step back onto solid ground!",
                cz: "Je čas vrátit se zpátky na pevnou zem!",
              },
              {
                en: "My wings of fire are out of fuel!",
                cz: "Mým ohnivým křídlům dochází palivo!",
              },
              {
                en: "The flame dims... down I go!",
                cz: "Plamen pohasíná... tak zase po svých!",
              },
              {
                en: "The flame's going out... better stick the landing!",
                cz: "Plamen pohasíná... teď jen zvládnout přistání!",
              },
            ],
          }),

          ACTION.pause(0.4),

          ACTION.characterAnimation({
            animationId: CharacterAnimationId.celestialFlameEnd,
          }),

          ACTION.pause(0.4),

          ACTION.talkRandom({
            dialogOptions: [
              { en: "Phew, back to normal!", cz: "Uf, zpátky do normálu!" },
              {
                en: "Well, that was fiery fun!",
                cz: "To bylo ohnivé dobrodružství!",
              },
              {
                en: "Back to the ground, where I belong!",
                cz: "Zpátky na zem, kam patřím!",
              },
              {
                en: "That was a bright idea - literally!",
                cz: "To byl zářivý nápad - doslova!",
              },
              {
                en: "Flame off, fun over!",
                cz: "Plamen zhasl, zábava skončila!",
              },
              {
                en: "I'm me again — less sparkly, but still charming!",
                cz: "A jsem to zase já - méně ohnivý, ale stále okouzlující!",
              },
              {
                en: "That was a blast — back to being boring!",
                cz: "To byla jízda - zpátky k mému nudnému já!",
              },
            ],
          }),
        ],
      },

      state_waitForReset: {
        duration: undefined,
      },
    },
  },

  dynamicLighting: {
    darkScenes: {
      lightSource: {
        tint: {
          amount: 1,
          finalColor: `#00b3ff`,
        },
        flicker: {
          color: `#afe7ff`,
          amount: 0.05,
          stepDurationSec: 0.5,
        },
      },
    },

    regularScenes: undefined,
    /* {
      lightSource: {
        tint: {
          color: `#00b3ff`,
          amount: 0.2,
        },
        flicker: {
          color: `#afe7ff`,
          amount: 0.05,
          stepDurationSec: 0.5,
        },
      },

      sceneFilters: {
        light: {
          brightness: 1,
          contrast: 1,
          saturation: 1,
        },
        shadowLayers: {
          brightness: 0.99,
          contrast: 0.99,
          saturation: 1,
        },
        shadowForeground: {
          brightness: 0.9,
          contrast: 0.9,
          saturation: 1,
        },
      },
    }, */
  },
};

export default CelestialFlame;
