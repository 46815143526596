/* eslint-disable react-hooks/exhaustive-deps */

import {
  CharacterRenderMode,
  CharacterRenderType,
  Direction,
  Position,
  SceneCharacterDataType,
  SceneMainCharacterOptions,
  SceneWalkPathsType,
} from "game-engine/types";
import { useEffect, useMemo, useState } from "react";
import useGame, {
  LOCAL_STORAGE_MAIN_CHARACTER_FACING,
  LOCAL_STORAGE_MAIN_CHARACTER_POSITION,
  LOCAL_STORAGE_MAIN_CHARACTER_SCALE,
} from "game-engine/hooks/useGame";

import GAME_CONFIG from "game-files/gameConfig";
import { getCharacterRenderByCurrentObjective } from "game-engine/utils/get-by-current-objective";
import { storeInLocalStorage } from "game-engine/utils/local-storage";

const useMainCharacter = (props: {
  mainCharacterOptions: SceneMainCharacterOptions;
  sceneWalkPaths: SceneWalkPathsType;
  isCinematic?: boolean;
}) => {
  const { mainCharacterOptions, sceneWalkPaths, isCinematic } = props;

  const { gamePlay, mainCharacterConfig, gameFns } = useGame();

  //
  // DATA BY CURRENT OBJECTIVE
  //
  const mainCharacterRender = useMemo<CharacterRenderType>(() => {
    let render: CharacterRenderType;

    render = getCharacterRenderByCurrentObjective({
      dataByCurrentObjective: mainCharacterConfig.render,
      currentObjective: gameFns.getCurrentObjective(),
      events: gameFns.getEvents(),
    });

    return render;
  }, [
    mainCharacterConfig,
    gamePlay.state.currentObjective,
    gamePlay.state.events,
  ]);

  //
  // DEFAULT POSITION
  //
  const isWalkingBetweenScenes =
    !isCinematic && gamePlay.state.currentScene.walkIn && sceneWalkPaths;

  const positionBeforeWalkIn: Position = {
    x: GAME_CONFIG.scene.dimensions.x * 10, // purposely put character out of scene
    y: GAME_CONFIG.scene.dimensions.y * 10, // -> position will be updated from walk-in animation
  };

  const positionFromSavedGame: Position = gamePlay.state.mainCharacterPosition;

  const positionDefault: Position = {
    x: Math.round(GAME_CONFIG.scene.dimensions.x / 2),
    y: Math.round((GAME_CONFIG.scene.dimensions.y / 4) * 3),
  };

  //
  // CHARACTER DEF
  //
  const [mainCharacter, setMainCharacter] = useState<SceneCharacterDataType>({
    config: mainCharacterConfig,
    renderMode: CharacterRenderMode.default,
    scale: gamePlay.state.mainCharacterScale ?? 1,
    facing:
      mainCharacterOptions?.facing ??
      gamePlay.state.mainCharacterFacing ??
      Direction.left,
    position: isWalkingBetweenScenes
      ? positionBeforeWalkIn
      : mainCharacterOptions?.position ??
        positionFromSavedGame ??
        positionDefault,
    walkAnimationFrame: 0,
    dialog: undefined,
    playAnimation: undefined,
    talkRenderOptions: undefined,
  });

  useEffect(() => {
    setMainCharacter((prevState) => {
      return { ...prevState, config: mainCharacterConfig };
    });
  }, [mainCharacterConfig]);

  //
  // STORE INFO
  //
  useEffect(() => {
    storeInLocalStorage(
      LOCAL_STORAGE_MAIN_CHARACTER_POSITION,
      JSON.stringify(mainCharacter.position)
    );
    storeInLocalStorage(
      LOCAL_STORAGE_MAIN_CHARACTER_SCALE,
      JSON.stringify(mainCharacter.scale)
    );
    if (mainCharacter.facing) {
      storeInLocalStorage(
        LOCAL_STORAGE_MAIN_CHARACTER_FACING,
        JSON.stringify(mainCharacter.facing)
      );
    }
  }, [mainCharacter?.position, mainCharacter?.scale, mainCharacter?.facing]);

  return { mainCharacter, setMainCharacter, mainCharacterRender };
};

export default useMainCharacter;
