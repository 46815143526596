import { ACTION } from "game-engine/utils";
import { ActionQueueType } from "game-engine/types";

const OnClickWithoutItem: ActionQueueType = [
  ACTION.talkRandom({
    dialogOptions: [
      {
        en: "Hey! Did you just click on me?",
        cz: "Hej! To jsi na mě vážně kliknul?",
      },
      [
        {
          en: "Are you clicking on me?",
          cz: "To jsi na mě kliknul?",
        },
        {
          en: "How rude!",
          cz: "Jak nevychované!",
        },
      ],
    ],
  }),
];

export default OnClickWithoutItem;
