import {
  CharacterRenderType,
  Direction,
  SpriteDirection,
} from "game-engine/types";

import { CharacterAnimationId } from "game-files/common/ids";
import IMG_animation_celestialFlameEnd from "./assets/animation-celestial-flame-end.png";
import IMG_animation_celestialFlameStart from "./assets/animation-celestial-flame-start.png";
import IMG_animation_deathChoke from "./assets/animation-death-choke.png";
import IMG_animation_headLookBack from "./assets/animation-head-look-back.png";
import IMG_animation_kick from "./assets/animation-kick.png";
import IMG_animation_shrug from "./assets/animation-shrug.png";
import IMG_animation_stretch from "./assets/animation-stretch.png";
import IMG_animation_yawn from "./assets/animation-yawn.png";
import IMG_default from "./assets/default.png";
import IMG_default_no_head from "./assets/default-no-head.png";
import IMG_talk_head from "./assets/talk-head.png";
import IMG_walk_down from "./assets/walk-down.png";
import IMG_walk_left from "./assets/walk-left.png";
import IMG_walk_up from "./assets/walk-up.png";

const RenderDefault: CharacterRenderType = {
  facing: Direction.left,
  offsetX: -1,
  offsetY: 2,

  filters: {
    skin: {
      includeColors: [
        { hex: "#F0896E", threshold: 1 },
        { hex: "#D76F59", threshold: 1 },
        { hex: "#C0564F", threshold: 1 },
        { hex: "#AC413F", threshold: 1 },
        { hex: "#8D2B33", threshold: 1 },
      ],
      ignoreColors: [],
    },
    poison: {
      color: "#66B057",
      amount: 1,
      brightness: 2,
    },
  },

  //
  // DEFAULT
  //
  default: {
    image: { src: IMG_default },
    offsetX: 0,
    offsetY: 0,
    width: 21,
    height: 51,
  },

  //
  // DEFAULT NO-HEAD
  //
  defaultNoHead: {
    image: { src: IMG_default_no_head },
    offsetX: 0,
    offsetY: 0,
    width: 21,
    height: 42,
  },

  //
  // TALK
  //
  talk: {
    renderCharacterBody: true,
    offsetX: 0,
    offsetY: -40,
    image: { src: IMG_talk_head },
    spriteConfig: {
      direction: SpriteDirection.Horizontal,
      frameWidth: 13,
      frameHeight: 11,
      frameCount: 12,
    },
  },

  //
  // WALK
  //
  walkLeft: {
    offsetX: 1,
    offsetY: 0,
    image: { src: IMG_walk_left },
    spriteConfig: {
      direction: SpriteDirection.Horizontal,
      frameWidth: 28,
      frameHeight: 51,
      frameCount: 8,
    },
    pixelsWalked: 34, // how many pixels the character travels in one loop
  },

  walkUp: {
    offsetX: 0,
    offsetY: 2,
    image: { src: IMG_walk_up },
    spriteConfig: {
      direction: SpriteDirection.Horizontal,
      frameWidth: 24,
      frameHeight: 53,
      frameCount: 6,
    },
    pixelsWalked: 18, // how many pixels the character travels in one loop
  },

  walkDown: {
    offsetX: 0,
    offsetY: 1,
    image: { src: IMG_walk_down },
    spriteConfig: {
      direction: SpriteDirection.Horizontal,
      frameWidth: 24,
      frameHeight: 53,
      frameCount: 6,
    },
    pixelsWalked: 18, // how many pixels the character travels in one loop
  },

  //
  // ANIMATIONS
  //
  animations: [
    {
      animationId: CharacterAnimationId.kick,
      isIdle: true,
      offsetX: -3,
      offsetY: 0,
      image: { src: IMG_animation_kick },
      spriteConfig: {
        direction: SpriteDirection.Horizontal,
        frameWidth: 38,
        frameHeight: 51,
        frameCount: 23,
        frameDurationMilliseconds: 100,
      },
    },

    {
      animationId: CharacterAnimationId.yawn,
      isIdle: true,
      offsetX: 1,
      offsetY: -1,
      image: { src: IMG_animation_yawn },
      spriteConfig: {
        direction: SpriteDirection.Horizontal,
        frameWidth: 28,
        frameHeight: 49,
        frameCount: 23,
        frameDurationMilliseconds: 140,
      },
    },

    {
      animationId: CharacterAnimationId.stretch,
      isIdle: true,
      offsetX: 2,
      offsetY: -1,
      image: { src: IMG_animation_stretch },
      spriteConfig: {
        direction: SpriteDirection.Horizontal,
        frameWidth: 33,
        frameHeight: 49,
        frameCount: 21,
      },
    },

    {
      animationId: CharacterAnimationId.shrug,
      isIdle: false,
      offsetX: -2,
      offsetY: -1,
      image: { src: IMG_animation_shrug },
      spriteConfig: {
        direction: SpriteDirection.Horizontal,
        frameWidth: 26,
        frameHeight: 49,
        frameCount: 11,
        frameDurationMilliseconds: 110,
        frameSequence: [{ from: 0, to: 6 }, 6, 7, 7, { from: 8, to: 11 }],
      },
    },

    {
      animationId: CharacterAnimationId.lookBack,
      isIdle: true,
      offsetX: 0,
      offsetY: -40,
      renderCharacterBody: true,
      image: { src: IMG_animation_headLookBack },
      spriteConfig: {
        direction: SpriteDirection.Horizontal,
        frameWidth: 13,
        frameHeight: 11,
        frameCount: 12,
        frameDurationMilliseconds: 140,
      },
    },

    {
      animationId: CharacterAnimationId.deathChoke,
      isIdle: false,
      offsetX: -11,
      offsetY: 4,
      image: { src: IMG_animation_deathChoke },
      spriteConfig: {
        direction: SpriteDirection.Horizontal,
        frameWidth: 43,
        frameHeight: 54,
        frameCount: 24,
        frameDurationMilliseconds: 80,
        frameSequence: [
          { from: 0, to: 14 },
          { pause: 2 },
          { from: 15, to: 19 },
          { pause: 5 },
          { from: 20, to: 24 },
        ],
      },
    },

    {
      animationId: CharacterAnimationId.celestialFlameStart,
      isIdle: false,
      offsetX: 1,
      offsetY: 1,
      image: { src: IMG_animation_celestialFlameStart },
      spriteConfig: {
        direction: SpriteDirection.Horizontal,
        frameWidth: 59,
        frameHeight: 61,
        frameCount: 50,
        frameDurationMilliseconds: 80,
      },
    },

    {
      animationId: CharacterAnimationId.celestialFlameEnd,
      isIdle: false,
      offsetX: -3,
      offsetY: 0,
      image: { src: IMG_animation_celestialFlameEnd },
      spriteConfig: {
        direction: SpriteDirection.Horizontal,
        frameWidth: 39,
        frameHeight: 57,
        frameCount: 20,
        frameDurationMilliseconds: 80,
      },
    },
  ],
};

export default RenderDefault;
