import {
  ActionId,
  Action_CharacterWalk,
  CharacterWalkPath,
  Direction,
  ExtendWalkPathType,
  Position,
} from "game-engine/types";
import { positionToString, walkPathEndToString } from "../by-types";

import { CharacterId } from "game-files/common/ids";
import { createActionName } from ".";
import { getOppositeDirection } from "../geometry";

//
// CREATE BASIC WALK ACTION
//
type WalkProps = {
  characterId?: CharacterId;
  walkPath: CharacterWalkPath;
  blockGui?: boolean;
  unskippable?: boolean;
  extendPath?: ExtendWalkPathType;
  ignoreWalkMap?: boolean;
};

type WalkReturn = () => Action_CharacterWalk;

export const walk: (props: WalkProps) => WalkReturn = (props) => () => {
  return {
    ...props,
    _name: createActionName({
      name: `walk to`,
      value: `${walkPathEndToString(props.walkPath)}`,
      prefix: props.characterId || "<main-character>",
    }),
    id: ActionId.characterWalk,
    walkAnimationFrame: 0,
    ignoreWalkMap: props.ignoreWalkMap,

    ifMainCharacter: {
      blockGui: props.blockGui,
      unskippable: props.unskippable,
    },

    ifNonMainCharacter: {
      blockGui: true,
      unskippable: true, // if any other than main character walks, mouse click would stop it!
    },
  };
};

//
// CREATE BASIC WALK TO ACTION
//
type WalkToProps = {
  characterId?: CharacterId;
  fromPosition?: Position;
  position: Position;
  blockGui?: boolean;
  unskippable?: boolean;
  extendPath?: ExtendWalkPathType;
  ignoreWalkMap?: boolean;
};

type WalkToReturn = () => Action_CharacterWalk;

export const walkTo: (props: WalkToProps) => WalkToReturn = (props) => () => {
  return {
    ...props,
    _name: createActionName({
      name: `walk to`,
      value: `${positionToString(props.position)}`,
      prefix: props.characterId || "<main-character>",
    }),
    id: ActionId.characterWalk,
    walkFromPosition: props.fromPosition,
    walkToPosition: props.position,
    walkAnimationFrame: 0,
    ignoreWalkMap: props.ignoreWalkMap,

    ifMainCharacter: {
      blockGui: props.blockGui,
      unskippable: props.unskippable,
    },

    ifNonMainCharacter: {
      blockGui: true,
      unskippable: true, // if any other than main character walks, mouse click would stop it!
    },
  };
};

//
// CREATE BASIC WALK IN DIRECTION ACTION
//
type WalkInDirectionProps = {
  characterId?: CharacterId;
  fromPosition?: Position;
  toPosition?: Position;
  direction: Direction;
  distance: number;
  blockGui?: boolean;
  unskippable?: boolean;
} & (
  | {
      fromPosition: Position;
    }
  | {
      toPosition: Position;
    }
);

type WalkInDirectionReturn = () => Action_CharacterWalk;

export const walkInDirection: (
  props: WalkInDirectionProps
) => WalkInDirectionReturn = (props) => () => {
  return {
    ...props,
    _name: createActionName({
      name: `walk`,
      value: `${props.direction}`,
      prefix: props.characterId || "<main-character>",
    }),
    id: ActionId.characterWalk,
    walkInDirection: props.direction,
    walkInDirectionTo: props.toPosition,
    walkInDirectionFrom: props.fromPosition,
    walkInDirectionDistance: props.distance,
    walkAnimationFrame: 0,

    ifMainCharacter: {
      blockGui: props.blockGui,
      unskippable: props.unskippable,
    },

    ifNonMainCharacter: {
      blockGui: true,
      unskippable: true, // if any other than main character walks, mouse click would stop it!
    },
  };
};

//
// CREATE WALK-INTO-SCENE ACTION
//
type WalkIntoSceneProps = {
  characterId?: CharacterId;
  fromDirection: Direction;
  toPosition?: Position; // optional position to walk to after entering the scene
  blockGui?: boolean;
  unskippable?: boolean;
};

type WalkIntoSceneReturn = () => Action_CharacterWalk;

export const walkIntoScene: (
  props: WalkIntoSceneProps
) => WalkIntoSceneReturn = (props) => () => {
  const direction = getOppositeDirection(props.fromDirection);

  return {
    ...props,
    _name: createActionName({
      name: `walk into scene from direction`,
      value: `${direction}`,
      prefix: props.characterId || "<main-character>",
    }),
    id: ActionId.characterWalk,
    walkIntoSceneFromDirection: direction,
    walkIntoSceneToPosition: props.toPosition,
    walkAnimationFrame: 0,

    ifMainCharacter: {
      blockGui: props.blockGui,
      unskippable: props.unskippable,
    },

    ifNonMainCharacter: {
      blockGui: true,
      unskippable: true, // if any other than main character walks, mouse click would stop it!
    },
  };
};

//
// CREATE WALK-OUT-OF-SCENE ACTION
//
type WalkOutOfSceneProps = {
  characterId?: CharacterId;
  toDirection: Direction;
  fromPosition?: Position;
  unskippableWalkOut?: boolean; // needed to block main-character clicking when out of scene
  unskippableWalkOutInitiatedRef?: any;
  blockGui?: boolean;
  unskippable?: boolean;
  walkOutOfSceneWithBlockedGui?: boolean;
};

type WalkOutOfSceneReturn = () => Action_CharacterWalk;

export const walkOutOfScene: (
  props: WalkOutOfSceneProps
) => WalkOutOfSceneReturn = (props) => () => {
  return {
    ...props,
    _name: createActionName({
      name: `walk out of scene in direction`,
      value: `${props.toDirection}`,
      prefix: props.characterId || "<main-character>",
    }),
    id: ActionId.characterWalk,
    walkFromPosition: props.fromPosition,
    walkOutOfSceneToDirection: props.toDirection,
    walkOutOfSceneWithBlockedGui: props.unskippableWalkOut,
    walkOutOfSceneWithBlockedGuiInitiatedRef:
      props.unskippableWalkOutInitiatedRef,
    walkAnimationFrame: 0,

    ifMainCharacter: {
      blockGui: props.blockGui,
      unskippable: props.unskippable,
    },

    ifNonMainCharacter: {
      blockGui: true,
      unskippable: true, // if any other than main character walks, mouse click would stop it!
    },
  };
};
