/* eslint-disable react-hooks/exhaustive-deps */

import {
  capitalizeFirstLetter,
  downloadImage,
  getCode_SpriteConfig,
} from "game-engine/utils";
import { useEffect, useState } from "react";

import Box from "game-engine/_dev/basic-components/Box";
import Button from "game-engine/_dev/basic-components/Button";
import Checkbox from "game-engine/_dev/basic-components/inputs/Checkbox";
import Code from "game-engine/_dev/basic-components/Code";
import Divider from "game-engine/_dev/basic-components/Divider";
import FrameSequenceEditor from "game-engine/_dev/dev-components/FrameSequenceEditor";
import GAME_CONFIG from "game-files/gameConfig";
import NumberInput from "game-engine/_dev/basic-components/inputs/NumberInput";
import Sidebar from "game-engine/_dev/basic-components/Sidebar";
import SpriteAnimationPreview from "../../../basic-components/SpriteAnimationPreview";
import { SpriteDirection } from "game-engine/types";
import { SpriteEditorType } from "../..";
import styled from "styled-components";

const PlayButtons = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 5px;

  & > * {
    flex: 1;
  }
`;

const SidebarRight = (props: {
  spriteEditor: SpriteEditorType;
  setSpriteEditor: (spriteEditor: SpriteEditorType) => void;
}) => {
  const { spriteEditor, setSpriteEditor } = props;

  const [code, setCode] = useState<string>("");

  const [playPreviewAnimation, setPlayPreviewAnimation] = useState<
    "once" | "loop"
  >();

  useEffect(() => {
    setPlayPreviewAnimation(undefined);
    if (spriteEditor?.calculated?.frameWidth) {
      const code = `spriteConfig: ${getCode_SpriteConfig(
        {
          frameWidth: spriteEditor.calculated.frameWidth,
          frameHeight: spriteEditor.calculated.frameHeight,
          frameCount: spriteEditor.calculated.frameCount,
          frameSequence: spriteEditor.frameSequence,
          direction: spriteEditor.calculated.spriteDirection,
          frameDurationMilliseconds: spriteEditor.frameDurationMilliseconds,
          frameSequenceRandom: spriteEditor.frameSequenceRandom,
          originOffset: spriteEditor.calculated.originOffset,
        },
        { onlyValidValues: true }
      )}`;
      setCode(code);
    }
  }, [spriteEditor]);

  return (
    <Sidebar name="Sprite Editor" position="right" width="500px" isOpen>
      <Box renderContentOnly childMinWidth="90%">
        <Button onClick={() => setSpriteEditor(undefined)}>Clear</Button>

        <Divider name="Settings:" />

        {Object.keys(SpriteDirection).map((direction) => {
          const isActive =
            SpriteDirection[direction] ===
            spriteEditor.calculated.spriteDirection;
          return (
            <Checkbox
              key={direction}
              label={capitalizeFirstLetter(direction)}
              value={isActive}
              onChange={(v) =>
                !v
                  ? undefined
                  : setSpriteEditor({
                      ...spriteEditor,
                      calculated: {
                        ...spriteEditor.calculated,
                        spriteDirection: SpriteDirection[direction],
                      },
                    })
              }
            />
          );
        })}

        <NumberInput
          prefix="Frame Padding X (px)"
          value={spriteEditor.paddingX}
          onChange={(v) => {
            setSpriteEditor({
              ...spriteEditor,
              paddingX: v,
            });
          }}
          rangeController={{ min: 0, max: 10, step: 1 }}
          noBorder
        />

        <NumberInput
          prefix="Frame Padding Y (px)"
          value={spriteEditor.paddingY}
          onChange={(v) => {
            setSpriteEditor({
              ...spriteEditor,
              paddingY: v,
            });
          }}
          rangeController={{ min: 0, max: 10, step: 1 }}
          noBorder
        />

        <NumberInput
          prefix="Frame Duration (milliseconds)"
          value={
            spriteEditor.frameDurationMilliseconds ||
            GAME_CONFIG.sprites.defaultSpriteConfig.frameDurationMilliseconds
          }
          onChange={(v) => {
            if (
              v ===
              GAME_CONFIG.sprites.defaultSpriteConfig.frameDurationMilliseconds
            ) {
              // if value is equal to default value, set to undefined to fallback on default
              setSpriteEditor({
                ...spriteEditor,
                frameDurationMilliseconds: undefined,
              });
            } else {
              setSpriteEditor({
                ...spriteEditor,
                frameDurationMilliseconds: v,
              });
            }
          }}
          rangeController={{ min: 10, max: 1000, step: 10 }}
          noBorder
        />

        {spriteEditor?.uploadedSpriteImage ? (
          <NumberInput
            prefix="Frame Count"
            value={spriteEditor.calculated?.frameCount}
            onChange={(v) => {
              setSpriteEditor({
                ...spriteEditor,
                calculated: {
                  ...spriteEditor?.calculated,
                  frameCount: v,
                  frameWidth:
                    spriteEditor.calculated.spriteDirection ===
                    SpriteDirection.Horizontal
                      ? spriteEditor?.uploadedSpriteImage.width / v
                      : spriteEditor?.uploadedSpriteImage.width,
                  frameHeight:
                    spriteEditor.calculated.spriteDirection ===
                    SpriteDirection.Vertical
                      ? spriteEditor?.uploadedSpriteImage.height / v
                      : spriteEditor?.uploadedSpriteImage.height,
                },
              });
            }}
            rangeController={{ min: 1, max: 100, step: 1 }}
            noBorder
          />
        ) : null}
      </Box>

      <Box renderContentOnly childMinWidth="90%">
        <Divider name="Frame Sequence:" />

        <Checkbox
          label="Random Frames"
          value={spriteEditor.frameSequenceRandom}
          onChange={(v) =>
            setSpriteEditor({ ...spriteEditor, frameSequenceRandom: v })
          }
        />

        <Box renderContentOnly childMinWidth="90%" contentPaddingLeft="10px">
          <FrameSequenceEditor
            frameCount={spriteEditor.calculated?.frameCount}
            frameSequence={spriteEditor.frameSequence}
            setFrameSequence={(frameSequence) =>
              setSpriteEditor({ ...spriteEditor, frameSequence })
            }
          />
        </Box>
      </Box>

      <Box renderContentOnly childMinWidth="90%">
        <Divider name="Preview:" />
        <SpriteAnimationPreview
          src={spriteEditor?.calculated?.spriteUrl}
          spriteConfig={{
            frameCount: spriteEditor?.calculated?.frameCount,
            frameWidth: spriteEditor?.calculated?.frameWidth,
            frameHeight: spriteEditor?.calculated?.frameHeight,
            direction: spriteEditor?.calculated?.spriteDirection,
            frameDurationMilliseconds: spriteEditor?.frameDurationMilliseconds,
            frameSequenceRandom: spriteEditor.frameSequenceRandom,
            frameSequence: spriteEditor?.frameSequence,
            playCount: playPreviewAnimation === "once" ? 1 : undefined,
          }}
          playSpritePreview={playPreviewAnimation !== undefined}
          onAnimationEnd={() => setPlayPreviewAnimation(undefined)}
        />
        {playPreviewAnimation ? (
          <Button onClick={() => setPlayPreviewAnimation(undefined)}>
            Stop Animation
          </Button>
        ) : (
          <PlayButtons>
            <Button onClick={() => setPlayPreviewAnimation("loop")}>
              Play In Loop
            </Button>

            <Button onClick={() => setPlayPreviewAnimation("once")}>
              Play Once
            </Button>
          </PlayButtons>
        )}
      </Box>

      <Box renderContentOnly childMinWidth="90%">
        <Divider name="Output:" marginTop="30px" />

        <Code code={code} background="#0001" />

        <Button
          onClick={() => {
            navigator.clipboard.writeText(code);
          }}
        >
          Copy Sprite Settings
        </Button>

        <Button
          onClick={() => {
            downloadImage(
              spriteEditor.calculated.spriteUrl,
              spriteEditor.calculated.filename
            );
          }}
        >
          Download Image
        </Button>
      </Box>
    </Sidebar>
  );
};

export default SidebarRight;
