/* eslint-disable react-hooks/exhaustive-deps */

import {
  BlendingMode,
  ImageFilters,
  ImageTransitionType,
  Position,
} from "game-engine/types";
import { Group, Image as KonvaImage, Rect } from "react-konva";
import React, { useEffect, useState } from "react";

import Konva from "konva";
import useLocalImage from "game-engine/hooks/useLocalImage";

const Image = (props: {
  src: string;
  srcTransitionOnChange?: ImageTransitionType;
  onImageLoaded?: () => void;
  position?: Position;
  onClick?: (e?: any) => void;
  onMouseEnter?: (e?: any) => void;
  onMouseLeave?: (e?: any) => void;
  width?: number;
  height?: number;
  scale?: { x: number; y: number };
  scaleX?: number;
  scaleY?: number;
  opacity?: number;
  isHidden?: boolean;
  blendingMode?: BlendingMode;
  imageRef?: { current: Konva.Image };
  listening?: boolean;
  noClickThroughAlpha?: boolean;
  renderOutline?: boolean;
  renderFill?: boolean;
  outlineColor?: string;
  fillColor?: string;
  filters?: ImageFilters;
  preventSmartImageSwap?: boolean; // this turns off the prev vs new image swap for sprites that control it from the outside
}) => {
  const {
    src,
    srcTransitionOnChange,
    onImageLoaded,
    onClick,
    onMouseEnter,
    onMouseLeave,
    position = { x: 0, y: 0 },
    scale,
    scaleX,
    scaleY,
    blendingMode,
    listening,
    noClickThroughAlpha = false,
    renderOutline = false,
    renderFill = false,
    outlineColor = "#00f",
    fillColor = "#00f5",
    preventSmartImageSwap = false,
    //isHidden = false,
  } = props;

  // State to store the previous image for transition effects
  const [previousImage, setPreviousImage] = useState<HTMLImageElement | null>(
    null
  );
  // State to handle the opacity transition of the new image
  const [opacity, setOpacity] = useState(1);
  const duration = srcTransitionOnChange?.durationSec ?? 0;

  // Hook to load the image and handle the local image state
  const { image, imageLoaded, imageRef, imageData } = useLocalImage({
    src: src,
    isDevMode: renderOutline || renderFill,
    clickThroughAlpha: !noClickThroughAlpha,
    imageRef: props.imageRef,
    filters: props.filters,
  });

  // Effect to handle animated transition when the image source changes
  useEffect(() => {
    if (imageLoaded && onImageLoaded) {
      if (duration > 0) {
        setOpacity(0); // Start fade out
        const frames = duration * 60;
        let currentFrame = 0;

        const animateOpacity = () => {
          if (currentFrame <= frames) {
            const nextOpacity = currentFrame / frames;
            setOpacity(nextOpacity);
            currentFrame++;
            requestAnimationFrame(animateOpacity);
          } else {
            setOpacity(1);
          }
        };

        animateOpacity();
      } else {
        setOpacity(1); // Instantly show the new image
      }

      onImageLoaded();
    }
  }, [imageLoaded, duration]);

  // Effect to store the previous image when the src changes
  useEffect(() => {
    if (!preventSmartImageSwap && imageLoaded && imageRef?.current) {
      // Convert current Konva.Image to an HTML5 Image element
      if (imageRef.current.toImage) {
        imageRef.current.toImage({
          callback: (img: HTMLImageElement) => {
            setPreviousImage(img); // Store the image as the previous image
          },
        });
      }
    }
  }, [src]);

  const width = props.width || imageData?.width || 0;
  const height = props.height || imageData?.height || 0;
  const dimensionsAvailable = !!(width && height);

  // Utility to check if the image is a valid HTMLImageElement
  const isValidImage = (img: any): img is HTMLImageElement => {
    return img instanceof HTMLImageElement;
  };

  //
  // RENDER
  //
  return (
    <Group
      position={position}
      opacity={props.opacity}
      scale={scale}
      scaleX={scaleX}
      scaleY={scaleY}
      onClick={onClick}
      onTap={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      listening={listening}
    >
      {dimensionsAvailable && renderFill ? (
        <Rect
          listening={false}
          width={width}
          height={height}
          fill={fillColor}
        />
      ) : null}

      {preventSmartImageSwap ? (
        <KonvaImage
          ref={imageRef}
          image={image}
          globalCompositeOperation={blendingMode}
        />
      ) : (
        <>
          {/* Render previous image (if available and valid) */}
          {previousImage && isValidImage(previousImage) && (
            <KonvaImage
              image={previousImage}
              opacity={opacity === 1 ? 0 : 1} // Fade out previous image
              listening={false} // Ensure previous image is not clickable
              globalCompositeOperation={blendingMode}
            />
          )}

          {/* Render new image with opacity animation if valid */}
          {isValidImage(image) && (
            <KonvaImage
              ref={imageRef}
              image={image}
              opacity={opacity} // Transition from 0 to 1 when loaded
              globalCompositeOperation={blendingMode}
            />
          )}
        </>
      )}

      {dimensionsAvailable && renderOutline ? (
        <Rect
          listening={false}
          width={width + 1}
          height={height + 1}
          stroke={outlineColor}
          strokeWidth={1}
          position={{ x: -0.5, y: -0.5 }} // fixes stroke blur
        />
      ) : null}
    </Group>
  );
};

export default React.memo(Image);
