import { ActionId, Action_Event } from "game-engine/types";
import { GameEvent, GameEventsType } from "game-files/gameEvents";

import { createActionName } from ".";

//
// SET EVENTS WITH PREV-STATE
//
type SetEventsProps = (events: GameEventsType) => GameEventsType;
type SetEventsReturn = () => Action_Event;

export const setEvents: (props: SetEventsProps) => SetEventsReturn =
  (getUpdatedEvents) => () => {
    return {
      _name: createActionName({
        name: "update game events",
      }),
      id: ActionId.event,
      getUpdatedEvents,
      blockGui: true,
      unskippable: true,
    };
  };

//
// SET EVENT BY KEY-VALUE
//
type SetEventProps = { key: GameEvent; value: any };

export const setEvent: (props: SetEventProps) => () => Action_Event =
  (props) => () => {
    return {
      _name: createActionName({
        name: `set game event ${props.key} = ${props.value}`,
      }),
      id: ActionId.event,
      setEvent: props,
      blockGui: true,
      unskippable: true,
    };
  };
