import { CharacterConfigType, CursorRenderType } from "game-engine/types";
import render, { devTools } from "./render";

import { CharacterId } from "game-files/common/ids";
import { GameObjective } from "game-files/gameObjectives";
import onClick from "./on-click";
import onSceneIdle from "./on-scene-idle";

const THIS_ID = CharacterId.Tester;

//
// CONFIG
//
const CharacterConfig: CharacterConfigType = {
  id: THIS_ID,
  name: { en: "Tester", cz: "Tester" },

  cursorOnHover: CursorRenderType.arrowUp,

  dialogConfig: {
    [GameObjective.GAME_START]: {
      aboveOffsetMax: 54,
      aboveOffsetMin: 40,
      offsetBelowMax: -5,
      color: `#FF5648`,
    },
  },

  idleConfig: {
    idleAfterMinSec: 6,
    idleAfterMaxSec: 12,
  },

  render: render,
  onSceneIdle: onSceneIdle,
  onClick: onClick,
  devTools: devTools,
};

export default CharacterConfig;
