import { Position, SpriteDirection } from "game-engine/types";
import { SpriteCalculatedDataType, SpriteEditorType } from "..";

/* const loadImages = () => async (spriteEditor: SpriteEditorType) => {
  const loadedImages = await Promise.all(
    spriteEditor?.frames.map((frame) => {
      return new Promise<HTMLImageElement>((resolve) => {
        const img = new Image();
        img.src = frame.src;
        img.onload = () => resolve(img);
      });
    })
  );

  return loadedImages;
}; */

/**
 * Generates a sprite image and returns its Blob URL
 * @param spriteEditor SpriteEditorType - contains frames and sprite settings
 * @returns {Promise<SpriteCalculatedDataType>} - The generated sprite image data and dimensions
 */
export const generateSpriteSheet = async (
  spriteEditor: SpriteEditorType
): Promise<SpriteCalculatedDataType> => {
  const loadedImages = await Promise.all(
    spriteEditor?.frames.map((frame) => {
      return new Promise<HTMLImageElement>((resolve) => {
        const img = new Image();
        img.src = frame.src;
        img.onload = () => resolve(img);
      });
    })
  );

  const paddingX = spriteEditor.paddingX ?? 0;
  const paddingY = spriteEditor.paddingY ?? 0;

  // Step 1: Calculate the global bounding box for all frames
  let globalMinX = Infinity,
    globalMinY = Infinity,
    globalMaxX = -Infinity,
    globalMaxY = -Infinity;

  loadedImages.forEach((img) => {
    const boundingBox = getBoundingBox(img);
    if (boundingBox.left < globalMinX) globalMinX = boundingBox.left;
    if (boundingBox.right > globalMaxX) globalMaxX = boundingBox.right;
    if (boundingBox.top < globalMinY) globalMinY = boundingBox.top;
    if (boundingBox.bottom > globalMaxY) globalMaxY = boundingBox.bottom;
  });

  const globalFrameWidth = globalMaxX - globalMinX;
  const globalFrameHeight = globalMaxY - globalMinY;

  const finalFrameWidth = globalFrameWidth + 2 * paddingX;
  const finalFrameHeight = globalFrameHeight + 2 * paddingY;

  const direction =
    spriteEditor?.calculated?.spriteDirection ||
    (globalFrameWidth > globalFrameHeight
      ? SpriteDirection.Vertical
      : SpriteDirection.Horizontal);

  // Step 2: Create a canvas and draw the sprite sheet
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d", { willReadFrequently: true });

  if (ctx) {
    canvas.width =
      direction === SpriteDirection.Horizontal
        ? finalFrameWidth * spriteEditor?.frames.length
        : finalFrameWidth;
    canvas.height =
      direction === SpriteDirection.Vertical
        ? finalFrameHeight * spriteEditor?.frames.length
        : finalFrameHeight;

    loadedImages.forEach((img, index) => {
      const boundingBox = getBoundingBox(img);
      const offsetX = boundingBox.left - globalMinX; // Align to global bounding box
      const offsetY = boundingBox.top - globalMinY; // Align to global bounding box

      const clippedWidth = boundingBox.right - boundingBox.left;
      const clippedHeight = boundingBox.bottom - boundingBox.top;

      let x = paddingX + index * (globalFrameWidth + 2 * paddingX) + offsetX;
      let y = offsetY + paddingY;

      if (direction === SpriteDirection.Vertical) {
        x = offsetX + paddingX;
        y = paddingY + index * (globalFrameHeight + 2 * paddingX) + offsetY;
      }

      ctx.drawImage(
        img,
        boundingBox.left, // Source X
        boundingBox.top, // Source Y
        clippedWidth, // Source width
        clippedHeight, // Source height
        x, // Destination X
        y, // Destination Y
        clippedWidth, // Destination width
        clippedHeight // Destination height
      );
    });

    // Step 3: Convert the canvas to a Blob and generate a Blob URL
    const blob = await new Promise<Blob | null>((resolve) =>
      canvas.toBlob((blob) => resolve(blob), "image/png")
    );

    const blobUrl = blob ? URL.createObjectURL(blob) : "";

    // Create origin offset (= how many pixels were trimmed on the left and top)
    let originOffset: Position;
    if (globalMinX > 0 || globalMinY > 0) {
      originOffset = { x: globalMinX, y: globalMinY };
    }

    // Return sprite config
    return {
      ...spriteEditor.calculated,
      frameWidth: finalFrameWidth,
      frameHeight: finalFrameHeight,
      frameCount: spriteEditor?.frames.length || 0,
      spriteUrl: blobUrl, // Use Blob URL instead of data URL
      spriteDirection: direction,
      originOffset,
    };
  }

  return spriteEditor.calculated;
};

/**
 * Get the bounding box of non-transparent pixels in an image
 * @param image HTMLImageElement
 * @returns {top: number, left: number, right: number, bottom: number}
 */
export const getBoundingBox = (
  image: HTMLImageElement
): { top: number; left: number; right: number; bottom: number } => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d", { willReadFrequently: true });
  canvas.width = image.width;
  canvas.height = image.height;

  ctx?.drawImage(image, 0, 0);
  const imageData = ctx?.getImageData(0, 0, image.width, image.height);
  const pixels = imageData?.data;

  let minX = image.width,
    minY = image.height,
    maxX = 0,
    maxY = 0;

  for (let y = 0; y < image.height; y++) {
    for (let x = 0; x < image.width; x++) {
      const index = (y * image.width + x) * 4;
      const alpha = pixels ? pixels[index + 3] : 0; // check alpha channel
      if (alpha > 0) {
        if (x < minX) minX = x;
        if (x >= maxX) maxX = x + 1; // Include the pixel itself
        if (y < minY) minY = y;
        if (y >= maxY) maxY = y + 1; // Include the pixel itself
      }
    }
  }

  return { top: minY, left: minX, right: maxX, bottom: maxY };
};
