import { ACTION } from "game-engine/utils";
import { GameObjective } from "game-files/gameObjectives";
import IMAGE_LayerClickArea_Glowcaps from "./assets/layer-click-area - glowcaps.png";
import IMAGE_Layer_00_DEPTH_MAP from "./assets/layer - 00 - DEPTH MAP.png";
import IMAGE_Layer_00_DROP_MAP from "./assets/layer - 00 - DROP MAP.png";
import IMAGE_Layer_00_WALK_MAP from "./assets/layer - 00 - WALK MAP.png";
import IMAGE_Layer_01_Background from "./assets/layer - 01 - background.png";
import IMAGE_Layer_02_Trees_And_Cave from "./assets/layer - 02 - trees and cave.png";
import IMAGE_Layer_03_Tree_Left from "./assets/layer - 03 - tree left.png";
import IMAGE_Layer_04_Glowcap_1 from "./assets/layer - 04 - glowcap 1.png";
import IMAGE_Layer_04_Glowcap_2 from "./assets/layer - 04 - glowcap 2.png";
import IMAGE_Layer_04_Glowcap_3 from "./assets/layer - 04 - glowcap 3.png";
import IMAGE_Layer_05_Tree_Left from "./assets/layer - 05 - tree left.png";
import IMAGE_Layer_06_Cave from "./assets/layer - 06 - cave.png";
import IMAGE_Layer_07_Cave_Front from "./assets/layer - 07 - cave front.png";
import IMAGE_Layer_08_Foreground_Trees from "./assets/layer - 08 - foreground trees.png";
import IMAGE_Preview from "./assets/preview.png";
import IMAGE_WalkOutArea_Cave from "./assets/walk-out-area - cave.png";
import MUSIC_CONFIGS from "game-files/audio/MUSIC_CONFIGS";
import SHARED_DATA from "game-files/scenes/SHARED_DATA";
import { SceneTag } from "game-files/common/ids";
import { SceneType } from "game-engine/types";

const Test_Forest_Cave: SceneType = {
  configId: "Test_Forest_Cave", // --------- KEEP SAME AS COMPONENT NAME
  isDev: false, // ----------------- DEV SCENES ARE HIDDEN IN SCENES LAYOUT EDITOR
  isUsingTestObjectives: false, // - OBJECTIVES WILL BE DISPLAYED UNDER TEST NAMES IN DEV TOOLS
  sceneNeighbors: undefined, // ---- GENERATED FROM SCENES LAYOUT
  uniqueId: undefined, // ---------- GENERATED FROM SCENES LAYOUT (put manual id here for scenes out of scenes layout)
  preview: { src: IMAGE_Preview },

  tags: [SceneTag.Test],

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Scene Name
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  name: {
    [GameObjective.GAME_START]: {
      en: "Cave Entrance",
      cz: "Vstup do jeskyně",
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Scene Audio
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  audio: {
    [GameObjective.GAME_START]: {
      music: {
        musicConfigId: MUSIC_CONFIGS.Test_01.id,
        volume: undefined,
      },
      sound: {
        soundConfigId: undefined,
        volume: undefined,
        loop: undefined,
      },
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Scene Walk Paths
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  sceneWalkPaths: {
    [GameObjective.GAME_START]: {
      left: {
        edgeWalkY: 146,
        walkTo: { x: 72, y: 137 },
      },

      right: {
        walkTo: { x: 235, y: 124 },
        walkToEnd: { x: 299, y: 122 },
        clickArea: {
          image: { src: IMAGE_WalkOutArea_Cave },
        },
      },

      up: {
        edgeWalkX: 39,
        walkTo: { x: 77, y: 58 },
      },

      down: {
        edgeWalkX: 130,
        walkTo: { x: 130, y: 128 },
      },
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Dynamic Lighting
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  dynamicLighting: undefined,

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE IMAGES
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  images: {
    background: {
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Background
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      dataByCurrentObjective: {
        [GameObjective.GAME_START]: {
          fillColor: undefined,
          image: {
            src: IMAGE_Layer_01_Background,
            transition: undefined,
          },
          dropMap: { src: IMAGE_Layer_00_DROP_MAP },
          walkMap: { src: IMAGE_Layer_00_WALK_MAP },
          depthMap: { src: IMAGE_Layer_00_DEPTH_MAP },
          depthSettings: {
            scaleMin: undefined,
            scaleMax: undefined,
          },
        },
      },

      actionsByCurrentObjective: {
        [GameObjective.GAME_START]: {
          actionsWithItem: undefined,
          actions: [
            ACTION.talkRandom({
              dialogOptions: [...SHARED_DATA.dialogOptions.forestBackground],
            }),
          ],
        },
      },
    },

    layers: [
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_02_Trees_And_Cave",
        depthY: 29,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_02_Trees_And_Cave },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: [
              ACTION.talkRandom({
                dialogOptions: [...SHARED_DATA.dialogOptions.rocksAndBushes],
              }),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_03_Tree_Left",
        depthY: 51,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_03_Tree_Left },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: [
              ACTION.talkRandom({
                dialogOptions: [...SHARED_DATA.dialogOptions.tree],
              }),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_04_Glowcaps_Empty",
        depthY: 60,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_LayerClickArea_Glowcaps },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: true, // handles clicking on glowcaps when none are available
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  {
                    en: "I have to wait for more Glowcaps to grow.",
                    cz: "Musím počkat než vyrostou další Světlohlávky.",
                  },
                  {
                    en: "I took all of them, I need to wait for a bit.",
                    cz: "Už jsem všechny posbíral, musím počkat.",
                  },
                ],
              }),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_04_Glowcap_1",
        depthY: 68,
        generateItem: SHARED_DATA.items.Glowcap.generateItem,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_04_Glowcap_1 },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,
            clickArea: {
              image: { src: IMAGE_LayerClickArea_Glowcaps },
              offset: undefined,
            },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: undefined,
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_04_Glowcap_2",
        depthY: 78,
        generateItem: SHARED_DATA.items.Glowcap.generateItem,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_04_Glowcap_2 },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,
            clickArea: {
              image: { src: IMAGE_LayerClickArea_Glowcaps },
              offset: undefined,
            },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: undefined,
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_04_Glowcap_3",
        depthY: 80,
        generateItem: SHARED_DATA.items.Glowcap.generateItem,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_04_Glowcap_3 },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,
            clickArea: {
              image: { src: IMAGE_LayerClickArea_Glowcaps },
              offset: undefined,
            },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: undefined,
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_05_Tree_Left",
        depthY: 122,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_05_Tree_Left },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: [
              ACTION.talkRandom({
                dialogOptions: [...SHARED_DATA.dialogOptions.tree],
              }),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_06_Cave",
        depthY: 85,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_06_Cave },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  {
                    en: `I wonder what's inside of that cave.`,
                    cz: `Co v té jeskyni asi je?`,
                  },
                ],
              }),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_07_Cave_Front",
        depthY: 1000,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_07_Cave_Front },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  {
                    en: `Why do I have a feeling that I'm gonna have to go inside?`,
                    cz: `Proč mám pocit, že budu muset jít dovnitř?`,
                  },
                ],
              }),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_08_Foreground_Trees",
        depthY: 1000,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_08_Foreground_Trees },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.treeYoung,
                  ...SHARED_DATA.dialogOptions.forest,
                ],
              }),
            ],
          },
        },
      },
    ],
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE ACTIONS
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  onSceneIdle: {
    // idleAfterMinSec: 1,
    // idleAfterMaxSec: 2,
    actionsByCurrentObjective: {
      [GameObjective.GAME_START]: {
        // IDLE ACTIONS ARE NOT EXECUTED AS A SEQUENCE, BUT PLACED INTO A POOL OF
        // ACTIONS TO BE RANDOMLY PICKED FROM AMONG OTHER IDLES (CHARACTER IDLE ETC.)
        actions: [],
      },
    },
  },

  onSceneInit: {
    actionsByCurrentObjective: {
      [GameObjective.GAME_START]: {
        // ACTIONS TO BE RUN IN SEQUENCE AFTER SCENE IS INITIALIZED
        actions: [],
      },
    },
  },

  onSkillStart: {},

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE CHARACTERS
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  characters: [],
};

export default Test_Forest_Cave;
