import { GameDisplayModeEnum, GamePlayType, Language } from "game-engine/types";

import { CharacterId } from "./common/ids";
import GAME_CONFIG from "./gameConfig";
import { GAME_EVENTS } from "./gameEvents";
import { GameObjective } from "./gameObjectives";

//
// INIT FOR NEW GAME GAMEPLAY
//
const GAMEPLAY: GamePlayType = {
  isLoadGame: false,
  isAutoSaving: true,
  hideGui: undefined,
  labelOverride: undefined,
  devPreview: undefined,

  mainCharacterId: CharacterId.Felix,
  mainCharacterIsDead: undefined,
  mainCharacterPosition: undefined,
  mainCharacterFacing: undefined,
  mainCharacterScale: undefined,

  settings: {
    language: Language.en,
    walkSpeed: GAME_CONFIG.actions.walkSpeed.slow,
    talkSpeed: GAME_CONFIG.actions.talkSpeed.medium,
    displayMode: GameDisplayModeEnum.default,
    playMusic: true,
    playSounds: true,
  },

  audio: {
    overrideMusic: undefined,
  },

  ignoreIdle: {
    characterIds: [],
  },

  currentScene: {
    uniqueSceneId: undefined, // will be set from newGame func
    walkIn: undefined, //        will be set from newGame func
    fadeIn: undefined,
    isSetByDeveloper: false,
    cinematic: undefined, //     will be set from newGame func
  },
  currentObjective: GameObjective.GAME_START,
  currentSkills: undefined,
  triggerSkill: undefined,

  events: { ...GAME_EVENTS },

  effects: {
    viewportShake: undefined,
    fadeViewport: undefined,
    fadeScene: undefined,
  },

  sceneOverlayAnimation: undefined,
  customPopUp: undefined,
  hiddenLayers: undefined,
};

export default GAMEPLAY;
