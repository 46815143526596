/* eslint-disable react-hooks/exhaustive-deps */

import {
  ActionQueueType,
  CharacterConfigType,
  SceneType,
} from "game-engine/types";
import {
  getActionsByCurrentObjective,
  getRandomIndex,
} from "game-engine/utils";
import { useEffect, useMemo, useRef } from "react";

import GAME_CONFIG from "game-files/gameConfig";
import useGame from "game-engine/hooks/useGame";
import useIdle from "game-engine/hooks/useIdle";

const useSceneIdle = (props: {
  scene: SceneType;
  runActions;
  actionQueue;
  mainCharacter: CharacterConfigType;
  ignoreIdle;
}) => {
  const { scene, mainCharacter, runActions, actionQueue, ignoreIdle } = props;

  const { gamePlay, gameItems, logger, gameFns } = useGame();

  const idleIndexRef = useRef<number>(0);

  const idleActions: ActionQueueType = useMemo(() => {
    if (gamePlay.state.mainCharacterIsDead) {
      return [];
    }

    //
    // Idle Actions - Main Character     // TODO - IF MAIN CHARACTER IS HIDDEN FROM SCENE, THIS SHOULD BE IGNORED (also it is dead!)
    const mainCharacterIdleActions: ActionQueueType =
      getActionsByCurrentObjective({
        actionsByCurrentObjective:
          mainCharacter?.onSceneIdle?.actionsByCurrentObjective,
        currentObjective: gameFns.getCurrentObjective(),
      });

    // Idle Actions - Scene Characters
    const sceneCharactersActions: ActionQueueType = [];

    scene?.characters?.forEach((c) => {
      const cActions = getActionsByCurrentObjective({
        actionsByCurrentObjective:
          c?.config?.onSceneIdle?.actionsByCurrentObjective,
        currentObjective: gameFns.getCurrentObjective(),
      });
      if (cActions?.length) {
        sceneCharactersActions.push(...cActions);
      }
    });

    // Idle Actions - Scene
    const sceneIdleActions: ActionQueueType = getActionsByCurrentObjective({
      actionsByCurrentObjective: scene?.onSceneIdle?.actionsByCurrentObjective,
      currentObjective: gameFns.getCurrentObjective(),
    });

    // Return all actions
    const actions: ActionQueueType = [
      ...sceneIdleActions,
      ...sceneCharactersActions,
      ...mainCharacterIdleActions,
    ];

    return actions;
  }, [gamePlay.state.currentObjective, gamePlay.state.mainCharacterIsDead]);

  //
  // IDLE TIMING
  //
  const { idleAfterMinSec, idleAfterMaxSec } = scene?.onSceneIdle || {};
  const {
    idleAfterMinSec: idleAfterMinSecDefault,
    idleAfterMaxSec: idleAfterMaxSecDefault,
  } = GAME_CONFIG.character.animations.idle;

  const { isIdle, startIdleTimer } = useIdle({
    timeoutMinSec: idleAfterMinSec || idleAfterMinSecDefault,
    timeoutMaxSec: idleAfterMaxSec || idleAfterMaxSecDefault,
  });

  //
  // RESTART IDLE TIMEOUT
  //
  useEffect(() => {
    if (!ignoreIdle && idleActions?.length && !actionQueue?.length) {
      // start on scene idle time only if no actions are running
      startIdleTimer();
    }
  }, [ignoreIdle, actionQueue, gameItems.state]);

  //
  // ON IDLE
  //
  useEffect(() => {
    if (!ignoreIdle && isIdle && !actionQueue?.length) {
      // Run randomly picked idle - exclude last random action
      const excludeIndex = idleIndexRef.current;
      const idleIndex = getRandomIndex(idleActions.length, {
        excludeIndex,
      });
      idleIndexRef.current = idleIndex;

      runActions([idleActions[idleIndex]], { isIdle: true });

      logger.action(`scene idle started`, scene);
    }
  }, [ignoreIdle, isIdle]);
};

export default useSceneIdle;
