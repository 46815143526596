import { ACTION } from "game-engine/utils";
import { CharacterOnSceneIdleType } from "game-engine/types";
import { GameObjective } from "game-files/gameObjectives";

const onSceneIdle: CharacterOnSceneIdleType = {
  actionsByCurrentObjective: {
    [GameObjective.GAME_START]: {
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // ON SCENE IDLE
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      actions: [
        ACTION.talk({
          dialog: [
            {
              en: "What a boring day.",
              cz: "Jaký to nudný den.",
            },
          ],
        }),

        ACTION.talk({
          dialog: [
            {
              en: "Maybe something will happen if I stand here long enough.",
              cz: "Možná když tu budu stát dostatečně dlouho, něco se stane.",
            },
          ],
        }),
      ],
    },
  },
};

export default onSceneIdle;
