import CharacterBox from "game-engine/_dev/basic-components/CharacterList/CharacterBox";
import { CharacterId } from "game-files/common/ids";

const AssetsCharacterPage = (props: { characterConfigId: CharacterId }) => {
  const { characterConfigId } = props;

  const thumbnailSize = 70;

  //
  // RENDER
  //
  return (
    <CharacterBox
      characterId={characterConfigId}
      thumbnailSettings={{ width: thumbnailSize, height: thumbnailSize }}
      previewSettings={{ width: 100, height: 100, scale: 4 }}
      isOpen
      isDetailPage
    />
  );
};

export default AssetsCharacterPage;
