/* eslint-disable react-hooks/exhaustive-deps */

import { RunActionsType, StopActionsType } from "../logic/useSceneActions";
import { SkillWatcherFunc, useSkills } from "game-engine/hooks/useSkills";
import { useEffect, useRef } from "react";

import { ACTION } from "game-engine/utils";
import { ActionQueueType } from "game-engine/types";
import { SkillId } from "game-files/common/ids";
import useGame from "game-engine/hooks/useGame";

const useSceneSkills = (props: {
  runActions: RunActionsType;
  stopActions: StopActionsType;
  sceneInitActionsEndedRef: { current: boolean };
  walkOutInitiatedRef: { current: boolean };
}) => {
  const {
    runActions,
    stopActions,
    sceneInitActionsEndedRef,
    walkOutInitiatedRef,
  } = props;

  const { gameFns, gamePlay } = useGame();

  //
  // TRIGGER SKILL START WITH PROPER ACTION QUEUE
  //
  useEffect(() => {
    if (gamePlay.state.triggerSkill) {
      gameFns.setTriggerSkillStart(undefined);
      runActions(gameFns.getSkillActionsOnStart(gamePlay.state.triggerSkill));
    }
  }, [gamePlay.state.triggerSkill]);

  //
  // RUN ACTIONS ON SKILL STOP
  //
  const onSkillStop: SkillWatcherFunc = (skillId) => {
    // If action is stopped while scene init actions are playing, we cannot use stopActions(),
    // because it would clear remaining init actions -> append stop actions to current queue instead
    if (!sceneInitActionsEndedRef.current) {
      const actionsOnStop = gameFns.getSkillActionsOnStop(skillId);
      if (actionsOnStop?.length) {
        runActions(actionsOnStop, { appendToCurrentActionQueue: true });
      }
    }

    // Scene init actions already ended, stopActions() may be required
    else {
      const currentSkill = gamePlay.state.currentSkills
        ? gamePlay.state.currentSkills[skillId]
        : undefined;

      if (currentSkill) {
        const appendStopActions = currentSkill.appendStopActions;
        const prependStopActions = currentSkill.prependStopActions;

        if (!appendStopActions && !prependStopActions) {
          stopActions();
        }

        const actionsOnStop = gameFns.getSkillActionsOnStop(skillId);
        if (actionsOnStop?.length) {
          setTimeout(
            () =>
              runActions(actionsOnStop, {
                appendToCurrentActionQueue: appendStopActions,
                prependToCurrentActionQueue: prependStopActions,
              }),
            0
          );
        }
      }
    }
  };

  //
  // PERIODICALLY CHECK FOR SKILLS PAST THEIR TIME AND STOP/RESET THEM
  //
  const timerRef = useRef<any>();
  const timeoutIntervalSec = 0.5;

  useEffect(() => {
    if (Object.keys(gamePlay.state.currentSkills || {}).length) {
      const checkSkillsTimestamps = () => {
        const remainingSkillIds = [];
        const skillIdsToStop = [];

        const skipMonitoringStep =
          !sceneInitActionsEndedRef.current || // skip if onInit scene actions are still running
          walkOutInitiatedRef.current; // skip if unskippable scene walk-out began

        if (!skipMonitoringStep) {
          Object.values(SkillId).forEach((skillId) => {
            const currentSkill = gamePlay.state.currentSkills[skillId];

            if (!currentSkill) {
              return;
            }

            const currentTimeMilliseconds = Date.now();

            // handle active skills past their time
            if (
              currentSkill?.isActive &&
              currentSkill.activatedForTime && // this will ignore skills with no duration -> those handle the stop in init actions themselves
              currentSkill.activatedAtTime &&
              currentTimeMilliseconds - currentSkill.activatedAtTime >=
                currentSkill.activatedForTime
            ) {
              skillIdsToStop.push(skillId);
              remainingSkillIds.push(skillId); // stopped skills still need to be monitored
              return;
            }

            // handle paused skills past their time
            if (
              currentSkill?.isPaused &&
              currentSkill.pausedAtTime &&
              currentTimeMilliseconds - currentSkill.pausedAtTime >=
                currentSkill.pausedForTime
            ) {
              gameFns.resetSkill(skillId);
              return;
            }

            // skill need to be monitored
            remainingSkillIds.push(skillId);
          });
        }

        // Stop skills
        if (skillIdsToStop.length) {
          const actions: ActionQueueType = [];

          skillIdsToStop.forEach((skillId, i) => {
            actions.push(
              ACTION.stopSkill(skillId, { appendStopActions: true })
            );
          });

          runActions(actions, {
            appendToCurrentActionQueue: true,
            prependToCurrentActionQueue: false,
          });
        }

        // Monitor next step
        if (skipMonitoringStep || remainingSkillIds.length) {
          clearTimeout(timerRef.current);
          timerRef.current = setTimeout(
            checkSkillsTimestamps,
            timeoutIntervalSec * 1000
          );
        }
      };

      checkSkillsTimestamps();
    }

    return () => clearTimeout(timerRef.current);
  }, [gamePlay.state.currentSkills]);

  //
  // SKILLS LISTENER HOOK
  //
  useSkills({
    onSkillStop,
  });
};

export default useSceneSkills;
