import Box from "game-engine/_dev/basic-components/Box";
import { SavedDataType } from "game-engine/utils";
import SectionCurrentObjective from "./sections/Objective";
import SectionEventsEditor from "./sections/EventsEditor";
import SectionMainCharacter from "./sections/MainCharacter";
import SectionMusic from "./sections/Music";
import SectionSaveNew from "./sections/SaveNew";
import SectionSavedGames from "./sections/SavedGames";
import SectionSettings from "./sections/Settings";
import SectionSkills from "./sections/Skills";
import SectionUserInterface from "./sections/UserInterface";
import useGame from "game-engine/hooks/useGame";
import { useState } from "react";

const GamePlay = (props: { isOpen?: boolean }) => {
  const { gameFns } = useGame();

  const [savedData, setSavedData] = useState<SavedDataType>(
    gameFns.getSavedData()
  );

  //
  // RENDER
  //
  return (
    <Box
      label="Game Play"
      isOpen={props.isOpen}
      level={1}
      useFlex
      childMinWidth="90%"
    >
      <SectionSettings />
      <SectionSaveNew setSavedData={setSavedData} />
      <SectionSavedGames savedData={savedData} setSavedData={setSavedData} />
      <SectionMainCharacter />
      <SectionSkills />
      <SectionMusic />
      <SectionUserInterface />
      <SectionCurrentObjective />
      <SectionEventsEditor />
    </Box>
  );
};

export default GamePlay;
