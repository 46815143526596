import {
  FontType,
  TextAlign,
  TextOutlineStyle,
  TextVerticalAlign,
} from "game-engine/types";

import AssetPreview from "../AssetPreview";
import Text from "game-engine/components/basic-elements/Text";
import styled from "styled-components";

const GlyphPreview = styled.div<{ minWidth?: string }>`
  min-width: ${(props) => props.minWidth};
`;

const AssetPreviewMatrix = (props: {
  font: FontType;
  width: number;
  height: number;
  scale?: number;
  children?: any;
  background?: string;
  text: string;
  textMinWidth?: number;
  textMaxWidth?: number;
  textAlign?: TextAlign;
  textVerticalAlign?: TextVerticalAlign;
  textFillColor?: string;
  outlined?: boolean;
  outlineStyle?: TextOutlineStyle;
  shadow?: boolean;
}) => {
  const {
    width,
    height,
    scale = 1,
    background = "#0000",
    text,
    textAlign = TextAlign.left,
    textVerticalAlign = TextVerticalAlign.center,
    textMinWidth,
    textMaxWidth,
    textFillColor,
    outlined,
    outlineStyle,
    shadow,
    font,
  } = props;

  const textLineWidth = Math.max(textMinWidth, textMaxWidth) || width;
  const textPaddingX = Math.max(0, Math.round((width - textLineWidth) / 2));

  //
  // RENDER
  //
  return (
    <GlyphPreview minWidth={`${width * scale}px`}>
      <AssetPreview
        width={width}
        height={height}
        scale={scale}
        background={background}
      >
        <Text
          position={{
            x:
              textPaddingX +
              (textAlign === TextAlign.right
                ? textLineWidth
                : textAlign === TextAlign.center
                ? Math.round(textLineWidth / 2)
                : 0),
            y: Math.round(height / 2),
          }}
          text={text}
          align={textAlign}
          verticalAlign={textVerticalAlign}
          minWidth={textMinWidth}
          maxWidth={textMaxWidth}
          outlined={outlined}
          outlineStyle={outlineStyle}
          shadow={shadow}
          font={font}
          textFillColor={textFillColor}
        />
      </AssetPreview>
    </GlyphPreview>
  );
};

export default AssetPreviewMatrix;
