import { CharacterRenderType, Direction } from "game-engine/types";

import { CharacterAnimationId } from "game-files/common/ids";
import IMG_healthy_animation_kick from "./animation-kick.png";
import IMG_healthy_animation_shrug from "./animation-shrug.png";
import IMG_healthy_animation_stretch from "./animation-stretch.png";
import IMG_healthy_animation_yawn from "./animation-yawn.png";
import IMG_healthy_default from "./default.png";
import IMG_healthy_talk_body from "./talk-body.png";
import IMG_healthy_talk_head from "./talk-head.png";
import IMG_healthy_walk_down from "./walk-down.png";
import IMG_healthy_walk_left from "./walk-left.png";
import IMG_healthy_walk_up from "./walk-up.png";

const RenderDefault: CharacterRenderType = {
  facing: Direction.left,
  offsetY: 3,

  default: {
    image: { src: IMG_healthy_default },
    offsetY: 0,
    width: 24,
    height: 66,
  },

  defaultNoHead: {
    image: { src: IMG_healthy_talk_body },
    width: 24,
    height: 58,
  },

  talk: {
    offsetY: -45,
    image: { src: IMG_healthy_talk_head },
    spriteConfig: {
      frameWidth: 18,
      frameHeight: 18,
      frameCount: 12,
    },
  },

  walkLeft: {
    offsetY: 0,
    image: { src: IMG_healthy_walk_left },
    spriteConfig: {
      frameWidth: 30,
      frameHeight: 66,
      frameCount: 8,
    },
    pixelsWalked: 32, // how many pixels the character travels in one loop
  },

  walkUp: {
    offsetY: 0,
    image: { src: IMG_healthy_walk_up },
    spriteConfig: {
      frameWidth: 26,
      frameHeight: 66,
      frameCount: 6,
    },
    pixelsWalked: 18, // how many pixels the character travels in one loop
  },

  walkDown: {
    offsetY: 0,
    image: { src: IMG_healthy_walk_down },
    spriteConfig: {
      frameWidth: 26,
      frameHeight: 66,
      frameCount: 6,
    },
    pixelsWalked: 18, // how many pixels the character travels in one loop
  },

  animations: [
    {
      animationId: CharacterAnimationId.kick,
      isIdle: true,
      offsetY: 0,
      image: { src: IMG_healthy_animation_kick },
      spriteConfig: {
        frameWidth: 32,
        frameHeight: 66,
        frameCount: 10,
      },
    },

    {
      animationId: CharacterAnimationId.yawn,
      isIdle: true,
      offsetY: 0,
      image: { src: IMG_healthy_animation_yawn },
      spriteConfig: {
        frameWidth: 32,
        frameHeight: 66,
        frameCount: 20,
      },
    },

    {
      animationId: CharacterAnimationId.stretch,
      isIdle: true,
      offsetY: 0,
      image: { src: IMG_healthy_animation_stretch },
      spriteConfig: {
        frameWidth: 44,
        frameHeight: 66,
        frameCount: 14,
      },
    },

    {
      animationId: CharacterAnimationId.shrug,
      isIdle: false,
      offsetY: 0,
      image: { src: IMG_healthy_animation_shrug },
      spriteConfig: {
        frameWidth: 50,
        frameHeight: 66,
        frameCount: 20,
      },
    },
  ],
};

export default RenderDefault;
