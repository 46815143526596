import { Direction, SceneType, ScenesLayoutsDefType } from "game-engine/types";

import SCENE_CONFIGS from "game-files/scenes/SCENE_CONFIGS";
import { getGameScenesData } from "game-engine/utils";

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// NOTICE: THIS CODE SHOULDN'T BE EDITED MANUALLY, BUT VIA SCENES-LAYOUT-EDITOR AND EXPORTED
//
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const SCENES_LAYOUTS_DEF: ScenesLayoutsDefType = {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE LAYOUT GRIDS
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  scenesLayouts: [
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // Scene Layout
    //
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    {
      id: "ScenesLayout_Test1",
      grid: [
        [
          {
            scene: SCENE_CONFIGS.Test_Forest_R_D,
            overrideSceneId: "test-scene-01",
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },

          {
            scene: SCENE_CONFIGS.Test_Forest_L_R_D,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },

          {
            scene: SCENE_CONFIGS.Test_Forest_L_R_D,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },

          {
            scene: SCENE_CONFIGS.Test_Forest_L_D,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },
        ],
        [
          {
            scene: SCENE_CONFIGS.Test_Forest_R_U_D,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },

          {
            scene: SCENE_CONFIGS.Test_Forest_L_R_U_D,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },

          {
            scene: SCENE_CONFIGS.Test_Forest_L_R_U_D_v2,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },

          {
            scene: SCENE_CONFIGS.Test_Forest_L_U_D,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },
        ],
        [
          {
            scene: SCENE_CONFIGS.Test_Forest_R_U_D,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },

          {
            scene: SCENE_CONFIGS.Test_Forest_L_R_U_D_v2,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },

          null,

          {
            scene: SCENE_CONFIGS.Test_Forest_L_U_D,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },
        ],
        [
          {
            scene: SCENE_CONFIGS.Test_Forest_L_R_U_D,
            overrideSceneId: "test-scene-02",
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },

          {
            scene: SCENE_CONFIGS.Test_Forest_L_R_U_D_v2,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },

          {
            scene: SCENE_CONFIGS.Test_Forest_L_R_U_D_v2,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },

          {
            scene: SCENE_CONFIGS.Test_Forest_L_R_U_D,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },
        ],
      ],
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // Scene Layout
    //
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    {
      id: "ScenesLayout_Test2",
      grid: [
        [
          {
            scene: SCENE_CONFIGS.Test_Forest_L_R_D,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },

          {
            scene: SCENE_CONFIGS.Test_Forest_L_D,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },

          null,

          {
            scene: SCENE_CONFIGS.Test_Forest_L_D,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },
        ],
        [
          {
            scene: SCENE_CONFIGS.Test_Forest_L_R_U_D_v2,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },

          {
            scene: SCENE_CONFIGS.Test_Forest_L_R_U_D,
            overrideSceneId: "test1",
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },

          null,

          {
            scene: SCENE_CONFIGS.Test_Forest_L_D,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },
        ],
      ],
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // Scene Layout
    //
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    {
      id: "ScenesLayout_Test3",
      grid: [
        [
          {
            scene: SCENE_CONFIGS.Test_Forest_L_R_D,
            overrideSceneId: "test2",
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },

          {
            scene: SCENE_CONFIGS.Test_Forest_L_D,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },
        ],
        [
          {
            scene: SCENE_CONFIGS.Test_Forest_L_R_U_D_v2,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },

          {
            scene: SCENE_CONFIGS.Test_Forest_L_R_U_D,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },
        ],
      ],
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // Scene Layout
    //
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    {
      id: "Scene_Layout_Test_Darkness",
      grid: [
        [
          {
            scene: SCENE_CONFIGS.Test_Forest_Cave,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },

          {
            scene: SCENE_CONFIGS.Test_Night_Forest_L_R_U_D,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },

          {
            scene: SCENE_CONFIGS.Test_Forest_L_D,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },
        ],
        [
          {
            scene: SCENE_CONFIGS.Test_Forest_R_U_D,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },

          {
            scene: SCENE_CONFIGS.Test_Night_Forest_L_R_U_D,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },

          {
            scene: SCENE_CONFIGS.Test_Forest_L_U_D,
            overrideSceneId: undefined,
            noWalkLeft: undefined,
            noWalkRight: undefined,
            noWalkUp: undefined,
            noWalkDown: undefined,
          },
        ],
      ],
    },
  ],

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE LINKS (LINKS BETWEEN GRIDS ABOVE)
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  sceneLinks: [
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // Scene Link
    //
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    {
      sceneLinkId: "scene-link-243754a5-d3c58fe1-456de4ce-b8f4034a",
      scene1: {
        uniqueId: "test-scene-02",
        direction: Direction.down,
      },
      scene2: {
        uniqueId: "Test_Forest_L_R_D_ScenesLayout_Test2_0_0",
        direction: Direction.left,
      },
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // Scene Link
    //
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    {
      sceneLinkId: "scene-link-74e925a5-73f9fb8c-4c80e6d7-b9666b99",
      scene1: {
        uniqueId: "test1",
        direction: Direction.down,
      },
      scene2: {
        uniqueId: "test2",
        direction: Direction.left,
      },
    },
  ],
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// GENERATED SCENES LAYOUTS
//
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const SCENES_LAYOUTS = getGameScenesData(SCENES_LAYOUTS_DEF, {
  logErrors: true,
});

export const getSceneByUniqueId: (id: string) => SceneType = (id) => {
  if (id === undefined || id === null) {
    return null;
  }
  return SCENES_LAYOUTS.scenes.find((s) => s.uniqueId === id);
};

export default SCENES_LAYOUTS;
