import GAME_CONFIG from "game-files/gameConfig";
import { GameDisplayModeEnum } from "game-engine/types";
import useGame from "./useGame";
import useWindowSize from "hooks/useWindowSize";

interface Dimensions {
  x: number;
  y: number;
}

const useCanvasDimensions = (): {
  dimensions: Dimensions;
  originalDimensions: Dimensions;
  scale: Dimensions;
} => {
  const { gamePlay } = useGame();

  //
  // DIMENSIONS
  //
  const isFullscreen =
    gamePlay.state.settings.displayMode === GameDisplayModeEnum.fullscreen;
  const fullscreenDimension = useWindowSize();

  const forceFullscreen =
    fullscreenDimension.x < GAME_CONFIG.window.displayDimensions.x;

  const originalDimensions: Dimensions = {
    x: GAME_CONFIG.window.canvasDimensions.x,
    y: GAME_CONFIG.window.canvasDimensions.y,
  };
  const dimensionsFinal: Dimensions = {
    x:
      isFullscreen || forceFullscreen
        ? fullscreenDimension.x
        : GAME_CONFIG.window.displayDimensions.x,
    y:
      isFullscreen || forceFullscreen
        ? fullscreenDimension.y
        : GAME_CONFIG.window.displayDimensions.y,
  };

  //
  // TRANSFORMS
  //
  const scale: Dimensions = {
    x: dimensionsFinal.x / originalDimensions.x,
    y: dimensionsFinal.y / originalDimensions.y,
  };
  const scaleVal = Math.min(scale.x, scale.y);
  const scaleWithAspectRatio: Dimensions = {
    x: scaleVal,
    y: scaleVal,
  };

  const dimensionsWithAspectRatio: Dimensions = {
    x: scaleVal * originalDimensions.x,
    y: scaleVal * originalDimensions.y,
  };

  return {
    dimensions: dimensionsWithAspectRatio,
    originalDimensions: originalDimensions,
    scale: scaleWithAspectRatio,
  };
};

export default useCanvasDimensions;
