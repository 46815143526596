import {
  AnimationColorEnum,
  DynamicLightingLightSource,
  ImageType,
  Position,
  SpriteConfigType,
  TranslatedString,
} from ".";

import { ItemConfigId } from "game-files/common/ids";

export enum ItemSuffixType {
  "grab" = "grab",
  "drop" = "drop",
  "store" = "store",
}

export enum ItemSoundType {
  "grab" = "grab",
  "drop" = "drop",
}

export type ItemConfigType = {
  configId: ItemConfigId;

  name: TranslatedString;
  suffix: {
    [ItemSuffixType.grab]: TranslatedString; // text to show on item pick (after name)
    [ItemSuffixType.drop]: TranslatedString; // text to show on item drop in scene
    [ItemSuffixType.store]: TranslatedString; // text to show on item drop in inventory
  };

  sounds?: {
    [ItemSoundType.grab]?: {
      soundConfigId: string;
      volume?: number;
    };
    [ItemSoundType.drop]?: {
      soundConfigId: string;
      volume?: number;
    };
  };

  render: ItemRenderType;

  lightSource?: DynamicLightingLightSource;
  lightSourceDecay?: ItemLightSourceDecay;
};

export type ItemRenderType = {
  image: ImageType;
  spriteConfig?: SpriteConfigType;
  width?: number; //  ignored if spriteConfig is provided
  height?: number; // ignored if spriteConfig is provided
  offsetX?: number; // offset from origin
  offsetY?: number; // offset from origin
  noClickThroughAlpha?: boolean; // onClick will be triggered when clicking the image rect, ignoring alpha
  deleteItemOnAnimationEnd?: boolean; // for decaying items with lightSource
};

export type ItemPropInInventory = {
  index: number;
};

export type ItemPropInScene = {
  sceneId: string;
  position: Position;
  scale?: number;
};

export type ItemPropInSceneOverlay = {
  sceneId: string;
  position?: Position;
  depthY?: number;
  scale?: number;
  deleteAfterSec?: number;
  deleteItemFadeOutSec?: number;
  deleteItemScaleOutSec?: number;
  deleteAnimation?: ItemDeleteAnimation;
  deleteAnimationColor?: AnimationColorEnum;
};

export enum ItemDeleteAnimation {
  "stars" = "stars",
  "flame" = "flame",
}

export type ItemType = {
  id: string; // unique item id; because config id is not unique - e.g. when having two copies of the same item
  configId: ItemConfigId;

  sceneChangeCounter?: number;
  sceneChangeCounterMax?: number;

  inInventory?: ItemPropInInventory;
  inScene?: ItemPropInScene;
  inSceneOverlay?: ItemPropInSceneOverlay;
  inCursor?: boolean;
  inTrash?: boolean; // to explicitly specify that item is no longer in game (e.g. item is used for its purpose and is supposed to disappear for good)
};

export type ItemInitType = ItemType;

export type ItemLightSourceDecay = {
  bySceneChange: ItemLightSourceDecayItem[];
};

export type ItemLightSourceDecayItem = {
  render: ItemRenderType;
  lightSource?: DynamicLightingLightSource;
};
