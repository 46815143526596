import {
  ActionFacingProps,
  ActionId,
  Action_CharacterTalk,
  CharacterDialogType,
  TalkRenderOptionsType,
} from "game-engine/types";
import { getPreviewString, getRandomItemFromArray } from "../by-types";

import { CharacterId } from "game-files/common/ids";
import { createActionName } from ".";

type RenderOptionsProps = { renderOptions?: TalkRenderOptionsType };

//
// CREATE BASIC TALK ACTION
//
type TalkProps = {
  characterId?: CharacterId;
  dialog: CharacterDialogType;
  unskippable?: boolean;
} & ActionFacingProps &
  RenderOptionsProps;

type TalkReturn = () => Action_CharacterTalk[];

export const talk: (props: TalkProps) => TalkReturn = (props) => () => {
  const { dialog, renderOptions } = props;
  // dialog can be an obj or obj[] -> normalize into array
  const dialogArray = Array.isArray(dialog) ? dialog : [dialog];

  return dialogArray.map((translatedString) => {
    return {
      ...props,
      _name: createActionName({
        name: `talk`,
        value: `"${getPreviewString(translatedString)}"`,
        prefix: props.characterId || "<main-character>",
      }),
      blockGui: true,
      id: ActionId.characterTalk,
      dialog: translatedString,
      talkRenderOptions: renderOptions,
    };
  });
};

//
// CREATE TALK ACTION FROM RANDOMLY CHOSEN DIALOG OPTION
//
type TalkRandomProps = {
  characterId?: CharacterId;
  dialogOptions: CharacterDialogType[];
  unskippable?: boolean;
} & ActionFacingProps &
  RenderOptionsProps;

type TalkRandomReturn = () => Action_CharacterTalk[];

export const talkRandom: (props: TalkRandomProps) => TalkRandomReturn =
  (props) => () => {
    const { dialogOptions } = props;
    if (dialogOptions?.length) {
      return talk({
        ...props,
        dialog: getRandomItemFromArray(dialogOptions),
      })();
    }
  };

//
// CREATE TALK ACTION ARRAY FROM GIVEN TALK ARRAY
//
type ConversationReturn = () => Action_CharacterTalk[];

export const conversation: (conversation: TalkProps[]) => ConversationReturn =
  (conversation) => () => {
    const talkActions = [];
    conversation.forEach((talkProps) => talkActions.push(...talk(talkProps)()));
    return talkActions;
  };
