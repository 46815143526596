import { GameObjective } from "game-files/gameObjectives";
import Layer_Map_Depth from "./assets/layer - 00 - DEPTH MAP_00.png";
import Layer_Map_Drop from "./assets/layer - 00 - DROP MAP_00.png";
import Layer_Map_Walk from "./assets/layer - 00 - WALK MAP_00.png";
import { SceneType } from "game-engine/types";

const Layer_01_Background = Layer_Map_Depth;

//
// SCENE
//
const DevScene_CharacterAssetPreview: SceneType = {
  isDev: true,
  isUsingTestObjectives: true, // IMPORTANT TO KEEP TEST AND GAME OBJECTIVES SEPARATELY

  configId: "DevScene_CharacterAssetPreview",
  name: {
    [GameObjective.GAME_START]: { en: "Development Scene 1", cz: undefined },
  },

  preview: { src: Layer_01_Background },
  uniqueId: "DevScene_CharacterAssetPreview", // USUALLY GENERATED FROM SCENES LAYOUT, UNLESS DEV SCENE THAT'S NOT IN SCENE LAYOUTS
  sceneNeighbors: undefined, // IS GENERATED FROM SCENES LAYOUT
  sceneWalkPaths: undefined,
  audio: undefined,

  images: {
    background: {
      dataByCurrentObjective: {
        [GameObjective.GAME_START]: {
          image: { src: Layer_01_Background, opacity: 0.3 },
          dropMap: { src: Layer_Map_Drop },
          walkMap: { src: Layer_Map_Walk },
          depthMap: { src: Layer_Map_Depth },
          depthSettings: {
            scaleMin: 0.4,
            //scaleMax: 1,
          },
        },
      },
      actionsByCurrentObjective: undefined,
    },

    layers: [],
  },
};

export default DevScene_CharacterAssetPreview;
