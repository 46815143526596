/* eslint-disable react-hooks/exhaustive-deps */

import {
  CharacterConfigType,
  DevPreviewMainCharacterSettings,
} from "game-engine/types";
import {
  getDevSceneForCharacterConfig,
  stringifyTranslations,
} from "game-engine/utils";
import { useEffect, useMemo, useRef, useState } from "react";

import AssetBox from "game-engine/_dev/basic-components/Box/AssetBox";
import Box from "../Box";
import Button from "../Button";
import { CharacterId } from "game-files/common/ids";
import CharacterRenderAssets from "./CharacterRenderAssets";
import InfoTable from "../InfoTable";
import JsonView from "../JsonView";
import ScenePreview from "game-engine/_dev/scene-editor/components/ScenePreview";
import { URL_PARAM_CHARACTER_CONFIG_ID } from "game-engine/tabs/TabAssets";
import { getCharacterConfigById } from "game-files/characters/CHARACTER_CONFIGS";
import styled from "styled-components";
import useGame from "game-engine/hooks/useGame";

const BoxLabel = styled.div`
  flex: 1;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Name = styled.div``;

const GameplayPreview = styled.div`
  margin: 20px 0;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ScenePreviewLoading = styled.div<{ opacity: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  opacity: ${(props) => props.opacity * 0.5};
  transition: opacity 1s ease;
`;

const ScenePreviewWrapper = styled.div<{ opacity: number }>`
  opacity: ${(props) => props.opacity};
  transition: opacity 1s ease;
`;

const GameplayPreviewUnavailable = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin: 20px 10px;
`;

const GameplayPreviewUnavailableText = styled.div`
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  span {
    font-style: italic;
    opacity: 0.6;
  }
`;

const CharacterBox = (props: {
  characterId: CharacterId;
  characterConfig?: CharacterConfigType;
  thumbnailSettings?: {
    width: number;
    height: number;
  };
  previewSettings?: {
    width: number;
    height: number;
    scale: number;
  };
  isOpen?: boolean;
  isDetailPage?: boolean;
}) => {
  const { characterId, thumbnailSettings, previewSettings, isDetailPage } =
    props;

  const { gameFns } = useGame();

  const [mainCharacterSettings, setMainCharacterSettings] =
    useState<DevPreviewMainCharacterSettings>({
      isPoisoned: false,
      renderIndex: 0,
    });

  const config = useMemo(() => {
    return props.characterConfig || getCharacterConfigById(characterId);
  }, [characterId]);

  const { width: thumbnailWidth, height: thumbnailHeight } =
    thumbnailSettings || {
      width: 70,
      height: 70,
    };

  const onOpenInNewTab = () => {
    window.open(
      `${window.location.pathname}?tab=assets&${URL_PARAM_CHARACTER_CONFIG_ID}=${characterId}`,
      "_blank"
    );
  };

  const renderBoxLabel = () => {
    const name = stringifyTranslations(config?.name);

    if (!isDetailPage) {
      return (
        <BoxLabel>
          <Name>{name}</Name>
          <Button onClick={onOpenInNewTab}>Open in a new tab</Button>
        </BoxLabel>
      );
    }

    return name;
  };

  //
  // SET MAIN CHARACTER FOR PREVIEW
  //
  const [previewIsSet, setPreviewIsSet] = useState(false);
  const timerRef = useRef<any>();

  const updateDevPreview = () => {
    gameFns.setDevPreview({
      mainCharacterId: characterId,
      mainCharacterSettings,
    });
    setPreviewIsSet(true);
  };

  useEffect(() => {
    if (isDetailPage) {
      // timeout is needed, because sometimes walking doesn't work on load
      timerRef.current = setTimeout(updateDevPreview, 400);
    }
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [isDetailPage]);

  useEffect(() => {
    updateDevPreview();
  }, [mainCharacterSettings]);

  //
  // RENDER
  //
  return (
    <AssetBox
      isOpen={props.isOpen}
      label={renderBoxLabel()}
      thumbnail={{
        src: config?.devTools.renderAssets[0].render.default.image.src,
        imgFillPercentage: "90%",
        width: thumbnailWidth,
        height: thumbnailHeight,
      }}
      level={2}
      useFlex
      childMinWidth="90%"
    >
      {/* SCENE DATA */}
      {config?.devTools.sceneCharacter && (
        <Box
          renderContentOnly
          isOpen
          contentPaddingTop="20px"
          contentPaddingBottom="15px"
        >
          <InfoTable
            data={[
              {
                label: "position",
                value: `[${config?.devTools.sceneCharacter.position.x}, ${config?.devTools.sceneCharacter.position.y}]`,
              },
              {
                label: "scale",
                value: config?.devTools.sceneCharacter.scale || 1,
              },
              {
                label: "facing",
                value: config?.devTools.sceneCharacter.facing,
              },
            ]}
          />
        </Box>
      )}

      {/* PREVIEW */}
      <Box renderContentOnly childMinWidth="90%">
        {config?.devTools.renderAssets.map((characterRender, i) => {
          return (
            <CharacterRenderAssets
              key={i}
              isOpen={!config?.devTools.sceneCharacter}
              characterConfig={config}
              characterRender={characterRender}
              previewSettings={previewSettings}
            />
          );
        })}
      </Box>

      {/* TEST GAME VIEWPORT */}
      <Box label="Gameplay Preview" isOpen={isDetailPage} level={3}>
        {isDetailPage ? (
          <GameplayPreview>
            <ScenePreviewLoading opacity={previewIsSet ? 0 : 1}>
              Loading Preview...
            </ScenePreviewLoading>

            <ScenePreviewWrapper opacity={previewIsSet ? 1 : 0}>
              <ScenePreview
                sceneOnly
                scene={getDevSceneForCharacterConfig({
                  characterConfig: config,
                  mainCharacterSettings: mainCharacterSettings,
                  setMainCharacterSettings: setMainCharacterSettings,
                })}
                canvasClickSettings={undefined}
                ignoreFaceClickedPosition
              />
            </ScenePreviewWrapper>
          </GameplayPreview>
        ) : (
          <GameplayPreviewUnavailable>
            <GameplayPreviewUnavailableText>
              <div>Preview available only in detail page</div>
              <div>
                <span>
                  Changes global game config to set the main character, which
                  would be in conflict when previewing multiple character assets
                  at the same time, or during actual gameplay.
                </span>
              </div>
            </GameplayPreviewUnavailableText>

            <Button onClick={onOpenInNewTab}>Open in a new tab</Button>
          </GameplayPreviewUnavailable>
        )}
      </Box>

      {/* CONFIG */}
      <Box label="Config" isOpen={isDetailPage} level={3}>
        <JsonView json={config} autoExpandToLevel={2} hideRoot />
      </Box>
    </AssetBox>
  );
};

export default CharacterBox;
