/* eslint-disable react-hooks/exhaustive-deps */

import {
  ACTION,
  SaveGameProps,
  SavedGameType,
  autosaveGame as autosaveGameFunc,
  clearSavedData as clearSavedDataFunc,
  createActionName,
  deathsaveGame as deathsaveGameFunc,
  deleteSavedGame as deleteSavedGameFunc,
  downloadSavedData as downloadSavedDataFunc,
  getAutosavedGame,
  getDeathsavedGame,
  getNewGameItems,
  getNewGamePlay,
  getPreviewString,
  getSavedData as getSavedDataFunc,
  getSavedSettings,
  getSkillStatesByCurrentObjective,
  playSound as playSoundFunc,
  saveGame as saveGameFunc,
  saveSettings as saveSettingsFunc,
  storeEventInDevTools,
  uploadSavedData as uploadSavedDataFunc,
} from "game-engine/utils";
import { CharacterId, ItemConfigId, SkillId } from "game-files/common/ids";
import { CursorConfigType, CursorRenderType } from "game-engine/types/cursor";
import {
  CursorContext,
  DevToolsContext,
  EngineConfigContext,
  GameItemsContext,
  GamePlayContext,
} from "game-engine";
import {
  CustomPopUpOptionsType,
  DevPreviewMainCharacterSettings,
  DynamicLightingType,
  GameEffectFadeScene,
  GameEffectFadeViewport,
  GameEffectShakeViewport,
  GameItemsType,
  GamePlayAudioType,
  GamePlayCurrentSceneType,
  GamePlayCurrentSkillType,
  GamePlayHiddenSceneLayer,
  GamePlayHideGuiType,
  GamePlayLabelOverrideType,
  GamePlayOverrideMusicType,
  GamePlaySceneOverlayAnimation,
  GamePlayType,
  GameSettingsType,
  ItemConfigType,
  ItemDropAnimationType,
  ItemPropInSceneOverlay,
  ItemSoundType,
  ItemType,
  Language,
  LoggerType,
  Position,
  TranslatedString,
} from "game-engine/types";
import { GameEvent, GameEventsType } from "game-files/gameEvents";
import SKILL_CONFIGS, {
  getSkillConfigById,
} from "game-files/skills/SKILL_CONFIGS";
import {
  addItemToGameItems,
  getActiveItemsArray,
  getItemDroppedLabel,
  getItemGrabbedLabel,
  getItemStoredLabel,
  updateItemsState,
} from "game-engine/utils/objects/item";
import { useContext, useMemo } from "react";

import GAMEPLAY from "game-files/gamePlay";
import GAME_CONFIG from "game-files/gameConfig";
import { GAME_ITEMS_INIT } from "game-files/gameItems";
import { GameObjective } from "game-files/gameObjectives";
import Konva from "konva";
import MAIN_CHARACTER_CONFIG from "game-files/characters/_MAIN_CHARACTER";
import SOUND_CONFIGS from "game-files/audio/SOUND_CONFIGS";
import { getCharacterConfigById } from "game-files/characters/CHARACTER_CONFIGS";
import { getDynamicLightingByCurrentObjective } from "game-engine/utils/get-by-current-objective/get-scene-dynamic-lighting";
import { getItemConfigById } from "game-files/items/ITEM_CONFIGS";
import { getSceneByUniqueId } from "game-files/scenes/SCENE_LAYOUTS";
import { getSceneSkillActionsByCurrentObjective } from "game-engine/utils/get-by-current-objective/get-scene-skill-actions";
import { loadFromLocalStorage } from "game-engine/utils/local-storage";

export const LOCAL_STORAGE_MAIN_CHARACTER_POSITION = "mainCharacterPosition";
export const LOCAL_STORAGE_MAIN_CHARACTER_FACING = "mainCharacterFacing";
export const LOCAL_STORAGE_MAIN_CHARACTER_SCALE = "mainCharacterScale";

const useGame = () => {
  const engineConfig = useContext(EngineConfigContext);
  const gamePlay = useContext(GamePlayContext);
  const gameItems = useContext(GameItemsContext);
  const cursor = useContext(CursorContext);
  const devTools = useContext(DevToolsContext);

  const { window, scene } = GAME_CONFIG;
  const sceneOffset: Position = {
    x: Math.round((window.canvasDimensions.x - scene.dimensions.x) / 2),
    y: Math.round(scene.dimensions.offsetY || 0),
  };
  const sceneCenter: Position = {
    x: Math.round(scene.dimensions.x / 2),
    y: Math.round(scene.dimensions.y / 2),
  };

  //
  // ITEMS SORTED FOR DYNAMIC LIGHTING
  //
  const itemsSortedForDynamicLighting = useMemo(() => {
    // Sort items by priority for dynamic lighting
    return getActiveItemsArray(gameItems.state).sort((a, b) => {
      if (a.inScene) return -1;
      if (b.inScene) return 1;
      if (a.inSceneOverlay) return -1;
      if (b.inSceneOverlay) return 1;
      if (a.inCursor) return -1;
      if (b.inCursor) return 1;
      if (a.inInventory) return -1;
      return 1;
    });
  }, [gameItems.state]);

  //
  // MAIN CHARACTER CONFIG
  //
  const mainCharacterConfig = useMemo(() => {
    let config = getCharacterConfigById(gamePlay.state.mainCharacterId);

    if (!!gamePlay.state.devPreview) {
      return config;
    }

    if (config) {
      // take character config and add main-character overrides
      // (handles clicks with item, poison death etc.)
      config = { ...config, ...MAIN_CHARACTER_CONFIG };
    }

    return config;
  }, [gamePlay.state.mainCharacterId]);

  //
  // MAIN CHARACTER IS-POISONED
  //
  const mainCharacterIsPoisoned = useMemo(() => {
    return (
      gamePlay.state.events[GameEvent.poisonedCharacterIds] || []
    ).includes(gamePlay.state.mainCharacterId);
  }, [gamePlay.state.events, gamePlay.state.mainCharacterId]);

  //
  //
  //
  //
  // GAME FUNCTIONS
  //
  //
  //
  //

  const gameFns = {
    /////////////////////////////////////////////////////////////////////////////////////
    //
    // TRANSLATIONS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    t: (text: TranslatedString) => {
      if (text) {
        let translated = text[gamePlay.state.settings.language];
        if (translated) {
          return translated;
        }

        // Fallback on any translation that might exist
        for (const lang of Object.values(Language)) {
          translated = text[lang];
          if (translated) {
            return translated;
          }
        }
      }
      return "";
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // DEV PREVIEW
    //
    /////////////////////////////////////////////////////////////////////////////////////

    setDevPreview: (params: {
      mainCharacterId: CharacterId;
      mainCharacterSettings: DevPreviewMainCharacterSettings;
    }) => {
      const { mainCharacterId, mainCharacterSettings } = params;

      gamePlay.setState((prevState) => {
        const state: GamePlayType = {
          ...GAMEPLAY,
          devPreview: {
            mainCharacterSettings,
          },
          mainCharacterId: mainCharacterId,
        };

        return state;
      });
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // SCENE
    //
    /////////////////////////////////////////////////////////////////////////////////////

    //
    // Set Current Scene
    //
    setCurrentScene: (currentScene: GamePlayCurrentSceneType) => {
      gamePlay.setState((prevState) => {
        const updatedGamePlay: GamePlayType = {
          ...prevState,
          currentScene,
          effects: {
            ...prevState.effects,
            fadeScene: undefined, // reset fadeScene effect on scene change
          },
          sceneOverlayAnimation: undefined, // reset scene overlay animations on scene change
        };

        return updatedGamePlay;
      });
    },

    //
    // Get Scene Light Source
    //
    getSceneLightSource: (params: {
      uniqueSceneId: string;
      dynamicLighting: DynamicLightingType;
      isRegularScene?: boolean;
      isNextScene?: boolean;
    }) => {
      const { uniqueSceneId, dynamicLighting, isRegularScene, isNextScene } =
        params;

      // 1) check for light source in skills
      if (gamePlay.state.currentSkills?.CelestialFlame?.isActive) {
        const skillLighting = SKILL_CONFIGS.CelestialFlame.dynamicLighting;
        return isRegularScene
          ? skillLighting.regularScenes?.lightSource
          : skillLighting.darkScenes?.lightSource;
      }

      if (!dynamicLighting) {
        return undefined;
      }

      // 2) check items
      for (let i = 0; i < itemsSortedForDynamicLighting.length; i++) {
        const item = itemsSortedForDynamicLighting[i];
        const itemConfig = getItemConfigById(item.configId);
        const lightSource = itemConfig?.lightSource;

        if (!lightSource) {
          continue;
        }

        // a) item is in current scene - include its position
        if (item.inScene?.sceneId === uniqueSceneId) {
          return {
            position: item.inScene.position,
            ...lightSource,
          };
        }

        // b) item is in sceneOverlay - include its position
        if (item.inSceneOverlay?.sceneId === uniqueSceneId) {
          return {
            position: item.inSceneOverlay.position,
            ...lightSource,
          };
        }

        // c) item is able to light up current scene even if not inside of it
        if (item.inInventory || item.inCursor) {
          if (
            isNextScene &&
            item.sceneChangeCounter + 1 > item.sceneChangeCounterMax
          ) {
            // when checking lightSource for the next scene, take decay into consideration (+1 on sceneChangeCounter above)
            continue;
          }
          return lightSource;
        }
      }

      return undefined;
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // HIDDEN SCENE LAYERS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    hideSceneLayer: (params: {
      uniqueSceneId: string;
      sceneLayerId: string;
      durationSec: number;
    }) => {
      const { uniqueSceneId, sceneLayerId, durationSec } = params;

      gamePlay.setState((prevState) => {
        const updatedHiddenLayers = structuredClone(
          prevState.hiddenLayers || {}
        );

        const layer: GamePlayHiddenSceneLayer = {
          hiddenAtTime: new Date().getTime(),
          durationSec,
        };

        if (!updatedHiddenLayers[uniqueSceneId]) {
          updatedHiddenLayers[uniqueSceneId] = {};
        }
        updatedHiddenLayers[uniqueSceneId][sceneLayerId] = layer;

        const updatedGamePlay: GamePlayType = {
          ...prevState,
          hiddenLayers: updatedHiddenLayers,
        };

        return updatedGamePlay;
      });
    },

    showSceneLayer: (params: {
      uniqueSceneId: string;
      sceneLayerId?: string;
      sceneLayerIds?: string[];
    }) => {
      const { uniqueSceneId, sceneLayerId, sceneLayerIds } = params;

      const layerIds = [...(sceneLayerIds || [])];

      if (sceneLayerId) {
        layerIds.push(sceneLayerId);
      }

      gamePlay.setState((prevState) => {
        const updatedHiddenLayers = structuredClone(
          prevState.hiddenLayers || {}
        );

        layerIds.forEach((layerId) => {
          delete updatedHiddenLayers[uniqueSceneId][layerId];
        });

        const updatedGamePlay: GamePlayType = {
          ...prevState,
          hiddenLayers: updatedHiddenLayers,
        };

        return updatedGamePlay;
      });
    },

    checkAndShowHiddenLayersForScene: (uniqueSceneId) => {
      if (
        gamePlay.state.hiddenLayers &&
        gamePlay.state.hiddenLayers[uniqueSceneId]
      ) {
        const currentTime = new Date().getTime();
        const sceneLayerIds = Object.entries(
          gamePlay.state.hiddenLayers[uniqueSceneId]
        )
          .filter(
            ([hiddenLayerId, hiddenLayer]) =>
              hiddenLayer.hiddenAtTime + hiddenLayer.durationSec * 1000 <
              currentTime
          )
          .map(([hiddenLayerId, hiddenLayer]) => {
            return hiddenLayerId;
          });

        if (sceneLayerIds.length) {
          gameFns.showSceneLayer({ uniqueSceneId, sceneLayerIds });
        }
      }
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // SCENE OVERLAY ANIMATIONS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    //
    // Set Scene Overlay Animation
    //
    setSceneOverlayAnimation: (
      sceneOverlayAnimation: GamePlaySceneOverlayAnimation
    ) => {
      gamePlay.setState((prevState) => {
        const updatedGamePlay: GamePlayType = {
          ...prevState,
          sceneOverlayAnimation,
        };

        return updatedGamePlay;
      });
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // CHARACTERS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    setMainCharacter: (characterId) => {
      gamePlay.setState((prevState) => {
        const prevCharacterId = prevState.mainCharacterId;

        // turn off poison for both the old and new main characters (to avoid problems, healing no longer possible etc.)
        const poisonedCharacterIds = [
          ...(prevState.events[GameEvent.poisonedCharacterIds] || []).filter(
            (id) => id !== characterId && id !== prevCharacterId
          ),
        ];

        return {
          ...prevState,
          mainCharacterId: characterId ?? gamePlay.state.mainCharacterId,
          events: {
            ...prevState.events,
            [GameEvent.poisonedCharacterIds]: poisonedCharacterIds,
          },
        };
      });
    },

    setCharacterIsPoisoned: (params: {
      characterId?: CharacterId;
      isPoisoned: boolean;
    }) => {
      const { characterId = gamePlay.state.mainCharacterId, isPoisoned } =
        params;

      gamePlay.setState((prevState) => {
        let poisonedCharacterIds = [
          ...(prevState.events[GameEvent.poisonedCharacterIds] || []),
        ];

        if (isPoisoned && !poisonedCharacterIds.includes(characterId)) {
          poisonedCharacterIds.push(characterId);
        }
        if (!isPoisoned && poisonedCharacterIds.includes(characterId)) {
          poisonedCharacterIds = poisonedCharacterIds.filter(
            (id) => id !== characterId
          );
        }

        return {
          ...prevState,
          events: {
            ...prevState.events,
            [GameEvent.poisonedCharacterIds]: poisonedCharacterIds,
          },
        };
      });
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // ITEMS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    getItemInCursor: () => {
      return gameItems.state.itemInCursor;
    },

    getItemsInScene: (uniqueSceneId: string) => {
      return Object.values(
        gameItems.state.itemsInScene[uniqueSceneId] || {}
      ) as ItemType[];
    },

    getItemsInSceneOverlay: (uniqueSceneId: string) => {
      return Object.values(
        gameItems.state.itemsInSceneOverlay[uniqueSceneId] || {}
      ) as ItemType[];
    },

    getItemsInInventory: () => {
      return Object.values(
        gameItems.state.itemsInInventory || {}
      ) as ItemType[];
    },

    getItemsInTrash: () => {
      return gameItems.state.itemsInTrash;
    },

    getItemById: (itemId: string) => {
      if (itemId === undefined) {
        return null;
      }

      // 1. Check if the item is in the cursor
      if (gameItems.state.itemInCursor?.id === itemId) {
        return gameItems.state.itemInCursor;
      }

      // 2. Check if the item is in the trash
      const trashItem = gameItems.state.itemsInTrash.find(
        (item) => item.id === itemId
      );
      if (trashItem) {
        return trashItem;
      }

      // 3. Check if the item is in the inventory
      for (const slotIndex in gameItems.state.itemsInInventory) {
        if (gameItems.state.itemsInInventory[slotIndex].id === itemId) {
          return gameItems.state.itemsInInventory[slotIndex];
        }
      }

      // 4. Check if the item is in any scene
      for (const sceneId in gameItems.state.itemsInScene) {
        const sceneItems = gameItems.state.itemsInScene[sceneId];
        if (sceneItems[itemId]) {
          return sceneItems[itemId];
        }
      }

      // 5. Check if the item is in any scene overlay
      for (const sceneId in gameItems.state.itemsInSceneOverlay) {
        const sceneOverlayItems = gameItems.state.itemsInSceneOverlay[sceneId];
        if (sceneOverlayItems[itemId]) {
          return sceneOverlayItems[itemId];
        }
      }

      // Item not found
      return null;
    },

    //
    // Increment scene change count for relevant items and return decayed items
    //
    incrementItemSceneChangeAndGetDecayed: () => {
      let itemsUpdated = false;
      const decayedItems: { item: ItemType; itemConfig: ItemConfigType }[] = [];

      // Create a copy of the items in inventory to update them immutably
      const updatedItemsInInventory = { ...gameItems.state.itemsInInventory };

      // Check items in inventory for decay updates
      Object.values(updatedItemsInInventory).forEach((item) => {
        let sceneChangeCounter = item.sceneChangeCounter;

        if (sceneChangeCounter !== undefined) {
          // Increment the scene change counter
          sceneChangeCounter += 1;
          itemsUpdated = true; // Mark that an update has occurred

          const itemConfig = getItemConfigById(item.configId);

          // Update the item with the incremented sceneChangeCounter
          item.sceneChangeCounter = sceneChangeCounter;

          // Check if the decay has reached the threshold
          if (
            sceneChangeCounter >=
            itemConfig.lightSourceDecay?.bySceneChange?.length
          ) {
            decayedItems.push({ item, itemConfig }); // Add to decayedItems
          }
        }
      });

      // Set new state only if any items were updated
      if (itemsUpdated) {
        gameItems.setState((prevState) => ({
          ...prevState,
          itemsInInventory: updatedItemsInInventory, // Set updated items
        }));
      }

      return decayedItems;
    },

    //
    // Grab Item
    //
    grabItem: (grabbedItem: ItemType) => {
      gameItems.setState((prevState) =>
        updateItemsState(prevState, grabbedItem, {
          inCursor: true,
        })
      );

      gameFns.playItemSound({ item: grabbedItem, type: ItemSoundType.grab });

      cursor.setState((prevState) => {
        const newState: CursorConfigType = {
          ...prevState,
          isHidden: false,
          itemDropAnimation: null,
        };
        return newState;
      });

      gameFns.setOverrideLabel({ label: getItemGrabbedLabel(grabbedItem) });

      storeEventInDevTools({
        devTools,
        event: {
          name: createActionName({
            name: "grab item",
            value: `${grabbedItem?.configId}`,
          }),
          data: {
            itemId: grabbedItem?.id,
            configId: grabbedItem?.configId,
          },
        },
      });
    },

    //
    // Drop Item In Scene
    //
    dropItemInScene: (options: {
      item: ItemType;
      position: Position;
      scale?: number;
      animation?: { from: Position };
    }) => {
      const { item, animation, position, scale } = options;

      const droppedItem = item;
      let dropAnimation = null;
      if (animation) {
        dropAnimation = {
          itemId: droppedItem.id,
          from: animation.from,
        };
      }

      cursor.setState((prevState) => {
        const newState: CursorConfigType = {
          ...prevState,
          isHidden: false,
          itemDropAnimation: dropAnimation,
        };
        return newState;
      });

      gameFns.playItemSound({ item: droppedItem, type: ItemSoundType.drop });

      gameItems.setState((prevState) =>
        updateItemsState(prevState, droppedItem, {
          inScene: {
            sceneId: gamePlay.state.currentScene.uniqueSceneId,
            position: position,
            scale: scale || 1,
          },
        })
      );

      gameFns.setOverrideLabel({ label: getItemDroppedLabel(droppedItem) });

      storeEventInDevTools({
        devTools,
        event: {
          name: createActionName({
            name: "drop item",
            value: `${droppedItem?.configId}`,
          }),
          data: {
            itemId: droppedItem?.id,
            configId: droppedItem?.configId,
          },
        },
      });
    },

    //
    // Drop Item In Inventory Slot (SWAP IT IF INVENTORY SLOT ALREADY HAS AN ITEM IN IT)
    //
    dropItemInInventorySlot: (options: {
      item: ItemType;
      slotIndex: number;
    }) => {
      const { item, slotIndex } = options;

      const itemInSlot = gameFns.getItemInInventorySlot(slotIndex);
      let grabbedItem: ItemType | undefined = itemInSlot;
      let storedItem: ItemType | undefined = item;

      const logInfo = (p: {
        logMessage: string;
        eventPrefix: string;
        eventName: string;
        eventValue: string;
      }) => {
        const { logMessage, eventPrefix, eventName, eventValue } = p;
        const data = {
          itemInCursor: gameItems.state.itemInCursor,
          itemInSlot,
          grabbedItem,
        };
        logger.info(logMessage, data);
        storeEventInDevTools({
          devTools,
          event: {
            name: createActionName({
              prefix: eventPrefix,
              name: eventName,
              value: eventValue,
            }),
            data,
          },
        });
      };

      // Update the game items state
      gameItems.setState((prevState) => {
        // Store item from cursor in inventory slot
        const updatedState1 = updateItemsState(prevState, item, {
          inInventory: { index: slotIndex },
        });

        // If there was an item in the slot, grab it (swap with the cursor item)
        if (grabbedItem) {
          const updatedState2 = updateItemsState(updatedState1, grabbedItem, {
            inCursor: true,
          });
          return updatedState2;
        }
        return updatedState1;
      });

      // Item swapped with another in inventory slot
      if (grabbedItem) {
        gameFns.playItemSound({ item: grabbedItem, type: ItemSoundType.grab });
        gameFns.setOverrideLabel({ label: getItemGrabbedLabel(grabbedItem) });
        logInfo({
          logMessage: `Item swapped in inventory slot (${slotIndex})`,
          eventPrefix: `slot ${slotIndex}`,
          eventName: `swap item`,
          eventValue: `${grabbedItem?.configId}`,
        });
      }

      // Item stored in empty inventory slot
      else if (storedItem) {
        gameFns.playItemSound({ item: storedItem, type: ItemSoundType.drop });
        gameFns.setOverrideLabel({ label: getItemStoredLabel(storedItem) });
        logInfo({
          logMessage: `Item stored in inventory slot (${slotIndex})`,
          eventPrefix: `slot ${slotIndex}`,
          eventName: `store item`,
          eventValue: `${storedItem?.configId}`,
        });
      }

      return {
        grabbedItem,
        storedItem,
      };
    },

    //
    // Grab Item From Inventory Slot
    //
    grabItemFromInventorySlot: (slotIndex: number) => {
      const itemInSlot = gameFns.getItemInInventorySlot(slotIndex);

      // Update the game items state
      gameItems.setState((prevState) => {
        return updateItemsState(prevState, itemInSlot, {
          inCursor: true,
        });
      });

      gameFns.playItemSound({ item: itemInSlot, type: ItemSoundType.grab });
      gameFns.setOverrideLabel({ label: getItemGrabbedLabel(itemInSlot) });

      logger.info(`Item grabbed from inventory slot (${slotIndex})`, {
        itemInCursor: gameItems.state.itemInCursor,
        itemInSlot,
      });
      storeEventInDevTools({
        devTools,
        event: {
          name: createActionName({
            prefix: `slot ${slotIndex}`,
            name: `grab item`,
            value: `${itemInSlot?.configId}`,
          }),
          data: {
            itemInSlot,
          },
        },
      });

      return itemInSlot;
    },

    //
    // Get Item From Inventory Slot
    //
    getItemInInventorySlot: (slotIndex: number) => {
      return gameItems.state.itemsInInventory[slotIndex];
    },

    //
    // Put Item In Scene Overlay
    //
    putItemInSceneOverlay: (
      item: ItemType | undefined,
      sceneOverlayParams: ItemPropInSceneOverlay
    ) => {
      if (!item) return; // Early return if item is undefined

      gameItems.setState((prevState) =>
        updateItemsState(prevState, item, {
          inSceneOverlay: sceneOverlayParams,
        })
      );

      // Log the event in development tools
      storeEventInDevTools({
        devTools,
        event: {
          name: createActionName({
            name: "put item in scene overlay",
            value: `${item.configId}`,
          }),
          data: {
            itemId: item.id,
            configId: item.configId,
            params: sceneOverlayParams,
          },
        },
      });
    },

    // Swap Item Config
    swapItemConfig: (params: { item: ItemType; newConfigId: ItemConfigId }) => {
      const { item, newConfigId } = params;
      const newItem = { ...item, configId: newConfigId };

      gameItems.setState((prevState) => {
        const updatedState = { ...prevState };

        // Update item in itemInCursor if it matches
        if (updatedState.itemInCursor.id === item.id) {
          updatedState.itemInCursor = newItem;
          return updatedState;
        }

        // Update item in itemsInScene if applicable
        let foundInScene = false;
        for (const sceneId in updatedState.itemsInScene) {
          if (updatedState.itemsInScene[sceneId][item.id]) {
            updatedState.itemsInScene[sceneId][item.id] = newItem;
            foundInScene = true;
            break;
          }
        }

        // Update item in itemsInSceneOverlay if applicable and item was not found in the scene
        if (!foundInScene) {
          for (const sceneId in updatedState.itemsInSceneOverlay) {
            if (updatedState.itemsInSceneOverlay[sceneId][item.id]) {
              updatedState.itemsInSceneOverlay[sceneId][item.id] = newItem;
              break;
            }
          }
        }

        return updatedState; // Return the updated state
      });
    },

    //
    // Delete Item
    //
    deleteItem: (item: ItemType | undefined) => {
      if (!item) return; // Check if item is undefined

      gameItems.setState((prevState) =>
        updateItemsState(prevState, item, {
          inTrash: true,
        })
      );

      // Log the delete event to DevTools
      storeEventInDevTools({
        devTools,
        event: {
          name: createActionName({
            name: `delete item`,
            value: `${item.configId}`,
          }),
          data: {
            itemId: item.id,
            configId: item.configId,
          },
        },
      });
    },

    //
    // Delete item in cursor
    //
    deleteItemInCursor: () => {
      const item = gameItems.state.itemInCursor;
      if (item) {
        gameFns.deleteItem(item);
      }
    },

    //
    // Set Cursor Item Drop Animation
    //
    setCursorItemDropAnimation: (
      dropAnimation: ItemDropAnimationType | undefined
    ) => {
      cursor.setState((prevState) => {
        const newState: CursorConfigType = {
          ...prevState,
          itemDropAnimation: dropAnimation,
        };

        return newState;
      });
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // OBJECTIVES
    //
    /////////////////////////////////////////////////////////////////////////////////////

    setCurrentObjective: (newObjective: GameObjective) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        currentObjective: newObjective,
      }));
    },

    getCurrentObjective: () => {
      return gamePlay.state.currentObjective;
    },

    objectiveIsPending: (objective: GameObjective) => {
      // taking advantage of objectives being numbers
      return objective > gameFns.getCurrentObjective();
    },

    objectiveIsActive: (objective: GameObjective) => {
      // taking advantage of objectives being numbers
      return objective === gameFns.getCurrentObjective();
    },

    objectiveIsCompleted: (objective: GameObjective) => {
      // taking advantage of objectives being numbers
      return objective < gameFns.getCurrentObjective();
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // EVENTS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    setEvents: (newEvents: GameEventsType) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        events: newEvents,
      }));

      storeEventInDevTools({
        devTools,
        event: {
          name: createActionName({
            name: `set game events`,
          }),
          data: newEvents,
        },
      });
    },

    getEvents: () => {
      return gamePlay.state.events;
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // SKILLS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    getActiveSkillIds: (options?: {
      excludeSkillsWithNoDuration?: boolean;
    }) => {
      const activeSkillIds = [];

      Object.entries(gamePlay.state.currentSkills || {}).forEach((entry) => {
        const skillId = entry[0] as SkillId;
        const currentSkill = entry[1] as GamePlayCurrentSkillType;

        const excludeBasedOnDuration =
          options?.excludeSkillsWithNoDuration &&
          !currentSkill.activatedForTime &&
          !currentSkill.activeForSceneChangeCount;

        if (!excludeBasedOnDuration && currentSkill?.isActive) {
          activeSkillIds.push(skillId);
        }
      });

      return activeSkillIds;
    },

    getCurrentSkillStates: (skillId: SkillId) => {
      const skillConfig = getSkillConfigById(skillId);
      const currentStates = getSkillStatesByCurrentObjective({
        dataByCurrentObjective: skillConfig.states,
        currentObjective: gameFns.getCurrentObjective(),
        events: gameFns.getEvents(),
      });

      return currentStates;
    },

    setTriggerSkillStart: (skillId: SkillId) => {
      gamePlay.setState((prevState) => {
        return {
          ...prevState,
          triggerSkill: skillId,
        };
      });
    },

    activateSkill: (skillId: SkillId) => {
      gamePlay.setState((prevState) => {
        const currentSkills = { ...(prevState.currentSkills || {}) };
        const currentTimeMilliseconds = Date.now();

        const skill = getSkillConfigById(skillId);

        const currentStates = getSkillStatesByCurrentObjective({
          dataByCurrentObjective: skill.states,
          currentObjective: gameFns.getCurrentObjective(),
          events: gameFns.getEvents(),
        });

        const activatedForTimeSec =
          currentStates.state_active?.duration?.durationSec ?? 0;

        const pausedForTimeSec =
          currentStates.state_waitForReset?.duration?.durationSec ?? 0;

        currentSkills[skillId] = {
          isActive: true,
          progress: 1,
          activatedAtTime: currentTimeMilliseconds,
          activatedForTime: activatedForTimeSec * 1000, // convert to milliseconds
          activeForSceneChangeCount:
            currentStates.state_active?.duration?.sceneChangeCount,

          isPaused: false,
          pausedAtTime: undefined,
          pausedForTime: pausedForTimeSec * 1000, // convert to milliseconds
          pauseForSceneChangeCount:
            currentStates.state_waitForReset?.duration?.sceneChangeCount,
        };

        return {
          ...prevState,
          currentSkills,
        };
      });
    },

    setSkillProgress: (skillId: SkillId, progress: number) => {
      if (gamePlay.state.currentSkills[skillId]?.isActive) {
        // update progress only if the skill is active
        gamePlay.setState((prevState) => {
          const currentSkills = { ...(prevState.currentSkills || {}) };

          currentSkills[skillId] = {
            ...currentSkills[skillId],
            progress,
          };

          return {
            ...prevState,
            currentSkills,
          };
        });
      }
    },

    stopSkill: (
      skillId: SkillId,
      options?: { appendStopActions?: boolean; prependStopActions?: boolean }
    ) => {
      gamePlay.setState((prevState) => {
        const currentSkills = { ...(prevState.currentSkills || {}) };
        const storedSkill = currentSkills[skillId];
        const currentTimeMilliseconds = Date.now();

        currentSkills[skillId] = {
          isActive: false,
          progress: undefined,
          activatedAtTime: undefined,
          activatedForTime: undefined,
          activeForSceneChangeCount: undefined,

          isPaused: true,
          pausedAtTime: storedSkill?.pausedForTime
            ? currentTimeMilliseconds
            : undefined,
          pausedForTime: storedSkill?.pausedForTime,
          pauseForSceneChangeCount: storedSkill?.pauseForSceneChangeCount,

          appendStopActions: options?.appendStopActions,
          prependStopActions: options?.prependStopActions,
        };

        return {
          ...prevState,
          currentSkills,
        };
      });
    },

    stopAllSkills: (options?: {
      appendStopActions?: boolean;
      prependStopActions?: boolean;
    }) => {
      Object.keys({ ...(gamePlay.state.currentSkills || {}) }).forEach(
        (skillId) => {
          gameFns.stopSkill(skillId as SkillId, options);
        }
      );
    },

    resetSkill: (skillId: SkillId) => {
      gamePlay.setState((prevState) => {
        const currentSkills = { ...(prevState.currentSkills || {}) };
        delete currentSkills[skillId];

        return {
          ...prevState,
          currentSkills: Object.keys(currentSkills)?.length
            ? currentSkills
            : undefined,
        };
      });
    },

    getSkillActionsOnStart: (skillId: SkillId) => {
      const skill = getSkillConfigById(skillId);
      const currentStates = getSkillStatesByCurrentObjective({
        dataByCurrentObjective: skill.states,
        currentObjective: gameFns.getCurrentObjective(),
        events: gameFns.getEvents(),
      });

      // 01 - skill-defined actions on start
      let actionsOnStart =
        currentStates?.state_start?.getActions({
          gamePlay: gamePlay.state,
          gameItems: gameItems.state,
        }) || [];

      // 02 - action to start skill
      actionsOnStart.push(ACTION.activateSkill(skillId));

      // 03 - if main character isPoisoned, override scene-defined actions
      let skillActionsOverride;

      if (skillId === SkillId.Healing && mainCharacterIsPoisoned) {
        skillActionsOverride = mainCharacterConfig.onPoison.actionsOnHeal;
        actionsOnStart.push(...skillActionsOverride);
      }

      // 04 - if no override-actions, check scene-defined skill actions
      else {
        const currentSceneConfig = getSceneByUniqueId(
          gamePlay.state.currentScene.uniqueSceneId
        );

        if (
          currentSceneConfig?.onSkillStart &&
          currentSceneConfig?.onSkillStart[skillId]
        ) {
          const { actionsBeforeSkill, actions } =
            getSceneSkillActionsByCurrentObjective({
              actionsByCurrentObjective:
                currentSceneConfig?.onSkillStart[skillId]
                  .actionsByCurrentObjective,
              currentObjective: gameFns.getCurrentObjective(),
            });

          if (actionsBeforeSkill?.length) {
            actionsOnStart = [...actionsBeforeSkill, ...(actionsOnStart || [])];
          }
          if (actions?.length) {
            actionsOnStart.push(...actions);
          }
        }
      }

      // 05 - Stop skill if no duration
      const hasNoDuration =
        !currentStates?.state_active?.duration ||
        (!currentStates?.state_active?.duration?.durationSec &&
          !currentStates?.state_active?.duration?.sceneChangeCount);

      if (hasNoDuration) {
        // this ensures that onStart actions run before stopping the skill
        actionsOnStart.push(ACTION.stopSkill(skill.id));
      }

      // Return actions on skill start
      return actionsOnStart;
    },

    isSkillStopIgnored: (skillId: SkillId) => {
      const currentStates = gameFns.getCurrentSkillStates(skillId);

      // No ignore settings - is not ignored
      if (
        !Object.values(currentStates?.state_stop?.ignoreStopIf || {}).length
      ) {
        return false;
      }

      // Ignore is deathByDarkness in current scene
      if (currentStates?.state_stop?.ignoreStopIf?.deathByDarkness) {
        const scene = getSceneByUniqueId(
          gamePlay.state.currentScene.uniqueSceneId
        );

        if (scene.dynamicLighting) {
          const sceneDynamicLighting = getDynamicLightingByCurrentObjective({
            dataByCurrentObjective: scene?.dynamicLighting,
            currentObjective: gameFns.getCurrentObjective(),
            events: gameFns.getEvents(),
          });

          return sceneDynamicLighting?.deathByDarkness;
        }
      }

      // Not ignored by default
      return false;
    },

    getSkillActionsOnStop: (skillId: SkillId) => {
      const currentStates = gameFns.getCurrentSkillStates(skillId);
      const actionsOnStop =
        currentStates?.state_stop?.getActions({
          gamePlay: gamePlay.state,
          gameItems: gameItems.state,
        }) || [];

      return actionsOnStop;
    },

    incrementSkillSceneChangeCount: () => {
      const currentSkills = { ...gamePlay.state.currentSkills };
      const skillIdsToStop = [];
      const skillIdsToReset = [];

      Object.values(SkillId).forEach((skillId) => {
        const currentSkill = currentSkills[skillId];
        if (
          currentSkill?.isActive &&
          currentSkill.activeForSceneChangeCount !== undefined
        ) {
          const updatedActiveSceneChangeCount =
            currentSkill.activeForSceneChangeCount - 1;
          currentSkills[skillId].activeForSceneChangeCount =
            updatedActiveSceneChangeCount;

          if (
            updatedActiveSceneChangeCount <= 0 &&
            !gameFns.isSkillStopIgnored(skillId)
          ) {
            skillIdsToStop.push(skillId);
          }
        }

        if (
          currentSkill?.isPaused &&
          currentSkill.pauseForSceneChangeCount !== undefined
        ) {
          const updatedPausedSceneChangeCount =
            currentSkill.pauseForSceneChangeCount - 1;
          currentSkills[skillId].pauseForSceneChangeCount =
            updatedPausedSceneChangeCount;

          if (updatedPausedSceneChangeCount <= 0) {
            skillIdsToReset.push(skillId);
          }
        }
      });

      return { skillIdsToStop, skillIdsToReset };
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // IGNORE IDLE
    //
    /////////////////////////////////////////////////////////////////////////////////////

    setCharacterIdsForIdleIgnore: (characterIds: string[]) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        ignoreIdle: {
          ...prevState.ignoreIdle,
          characterIds,
        },
      }));
    },

    getCharacterIdsForIdleIgnore: () => {
      return gamePlay.state.ignoreIdle?.characterIds || [];
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // CLICK EVENTS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    getClickedPosition: (e: Konva.KonvaPointerEvent) => {
      const pointerPosition = e.target.getStage().getPointerPosition();
      const position = {
        x: Math.round(pointerPosition.x),
        y: Math.round(pointerPosition.y),
      };
      return position;
    },

    getClickedScenePosition: (e: Konva.KonvaPointerEvent) => {
      const pointerPosition = e.target.getStage().getPointerPosition();
      const position = {
        x: Math.round(pointerPosition.x - sceneOffset.x),
        y: Math.round(pointerPosition.y - sceneOffset.y),
      };
      return position;
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // CURSOR FUNCTIONS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    setCursorVisibility: (value: boolean) => {
      // NOTE - THIS FUNC IS UNNECESSARY, CURSOR IS AUTOMATICALLY HIDDEN BY SHOWING GUI BLOCKER
      logger.info(`cursor ${value ? "visible" : "hidden"}`);

      cursor.setState((prevState) => ({
        ...prevState,
        isHidden: !value,
      }));
    },

    setCursorRenderMode: (value: CursorRenderType) => {
      logger.info(`cursor render mode set to "${cursor.state.renderMode}"`);

      cursor.setState((prevState) => ({
        ...prevState,
        renderMode: value,
      }));
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // GAME EFFECTS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    setEffectShakeViewport: (effect: GameEffectShakeViewport) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        effects: {
          ...prevState.effects,
          viewportShake: effect,
        },
      }));

      logger.info(`shake viewport effect set`, effect);
    },

    setEffectFadeViewport: (effect: GameEffectFadeViewport) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        effects: {
          ...prevState.effects,
          fadeViewport: effect,
        },
      }));

      logger.info(`viewport fade effect set`, effect);
    },

    setEffectFadeScene: (effect: GameEffectFadeScene) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        effects: {
          ...prevState.effects,
          fadeScene: effect,
        },
      }));

      logger.info(`scene fade effect set`, effect);
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // GUI FUNCTIONS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    setOverrideLabel: (labelOverride: GamePlayLabelOverrideType) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        labelOverride: labelOverride,
      }));

      logger.info(`action label set to '${gameFns.t(labelOverride?.label)}'`);
    },

    setHideGui: (hideGui: GamePlayHideGuiType) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        hideGui,
      }));
    },

    setCustomPopUp: (customPopUp: CustomPopUpOptionsType) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        customPopUp,
      }));
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // NEW GAME
    //
    /////////////////////////////////////////////////////////////////////////////////////

    newGame: (params?: { skipIntro?: boolean }) => {
      try {
        // Set new-game gamePlay
        gamePlay.setState(
          getNewGamePlay({
            currentSettings: gamePlay.state.settings,
            skipIntro: params?.skipIntro,
          })
        );

        // Set new-game items
        gameItems.setState(getNewGameItems());

        // Loggers
        logger.info("new game loaded");
        storeEventInDevTools({
          devTools,
          event: {
            name: createActionName({
              name: `NEW GAME`,
            }),
          },
        });

        return true;
      } catch (e) {
        logger.error("new game failed", e);
      }
      return false;
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // SAVE / LOAD GAME
    //
    /////////////////////////////////////////////////////////////////////////////////////

    saveGame: (options: {
      name: string;
      overwriteSavedGame?: SavedGameType;
    }) => {
      saveGameFunc(gameFns.getSaveGameData(options));
      logger.info("game saved", options);
      storeEventInDevTools({
        devTools,
        event: {
          name: createActionName({
            name: `save game`,
            value: `'${getPreviewString(options.name)}'`,
          }),
          data: {
            name: options.name,
            overwriteGame: options.overwriteSavedGame?.name,
          },
        },
      });
    },

    loadGame: (savedGame: SavedGameType) => {
      try {
        if (!(savedGame?.gamePlay && savedGame?.gameItems)) {
          throw new Error("saved game incomplete");
        }

        // base load game on new game data in case loaded game is missing new features
        const newGamePlay = getNewGamePlay({
          currentSettings: gamePlay.state.settings,
        });

        const autosavedSettings = getSavedSettings() || {};

        const currentTimeMilliseconds = Date.now();

        const skillEntries = Object.entries(
          savedGame?.gamePlay?.currentSkills || {}
        );
        skillEntries.forEach(([skillId, skillValues]) => {
          // Reset activatedAtTime to handle outdated timers created at the time of saving
          savedGame.gamePlay.currentSkills[skillId].activatedAtTime =
            currentTimeMilliseconds;

          if (skillValues.activeForSceneChangeCount) {
            // Scene load acts as a scene-change, so sceneChangeCount needs to be incremented:
            savedGame.gamePlay.currentSkills[
              skillId
            ].activeForSceneChangeCount =
              skillValues.activeForSceneChangeCount + 1;
          }
        });

        // SET LOADED DATA
        gamePlay.setState({
          ...newGamePlay,
          ...savedGame.gamePlay,
          isLoadGame: true,
          isAutoSaving: true,
          settings: { ...newGamePlay.settings, ...autosavedSettings }, // ignore settings from game, keep current settings
          mainCharacterIsDead: undefined,
          labelOverride: undefined,
          audio: {
            overrideMusic: undefined,
          },
          events: {
            ...newGamePlay.events,
            ...savedGame.gamePlay.events,
          },
          currentScene: {
            ...savedGame.gamePlay.currentScene,
            walkIn: undefined,
          },
        });

        let loadedGameItems: GameItemsType = { ...savedGame.gameItems };

        GAME_ITEMS_INIT.forEach((initItem) => {
          // handle init items missing from loaded items
          if (!gameFns.getItemById(initItem.id)) {
            loadedGameItems = addItemToGameItems({
              gameItemsState: loadedGameItems,
              item: initItem,
            });
          }
        });

        gameItems.setState(loadedGameItems);

        logger.info("game loaded", savedGame);
        storeEventInDevTools({
          devTools,
          event: {
            name: createActionName({
              name: `load game`,
              value: `'${getPreviewString(savedGame.name)}'`,
            }),
            data: {
              name: savedGame.name,
            },
          },
        });
        return true;
      } catch (e) {
        logger.error("game load failed", e, savedGame);
      }

      return false;
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // AUTOSAVE / LOAD AUTOSAVED GAME
    //
    /////////////////////////////////////////////////////////////////////////////////////

    autosaveGame: () => {
      if (gamePlay.state.isAutoSaving) {
        const savedGame = gameFns.getSaveGameData({ name: "autosave" });
        autosaveGameFunc(savedGame);
        logger.info("game auto saved");
      }
    },

    loadAutosavedGame: () => {
      const autosavedGame = getAutosavedGame();
      if (autosavedGame) {
        gameFns.loadGame(autosavedGame);
        return true;
      }
      return false;
    },

    setAutoSaving: (value) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        isAutoSaving: value,
      }));
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // DEATHSAVE / LOAD DEATHSAVED GAME
    //
    /////////////////////////////////////////////////////////////////////////////////////

    deathsaveGame: () => {
      gameFns.setAutoSaving(false); // necessary for cases when user refreshes window after dying!
      deathsaveGameFunc(gameFns.getSaveGameData({ name: "deathsave" }));
      logger.info("game auto saved before death");
    },

    loadDeathsavedGame: () => {
      const deathsavedGame = getDeathsavedGame();
      gameFns.loadGame(deathsavedGame);
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // CREATE SAVED GAME DATA
    //
    /////////////////////////////////////////////////////////////////////////////////////

    getSaveGameData: (options: {
      name: string;
      overwriteSavedGame?: SavedGameType;
    }) => {
      // Get Main Character Info
      const mainCharacterPosition = JSON.parse(
        loadFromLocalStorage(LOCAL_STORAGE_MAIN_CHARACTER_POSITION)
      );
      const mainCharacterFacing = JSON.parse(
        loadFromLocalStorage(LOCAL_STORAGE_MAIN_CHARACTER_FACING)
      );
      const mainCharacterScale = JSON.parse(
        loadFromLocalStorage(LOCAL_STORAGE_MAIN_CHARACTER_SCALE)
      );

      const saveGameItems: SaveGameProps = {
        name: options.name || "saved-game",
        saveVersion: GAME_CONFIG.saveVersion,
        gamePlay: {
          ...gamePlay.state,
          mainCharacterPosition,
          mainCharacterFacing,
          mainCharacterScale,
        },
        gameItems: gameItems.state,
        overwriteSavedGame: options.overwriteSavedGame,
      };

      return saveGameItems;
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // SAVED DATA
    //
    /////////////////////////////////////////////////////////////////////////////////////

    getSavedData: () => {
      const savedData = getSavedDataFunc();
      return savedData;
    },

    clearSavedData: () => {
      clearSavedDataFunc();
      logger.info("saved data cleared");
    },

    downloadSavedData: () => {
      downloadSavedDataFunc();
      logger.info("saved data downloaded");
    },

    uploadSavedData: async () => {
      try {
        await uploadSavedDataFunc();
        logger.info("saved data uploaded");
        return true;
      } catch (e) {
        logger.error("saved data upload failed", e);
      }
      return false;
    },

    deleteSavedGame: (deleteGame: SavedGameType) => {
      deleteSavedGameFunc(deleteGame);
      logger.info("saved game deleted", deleteGame);
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // MAIN CHARACTER DEATH
    //
    /////////////////////////////////////////////////////////////////////////////////////

    killMainCharacter: () => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        mainCharacterIsDead: true,
      }));
      logger.info("main character died");
    },

    isMainCharacterDead: () => {
      return gamePlay.state.mainCharacterIsDead;
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // AUDIO - MUSIC AND BACKGROUND SOUND
    //
    /////////////////////////////////////////////////////////////////////////////////////

    setAudio: (audio: GamePlayAudioType) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        audio: {
          ...prevState.audio,
          ...audio,
        },
      }));

      logger.info("audio set", audio);
    },

    setOverrideMusic: (overrideMusic: GamePlayOverrideMusicType) => {
      gameFns.setAudio({
        overrideMusic: overrideMusic,
      });

      logger.info("special music set", overrideMusic);
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // AUDIO - DIRECT-PLAY SOUNDS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    playSound: (options: {
      soundConfigId: string;
      volume?: number;
      repeat?: number;
      onEnd?: () => void;
    }) => {
      const { soundConfigId, volume = 1, repeat = 1, onEnd } = options;

      // Use setTimeout to run the playSoundFunc asynchronously
      setTimeout(() => {
        try {
          playSoundFunc({
            soundConfigId,
            volume,
            repeat,
            onEnd,
            logger,
            gamePlay: gamePlay.state,
          });
        } catch (error) {
          console.error("Error playing sound:", error);
        }
      }, 0);
    },

    playItemSound: (options: { item: ItemType; type: ItemSoundType }) => {
      const { item, type } = options;
      const itemConfig = getItemConfigById(item?.configId);
      let soundConfigId = itemConfig?.sounds
        ? itemConfig.sounds[type]?.soundConfigId
        : undefined;

      if (!soundConfigId) {
        switch (type) {
          case ItemSoundType.grab:
            soundConfigId = SOUND_CONFIGS.Test_Grab.id;
            break;
          case ItemSoundType.drop:
            soundConfigId = SOUND_CONFIGS.Test_Drop.id;
            break;
        }
      }

      gameFns.playSound({
        soundConfigId: soundConfigId,
        volume: GAME_CONFIG.audio.itemSoundVolume,
      });
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // GAME SETTINGS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    setSettings: (settings: Partial<GameSettingsType>) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        settings: {
          ...prevState.settings,
          ...settings,
        },
      }));
    },

    saveSettings: () => {
      saveSettingsFunc({ gameSettings: gamePlay.state.settings });
      logger.info("game settings saved");
    },

    loadSettings: () => {
      const savedSettings: GameSettingsType = getSavedSettings();
      gameFns.setSettings(savedSettings);
      logger.info("game settings loaded");
    },
  };

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // LOGGER
  //
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  const customConsoleLog = (options: {
    message: any;
    background: string;
    visible: boolean;
    data?: any[];
  }) => {
    if (options.visible) {
      (options.data ? console.groupCollapsed : console.log)(
        "%c" + options.message,
        `background-color: ${options.background}; padding: 4px 10px`
      );
      if (options.data) {
        console.info(...options.data);
        console.groupEnd();
      }
    }
  };

  const logger: LoggerType = {
    info: (message, ...data) =>
      customConsoleLog({
        message,
        background: engineConfig.state.loggerInfoColor,
        visible: engineConfig.state.loggerInfo,
        data,
      }),

    graphics: (message, ...data) =>
      customConsoleLog({
        message,
        background: engineConfig.state.loggerGraphicsColor,
        visible: engineConfig.state.loggerGraphics,
        data,
      }),

    action: (message, ...data) =>
      customConsoleLog({
        message,
        background: engineConfig.state.loggerActionsColor,
        visible: engineConfig.state.loggerActions,
        data,
      }),

    audio: (message, ...data) =>
      customConsoleLog({
        message,
        background: engineConfig.state.loggerAudioColor,
        visible: engineConfig.state.loggerAudio,
        data,
      }),

    error: (message, ...data) =>
      customConsoleLog({
        message,
        background: engineConfig.state.loggerErrorsColor,
        visible: engineConfig.state.loggerErrors,
        data,
      }),
  };

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // RETURN DATA
  //
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  return {
    gamePlay,
    gameItems,
    engineConfig,
    cursor,
    sceneOffset,
    sceneCenter,
    logger,
    gameFns,
    mainCharacterConfig,
    mainCharacterIsPoisoned,
  };
};

//
// EXPORT
//
export default useGame;

export type GameFnsType = ReturnType<typeof useGame>["gameFns"];
