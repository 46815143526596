import { CharacterDialogType, SceneLayerGenerateItem } from "game-engine/types";

import DIALOG_OPTIONS from "game-files/common/dialogs";
import { ItemConfigId } from "game-files/common/ids";

const SHARED_DATA: {
  dialogOptions: {
    tree: CharacterDialogType[];
    treeOld: CharacterDialogType[];
    treeYoung: CharacterDialogType[];
    forest: CharacterDialogType[];
    forestBackground: CharacterDialogType[];
    rockSmall: CharacterDialogType[];
    rockLarge: CharacterDialogType[];
    rocksAndBushes: CharacterDialogType[];
    bush: CharacterDialogType[];
    notGoingToWalk: CharacterDialogType[];
    obstacleNoSceneWalkOut: CharacterDialogType[];
  };
  items: {
    [ItemConfigId.Glowcap]: {
      generateItem: SceneLayerGenerateItem;
    };
  };
} = {
  dialogOptions: { ...DIALOG_OPTIONS.sceneLayers },
  items: {
    [ItemConfigId.Glowcap]: {
      generateItem: {
        itemConfigId: ItemConfigId.Glowcap,
        pause: {
          durationSec: 30,
        },
      },
    },
  },
};

export default SHARED_DATA;
