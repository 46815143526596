import {
  CharacterRenderType,
  CharacterWalkPath,
  CursorRenderType,
  ExtendWalkPathType,
  Position,
  SceneBackgroundType,
  SceneCharacterDataType,
  SceneType,
  WalkConfigType,
} from "game-engine/types";
import { Group, Rect } from "react-konva";
import {
  convertPathToWalk,
  createMatrix,
  getPathInMatrix,
} from "game-engine/utils";
import { useRef, useState } from "react";

import GAME_CONFIG from "game-files/gameConfig";
import Image from "game-engine/components/basic-elements/Image";
import Konva from "konva";
import SceneFill from "game-engine/components/game-elements/SceneFill";
import SceneOverlay from "../../SceneOverlay";
import useGame from "game-engine/hooks/useGame";
import useKonvaImageData from "game-engine/hooks/useKonvaImageData";

const useWalkMap = (props: {
  scene: SceneType;
  sceneBackground: SceneBackgroundType;
  mainCharacter: SceneCharacterDataType;
  mainCharacterRender: CharacterRenderType;
  getPixelDepth: (position: Position) => number;
  onWalkMapClick?: (data: {
    position?: Position;
    walkPath: CharacterWalkPath;
  }) => void;
  setWalkMapLoaded: (v: boolean) => void;
}) => {
  const { engineConfig, logger, gameFns, gameItems, cursor } = useGame();

  const [renderedWalkPath, setRenderedWalkPath] = useState<CharacterWalkPath>();

  const [imageLoaded, setImageLoaded] = useState(false);
  const onImageLoaded = () => {
    setImageLoaded(true);
    props.setWalkMapLoaded(true);
  };

  const walkMapRef = useRef<Konva.Image>();
  const walkMap = props.sceneBackground?.walkMap;
  const isIgnored = engineConfig.state.ignoreWalkMap || !walkMap?.src;

  const { getPixelMatrix } = useKonvaImageData({
    ref: walkMapRef,
    imageLoaded,
  });

  //
  // HANDLERS
  //
  const onWalkMapClick = (e: Konva.KonvaPointerEvent) => {
    if (props.onWalkMapClick) {
      const from = props.mainCharacter.position;
      const to = gameFns.getClickedScenePosition(e);

      const walkConfig: WalkConfigType = {
        horizontal: {
          frameCount:
            props.mainCharacterRender?.walkLeft?.spriteConfig?.frameCount,
          pixelsWalked: props.mainCharacterRender?.walkLeft?.pixelsWalked,
        },
        vertical: {
          frameCount:
            props.mainCharacterRender?.walkDown?.spriteConfig?.frameCount,
          pixelsWalked: props.mainCharacterRender?.walkDown?.pixelsWalked,
        },
      };

      const walkPath = getWalkPath({
        from,
        to,
        walkConfig,
      });

      logger.info(`walk map clicked [${to.x}, ${to.y}]`, walkPath);

      setRenderedWalkPath(walkPath);
      props.onWalkMapClick({ position: to, walkPath });
    }
  };

  const onMouseMove = () => {
    if (
      !gameItems.state.itemInCursor &&
      cursor.state.renderMode !== CursorRenderType.default
    ) {
      // Handles cursor reset when onMouseMove is skipped
      //
      // How to reproduce the error that is fixed by this:
      //  1. mouse over a scene layer that changes cursor > click
      //  2. while the character talks, gui hides cursor > move cursor away
      //     (this way, onMouseLeave is ignored, and cursor remains changed)
      //  3. character stops talking > the cursor is not reset

      gameFns.setCursorRenderMode(CursorRenderType.default);
    }
  };

  //
  // UTILS
  //
  const getWalkMatrix = (options?: { ignoreWalkMap?: boolean }) => {
    const width = GAME_CONFIG.scene.dimensions.x;
    const height = GAME_CONFIG.scene.dimensions.y;

    if (engineConfig.state.ignoreWalkMap || options?.ignoreWalkMap) {
      return createMatrix({ width, height, value: 255 });
    }
    return getPixelMatrix();
  };

  const getWalkPath: (options: {
    from: Position;
    to: Position;
    walkConfig: WalkConfigType;
    extendPath?: ExtendWalkPathType;
    ignoreWalkMap?: boolean;
    noDiagonalPaths?: boolean;
  }) => CharacterWalkPath = ({
    from,
    to,
    walkConfig,
    extendPath,
    ignoreWalkMap,
    noDiagonalPaths = engineConfig.state.noDiagonalPaths,
  }) => {
    const matrix = getWalkMatrix({ ignoreWalkMap });
    if (matrix) {
      let path;
      const sceneDimensions = GAME_CONFIG.scene.dimensions;
      if (
        from.x < 0 ||
        from.y < 0 ||
        from.x > sceneDimensions.x ||
        from.y > sceneDimensions.y
      ) {
        // character is outside the scene, walk path cannot be calculated
        //   -> move character to final position
        path = [to];
      } else {
        path = getPathInMatrix({
          matrix,
          from,
          to,
          walkConfig,
          noDiagonalPaths,
          extendPath,
        });
      }

      if (!path?.length) {
        // walk path doesn't have any values, this indicates that the target position is not walkable
        //   -> move character to final position
        path = [to];
        logger.error(
          "No walk path. Make sure that the target position is IN WALKABLE AREA!",
          to
        );
      }

      const walkPath = convertPathToWalk({
        path,
        getPixelDepth: props.getPixelDepth,
        engineConfig: engineConfig.state,
      });

      return walkPath;
    } else {
      logger.error(`walk matrix missing`);
    }
  };

  //
  // RENDER PATH
  //
  const renderWalkPath = () => {
    const path = renderedWalkPath;
    if (engineConfig.state.renderWalkPath && path?.length) {
      const rectSize = 2;
      const offset = Math.floor((rectSize - 1) / 2);

      return (
        <SceneOverlay>
          <Group listening={false}>
            {path.map(({ position }, i) => {
              return (
                <Rect
                  key={`path-point-${i}`}
                  position={{ x: position.x - offset, y: position.y - offset }}
                  width={rectSize}
                  height={rectSize}
                  fill={engineConfig.state.walkPathColor}
                />
              );
            })}
          </Group>
        </SceneOverlay>
      );
    }
    return null;
  };

  //
  // RENDER
  //
  const renderWalkMap = () => (
    <Group
      opacity={engineConfig.state.renderWalkMap ? 0.6 : 0}
      onClick={onWalkMapClick}
      onTap={onWalkMapClick}
      onMouseMove={onMouseMove}
    >
      <SceneFill
        fill="white"
        opacity={isIgnored ? 1 : 0}
        listening={isIgnored}
      />
      <Image
        imageRef={walkMapRef}
        src={walkMap?.src}
        opacity={!isIgnored ? 1 : 0}
        listening={!isIgnored}
        onImageLoaded={onImageLoaded}
      />
    </Group>
  );

  return {
    renderWalkMap,
    renderWalkPath,
    getWalkMatrix,
    getWalkPath,
    walkMapLoaded: imageLoaded,
  };
};

export default useWalkMap;
