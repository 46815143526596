import {
  CharacterDevToolsPreviewType,
  CharacterRenderByCurrentObjective,
} from "game-engine/types";

import { GameObjective } from "game-files/gameObjectives";
import RenderDefault from "./render-default";
import RenderHat from "./render-hat";

//
// DEV TOOLS ASSETS
//
export const devTools: CharacterDevToolsPreviewType = {
  renderAssets: [
    {
      name: "Default",
      render: RenderDefault,
    },
    {
      name: "Hat",
      render: RenderHat,
    },
  ],
};

//
// RENDER
//
const render: CharacterRenderByCurrentObjective = {
  [GameObjective.GAME_START]: RenderDefault,
};

export default render;
