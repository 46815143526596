import { CharacterOnClickType } from "game-engine/types";
import { GameObjective } from "game-files/gameObjectives";
import OnClickWithoutItem from "./on-click-without-item";
import onClickWithItem from "./on-click-with-item";

const onClick: CharacterOnClickType = {
  actionsByCurrentObjective: {
    [GameObjective.GAME_START]: {
      actions: OnClickWithoutItem,
      actionsWithItem: onClickWithItem,
    },
  },
};

export default onClick;
