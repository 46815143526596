import {
  CinematicSceneType,
  CinematicTypeEnum,
  Direction,
} from "game-engine/types";

import { ACTION } from "game-engine/utils";
import CHARACTER_CONFIGS from "game-files/characters/CHARACTER_CONFIGS";
import { CinematicId } from "game-files/common/ids";
import DevScene03 from "game-files/scenes/__to-delete__dev-scenes/DevScene03";
import MUSIC_CONFIGS from "game-files/audio/MUSIC_CONFIGS";

const TestCinematicScene02: CinematicSceneType = {
  id: CinematicId.TestCinematicScene02,
  type: CinematicTypeEnum.scene,
  hideGui: true,

  uniqueSceneId: "DevScene03", // necessary for placing items in scene + for scenes outside scenes layout
  scene: DevScene03,

  audio: {
    // override scene config audio
    music: {
      musicConfigId: MUSIC_CONFIGS.Test_01.id,
      volume: undefined,
    },
    sound: {
      soundConfigId: undefined,
      volume: undefined,
      loop: undefined,
    },
  },

  characters: [
    {
      config: CHARACTER_CONFIGS.Felix,
      position: { x: 233, y: 134 },
      facing: Direction.left,
    },
    {
      config: CHARACTER_CONFIGS.TestCharacter,
      position: undefined, // defined in ACTION.walkIntoScene
      facing: Direction.right,
    },
  ],

  fadeIn: { durationSec: 2 }, // or boolean
  fadeOut: true,

  actions: [
    ACTION.walkIntoScene({
      characterId: CHARACTER_CONFIGS.TestCharacter.id,
      fromDirection: Direction.left,
      toPosition: { x: 78, y: 123 },
    }),

    ACTION.talk({
      characterId: CHARACTER_CONFIGS.TestCharacter.id,
      dialog: {
        en: `I came back just to say goodbye.`,
        cz: `Vrátil jsem se jen abych se rozloučil.`,
      },
    }),

    ACTION.walkOutOfScene({
      characterId: CHARACTER_CONFIGS.TestCharacter.id,
      toDirection: Direction.left,
      fromPosition: { x: 78, y: 123 },
      unskippableWalkOut: true,
    }),
  ],
};

export default TestCinematicScene02;
