// enums are necessary due to circular dependencies
// when using cinematicId in actions in other scenes

export enum CharacterId {
  "Tester" = "Tester",
  "Felix" = "Felix",

  "TestCharacter" = "TestCharacter",
}

export enum CharacterAnimationId {
  "kick" = "kick",
  "yawn" = "yawn",
  "stretch" = "stretch",
  "shrug" = "shrug",
  "lookBack" = "lookBack",
  "deathChoke" = "deathChoke",
  "celestialFlameStart" = "celestialFlameStart",
  "celestialFlameEnd" = "celestialFlameEnd",
  "hatOn" = "hatOn",
}

export enum ItemConfigId {
  "Test_Key" = "Test_Key",
  "Test_Flask" = "Test_Flask",
  "Test_FlaskWater" = "Test_FlaskWater",
  "Test_FlaskToxic" = "Test_FlaskToxic",
  "LanternEmpty" = "LanternEmpty",
  "LanternCelestialFlame" = "LanternCelestialFlame",
  "Glowcap" = "Glowcap",
  "TesterHat" = "TesterHat",
}

export enum SkillId {
  "Invisibility" = "Invisibility",
  "Healing" = "Healing",
  "Frost" = "Frost",
  "CelestialFlame" = "CelestialFlame",
}

export enum SceneOverlayAnimationId {
  "frost" = "frost",
}

export enum CinematicSequenceId {
  "NewGame" = "NewGame",
}

export enum CinematicId {
  "TestCinematicScene01" = "TestCinematicScene01",
  "TestCinematicScene02" = "TestCinematicScene02",
  "TestCinematicScreen01" = "TestCinematicScreen01",
  "TestCinematicScreen02" = "TestCinematicScreen02",
  "TestCinematicScreen03" = "TestCinematicScreen03",
  "TestCinematicScreen04" = "TestCinematicScreen04",
  "TestCinematicScreen05" = "TestCinematicScreen05",
  "TestCinematicScreen06" = "TestCinematicScreen06",

  "DeathByDarkness" = "DeathByDarkness",
}

export enum SceneTag {
  "Test" = "Test",
  "DeathByDarkness" = "DeathByDarkness",
  "Act1" = "Act1",
  "Act2" = "Act2",
}
