import { ACTION } from "game-engine/utils";
import { CharacterAnimationId } from "game-files/common/ids";
import { CharacterOnPoisonType } from "game-engine/types";
import MUSIC_CONFIGS from "game-files/audio/MUSIC_CONFIGS";

const onPoison: CharacterOnPoisonType = {
  actionsOnHeal: [
    //
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // POISON - ACTIONS ON HEAL
    //
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ACTION.poisonMainCharacter({ isPoisoned: false }),
    ACTION.pause({
      durationSec: 1,
    }),
    ACTION.talkRandom({
      dialogOptions: [
        {
          en: `Wow, that was close.`,
          cz: `Uf, tak to bylo těsně.`,
        },
      ],
    }),
  ],

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // POISON - ACTIONS ON DEATH
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  actionsOnDeath: [
    ACTION.stopAllSkills({ prependStopActions: true }),
    ACTION.playMusic({
      musicConfigId: MUSIC_CONFIGS.Test_03.id,
      stopBackgroundMusic: true,
    }),
    ACTION.pause({
      durationSec: 1,
    }),
    ACTION.talkRandom({
      dialogOptions: [
        {
          en: `I'm not feeling good.`,
          cz: `Není mi dobře.`,
        },
      ],
    }),
    ACTION.pause({
      durationSec: 2,
    }),
    ACTION.talkRandom({
      dialogOptions: [
        {
          en: `I think I'm dying actually...`,
          cz: `Nechci se plést, ale myslím že umírám...`,
        },
      ],
    }),
    ACTION.pause({
      durationSec: 1,
    }),
    ACTION.characterAnimation({
      animationId: CharacterAnimationId.deathChoke,
      keepLastFrame: true,
    }),
    ACTION.pause({
      durationSec: 3,
    }),
    ACTION.killMainCharacter(),
  ],
};

export default onPoison;
