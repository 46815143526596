/* eslint-disable react-hooks/exhaustive-deps */

import { Dimensions, Direction, Position, SceneType } from "game-engine/types";
import GameViewport, {
  CanvasClickSettings,
} from "game-engine/components/game-viewport/GameViewport";
import {
  RunActionsType,
  StopActionsType,
} from "game-engine/components/game-elements/Scene/logic/useSceneActions";
import { useRef, useState } from "react";

import GAME_CONFIG from "game-files/gameConfig";
import { GUIBlockerProps } from "game-engine/components/game-viewport/GameViewport";
import Scene from "game-engine/components/game-elements/Scene";
import styled from "styled-components";
import useCanvasDimensions from "game-engine/hooks/useCanvasDimensions";

const SCENE_WIDTH_COEFF =
  GAME_CONFIG.scene.dimensions.x / GAME_CONFIG.window.canvasDimensions.x;

const SCENE_HEIGHT_COEFF =
  GAME_CONFIG.scene.dimensions.y / GAME_CONFIG.window.canvasDimensions.y;

const SCENE_OFFSET_Y = GAME_CONFIG.scene.dimensions.offsetY;

const Wrapper = styled.div<{ dimensions: Dimensions; scale: Dimensions }>`
  --sceneWidth: ${(props) => props.dimensions.x * SCENE_WIDTH_COEFF}px;
  --sceneHeight: ${(props) => props.dimensions.y * SCENE_HEIGHT_COEFF}px;

  width: var(--sceneWidth);
  height: var(--sceneHeight);
  overflow: hidden;
`;

const ClippedScene = styled.div<{ dimensions: Dimensions; scale: Dimensions }>`
  --sceneOffsetX: calc(
    (${(props) => props.dimensions.x}px - var(--sceneWidth)) / 2
  );
  --sceneOffsetY: calc((${(props) => props.scale.y} * ${SCENE_OFFSET_Y}px));

  margin-left: calc(var(--sceneOffsetX) * -1);
  margin-top: calc(var(--sceneOffsetY) * -1);
`;

//
// COMPONENT
//
const ScenePreview = (props: {
  scene: SceneType;
  sceneOnly?: boolean;
  onSceneLoaded?: () => void;
  canvasClickSettings?: CanvasClickSettings;
  noCursor?: boolean;
  noGui?: boolean;

  ignoreIdle?: boolean;
  ignoreFaceClickedPosition?: boolean;

  forceWalkIn?: { fromDirection: Direction };
  forceWalkOut?: { walkTo: Position; direction: Direction };
}) => {
  const {
    scene,
    sceneOnly,
    onSceneLoaded,
    canvasClickSettings,
    noCursor,
    noGui,
    ignoreIdle = true,
    ignoreFaceClickedPosition = false,
    forceWalkIn,
    forceWalkOut,
  } = props;

  const [guiBlocker, setGuiBlocker] = useState<GUIBlockerProps>(undefined);
  const onGuiBlockerClickRef = useRef<(clickedPosition: Position) => void>();

  const [forceGuiBlocker, setForceGuiBlocker] = useState<boolean>(false);
  const setForceGuiBlockerRef = useRef(setForceGuiBlocker);

  const runActionsRef = useRef<RunActionsType>();
  const stopActionsRef = useRef<StopActionsType>();

  const { dimensions, scale } = useCanvasDimensions();

  const renderGameViewport = () => {
    return (
      <GameViewport
        renderGUI={
          sceneOnly || noGui
            ? undefined
            : { onExitGame: undefined, onNewGame: undefined }
        }
        noCursor={forceGuiBlocker || noCursor}
        guiBlocker={guiBlocker}
        guiBlockerForced={forceGuiBlocker}
        guiBlockerClickRef={onGuiBlockerClickRef}
        canvasClickSettings={canvasClickSettings}
        runActionsRef={runActionsRef}
        stopActionsRef={stopActionsRef}
      >
        <Scene
          key={scene.uniqueId}
          noAutosave
          ignoreIdle={ignoreIdle}
          ignoreFaceClickedPosition={ignoreFaceClickedPosition}
          scene={scene}
          walkIn={
            !!forceWalkIn
              ? {
                  fromDirection: forceWalkIn.fromDirection,
                }
              : undefined
          }
          isVisible={true}
          setForceGuiBlockerRef={setForceGuiBlockerRef}
          setGuiBlocker={setGuiBlocker}
          onGuiBlockerClickRef={onGuiBlockerClickRef}
          runActionsRef={runActionsRef}
          stopActionsRef={stopActionsRef}
          forceWalkIn={!!forceWalkIn}
          forceWalkOut={forceWalkOut}
          onSceneLoaded={onSceneLoaded}
        />
      </GameViewport>
    );
  };

  //
  // RENDER
  //
  return sceneOnly ? (
    <Wrapper dimensions={dimensions} scale={scale}>
      <ClippedScene dimensions={dimensions} scale={scale}>
        {renderGameViewport()}
      </ClippedScene>
    </Wrapper>
  ) : (
    <>{renderGameViewport()}</>
  );
};

export default ScenePreview;
