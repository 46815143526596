import {
  CharacterConfigType,
  CursorRenderType,
  DevPreviewMainCharacterSettings,
  SceneLayerType,
  SceneType,
  TextAlign,
  TextOutlineStyle,
} from "game-engine/types";

import { ACTION } from "../actions";
import { CharacterAnimationId } from "game-files/common/ids";
import DevScene_CharacterAssetPreview from "game-files/scenes/_DEVELOPMENT/DevScene_CharacterAssetPreview";
import FontMini from "game-engine/glyphs/FontMini";
import GAME_CONFIG from "game-files/gameConfig";
import { GameObjective } from "game-files/gameObjectives";

export const getDevSceneForCharacterConfig = (props: {
  characterConfig: CharacterConfigType;
  mainCharacterSettings: DevPreviewMainCharacterSettings;
  setMainCharacterSettings: (s: DevPreviewMainCharacterSettings) => void;
}) => {
  const { characterConfig, mainCharacterSettings, setMainCharacterSettings } =
    props;

  const characterRender =
    characterConfig.devTools.renderAssets[mainCharacterSettings.renderIndex];

  const TalkButtonWidth = 44;

  const ButtonWidth = 12;
  const ButtonHeight = 8;
  const ButtonGapX = 1;
  const ButtonGapY = 1;
  const PaddingX = 3;
  const PaddingY = 3;

  const ButtonColorText = `#fff`;
  const ButtonColorActive = `#555555`;
  const ButtonColorInactive = `#6d3232`;

  const getAnimationBtnOffset = (index: number) => {
    const btnOffsetX = ButtonWidth + ButtonGapX;
    const btnOffsetY = ButtonHeight + ButtonGapY;

    const maxIndexPerColumn = Math.floor(
      (GAME_CONFIG.scene.dimensions.y - PaddingY * 2) / btnOffsetY
    );

    const columnIndex = Math.floor(index / maxIndexPerColumn);
    const rowIndex = index % maxIndexPerColumn;

    return {
      x: PaddingX + columnIndex * btnOffsetX,
      y: PaddingY + rowIndex * btnOffsetY,
    };
  };

  const layers: SceneLayerType[] = [];

  //
  // CHARACTER ANIMATION BUTTONS
  //
  Object.keys(CharacterAnimationId).forEach((animationId, i) => {
    const isDefined = characterRender.render.animations
      ?.map((a) => a.animationId)
      .includes(animationId);

    layers.push({
      id: `animation-${i}-${animationId}`,
      depthY: 1,
      dataByCurrentObjective: {
        [GameObjective.GAME_START]: {
          offset: getAnimationBtnOffset(i),
          cursorOnHover: CursorRenderType.pointer,
          shape: {
            rect: {
              width: ButtonWidth,
              height: ButtonHeight,
              color: isDefined ? ButtonColorActive : ButtonColorInactive,
              opacity: 1,
            },
            text: {
              text: {
                en: `${i + 1}`,
                cz: undefined,
              },
              font: FontMini,
              color: ButtonColorText,
              x: ButtonWidth / 2,
              y: -2,
              width: ButtonWidth,
              align: TextAlign.center,
              outlined: false,
              outlineStyle: TextOutlineStyle.full,
              opacity: 1,
            },
          },
        },
      },

      actionsByCurrentObjective: {
        [GameObjective.GAME_START]: {
          actions: [
            ACTION.talk({
              dialog: {
                en: animationId,
                cz: undefined,
              },
            }),
            ACTION.characterAnimation({
              animationId,
            }),
          ],
        },
      },
    });
  });

  //
  // TALK ACTION
  //
  layers.push({
    id: `layer-talk`,
    depthY: 1,
    dataByCurrentObjective: {
      [GameObjective.GAME_START]: {
        offset: {
          x: GAME_CONFIG.scene.dimensions.x - TalkButtonWidth - PaddingX,
          y: PaddingY,
        },
        cursorOnHover: CursorRenderType.pointer,
        shape: {
          rect: {
            width: TalkButtonWidth,
            height: ButtonHeight,
            color: ButtonColorActive,
            opacity: 1,
          },
          text: {
            text: {
              en: `talk`,
              cz: undefined,
            },
            font: FontMini,
            color: ButtonColorText,
            x: TalkButtonWidth / 2,
            y: -2,
            width: TalkButtonWidth,
            align: TextAlign.center,
            outlined: false,
            opacity: 1,
          },
        },
      },
    },

    actionsByCurrentObjective: {
      [GameObjective.GAME_START]: {
        actions: [
          ACTION.talk({
            dialog: {
              en: `I could say something about it.`,
              cz: "Mohl bych o tom něco říct.",
            },
          }),
        ],
      },
    },
  });

  //
  // TOGGLE RENDER
  //
  const renderIndex = mainCharacterSettings.renderIndex;
  const renderCount = characterConfig.devTools.renderAssets.length;
  const newRenderIndex = (renderIndex + 1) % renderCount;

  layers.push({
    id: `layer-render-mode`,
    depthY: 1,
    dataByCurrentObjective: {
      [GameObjective.GAME_START]: {
        offset: {
          x: GAME_CONFIG.scene.dimensions.x - TalkButtonWidth - PaddingX,
          y: PaddingY + ButtonHeight + ButtonGapY,
        },
        cursorOnHover: CursorRenderType.pointer,
        shape: {
          rect: {
            width: TalkButtonWidth,
            height: ButtonHeight,
            color: renderCount > 1 ? ButtonColorActive : ButtonColorInactive,
            opacity: 1,
          },
          text: {
            text: {
              en: `render (${renderIndex})`,
              cz: undefined,
            },
            font: FontMini,
            color: ButtonColorText,
            x: TalkButtonWidth / 2,
            y: -2,
            width: TalkButtonWidth,
            align: TextAlign.center,
            outlined: false,
            opacity: 1,
          },
        },
      },
    },

    actionsByCurrentObjective: {
      [GameObjective.GAME_START]: {
        actions: [
          /* ACTION.talk({
            dialog: {
              en: `Toggle render (${newRenderIndex})`,
              cz: undefined,
            },
          }), */
          ACTION.execute({
            funcName: "toggle render",
            func: () => {
              setMainCharacterSettings({
                ...mainCharacterSettings,
                renderIndex: newRenderIndex,
              });
            },
            blockGui: true,
          }),
        ],
      },
    },
  });

  //
  // TOGGLE IS-POISONED
  //
  const currRender = characterConfig.devTools.renderAssets[renderIndex]?.render;

  layers.push({
    id: `layer-is-poisoned`,
    depthY: 1,
    dataByCurrentObjective: {
      [GameObjective.GAME_START]: {
        offset: {
          x: GAME_CONFIG.scene.dimensions.x - TalkButtonWidth - PaddingX,
          y: PaddingY + (ButtonHeight + ButtonGapY) * 2,
        },
        cursorOnHover: CursorRenderType.pointer,
        shape: {
          rect: {
            width: TalkButtonWidth,
            height: ButtonHeight,
            color: currRender?.filters?.poison
              ? ButtonColorActive
              : ButtonColorInactive,
            opacity: 1,
          },
          text: {
            text: {
              en: `poison ${mainCharacterSettings.isPoisoned ? "on" : "off"}`,
              cz: undefined,
            },
            font: FontMini,
            color: ButtonColorText,
            x: TalkButtonWidth / 2,
            y: -2,
            width: TalkButtonWidth,
            align: TextAlign.center,
            outlined: false,
            opacity: 1,
          },
        },
      },
    },

    actionsByCurrentObjective: {
      [GameObjective.GAME_START]: {
        actions: [
          /* ACTION.talk({
            dialog: {
              en: `Toggle poison (${
                !mainCharacterSettings.isPoisoned ? "true" : "false"
              })`,
              cz: undefined,
            },
          }), */
          ACTION.execute({
            funcName: "toggle is-poisoned",
            func: () => {
              setMainCharacterSettings({
                ...mainCharacterSettings,
                isPoisoned: !mainCharacterSettings.isPoisoned,
              });
            },
            blockGui: true,
          }),
        ],
      },
    },
  });

  const SceneConfig: SceneType = {
    ...DevScene_CharacterAssetPreview,
    images: {
      ...DevScene_CharacterAssetPreview.images,
      layers: [...DevScene_CharacterAssetPreview.images?.layers, ...layers],
    },
  };

  return SceneConfig;
};
