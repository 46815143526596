import { GameItemsType, GamePlayType } from "game-engine/types";
import {
  deleteFromLocalStorage,
  loadFromLocalStorage,
  storeInLocalStorage,
} from "../local-storage";
import { downloadJson, uploadJson } from "../files";

import GAME_CONFIG from "game-files/gameConfig";
import { getRandomId } from "../by-types";

export type SavedGameType = {
  id: string;
  saveVersion: string;
  dateIso: string;
  name: string;
  gamePlay: GamePlayType;
  gameItems: GameItemsType;
};

export type SavedDataType = {
  jsonId: string;
  savedGames: SavedGameType[];
};

const JSON_ID = "saved-data-json";

const SAVED_DATA_STORAGE_KEY = "saved-data";
const AUTOSAVE_STORAGE_KEY = "autosave";
const DEATHSAVE_STORAGE_KEY = "deathsave";

//
// CREATE SAVED-GAME OBJECT
//
export const createSavedGame = (props: {
  name: string;
  saveVersion: string;
  gamePlay: GamePlayType;
  gameItems: GameItemsType;
}) => {
  const trimmedGamePlay: GamePlayType = structuredClone(props.gamePlay);

  // delete data that is not needed to load game
  delete trimmedGamePlay.settings;
  delete trimmedGamePlay.isLoadGame;
  delete trimmedGamePlay.isAutoSaving;
  delete trimmedGamePlay.devPreview;
  delete trimmedGamePlay.labelOverride;
  delete trimmedGamePlay.mainCharacterIsDead;
  delete trimmedGamePlay.effects;
  delete trimmedGamePlay.audio;
  delete trimmedGamePlay.sceneOverlayAnimation;
  delete trimmedGamePlay.customPopUp;
  delete trimmedGamePlay.hiddenLayers;
  delete trimmedGamePlay.triggerSkill;
  delete trimmedGamePlay.mainCharacterId;

  // Keep only active skills
  if (trimmedGamePlay.currentSkills) {
    const skillEntries = Object.entries(trimmedGamePlay.currentSkills);

    if (!skillEntries.length) {
      delete trimmedGamePlay.currentSkills;
    } else {
      skillEntries.forEach(([skillId, skillValues]) => {
        // delete skills that are no longer active (waiting for reset)
        if (!skillValues.isActive) {
          delete trimmedGamePlay.currentSkills[skillId];
        }
      });
    }
  }

  const savedGame: SavedGameType = {
    id: getRandomId(),
    saveVersion: props.saveVersion,
    name: props.name,
    dateIso: new Date().toISOString(),
    gamePlay: trimmedGamePlay,
    gameItems: props.gameItems,
  };
  return savedGame;
};

//
// SAVED DATA
//
export const getSavedData = () => {
  const savedDataJson = loadFromLocalStorage(SAVED_DATA_STORAGE_KEY);
  let savedData: SavedDataType = JSON.parse(savedDataJson);
  if (!savedData) {
    savedData = {
      jsonId: JSON_ID,
      savedGames: [],
    };
  }
  return savedData;
};

export const downloadSavedData = () => {
  const savedData = getSavedData();
  downloadJson({
    filename: GAME_CONFIG.saveFilename,
    jsonString: JSON.stringify(savedData),
  });
};

export const clearSavedData = () => {
  deleteFromLocalStorage(SAVED_DATA_STORAGE_KEY);
};

export const uploadSavedData = async () => {
  const uploadedJson = await uploadJson();
  const data: SavedDataType = JSON.parse(uploadedJson);
  if (data?.jsonId === JSON_ID) {
    // this identifies that the json is actually the one created by me for this game
    storeInLocalStorage(SAVED_DATA_STORAGE_KEY, uploadedJson);
    return;
  }
  console.error("Uploaded JSON is not in the saved-data format!");
};

//
// SAVE GAME
//
export type SaveGameProps = {
  name: string;
  saveVersion: string;
  gamePlay: GamePlayType;
  gameItems: GameItemsType;
  overwriteSavedGame?: SavedGameType;
};

export const saveGame = (props: SaveGameProps) => {
  const savedGame = createSavedGame(props);
  const savedData = getSavedData();

  savedData.savedGames = [savedGame, ...savedData.savedGames];

  if (props.overwriteSavedGame) {
    savedData.savedGames = savedData.savedGames.filter(
      (s) => s.id !== props.overwriteSavedGame.id
    );
  }

  storeInLocalStorage(SAVED_DATA_STORAGE_KEY, JSON.stringify(savedData));
};

export const deleteSavedGame = (deleteGame: SavedGameType) => {
  const savedData = getSavedData();
  savedData.savedGames = [...savedData.savedGames].filter(
    (g) => g.id !== deleteGame.id
  );
  storeInLocalStorage(SAVED_DATA_STORAGE_KEY, JSON.stringify(savedData));
};

//
// AUTOSAVE
//
export const autosaveGame = (props: {
  name: string;
  saveVersion: string;
  gamePlay: GamePlayType;
  gameItems: GameItemsType;
}) => {
  const savedGame = createSavedGame(props);
  storeInLocalStorage(AUTOSAVE_STORAGE_KEY, JSON.stringify(savedGame));
};

export const getAutosavedGame = () => {
  const autosavedDataJson = loadFromLocalStorage(AUTOSAVE_STORAGE_KEY);
  const autosavedGame: SavedGameType = JSON.parse(autosavedDataJson);
  return autosavedGame;
};

export const deleteAutosavedGame = () => {
  deleteFromLocalStorage(AUTOSAVE_STORAGE_KEY);
};

//
// AUTOSAVE
//
export const deathsaveGame = (props: {
  name: string;
  saveVersion: string;
  gamePlay: GamePlayType;
  gameItems: GameItemsType;
}) => {
  const savedGame = createSavedGame(props);
  storeInLocalStorage(DEATHSAVE_STORAGE_KEY, JSON.stringify(savedGame));
};

export const getDeathsavedGame = () => {
  const deathsavedDataJson = loadFromLocalStorage(DEATHSAVE_STORAGE_KEY);
  const deathsavedGame: SavedGameType = JSON.parse(deathsavedDataJson);
  return deathsavedGame;
};

export const deleteDeathsavedGame = () => {
  deleteFromLocalStorage(DEATHSAVE_STORAGE_KEY);
};
