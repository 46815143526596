/* eslint-disable react-hooks/exhaustive-deps */

import { GamePlayCurrentSceneType, Position } from "game-engine/types";
import GameViewport, { GUIBlockerProps } from "../game-viewport/GameViewport";
import {
  RunActionsType,
  StopActionsType,
} from "../game-elements/Scene/logic/useSceneActions";
import { createActionName, storeEventInDevTools } from "game-engine/utils";
import { useContext, useEffect, useRef, useState } from "react";

import Cinematic from "../game-elements/Cinematic";
import { DevToolsContext } from "game-engine";
import GAME_CONFIG from "game-files/gameConfig";
import Scene from "../game-elements/Scene";
import { getSceneByUniqueId } from "game-files/scenes/SCENE_LAYOUTS";
import useGame from "../../hooks/useGame";

const GamePlay = (props: { onExitGame: () => void; onNewGame: () => void }) => {
  const { onExitGame, onNewGame } = props;

  const { gamePlay, mainCharacterConfig, logger, mainCharacterIsPoisoned } =
    useGame();
  const devTools = useContext(DevToolsContext);

  const [guiBlocker, setGuiBlocker] = useState<GUIBlockerProps>(undefined);
  const [forceGuiBlocker, setForceGuiBlocker] = useState<boolean>(false);
  const setForceGuiBlockerRef = useRef(setForceGuiBlocker);

  const onGuiBlockerClickRef = useRef<(clickedPosition: Position) => void>();

  const runActionsRef = useRef<RunActionsType>();
  const stopActionsRef = useRef<StopActionsType>();

  //
  // MENU
  //
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const onMenuOpen = () => {
    setMenuIsOpen(true);
  };

  const onMenuClose = () => {
    setMenuIsOpen(false);
  };

  //
  // SCENE STATES
  //
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isFirstSceneRender, setIsFirstSceneRender] = useState<boolean>(true);

  const [currentScene1, setCurrentScene1] =
    useState<GamePlayCurrentSceneType>();
  const [currentScene2, setCurrentScene2] =
    useState<GamePlayCurrentSceneType>();

  const [scene1IsVisible, setScene1IsVisible] = useState<boolean>();

  //
  // SCENE CHANGED / INIT
  //
  useEffect(() => {
    if (gamePlay.state.currentScene.isSetByDeveloper) {
      return initialize();
    }
    if (!isInitialized) {
      return initialize();
    }

    logger.info("current scene changed", gamePlay.state.currentScene);
    storeEventInDevTools({
      devTools,
      event: {
        name: createActionName({
          name: "scene changed",
        }),
        data: {
          sceneId: gamePlay.state.currentScene?.uniqueSceneId,
          cinematicId: gamePlay.state.currentScene?.cinematic?.id,
        },
      },
    });

    return swapRenderedScene();
  }, [gamePlay.state.currentScene]);

  //
  // SCENE LOGIC
  //
  const initialize = () => {
    setCurrentScene1(gamePlay.state.currentScene);
    setCurrentScene2(undefined);
    setScene1IsVisible(true);
    setIsInitialized(true);
    setIsFirstSceneRender(true);
  };

  const swapRenderedScene = () => {
    setIsFirstSceneRender(false);
    const setCurrentScene = scene1IsVisible
      ? setCurrentScene2
      : setCurrentScene1;

    setCurrentScene(gamePlay.state.currentScene);
  };

  const onSceneLoaded = () => {
    if (gamePlay.state.currentScene.isSetByDeveloper) {
      return;
    }
    // runs after all scene images are loaded -> swap visible scenes after that
    if (!isFirstSceneRender) {
      if (scene1IsVisible) {
        setCurrentScene1(undefined);
      } else {
        setCurrentScene2(undefined);
      }
      setScene1IsVisible(!scene1IsVisible);
    }
  };

  const onSceneReadyForPlaying = () => {
    // start poison death only after scene is ready for playing (init actions are finished)
    setAllowPoisonDeathMonitoring(true);
  };

  const onSceneWalkOut = () => {
    // stop poison death while transitioning between scenes
    setAllowPoisonDeathMonitoring(false);
  };

  //
  // GLOBAL TIMER - POISON
  //
  const [allowPoisonDeathMonitoring, setAllowPoisonDeathMonitoring] =
    useState(false);

  const [poisonStartTime, setPoisonStarTime] = useState<number>();
  const poisonStartTimeRef = useRef<number>();
  const poisonCheckTimer = useRef<NodeJS.Timer>();

  // set new poison-start time to monitor poison-death
  useEffect(() => {
    const newPoisonStarTime = mainCharacterIsPoisoned
      ? new Date().getTime()
      : undefined;

    setPoisonStarTime(newPoisonStarTime);
    poisonStartTimeRef.current = newPoisonStarTime;
  }, [mainCharacterIsPoisoned]);

  // monitor poison-death
  useEffect(() => {
    clearTimeout(poisonCheckTimer.current);
    if (poisonStartTime && allowPoisonDeathMonitoring && !menuIsOpen) {
      poisonCheckTimer.current = setInterval(() => {
        if (poisonStartTimeRef.current) {
          const timeNow = new Date().getTime();
          const timeStart = poisonStartTimeRef.current;
          const durationSec =
            GAME_CONFIG.actions.death.byPoison.timeBeforeDeathSec;

          if (timeNow - timeStart > durationSec * 1000) {
            const runActions = runActionsRef.current;

            setPoisonStarTime(undefined);
            runActions(mainCharacterConfig?.onPoison?.actionsOnDeath);
          }
        }
      }, GAME_CONFIG.actions.death.byPoison.checkEverySec * 1000);
    }

    return () => {
      clearTimeout(poisonCheckTimer.current);
    };
  }, [menuIsOpen, allowPoisonDeathMonitoring, poisonStartTime]);

  //
  // RENDER
  //
  return (
    <GameViewport
      renderGUI={{ onExitGame, onNewGame, onMenuOpen, onMenuClose }}
      noCursor={forceGuiBlocker || gamePlay.state.hideGui?.isHidden}
      guiBlocker={guiBlocker}
      guiBlockerForced={forceGuiBlocker}
      guiBlockerClickRef={onGuiBlockerClickRef}
      runActionsRef={runActionsRef}
      stopActionsRef={stopActionsRef}
      canvasClickSettings={undefined}
    >
      {[
        {
          zIndex: scene1IsVisible ? 1 : 0,
          render: currentScene1,
          isVisible: scene1IsVisible,
        },
        {
          zIndex: scene1IsVisible ? 0 : 1,
          render: currentScene2,
          isVisible: !scene1IsVisible,
        },
      ]
        .sort((a, b) => (a.zIndex < b.zIndex ? -1 : 0))
        .map((obj) => {
          if (obj.render?.cinematic) {
            return (
              <Cinematic
                key={obj.render.cinematic?.id}
                cinematicId={obj.render.cinematic.id}
                onEndSettings={obj.render.cinematic.onEnd}
                stopActionsRef={stopActionsRef}
                isVisible={obj.isVisible}
                setGuiBlocker={setGuiBlocker}
                onGuiBlockerClickRef={onGuiBlockerClickRef}
                setForceGuiBlockerRef={setForceGuiBlockerRef}
                onCinematicLoaded={onSceneLoaded}
              />
            );
          }

          if (obj.render?.uniqueSceneId) {
            return (
              <Scene
                key={obj.render.uniqueSceneId}
                scene={getSceneByUniqueId(obj.render.uniqueSceneId)}
                fadeIn={obj.render.fadeIn}
                walkIn={obj.render.walkIn}
                runActionsRef={runActionsRef}
                stopActionsRef={stopActionsRef}
                isVisible={obj.isVisible}
                setGuiBlocker={setGuiBlocker}
                onGuiBlockerClickRef={onGuiBlockerClickRef}
                setForceGuiBlockerRef={setForceGuiBlockerRef}
                onSceneLoaded={onSceneLoaded}
                onSceneReadyForPlaying={onSceneReadyForPlaying}
                animateLoadTransition={isFirstSceneRender}
                onSceneWalkOut={onSceneWalkOut}
                ignoreIdle={menuIsOpen}
              />
            );
          }

          return null;
        })}
    </GameViewport>
  );
};

export default GamePlay;
