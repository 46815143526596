import { ActionId, Action_Skill, SkillActionTypeEnum } from "game-engine/types";

import { SkillId } from "game-files/common/ids";
import { createActionName } from ".";

//
// CREATE BASIC SCENE ACTION
//
type SetCurrentSkillReturn = () => Action_Skill;

export const startSkill: (skillId: SkillId) => SetCurrentSkillReturn =
  (skillId) => () => {
    return {
      _name: createActionName({
        name: `start skill`,
        value: skillId,
      }),
      id: ActionId.skill,
      skillId,
      skillActionType: SkillActionTypeEnum.StartSkill,
      blockGui: true,
      unskippable: true,
    };
  };

export const activateSkill: (skillId: SkillId) => SetCurrentSkillReturn =
  (skillId) => () => {
    return {
      _name: createActionName({
        name: `activate skill`,
        value: skillId,
      }),
      id: ActionId.skill,
      skillId,
      skillActionType: SkillActionTypeEnum.ActivateSkill,
      blockGui: true,
      unskippable: true,
    };
  };

export const stopSkill: (
  skillId: SkillId,
  options?: { appendStopActions?: boolean; prependStopActions?: boolean }
) => SetCurrentSkillReturn = (skillId, options) => () => {
  return {
    _name: createActionName({
      name: `stop skill`,
      value: skillId,
    }),
    id: ActionId.skill,
    skillId,
    skillActionType: SkillActionTypeEnum.StopSkill,
    appendStopActions: options?.appendStopActions,
    prependStopActions: options?.prependStopActions,
    blockGui: true,
    unskippable: true,
  };
};

export const stopAllSkills: (options?: {
  appendStopActions?: boolean;
  prependStopActions?: boolean;
}) => SetCurrentSkillReturn = (options) => () => {
  return {
    _name: createActionName({
      name: `stop all skills`,
    }),
    id: ActionId.skill,
    skillActionType: SkillActionTypeEnum.StopAllSkills,
    appendStopActions: options?.appendStopActions,
    prependStopActions: options?.prependStopActions,
    blockGui: true,
    unskippable: true,
  };
};

export const resetSkill: (skillId: SkillId) => SetCurrentSkillReturn =
  (skillId) => () => {
    return {
      _name: createActionName({
        name: `reset skill`,
        value: skillId,
      }),
      id: ActionId.skill,
      skillId,
      skillActionType: SkillActionTypeEnum.ResetSkill,
      blockGui: true,
      unskippable: true,
    };
  };
