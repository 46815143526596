import SCENES_LAYOUTS, {
  getSceneByUniqueId,
} from "game-files/scenes/SCENE_LAYOUTS";
import ScenesLayoutGrid, {
  ScenesLayoutGridHoverMode,
} from "../../../_dev/dev-components/ScenesLayoutGrid";
import styled, { css } from "styled-components";

import AnimateHeight from "react-animate-height";
import Box from "../../../_dev/basic-components/Box";
import ExpandButton from "../../../_dev/basic-components/ExpandButton";
import PutItemIntoCursorList from "game-engine/_dev/basic-components/PutItemIntoCursorList";
import SCENE_CONFIGS_DEV from "game-files/scenes/_DEVELOPMENT";
import SceneOverlay from "../../../_dev/scenes-layout-editor/components/EditorViewSceneLinks/SceneOverlay";
import { SceneType } from "game-engine/types";
import ScenesLayout from "../../../_dev/dev-components/ScenesLayout";
import devToolsConfig from "game-engine/configs/devtools-config";
import { getScenesLayoutBySceneId } from "game-engine/utils";
import useGame from "game-engine/hooks/useGame";
import { useState } from "react";

const CONNECTION_LINE_WIDTH = 4;
const CONNECTION_LINE_LENGTH = 20;

const Wrapper = styled.div<{ isOpen: boolean }>`
  width: 100%;
  position: relative;

  min-height: 4px;
  background: ${devToolsConfig.background};

  ${(props) =>
    props.isOpen &&
    css`
      flex: 1;
    `}
`;

const AnimateHeightStyled = styled(AnimateHeight)`
  width: 100%;
  flex: 1;
  display: flex;

  & > * {
    width: 100%;
    flex: 1;
    display: flex;
  }
`;

const Content = styled.div`
  flex: 1;
  padding: 10px;
  padding-bottom: 20px;
  background: ${devToolsConfig.background};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
`;

const SectionButton = styled.div`
  z-index: 50;
  position: absolute;
  bottom: calc(100% + 8px);
  right: 10px;
`;

const Footer = () => {
  const { gamePlay, gameFns } = useGame();

  const [sectionIsOpen, setSectionIsOpen] = useState(true);

  const setActiveScene = (scene: SceneType) => {
    gameFns.setCurrentScene({
      uniqueSceneId: scene.uniqueId,
      walkIn: undefined,
      isSetByDeveloper: true,
    });
  };

  const renderOverlay = (options: {
    row: number;
    column: number;
    isActive: boolean;
    isHovered: boolean;
    hoverColor: string;
    scene?: SceneType;
  }) => {
    const { scene } = options;

    if (!scene) {
      return null;
    }

    return (
      <SceneOverlay
        scene={scene}
        sceneLinks={SCENES_LAYOUTS.scenesLayoutsDef.sceneLinks}
        connectionLineLength={CONNECTION_LINE_LENGTH}
        onSceneLinkClick={({ neighborId }) => {
          const neighborScene = getSceneByUniqueId(neighborId);
          setActiveScene(neighborScene);
        }}
      />
    );
  };

  //
  // RENDER
  //
  return (
    <Wrapper isOpen={sectionIsOpen}>
      <AnimateHeightStyled duration={200} height={sectionIsOpen ? "auto" : 0}>
        <Content>
          <Box label="Scenes Layouts" childMinWidth="100%" level={1}>
            {SCENES_LAYOUTS.scenesLayouts.map((scenesLayout) => {
              return (
                <Box
                  key={scenesLayout.id}
                  label={scenesLayout.id}
                  childMinWidth="100%"
                  level={2}
                  isOpen={
                    scenesLayout.id ===
                    getScenesLayoutBySceneId({
                      sceneId: gamePlay.state.currentScene?.uniqueSceneId,
                      scenesLayouts: SCENES_LAYOUTS.scenesLayouts,
                    })?.id
                  }
                >
                  <ScenesLayout
                    scenesLayout={scenesLayout.neighborGrid}
                    onSceneClick={({ scene }) => setActiveScene(scene)}
                    onSceneClickFeedback="scene set in gamePlay"
                    activeSceneId={gamePlay.state.currentScene?.uniqueSceneId}
                    itemWidth={100}
                    hover={{ mode: ScenesLayoutGridHoverMode.item }}
                    noEventsForNulls
                    renderOverlay={renderOverlay}
                    connectionLineLength={CONNECTION_LINE_LENGTH}
                    connectionLineWidth={CONNECTION_LINE_WIDTH}
                  />
                </Box>
              );
            })}
          </Box>

          <Box label="Dev Scenes" level={1}>
            <ScenesLayoutGrid
              scenesLayout={[Object.values(SCENE_CONFIGS_DEV)]}
              onSceneClick={({ scene }) => setActiveScene(scene)}
              onSceneClickFeedback="scene set in gamePlay"
              activeSceneId={gamePlay.state.currentScene?.uniqueSceneId}
              itemWidth={100}
              hover={{ mode: ScenesLayoutGridHoverMode.item }}
              ignoreErrors
            />
          </Box>

          <Box label="Put Item Into Cursor" level={1}>
            <PutItemIntoCursorList />
          </Box>
        </Content>
      </AnimateHeightStyled>

      <SectionButton>
        <ExpandButton
          name="Scenes Layout"
          namePosition="left"
          isOpen={sectionIsOpen}
          onClick={() => setSectionIsOpen(!sectionIsOpen)}
        />
      </SectionButton>
    </Wrapper>
  );
};

export default Footer;
