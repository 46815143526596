/* eslint-disable react-hooks/exhaustive-deps */

import {
  CharacterConfigType,
  CharacterRenderType,
  ImageFilters,
  ImageType,
} from "game-engine/types";
import { getCharacterDimensions, getImageOffset } from "game-engine/utils";

import GAME_CONFIG from "game-files/gameConfig";
import { Group } from "react-konva";
import SpriteAnimated from "game-engine/components/basic-elements/SpriteAnimated";
import useGame from "game-engine/hooks/useGame";

const CharacterImageDefault = (props: {
  config: CharacterConfigType;
  imageFilters: ImageFilters;
  characterRender: CharacterRenderType;
  isActive?: boolean;
  onImagesLoaded: () => void;
}) => {
  const { config, imageFilters, characterRender, isActive } = props;
  const { engineConfig, logger } = useGame();

  const image: ImageType = characterRender.default.image;

  const { width, height } = getCharacterDimensions(characterRender);

  if (!image?.src) {
    logger.error(`default character image missing (${config.id})`, config);
    return null;
  }

  const onImageLoaded = () => {
    props.onImagesLoaded();
    logger.graphics(`${config?.id} default image loaded`, config);
  };

  //
  // RENDER
  //
  return (
    <Group opacity={isActive ? 1 : 0} listening={isActive}>
      <Group
        x={characterRender.default.offsetX || 0}
        y={characterRender.default.offsetY || 0}
      >
        <SpriteAnimated
          src={image.src}
          spriteConfig={
            characterRender.default.spriteConfig ?? {
              ...GAME_CONFIG.sprites.defaultSpriteConfig,
              frameWidth: characterRender.default.width,
              frameHeight: characterRender.default.height,
            }
          }
          loggerString={
            characterRender.default.spriteConfig
              ? `character default animation (${config.id})`
              : undefined
          }
          controlledAnimation={{
            playAnimation: isActive,
          }}
          renderOutline={engineConfig.state.renderCharacterOutline}
          renderFill={engineConfig.state.renderCharacterFill}
          outlineColor={engineConfig.state.characterOutlineColor}
          fillColor={engineConfig.state.characterFillColor}
          onImageLoaded={onImageLoaded}
          filters={imageFilters}
          isHidden={!isActive}
          position={getImageOffset({ width, height })} // needs to be here for src changes (this handles that new position is used only after the new image is loaded)
        />
      </Group>
    </Group>
  );
};

export default CharacterImageDefault;
