/* eslint-disable react-hooks/exhaustive-deps */

import {
  CharacterConfigType,
  CharacterDialogPosition,
  Position,
  TextVerticalAlign,
  TranslatedString,
} from "game-engine/types";
import { useEffect, useMemo, useState } from "react";

import DepthLine from "game-engine/components/basic-elements/DepthLine";
import FontMain from "game-engine/glyphs/FontMain";
import GAME_CONFIG from "game-files/gameConfig";
import { Group } from "react-konva";
import SceneOverlay from "../../SceneOverlay";
import Text from "game-engine/components/basic-elements/Text";
import { getCharacterDialogConfigByCurrentObjective } from "game-engine/utils/get-by-current-objective";
import { getPixelMatrixDataFromText } from "game-engine/utils";
import useGame from "game-engine/hooks/useGame";

const CharacterDialog = (props: {
  characterConfig: CharacterConfigType;
  characterPosition: Position;
  dialog: TranslatedString;
  color?: string;
}) => {
  const { characterConfig, characterPosition } = props;
  const { gamePlay, engineConfig, gameFns } = useGame();

  //
  // CHARACTER DIALOG CONFIG
  //
  const characterDialogConfig = useMemo(() => {
    return getCharacterDialogConfigByCurrentObjective({
      dataByCurrentObjective: characterConfig?.dialogConfig,
      currentObjective: gameFns.getCurrentObjective(),
      events: gameFns.getEvents(),
    });
  }, [characterConfig, gamePlay.state.currentObjective, gamePlay.state.events]);

  //
  // DIALOG TEXT
  //
  const [text, setText] = useState(gameFns.t(props.dialog));

  useEffect(() => {
    if (engineConfig.state.renderCharacterDialogLong) {
      setText(
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima dolore rem recusandae placeat voluptatibus quo, numquam minus veniam?"
      );
    } else if (engineConfig.state.renderCharacterDialogShort) {
      setText("This is a short text");
    } else {
      setText(gameFns.t(props.dialog));
    }
  }, [engineConfig.state, props.dialog]);

  //
  // DIALOG SETTINGS
  //
  const {
    aboveOffsetMax,
    aboveOffsetMin,
    offsetBelowMax,
    align,
    maxWidth,
    color,
    outlined,
    verticalPosition,
  } = {
    ...GAME_CONFIG.character.defaultDialogConfig,
    ...characterDialogConfig,
  };

  let textVerticalPosition = verticalPosition;

  //
  // TEXT DATA
  //
  const textData = useMemo(() => {
    const data = getPixelMatrixDataFromText({
      font: FontMain,
      text,
      maxWidthPx: maxWidth,
      outlined,
      align,
    });

    return data;
  }, [text, maxWidth, outlined, align]);

  //
  // POSITION ABOVE / BELOW CALCULATIONS
  //
  const positionAboveCharacterY = props.characterPosition.y - aboveOffsetMax;
  const positionAboveCharacterYMin = props.characterPosition.y - aboveOffsetMin;
  const positionBelowCharacterY = props.characterPosition.y - offsetBelowMax;
  const yPadding = GAME_CONFIG.character.defaultDialogConfig.paddingY;

  const yAbove = Math.max(positionAboveCharacterY, textData.height + yPadding);
  const yBelow = Math.min(
    positionBelowCharacterY,
    GAME_CONFIG.scene.dimensions.y - textData.height - yPadding
  );

  //
  // AUTO POSITION - VERTICAL
  //
  if (textVerticalPosition === CharacterDialogPosition.auto) {
    const textOverflowAboveLimit = positionAboveCharacterYMin - textData.height;
    if (textOverflowAboveLimit < 0) {
      // text is below min limit => switch to 'below'
      textVerticalPosition = CharacterDialogPosition.below;
    } else {
      textVerticalPosition = CharacterDialogPosition.above;
    }
  }

  //
  // AUTO POSITION - HORIZONTAL
  //
  const xCentered = characterPosition.x - Math.round(textData.width / 2);
  const xPadding = GAME_CONFIG.character.defaultDialogConfig.paddingX;

  const x = Math.max(
    xPadding,
    Math.min(
      xCentered,
      GAME_CONFIG.scene.dimensions.x - textData.width - xPadding
    )
  );

  //
  // FINAL POSITION
  //
  const dialogPosition = {
    x,
    y: textVerticalPosition === CharacterDialogPosition.above ? yAbove : yBelow,
  };

  const verticalAlign =
    textVerticalPosition === CharacterDialogPosition.above
      ? TextVerticalAlign.bottom
      : TextVerticalAlign.top;

  //
  // RENDER
  //
  return (
    <SceneOverlay>
      {textData?.matrix && text?.length ? (
        <Group
          position={{
            x: Math.round(dialogPosition?.x || 0),
            y: Math.round(dialogPosition?.y || 0),
          }}
        >
          <Text
            text={text}
            textData={textData}
            maxWidth={maxWidth}
            verticalAlign={verticalAlign}
            color={props.color ?? color}
          />
        </Group>
      ) : null}

      {engineConfig.state.renderCharacterDialogLimits && (
        <>
          <DepthLine
            color={engineConfig.state.characterDialogLimitIdealColor}
            position={{ x: 0, y: positionAboveCharacterY }}
          />

          <DepthLine
            color={engineConfig.state.characterDialogLimitMinColor}
            position={{ x: 0, y: positionAboveCharacterYMin }}
          />

          <DepthLine
            color={engineConfig.state.characterDialogLimitIdealColor}
            position={{ x: 0, y: positionBelowCharacterY }}
          />
        </>
      )}
    </SceneOverlay>
  );
};

export default CharacterDialog;
