import { ACTION } from "game-engine/utils";
import { CharacterOnSceneIdleType } from "game-engine/types";
import { GameObjective } from "game-files/gameObjectives";

const onSceneIdle: CharacterOnSceneIdleType = {
  actionsByCurrentObjective: {
    [GameObjective.GAME_START]: {
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // ON SCENE IDLE
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      actions: [
        ACTION.talk({
          dialog: [
            {
              en: "What a beautiful day for an adventure!",
              cz: "Jaký to pěkný den na dobrodružství!",
            },
          ],
        }),

        ACTION.talk({
          dialog: [
            {
              en: "Maybe I should keep going.",
              cz: "Měl bych se dát na cestu.",
            },
          ],
        }),

        ACTION.talk({
          dialog: [
            {
              en: "Where was I going again?",
              cz: "Kam jsem to měl vlastně namířeno?",
            },
          ],
        }),
      ],
    },
  },
};

export default onSceneIdle;
